import { ResponseSchema, SystemTypeCode } from '../response'
import { FieldEntities, generateFieldEntities } from './field.model'
import { FolderNameEntities } from '../response/folder-names'

export interface Schema {
    guid: string
    name: string
    is_system?: number
    system_object_type_code: SystemTypeCode | string
    revision: number
    fieldEntities: FieldEntities
    object_type_code?: string
    acl?: number
}

export function generateSchema(
    responseSchema: ResponseSchema,
    folderNames: FolderNameEntities,
): Schema {
    const { guid, is_system, name, revision, system_object_type_code, object_type_code, acl } =
        responseSchema
    return {
        guid,
        is_system,
        name,
        revision,
        system_object_type_code,
        object_type_code,
        fieldEntities: generateFieldEntities(responseSchema.field, folderNames),
        acl,
    }
}

export function isDataTableSchema(schema: Schema | ResponseSchema) {
    return schema.system_object_type_code === SystemTypeCode.TABLE
}

export function isBusinessSchema(schema: Schema | ResponseSchema) {
    return schema.system_object_type_code === SystemTypeCode.TABLE && !!schema.object_type_code
}

export function isFolderSchema(schema: Schema) {
    return schema.system_object_type_code === SystemTypeCode.FOLDER
}

export function isViewSchema(schema: Schema) {
    return schema.system_object_type_code === SystemTypeCode.VIEW
}

export function getDataTableSchemas(schemas: Schema[]): Schema[] {
    return schemas.filter((schema) => isDataTableSchema(schema))
}

export function getFolderSchema(schemas: Schema[]): Schema {
    return schemas.find((schema) => isFolderSchema(schema))!
}

export function getViewSchema(schemas: Schema[]): Schema {
    return schemas.find((schema) => isViewSchema(schema))!
}
