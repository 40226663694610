import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NotificationPageComponent } from './notification-page.component'
import { TranslocoModule } from '@ngneat/transloco'

@NgModule({
    declarations: [NotificationPageComponent],
    imports: [CommonModule, TranslocoModule],
    exports: [NotificationPageComponent],
})
export class NotificationPageModule {}
