import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core'
import { MatInput } from '@angular/material/input'
import { FormControl, FormGroup } from '@angular/forms'
import { SelectOption } from '../../../@core/models/response/select-object-options'
import { MatDialog } from '@angular/material/dialog'
import { IconDialogComponent } from './status-icon-dialog/icon-dialog.component'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-option-item',
    templateUrl: './option-item.component.html',
    styleUrls: ['./option-item.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionItemComponent implements OnInit, AfterViewInit {
    @Input()
    option!: SelectOption

    @Input()
    isSelected!: boolean

    @Input()
    isDropdown: boolean = false

    @Input()
    disableDelete = false

    formGroup!: FormGroup
    @Output() optionChanged = new EventEmitter<any>()
    @Output() deleteOption: EventEmitter<any> = new EventEmitter()
    @Output() changeIcon = new EventEmitter<any>()
    @Output() setDefault = new EventEmitter<any>()
    @ViewChild('optionLabelInput') optionLabelInput!: MatInput
    @ViewChild('optionItem') optionItem!: ElementRef
    @ViewChild('colorPicker') colorPicker!: ElementRef

    isFocused: boolean = false
    isActive: boolean = false
    isDefault: boolean = false
    isClosed: boolean = false

    constructor(public dialog: MatDialog) {}

    ngOnInit(): void {
        this.formGroup = new FormGroup({
            label: new FormControl<string>(this.option.label, { updateOn: 'blur' }),
            color: new FormControl<string>(this.option.color),
        })
        this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((value: SelectOption) => {
            this.optionChanged.next(value)
        })
        this.isActive = this.option.tags === 'active'
        this.isClosed = this.option.tags === 'close'
        this.isDefault = this.option.is_default === 1
    }

    ngAfterViewInit() {
        this.optionLabelInput.stateChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.isFocused = this.optionLabelInput.focused
        })
    }

    deleteStatus() {
        this.optionItem.nativeElement.remove()
        this.deleteOption.emit(this.option.guid)
    }

    setDefaultStatus() {
        this.setDefault.emit(this.option.guid)
    }

    openIconDialog() {
        const dialogRef = this.dialog.open<IconDialogComponent, string, string>(
            IconDialogComponent,
            {
                width: '400px',
                data: this.option.icon,
            },
        )

        dialogRef.afterClosed().subscribe((res: string | undefined) => {
            if (res) {
                this.changeIcon.emit(res)
            }
        })
    }

    openColorPicker() {
        this.colorPicker.nativeElement.click()
    }
}
