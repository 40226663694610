import { Component, Input } from '@angular/core'
import { Colors, Palettes } from '../models/colors'
import { startCase, upperFirst } from 'lodash-es'

export enum TbButtonAppearance {
    primary = 'primary',
    outlined = 'outlined',
    text = 'text',
    icon = 'icon',
    fab = 'fab',
}

@Component({
    selector: 'app-tb-button',
    templateUrl: './tb-button.component.html',
    styleUrls: ['./tb-button.component.sass'],
})
export class TbButtonComponent {
    @Input()
    appearance: keyof typeof TbButtonAppearance = TbButtonAppearance.primary

    @Input()
    color: string = Palettes.primary

    @Input()
    disabled!: boolean

    @Input()
    icon!: string

    DISABLED_COLOR = Colors.neutral4

    get bgColor() {
        return this.prepareClass('bg')
    }

    get borderColor() {
        return this.prepareClass('border')
    }

    get textColor() {
        return this.prepareClass('text')
    }

    get iconColor() {
        return this.disabled ? this.DISABLED_COLOR : this.color
    }

    getDark(color: string) {
        return `${color}Dark`
    }

    getLight(color: string) {
        return `${color}Light`
    }

    private prepareClass(prefix: string) {
        const color = this.disabled ? this.DISABLED_COLOR : this.color
        return color ? `${prefix}-new${upperFirst(color)}` : ''
    }
}
