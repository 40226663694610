import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { viewFeature } from './view.reducer'
import { ViewEffects } from './view.effects'

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(viewFeature),
        EffectsModule.forFeature([ViewEffects]),
    ],
})
export class ViewStoreModule {}
