<ng-container *transloco="let tCellTypesDropdownDialog; read: 'cell_types.dropdown_dialog'">
    <h2 *ngIf="isAddNew; else editField" mat-dialog-title>
        {{tCellTypesDropdownDialog('add_field')}}
    </h2>
    <ng-template #editField>
        <h2 mat-dialog-title>{{tCellTypesDropdownDialog('edit_field')}}</h2>
    </ng-template>
    <mat-dialog-content>
        <div *ngIf="isAddNew" class="w-100">
            <mat-form-field>
                <input
                    [formControl]="dropdownNameFormControl"
                    matInput
                    placeholder="{{tCellTypesDropdownDialog('dropdown_placeholder')}}"
                />
            </mat-form-field>
        </div>
        <app-dropdown-content
            (defaultChanged)="defaultChangedDialog($event)"
            (fieldChanged)="cellValueChangedDialog($event)"
            [activeStatus]="active"
            [defaultStatus]="default"
            [field]="field"
        ></app-dropdown-content>
    </mat-dialog-content>
    <mat-dialog-actions *transloco="let tButtons; read: 'buttons'">
        <button (click)="saveStatusSettings()" color="primary" mat-button>
            {{tButtons('apply')}}
        </button>
        <button (click)="closeDialog()" mat-button>{{tButtons('cancel')}}</button>
    </mat-dialog-actions>
</ng-container>
