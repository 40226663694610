import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TemplateContainerComponent } from './template-container/template-container.component'
import { ModalRenderComponent } from './modal-render-container/modal-render.component'

const componentsForExport = [TemplateContainerComponent, ModalRenderComponent]

@NgModule({
    declarations: [...componentsForExport],
    imports: [CommonModule],
    exports: [...componentsForExport],
})
export class ModalManagerModule {}
