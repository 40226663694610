<ng-container *transloco="let tTable; read: 'table'">
    <div
        *ngIf="!isFormOpened"
        class="w-100 text-size-xs text-muted new-item-btn flex-start-center mb-xxl position-relative"
    >
        <button (click)="isFormOpened = true" mat-button>
            <span class="material-icons text-size-sm"> add</span>
            {{tTable('new_record')}}
        </button>
    </div>

    <div
        *ngIf="isFormOpened"
        class="row-item justify-content-between flex-start-center h-50px w-100"
    >
        <div class="flex-start-center justify-content-between create-row">
            <mat-form-field>
                <input [appAutoFocus]="true" [formControl]="textFormControl" matInput required />
            </mat-form-field>
            <div class="flex-start-center">
                <div class="position-relative status-wrapper">
                    <app-watch
                        (cellValueChanged)="saveWatchCreateRecord($event)"
                        [fieldType]="fieldTypes[watchField.field_type_code]"
                        [field]="watchField"
                        [value]="watchValue"
                        class="flex-start-center text-center"
                    ></app-watch>
                </div>
                <div class="position-relative status-wrapper">
                    <app-status
                        (cellValueChanged)="saveStatusCreateRecord($event)"
                        [fieldType]="fieldTypes[statusField.field_type_code]"
                        [field]="statusField"
                        [value]="statusValue"
                        class="flex-start-center text-center"
                    ></app-status>
                </div>
                <div class="position-relative status-wrapper">
                    <app-assign
                        (cellValueChanged)="saveAssigneeCreateRecord($event)"
                        [fieldType]="fieldTypes[assigneeField.field_type_code]"
                        [field]="assigneeField"
                        [value]="assigneeValue"
                        class="flex-start-center text-center"
                    ></app-assign>
                </div>
                <div *ngIf="isMyFolder" class="position-relative status-wrapper">
                    <mat-select [formControl]="folderListControl">
                        <ng-container *ngFor="let folder of folderList">
                            <mat-option
                                (click)="newRecordDataUpdateOnFolderChange()"
                                [value]="folder"
                            >
                                {{folders[folder]!.name.value}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </div>

                <ng-container *transloco="let tButtons; read: 'buttons'">
                    <button
                        (click)="createRecord()"
                        [disabled]="textFormControl.invalid"
                        mat-button
                    >
                        {{tButtons('save')}}
                    </button>
                    <mat-icon (click)="resetForm()" class="cancel-button"> close</mat-icon>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
