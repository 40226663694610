<div
    class="sm:pt-4 sm:pb-6 sm:px-1 sm:w-screen max-w-[568px]"
    *transloco="let tSelectViews; read: 'select_views_options'"
>
    <div class="py-0 px-5 max-sm:px-0 mb-6 max-sm:pt-4 text-xl font-semibold text-primaryDark">
        <div class="flex">
            {{tSelectViews('select_views')}}
            <div class="flex absolute sm:top-[8px] top-[16px] sm:right-[6px] right-[12px]">
                <mat-icon
                    mat-dialog-close
                    class="flex items-center w-5 h-5 box-content p-0.5 text-xl leading-4 rounded hover:bg-primaryLight cursor-pointer"
                >
                    close
                </mat-icon>
            </div>
        </div>
        <div class="text-xs leading-4 font-normal text-primarySecondary">
            {{tSelectViews('select_views_description')}}
        </div>
    </div>

    <div
        class="overflow-auto sm:max-h-[calc(100vh-180px)] max-h-[calc(100vh-395px)] py-0 px-5 max-sm:px-0"
    >
        <!--        this section should be reused and all text which starts with $ should be dynamically inserted-->
        <div class="mb-10">
            <div class="mb-1 text-primaryDark">$Section name</div>
            <div class="flex max-xsm:flex-col max-xsm:gap-2 gap-4">
                <div
                    [class]="content.checked ? 'border-secondaryMain' : 'border-transparent'"
                    class="flex justify-between items-center flex-1 p-4 rounded-2xl border-2 border-solid shadow-light"
                >
                    <div class="flex items-center">
                        <mat-icon svgIcon="custom_table" class="mr-2"></mat-icon>
                        $Content type
                    </div>
                    <mat-slide-toggle #content></mat-slide-toggle>
                </div>
                <div
                    [class]="content1.checked ? 'border-secondaryMain' : 'border-transparent'"
                    class="flex justify-between items-center flex-1 p-4 rounded-2xl border-2 border-solid shadow-light"
                >
                    <div class="flex items-center">
                        <mat-icon svgIcon="custom_table" class="mr-2"></mat-icon>
                        $Content type1
                    </div>
                    <mat-slide-toggle #content1></mat-slide-toggle>
                </div>
            </div>
        </div>
    </div>

    <div
        class="flex justify-end mt-4 py-0 px-5 max-sm:px-0"
        *transloco="let tButtons; read: 'buttons'"
    >
        <button
            mat-dialog-close
            class="flex-1 sm:flex-none mr-4 py-1 px-5 rounded-lg font-semibold text-secondaryMain border border-secondaryMain border-solid sm:h-9 h-11 shadowedBtn"
        >
            {{tButtons('cancel')}}
        </button>
        <div class="flex sm:gap-1 gap-2 flex-1 sm:flex-none">
            <button
                class="w-full py-1 px-5 rounded-lg bg-secondaryMain font-semibold text-white sm:h-9 h-11 shadowedBtn"
            >
                {{tButtons('apply')}}
            </button>
        </div>
    </div>
</div>
