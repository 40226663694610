import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { catchError, concatMap, mergeMap, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { UpdateService } from '../../services'
import { AppState } from '../app.state'
import { onMessage, setError, setLoading } from '../common'
import {
    createField,
    createFieldSuccess,
    deleteField,
    deleteFieldSuccess,
    updateField,
    updateFieldSuccess,
} from './schema.actions'
import { Field, FieldInsertionOptions, ObjectResponseModel } from '../../models'
import { ViewService } from '../../services/view.service'

@Injectable()
export class SchemaEffects implements OnInitEffects {
    updateField$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateField),
            tap(() => {
                console.log('updateField$ Effect')
                this.store.dispatch(setLoading({ loading: true }))
            }),
            switchMap((action: { field: Field }) => {
                console.log('updateField$ Effect switchMap')
                return this.updateService.updateField(action.field).pipe(
                    mergeMap((data: ObjectResponseModel) => {
                        return [
                            updateFieldSuccess(),
                            onMessage({ response: data }),
                            setLoading({ loading: false }),
                        ]
                    }),
                )
            }),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        ),
    )

    createField$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createField),
            tap(() => {
                console.log('createField$ Effect')
                this.store.dispatch(setLoading({ loading: true }))
            }),
            concatMap((action: { field: Field; options?: FieldInsertionOptions }) => {
                console.log('createField$ Effect mergeMap')
                return this.updateService.createField(action.field).pipe(
                    tap((data: ObjectResponseModel) => {
                        if (action.options) {
                            console.log('createField$ Effect updateRecord')
                            this.viewService.updateViewOnCreateField(data, action.options)
                        }
                    }),
                    mergeMap((data: ObjectResponseModel) => {
                        return [
                            createFieldSuccess(),
                            onMessage({ response: data }),
                            setLoading({ loading: false }),
                        ]
                    }),
                )
            }),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        ),
    )

    deleteField$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteField),
            tap(() => {
                console.log('deleteField$ Effect')
                this.store.dispatch(setLoading({ loading: true }))
            }),
            concatMap((action: { guid: string }) => {
                console.log('updateField$ Effect switchMap')
                return this.updateService.deleteField(action.guid).pipe(
                    mergeMap((data: ObjectResponseModel) => {
                        return [
                            deleteFieldSuccess(),
                            onMessage({ response: data }),
                            setLoading({ loading: false }),
                        ]
                    }),
                )
            }),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        ),
    )

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private viewService: ViewService,
        private updateService: UpdateService,
    ) {}

    ngrxOnInitEffects(): Action {
        return { type: '[SchemaEffects]: Init' }
    }
}
