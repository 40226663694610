<div class="">
    <div class="flex-start-center justify-content-between">
        <h1>People</h1>
        <div class="flex-start-center">
            <button mat-icon-button>
                <mat-icon>sort</mat-icon>
            </button>
            <button mat-icon-button>
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
    <mat-list role="list">
        <mat-list-item
            (click)="selectContactById(contact.guid)"
            *ngFor="let contact of contacts"
            role="listitem"
        >
            <div class="flex-start-center w-100">
                <div class="user-avatar"></div>
                <div class="flex-column">
                    <div class="">{{contact.name.value}}</div>
                    <div class="">{{contact.email.value}}</div>
                </div>
            </div>
        </mat-list-item>
    </mat-list>
</div>
