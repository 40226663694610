import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MetaReducer, StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { environment } from '../../../environments/environment'
import { UserStoreModule } from './user/user-store.module'
import { FieldTypeStoreModule } from './field-type/field-type-store.module'
import { FolderStoreModule } from './folder'
import { CommonStoreModule } from './common'
import { SchemaStoreModule } from './schema'
import { RecordStoreModule } from './record'
import { ViewStoreModule } from './view'
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store'
import { EffectsModule } from '@ngrx/effects'
import { LogService } from '../services'
import { ConfigStoreModule } from './config'

const metaReducers: MetaReducer[] = [LogService.logStoreActions]

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forRoot(routerReducer, { metaReducers }),
        StoreRouterConnectingModule.forRoot(),
        EffectsModule.forRoot(),
        // Instrumentation must be imported after importing StoreModule (config is optional)
        CommonStoreModule,
        ConfigStoreModule,
        UserStoreModule,
        FieldTypeStoreModule,
        SchemaStoreModule,
        FolderStoreModule,
        ViewStoreModule,
        RecordStoreModule,

        !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    ],
})
export class RootStoreModule {}
