import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import {
    Cell,
    CreateFolderViews,
    CurrentUser,
    Folder,
    SolutionModel,
    SystemObjectTypes,
    View,
} from '../../../@core/models'
import { FormControl, FormGroup } from '@angular/forms'
import { combineLatest, filter, Observable } from 'rxjs'
import { debounceTime, map, take } from 'rxjs/operators'
import { CdkStep } from '@angular/cdk/stepper'
import { RoleEntities } from '../../../@core/models/response/role'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TranslocoService } from '@ngneat/transloco'
import { Dictionary } from '@ngrx/entity'
import {
    SchemaFacadeService,
    CommonFacadeService,
    UserFacadeService,
    ViewFacadeService,
} from '../../../@core/services/store-facade'
import { ModalContainerComponent } from '../../../@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalManagerService } from '../../../@components-library/tb-modal-manager/modal-manager.service'
import { ModalContainerDataToken } from '../../../@components-library/tb-modal-manager/modal-container-factory.service'

interface FormGroupValue {
    name: string
    color: string
    icon: string
    views: {
        [sot_guid: string]: {
            [view_type_code: string]: boolean | null
        }
    }
    roles: {
        [guid: string]: boolean
    }
}

export type FolderSettingsDialogResult = {
    folder: Partial<Folder>
    views?: CreateFolderViews
    acl?: { roles: string[] }
}

export type FolderSettingsDialogInput = { folder?: Folder; folderExample?: Partial<Folder> | null }

@UntilDestroy()
@Component({
    selector: 'app-create-folder-dialog-container',
    templateUrl: 'create-folder-dialog-content.component.html',
    styleUrls: ['create-folder-dialog-content.component.sass'],
})
export class CreateFolderDialogContainerComponent
    extends ModalContainerComponent
    implements OnInit
{
    defaultFolderName!: string
    formGroup!: FormGroup
    selectedView!: View
    solutionModel!: SolutionModel
    viewTypesNames!: string[]
    edit!: boolean
    formValue!: FormGroupValue
    formValueViewsTypes!: { typeName: string; viewsTypes: string[] }[]
    formValueRoles!: string[]
    roles!: RoleEntities
    rolesInArray!: { name: string; guid: string }[]
    user$ = this.userFacadeService.selectCurrentUser$
    showUser?: boolean
    @ViewChild('stepSummary') stepSummary!: CdkStep

    constructor(
        @Inject(ModalContainerDataToken) public dialogInput: FolderSettingsDialogInput,
        private translation: TranslocoService,
        private schemaFacadeService: SchemaFacadeService,
        private commonFacadeService: CommonFacadeService,
        private userFacadeService: UserFacadeService,
        private viewFacadeService: ViewFacadeService,
        modalManagerService: ModalManagerService,
    ) {
        super(modalManagerService)
    }

    ngOnInit(): void {
        combineLatest([
            this.commonFacadeService.selectSolution$,
            this.viewFacadeService.selectSelectedView$,
            this.commonFacadeService.selectRoles$,
            this.viewFacadeService.selectAllViews$,
            this.translation.selectTranslate('folder_create.folder_name_placeholder'),
        ])
            .pipe(untilDestroyed(this), take(1))
            .subscribe(
                ([solution, view, roles, allViews, translation]: [
                    SolutionModel,
                    View | undefined,
                    RoleEntities,
                    View[],
                    any,
                ]) => {
                    if (!solution || !roles) {
                        return
                    }

                    this.defaultFolderName = translation
                    this.selectedView = view ?? allViews[0]
                    this.solutionModel = solution
                    this.viewTypesNames = Object.keys(solution.setting)
                    this.roles = roles
                    this.rolesInArray = Object.keys(roles).map((guid) => {
                        return {
                            guid: guid,
                            name: roles[guid].name,
                        }
                    })
                    let views = Object.keys(this.solutionModel.setting).reduce(
                        (
                            res: {
                                [key: string]: FormGroup<{
                                    [key: string]: FormControl<boolean | null>
                                }>
                            },
                            type: string,
                        ) => {
                            res[type] = new FormGroup(
                                this.solutionModel.setting[type].view.reduce(
                                    (
                                        viewRes: { [key: string]: FormControl<boolean | null> },
                                        viewResType: string,
                                        currentIndex: number,
                                    ) => {
                                        viewRes[viewResType] = new FormControl<boolean | null>(
                                            !currentIndex,
                                        )
                                        return viewRes
                                    },
                                    {},
                                ),
                            )
                            return res
                        },
                        {},
                    )
                    const inputFolder = this.dialogInput.folder
                    let inputRoles: { [key: string]: boolean }
                    if (inputFolder) {
                        if (inputFolder.roles && inputFolder.roles.value) {
                            inputRoles = inputFolder.roles.value.split(',').reduce(
                                (acc, role) => ({
                                    ...acc,
                                    [role]: true,
                                }),
                                {},
                            )
                        }
                        this.edit = true
                    }
                    const rolesControl = Object.keys(this.roles).reduce(
                        (res: { [key: string]: FormControl<boolean | null> }, guid: string) => {
                            if (inputRoles && inputRoles[guid]) {
                                res[guid] = new FormControl<boolean | null>(inputRoles[guid])
                                return res
                            }
                            res[guid] = new FormControl<boolean | null>(false)
                            return res
                        },
                        {},
                    )
                    this.formGroup = new FormGroup({
                        name: new FormControl<string>(
                            inputFolder ? inputFolder.name.value : this.defaultFolderName,
                        ),
                        color: new FormControl<string>(
                            inputFolder ? inputFolder.color.value : '#FF0',
                        ),
                        icon: new FormControl<string>(
                            inputFolder ? inputFolder.icon.value : 'folder',
                        ),
                        views: new FormGroup(views),
                        roles: new FormGroup(rolesControl),
                    })
                    this.getFormValue()
                    this.formGroup.valueChanges
                        .pipe(untilDestroyed(this), debounceTime(10))
                        .subscribe(() => {
                            this.getFormValue()
                        })
                    if (this.edit) {
                        setTimeout(() => {
                            this.stepSummary.select()
                        }, 0)
                    }
                },
            )

        if (!this.dialogInput.folder && !this.dialogInput.folderExample) {
            this.getFolderExampleFromSchema()
                .pipe(untilDestroyed(this))
                .subscribe((example: Partial<Folder>) => {
                    this.dialogInput.folderExample = example
                })
        }
    }

    getFormValue() {
        this.formValue = this.formGroup.value
        console.log('this.formValue', this.formValue)
        this.formValueViewsTypes = Object.keys(this.formValue.views).map((type) => {
            return {
                typeName: type,
                viewsTypes: Object.keys(this.formValue.views[type]).filter(
                    (key) => !!this.formValue.views[type][key],
                ),
            }
        })
        this.formValueRoles = Object.keys(this.formValue.roles).filter(
            (role) => this.formValue.roles[role],
        )
        this.showUser = !this.formValueRoles.find((guid) => this.formValue.roles[guid])
    }

    onIconPickerSelect(icon: string): void {
        this.formGroup.controls.icon.setValue(icon)
    }

    selectedRoles() {
        return this.formValueRoles.reduce((acc, role) => `${acc} ${this.roles[role].name}`, '')
    }

    currentUserRole(currentUser: CurrentUser | null) {
        if (currentUser !== null) {
            return ` ${this.roles[currentUser.role.guid].name} is current user role`
        } else {
            return 'Unavailable user'
        }
    }

    saveFolderSettings() {
        const value: FormGroupValue = this.formGroup.value
        const folder: Partial<Folder> = this.edit
            ? this.dialogInput.folder!
            : this.dialogInput.folderExample!
        let dialogResult: FolderSettingsDialogResult | undefined
        const updatedFolder = Object.keys(value).reduce(
            (res: Partial<Folder>, fieldName: string) => {
                if (fieldName !== 'views') {
                    const cell = <Cell>folder[fieldName as keyof Folder]
                    if (
                        !this.edit ||
                        (cell && value[fieldName as keyof FormGroupValue] !== cell.value)
                    ) {
                        if (fieldName !== 'roles') {
                            ;(<Cell>res[fieldName as keyof Folder]) = {
                                ...cell,
                                value: value[fieldName as keyof FormGroupValue].toString(),
                            }
                        }
                    }
                }
                return res
            },
            {},
        )

        if (this.edit) {
            if (Object.keys(updatedFolder).length) {
                updatedFolder.guid = folder.guid
                updatedFolder.revision = folder.revision
                updatedFolder.schemaGuid = folder.schemaGuid
                dialogResult = { folder: updatedFolder }
            }
        } else {
            console.log('value.views', value.views)

            dialogResult = {
                folder: updatedFolder,
                views: value.views,
                acl: { roles: this.formValueRoles },
            }
        }
        this.close(dialogResult)
    }

    closeFolderSettings() {
        this.close()
    }

    private getFolderExampleFromSchema(): Observable<Partial<Folder>> {
        return this.schemaFacadeService.selectSchemaBySystemObjectTypeCode$('folder').pipe(
            filter(Boolean),
            map((schema) => {
                const folderCells: Dictionary<Cell> = Object.keys(schema.fieldEntities).reduce(
                    (cells, guid) => ({
                        ...cells,
                        [schema.fieldEntities[guid].name as string]: {
                            fieldGuid: guid,
                            fieldName: schema.fieldEntities[guid].name,
                            value: '',
                            revision: 0,
                        },
                    }),
                    {},
                )

                return {
                    type: SystemObjectTypes.Folder,
                    name: folderCells['name'] as Cell,
                    is_global: folderCells['is_global'] as Cell,
                    icon: folderCells['icon'] as Cell,
                    color: folderCells['color'] as Cell,
                    roles: folderCells['roles'] as Cell,
                    guid: '',
                    schemaGuid: schema.guid,
                    revision: 0,
                    noNameCells: {},
                }
            }),
        )
    }
}
