import { Component, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { FilterTypesComponent } from '../filter-types.component'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-number-filter',
    templateUrl: './number-filter.component.html',
    styleUrls: ['./number-filter.component.sass'],
})
export class NumberFilterComponent extends FilterTypesComponent implements OnInit {
    numberFormControl!: FormControl<string | string[] | undefined | null>

    ngOnInit(): void {
        this.numberFormControl = this.form.controls['value'] as FormControl
    }
}
