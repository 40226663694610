import { ResponseFieldEntities, ResponseSchema } from './schema.model'
import { CurrentUser, UserModelEntities } from './user.model'
import { ResponseFieldTypeEntities } from './field-type.model'
import { TableModel } from './table.model'
import { SolutionModel } from './solution.model'
import { GroupEntities } from './group'
import { RoleEntities } from './role'
import { FolderNameEntities } from './folder-names'
import { LinkEntities } from './link'
import { ActionTypeModel } from './action-type.model'
import { SourceTypeModel } from './source-type.model'
import { AutomationEntities } from './automation.model'

export interface ObjectResponseModel {
    code: string
    data: DataItem[]
    user?: CurrentUser
    error?: []
    status: string
}

export enum ObjectType {
    TABLE = 'table',
    USER = 'user',
    SCHEMA = 'schema',
    FIELD_TYPE = 'field_type',
    SOLUTION = 'solution',
    GROUP = 'group',
    ROLE = 'role',
    SYSTEM_FIELDS = 'system_fields',
    FOLDER = 'folder',
    LINK = 'link',
    ACTION_TYPE = 'action_type',
    AUTOMATION = 'automation',
    SOURCE_TYPE = 'source_type',
}

export type DataItemObject =
    | UserModelEntities
    | GroupEntities
    | FolderNameEntities
    | RoleEntities
    | LinkEntities
    | ResponseFieldTypeEntities
    | SolutionModel
    | ResponseSchema
    | TableModel
    | ResponseFieldEntities
    | ActionTypeModel
    | SourceTypeModel
    | AutomationEntities

export interface DataItem<T extends DataItemObject = DataItemObject> {
    type: ObjectType | string
    object: T
}
