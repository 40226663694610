<ng-container *ngIf="selectedFolder$ | async as selectedFolder">
    <ng-container *ngIf="folders$ | async as folders">
        <!--        TODO: we should implement this "close bottomsheet" button somewhere in modal manager in order to avoid its duplication in bodies of each menu or modal -->
        <div class="flex sm:hidden items-center justify-center p-4 cursor-pointer">
            <div class="w-8 h-1 rounded bg-primarySecondary"></div>
        </div>
        <app-folder
            *ngIf="folders.global"
            [folder]="folders.global"
            [selectedFolderGuid]="selectedFolder.guid"
            (selectFolder)="selectFolder($event)"
            (edit)="openFolderDialog(folders.global)"
            [canDelete]="false"
        ></app-folder>
        <mat-divider
            class="max-sm:mt-4 mb-4 sm:mb-2 h-0.5 bg-primaryLight border-primaryLight"
        ></mat-divider>
        <app-folder
            *ngFor="let folder of folders.nonGlobal"
            [folder]="folder"
            [selectedFolderGuid]="selectedFolder.guid"
            (selectFolder)="selectFolder($event)"
            (edit)="openFolderDialog(folder)"
            (delete)="deleteFolder(folder)"
        ></app-folder>

        <button
            *transloco="let tButtons; read 'buttons'"
            (click)="openFolderDialog(undefined, folders.global)"
            class="flex items-center justify-center w-100 w-fit h-7 py-3 p-1 rounded-lg text-secondaryMainLight hover:text-secondaryMain"
        >
            <!--Todo: // [desing-ref] icon-->
            <mat-icon class="material-icons w-fit h-fit text-xl leading-5 mr-xs"
                >add_circle_outline</mat-icon
            >
            <span class="font-semibold">{{tButtons('add_workspace')}}</span>
        </button>

        <button
            *transloco="let tButtons; read 'buttons'"
            (click)="openFolderDialogNew(folders.global)"
            class="flex items-center justify-center w-100 w-fit h-7 py-3 p-1 rounded-lg text-secondaryMainLight hover:text-secondaryMain"
        >
            <!--Todo: // [desing-ref] icon-->
            <mat-icon class="material-icons w-fit h-fit text-xl leading-5 mr-xs"
                >add_circle_outline</mat-icon
            >
            <span class="font-semibold">{{tButtons('add_workspace')}}(Styles)</span>
        </button>
    </ng-container>
</ng-container>
