import { Component, Input, OnInit } from '@angular/core'
import {
    AppRecord,
    BusinessRecords,
    Cell,
    FieldEntities,
    FieldType,
    Folder,
    isDeal,
    RecordUpdate,
    Schema,
    User,
} from '../../../@core/models'
import { Dictionary } from '@ngrx/entity'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { RecordsService } from '../../../@core/services/records.service'
import { UserFacadeService } from '../../../@core/services/store-facade'

@UntilDestroy()
@Component({
    selector: 'app-card-title',
    templateUrl: './card-title.component.html',
    styleUrls: ['./card-title.component.sass'],
})
export class CardTitleComponent implements OnInit {
    @Input()
    fields!: FieldEntities

    @Input()
    record!: BusinessRecords

    @Input()
    schema!: Schema

    @Input()
    fieldTypes!: Dictionary<FieldType>

    @Input()
    folder!: Folder

    cardTitle!: string
    user!: User | undefined
    users: User[] = []

    constructor(
        private recordsService: RecordsService,
        private userFacadeService: UserFacadeService,
    ) {}

    isDeal = isDeal

    ngOnInit(): void {
        console.log('this.record', this.record)
        this.userFacadeService.selectUsers$.pipe(untilDestroyed(this)).subscribe((users) => {
            if (users) {
                this.users = users
            }
        })

        this.user = this.users.find(
            (user) => user.guid === this.record.assignee[this.folder.guid].value,
        )

        this.cardTitle = this.record.name.value
    }

    updateRecord(data: AppRecord, cell: Cell, newValue: any) {
        const dataRecord: RecordUpdate = {
            record: data,
            cell: cell,
            value: newValue,
        }
        this.recordsService.updateRecord(dataRecord)
    }
}
