import { Component, ComponentRef, Inject, ViewContainerRef, Optional, OnInit } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Subject } from 'rxjs'
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

type ComponentData = { renderComponent$: Subject<ComponentRef<unknown>> }

@UntilDestroy()
@Component({
    selector: 'app-modal-render-container',
    template: '',
})
export class ModalRenderComponent implements OnInit {
    renderedComponent: ComponentRef<unknown> | null = null

    constructor(
        @Optional()
        @Inject(MAT_BOTTOM_SHEET_DATA)
        public bottomSheetData: ComponentData,

        @Optional()
        @Inject(MAT_DIALOG_DATA)
        public dialogData: ComponentData,

        private viewContainerRef: ViewContainerRef,
    ) {}

    ngOnInit(): void {
        const renderComponent$ =
            this.bottomSheetData?.renderComponent$ || this.dialogData?.renderComponent$

        if (!renderComponent$) return

        renderComponent$.pipe(untilDestroyed(this)).subscribe((componentRef) => {
            if (this.renderedComponent) {
                this.viewContainerRef.detach()
            }
            this.renderedComponent = componentRef
            this.viewContainerRef.insert(componentRef.hostView)
        })
    }
}
