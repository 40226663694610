import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import {
    addConfigFromResponse,
    AppState,
    createConfigs,
    deleteConfigFromResponse,
    deleteConfigs,
    setConfigs,
    updateConfigFromResponse,
    updateConfigs,
} from '../../@ngrx'
import { Config, ConfigUpdateModel } from '../../models/ui/config.model'
import { Deleted } from '../../models'
import { Update } from '@ngrx/entity'
import { selectAllConfigs, selectConfigEntities } from '../../@ngrx/config/config.selector'

@Injectable({
    providedIn: 'root',
})
export class ConfigFacadeService {
    constructor(private store: Store<AppState>) {}

    selectConfigEntities$ = this.store.select(selectConfigEntities)

    selectAllConfigs$ = this.store.select(selectAllConfigs)

    setConfig(configs: Config[]) {
        this.store.dispatch(setConfigs({ configs }))
    }

    createConfig(configs: Map<string, string>) {
        this.store.dispatch(createConfigs({ configs }))
    }

    updateConfig(configs: ConfigUpdateModel) {
        this.store.dispatch(updateConfigs({ configs }))
    }

    deleteConfig(configs: ConfigUpdateModel) {
        this.store.dispatch(deleteConfigs({ configs }))
    }

    addConfigFromResponse(config: Config) {
        this.store.dispatch(addConfigFromResponse({ config }))
    }

    updateConfigFromResponse(config: Update<Config>[]) {
        this.store.dispatch(updateConfigFromResponse({ config }))
    }

    deleteConfigFromResponse(configs: Deleted[]) {
        this.store.dispatch(deleteConfigFromResponse({ configs }))
    }
}
