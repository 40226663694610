<div class="list-container flex-start-center align-items-start">
    <div>
        <app-list-contacts
            (selectContact)="selectContact($event)"
            [contacts]="records"
        ></app-list-contacts>
    </div>
    <div>
        <app-list-card
            *ngIf="selectedRecord"
            [record]="selectedRecord"
            [cells]="cells[selectedRecord.guid]"
        ></app-list-card>
    </div>
</div>
