import { NgModule } from '@angular/core'
import { UserSettingsComponent } from './user-settings.component'
import { UserSettingsContentComponent } from './user-settings-content/user-settings-content.component'
import { VersionComponent } from './version/version.component'

import { MatMenuModule } from '@angular/material/menu'
import { CommonAppModule } from '../common/common.module'
import { MatIconModule } from '@angular/material/icon'
import { MatDividerModule } from '@angular/material/divider'
import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common'
import { TranslocoModule } from '@ngneat/transloco'

@NgModule({
    declarations: [UserSettingsComponent, UserSettingsContentComponent, VersionComponent],
    imports: [
        MatMenuModule,
        CommonAppModule,
        MatIconModule,
        MatDividerModule,
        AsyncPipe,
        NgIf,
        TranslocoModule,
        NgTemplateOutlet,
    ],
    exports: [UserSettingsComponent, UserSettingsContentComponent, VersionComponent],
})
export class UserSettingsModule {}
