import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SearchComponent } from './search.component'
import { MatIconModule } from '@angular/material/icon'
import { SearchDialogComponent } from './search-dialog/search-dialog.component'
import { MatInputModule } from '@angular/material/input'
import { ReactiveFormsModule } from '@angular/forms'
import { MatTabsModule } from '@angular/material/tabs'
import { SearchResultsComponent } from './search-results/search-results.component'
import { SearchResultsItemComponent } from './search-results/search-results-item/search-results-item.component'
import { MatListModule } from '@angular/material/list'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { TranslocoModule } from '@ngneat/transloco'

@NgModule({
    declarations: [
        SearchComponent,
        SearchDialogComponent,
        SearchResultsComponent,
        SearchResultsItemComponent,
    ],
    exports: [SearchComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatListModule,
        MatButtonToggleModule,
        TranslocoModule,
    ],
})
export class SearchModule {}
