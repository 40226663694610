import { createAction, props } from '@ngrx/store'
import { Field, FieldInsertionOptions, Schema } from '../../models'
import { Update } from '@ngrx/entity'

export const initSchemas = createAction('[Schema] Init schemas', props<{ schemas: Schema[] }>())
export const setSelectedTableSchemaGuid = createAction(
    '[Schema] Set selected schema',
    props<{ selectedSchemaGuid: string | null }>(),
)
export const updateSchema = createAction(
    '[Update Schema WS] UPDATE_SCHEMA',
    props<{ schema: Update<Schema> }>(),
)

export const updateSchemas = createAction(
    '[Update Schemas WS] UPDATE_SCHEMA',
    props<{ schemas: Update<Schema>[] }>(),
)

export const updateField = createAction('[Update Field] UPDATE_FIELD', props<{ field: Field }>())

export const updateFieldSuccess = createAction('[Update Field Success Effect] UPDATE_FIELD_SUCCESS')

export const createField = createAction(
    '[Create Field] CREATE_FIELD',
    props<{ field: Field; options?: FieldInsertionOptions }>(),
)

export const createFieldSuccess = createAction('[Create Field Success Effect] CREATE_FIELD_SUCCESS')

export const deleteField = createAction('[Delete Field] DELETE_FIELD', props<{ guid: string }>())

export const deleteFieldSuccess = createAction('[Delete Field Success Effect] DELETE_FIELD_SUCCESS')
