<mat-tab-group #menuTabs [selectedIndex]="activeTab" animationDuration="0ms">
    <!--    todo: [table-ref] why is it tab??-->
    <mat-tab label="Add new">
        <ng-container *ngIf="fieldTypes$ | async as types">
            <button
                (click)="openAddColumnDialog(type.guid)"
                *ngFor="let type of types"
                mat-menu-item
            >
                <span class="material-icons mr-sm text-muted"> {{type.icon}} </span>
                <span> {{type.name}} </span>
            </button>
        </ng-container>
    </mat-tab>
</mat-tab-group>
