import { NgModule } from '@angular/core'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { TbIconComponent } from './tb-icon.component'
import { svgIcons } from './tb-icon.model'
import { forEach } from 'lodash-es'
import { CommonModule } from '@angular/common'

@NgModule({
    declarations: [TbIconComponent],
    imports: [MatIconModule, CommonModule],
    exports: [TbIconComponent],
})
export class TbIconModule {
    private path = '/assets/img/'
    private iconSet = svgIcons

    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
        forEach(this.iconSet, (file, name) => {
            matIconRegistry.addSvgIcon(name, this.getUrl(file))
        })
    }

    private getUrl(icon: string, path: string = this.path) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(`${path}${icon}`)
    }
}
