import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { folderFeature } from './folder.reducer'
import { FolderEffects } from './folder.effects'

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(folderFeature),
        EffectsModule.forFeature([FolderEffects]),
    ],
})
export class FolderStoreModule {}
