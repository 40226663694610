import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Field, Folder } from '../../../../@core/models'

@Component({
    selector: 'app-show-item',
    templateUrl: './show-item.component.html',
    styleUrls: ['./show-item.component.sass'],
})
export class ShowItemComponent implements OnInit {
    @Input()
    field!: Field

    @Input()
    selectedFolder!: Folder

    @Input()
    isShow!: boolean

    @Output() checkItem: EventEmitter<any> = new EventEmitter()
    checkItemControl!: FormControl<boolean | null>

    ngOnInit(): void {
        this.checkItemControl = new FormControl(this.isShow)
    }

    checkItemField() {
        this.checkItem.emit(this.checkItemControl.value)
    }
}
