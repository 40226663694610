import { Component, OnDestroy } from '@angular/core'
import { ViewFacadeService } from '../../@core/services/store-facade'
import { SchemaFacadeService } from '../../@core/services/store-facade'

@Component({
    selector: 'app-view-page',
    templateUrl: './view-page.component.html',
    styleUrls: ['./view-page.component.sass'],
})
export class ViewPageComponent implements OnDestroy {
    constructor(
        private schemaFacadeService: SchemaFacadeService,
        private viewFacadeService: ViewFacadeService,
    ) {}

    ngOnDestroy(): void {
        this.schemaFacadeService.setSelectedTableSchemaGuid(null)
        this.viewFacadeService.setSelectedViewGuid(null)
    }
}
