<div mat-dialog-content>
    <app-cell-container
        (cellValueChanged)="updateRecord(record, cells[record.guid][field.guid], $event)"
        [fieldTypes]="fieldTypes"
        [field]="field"
        [guid]="field.guid"
        [record]="record"
        [value]="newValue"
        class="flex-start-center text-center"
    ></app-cell-container>
</div>
<div mat-dialog-actions>
    <div
        class="flex-start-center w-100 mt-xxl justify-content-end"
        *transloco="let tButtons; read: 'buttons'"
    >
        <button (click)="closeEditField()" mat-button>{{tButtons('cancel')}}</button>
        <button (click)="applyEditField()" mat-button type="submit">{{tButtons('apply')}}</button>
    </div>
</div>
