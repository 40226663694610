<div
    class="list-container w-100 flex-start-center align-items-start"
    *transloco="let tListCardComponent; read: 'list.list_card.list_card_component'"
>
    <div>
        <div class="contact-avatar"></div>
        <div class="">{{ record.email.value }}</div>
        <div class="flex-start-center">
            <a href="mailto:{{ record.email.value }}" mat-icon-button>
                <mat-icon>alternate_email</mat-icon>
            </a>
            <button [matMenuTriggerFor]="actionMenu" mat-icon-button>
                <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
                <ng-template matMenuContent>
                    <menu mat-menu-item>{{tListCardComponent('edit')}}</menu>
                </ng-template>
            </mat-menu>
        </div>
        <div class="activity mt-lg">
            <h2>{{tListCardComponent('contact_activity')}}</h2>
            <mat-button-toggle-group aria-label="Font Style" name="fontStyle">
                <mat-button-toggle (click)="sortActivities()" [checked]="isGrouped" value="sort"
                    >{{tListCardComponent('sort')}}
                </mat-button-toggle>
                <mat-button-toggle (click)="unSortActivities()" [checked]="!isGrouped" value="list"
                    >{{tListCardComponent('list')}}
                </mat-button-toggle>
            </mat-button-toggle-group>
            <ng-container *ngIf="isGrouped; else unsorted">
                <app-card-activity
                    *ngFor="let activityGroup of contactActivitiesSortedKeys"
                    [groupActivities]="contactActivitiesGrouped[activityGroup]"
                >
                </app-card-activity>
            </ng-container>
            <ng-template #unsorted>
                <app-card-activity-item
                    *ngFor="let activity of contactActivities"
                    [activity]="activity"
                >
                </app-card-activity-item>
            </ng-template>
        </div>
    </div>
    <div class="sub-info">
        <h2>{{tListCardComponent('contact_info')}}</h2>
        <app-card-info [record]="record" [cells]="cells"></app-card-info>
    </div>
</div>
