import { Component, OnInit } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-sort-menu',
    templateUrl: './sort-menu.component.html',
    styleUrls: ['./sort-menu.component.sass'],
})
export class SortMenuComponent {
    //change for proper logic
    applyForSession: boolean = false
    saved: boolean = false
    sortParams: Array<any> = [{ name: 'test' }, { name: 'test' }, { name: 'test' }]

    constructor() {}

    onApplyForSession() {
        this.applyForSession = !this.applyForSession
    }
    onSaveSort() {
        this.saved = !this.saved
    }
    onClear() {
        this.sortParams = []
    }
}
