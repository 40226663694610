import { ValueJson } from '../ui'

export interface TableModel {
    guid: string
    record?: ResponseRecordEntities
    updated?: ResponseRecordEntities
    added?: ResponseRecordEntities
    deleted?: Deleted[]
    link?: {
        [guid: string]: {
            cell: { [guid: string]: string[] }
        }
    }
}

export interface Deleted {
    guid: string
}

export interface ResponseRecordEntities {
    [guid: string]: ResponseRecord
}

export interface ResponseRecord {
    revision: number
    parent_sot_guid?: string
    folders_guid?: string
    cells: ResponseCellValueEntities
}

export interface ResponseCellValueEntities {
    [field_guid: string]: ResponseCellValue
}

export interface ResponseCellValue {
    value?: string
    value_json?: ValueJson
    revision: number
}
