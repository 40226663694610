<div class="sm:mt-2 sm:py-2 rounded-lg" *transloco="let tViewList; read: 'view_list'">
    <div class="mb-6 text-xl h-[30px] font-semibold text-primaryDark">{{tViewList('views')}}</div>
    <div (click)="navigate(itemView)" *ngFor="let itemView of views">
        <div class="flex items-center mb-4 sm:mb-2 sm:allViewHolder">
            <button class="flex">
                <mat-icon class="sm:w-4 w-5 text-primarySecondary" svgIcon="custom_drag"></mat-icon>
            </button>

            <div
                class="flex items-center w-100 max-sm:h-12 sm:mr-4 max-sm:p-2 py-1 sm:rounded rounded-lg cursor-pointer max-sm:bg-primaryBackground hover:bg-primaryBackground"
            >
                <div
                    [class.text-secondaryMain]="IsSelected(itemView)"
                    class="flex items-center max-sm:h-7 sm:px-[7px] font-semibold"
                >
                    <mat-icon class="sm:h-4 h-6 sm:w-4 w-6 sm:text-base sm:leading-4 mr-2"
                        >{{itemView.icon}}</mat-icon
                    >
                    <span class="mr-7 leading-4">{{itemView.name.value}}</span>
                    <mat-icon
                        *ngIf="IsSelected(itemView)"
                        class="items-center justify-content-center mr-[-6px] ml-auto text-secondaryMain text-lg leading-5"
                        >check</mat-icon
                    >
                    <button
                        [class.visible]="trigger.menuOpen"
                        class="hidden sm:flex absolute right-[20px] flex"
                        (click)="$event.stopPropagation()"
                        #trigger="matMenuTrigger"
                        [matMenuTriggerFor]="itemViewMenu"
                    >
                        <mat-icon
                            class="rounded bg-primaryBackground sm:h-4 h-6 sm:w-4 w-6 sm:text-base sm:leading-4 text-inherit max-sm:text-primaryDark sm:hover:text-secondaryMain sm:hover:bg-white allViewsOptions"
                            >more_vert</mat-icon
                        >
                    </button>
                    <button
                        [class.visible]="trigger.menuOpen"
                        class="flex sm:hidden absolute right-[20px] flex"
                        (click)="openBottomSheetEdit($event)"
                    >
                        <mat-icon
                            class="rounded bg-primaryBackground sm:h-4 h-6 sm:w-4 w-6 sm:text-base sm:leading-4 text-inherit max-sm:text-primaryDark sm:hover:text-secondaryMain sm:hover:bg-white allViewsOptions"
                            >more_vert</mat-icon
                        >
                    </button>
                </div>
            </div>
        </div>

        <mat-menu #itemViewMenu="matMenu">
            <ng-template matMenuContent #templateBottomSheetEdit>
                <div class="text-primaryDark" *transloco="let tViewEdit; read: 'view_edit'">
                    <div class="sm:hidden">
                        <div class="flex items-center mb-6 cursor-pointer">
                            <mat-icon class="h-5 w-5 mr-2 text-3xl leading-5"
                                >chevron_left</mat-icon
                            >
                            <div class="font-semibold">{{tViewEdit('back')}}</div>
                        </div>

                        <app-views-rename [viewName]="itemView.name.value"></app-views-rename>
                    </div>

                    <div class="dropdown-holder max-sm:mt-6">
                        <span class="sm:hidden">
                            <div class="mb-2">{{tViewEdit('view_options')}}</div>
                        </span>
                        <ng-container *transloco="let tButtons; read: 'buttons'">
                            <div class="max-sm:hidden">
                                <mat-icon svgIcon="custom_edit"></mat-icon>
                                <span> {{tButtons('rename')}} </span>
                            </div>
                            <div>
                                <mat-icon svgIcon="custom_copy"></mat-icon>
                                <span> {{tButtons('duplicate')}} </span>
                            </div>
                            <div>
                                <mat-icon svgIcon="custom_share"></mat-icon>
                                <span> {{tButtons('share')}} </span>
                            </div>

                            <mat-divider
                                class="max-sm:hidden mb-2 h-0.5 border-primaryLight bg-primaryLight"
                            ></mat-divider>

                            <div class="mb-1">
                                <mat-icon class="text-red-200" svgIcon="custom_trash"></mat-icon>
                                <span class="text-red-600">{{tButtons('delete')}}</span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-template>
        </mat-menu>
    </div>

    <button
        (click)="$event.stopPropagation()"
        [matMenuTriggerFor]="menuViewAdd"
        class="flex sm:hidden items-center justify-center w-100 h-11 py-3 px-6 rounded-lg border border-solid border-secondaryMainLight text-secondaryMainLight hover:text-secondaryMain"
    >
        <mat-icon class="material-icons w-fit h-fit text-xl leading-5 mr-xs"
            >add_circle_outline</mat-icon
        >
        <span class="font-semibold">{{tViewList('create_view.add_view')}}</span>
    </button>
    <mat-menu #menuViewAdd="matMenu">
        <ng-template matMenuContent>
            <app-create-view [selectedView]="selectedView"></app-create-view>
        </ng-template>
    </mat-menu>
</div>
