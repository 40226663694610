import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    selector: 'app-icon-dialog',
    templateUrl: './icon-dialog.component.html',
    styleUrls: ['./icon-dialog.component.sass'],
})
export class IconDialogComponent implements OnInit {
    iconList!: string[]
    activeIcon!: string

    constructor(
        public dialogRef: MatDialogRef<IconDialogComponent, string | undefined>,
        @Inject(MAT_DIALOG_DATA) public data: string,
    ) {}

    ngOnInit(): void {
        this.activeIcon = this.data
        this.iconList = [
            'copyright',
            'change_history',
            'contact_support',
            'check_circle_outline',
            'commute',
        ]
    }

    selectIcon(icon: string) {
        this.dialogRef.close(icon)
    }
}
