<div
    class="sm:h-7 h-8 sm:bg-transparent bg-white rounded text-primarySecondary hover:text-primaryDark ml-4 sm:mr-0 mr-8"
>
    <div
        *transloco="let t"
        [matMenuTriggerFor]="fieldsMenu"
        [ngClass]="{'bg-white text-primaryDark': trigger.menuOpen}"
        #trigger="matMenuTrigger"
        class="flex-start-center h-100 c-pointer sm:p-1 p-2 pr-2 rounded-lg"
    >
        <div
            [class.text-accentContrastLight]="filterGroups && filterGroups.length !== 0"
            class="flex items-center"
        >
            <ng-container *ngIf="filterGroups && filterGroups.length === 0; else filterPrimary">
                <span class="material-icons w-5 h-5 text-xl leading-5 mr-2"> edit_note </span>
                <span class="font-semibold">{{t('manage_fields')}}</span>
            </ng-container>
            <ng-template #filterPrimary>
                <span class="material-icons w-5 h-5 text-xl leading-5 mr-2"> edit_note </span>
                <span class="font-semibold mr-2">{{t('manage_fields')}}</span>
                <span
                    class="max-sm:hidden flex items-center justify-center w-4 h-4 text-xs leading-3 rounded-full bg-accentContrastLight text-white"
                >
                    {{filterCountText}}
                </span>
            </ng-template>
        </div>
    </div>
    <mat-menu #fieldsMenu="matMenu" class="filter" xPosition="before">
        <ng-template matMenuContent>
            <app-filter-menu
                [filterGroups]="filterGroups"
                [selectedView]="selectedView"
            ></app-filter-menu>
        </ng-template>
    </mat-menu>
</div>
