<div
    *ngIf="!disabled, else disabledContent"
    [matMenuTriggerFor]="statusMenu"
    [ngStyle]="{'background':getTitleColor(value),'color': getTitleColor(value)|getContrastColor}"
    class="c-pointer status-title_wrap"
>
    <mat-icon class="status-icon"> {{getIcon(value)}} </mat-icon>
    <span class="status-title"> {{getTitle(value)}} </span>
</div>
<ng-template #disabledContent>
    <div
        [ngStyle]="{'background':getTitleColor(value),'color': getTitleColor(value)|getContrastColor}"
        class="c-pointer status-title_wrap"
    >
        <mat-icon class="status-icon"> {{getIcon(value)}} </mat-icon>
        <span class="status-title"> {{getTitle(value)}} </span>
    </div>
</ng-template>
<mat-menu #statusMenu="matMenu" class="status-menu">
    <ng-template matMenuContent>
        <div (click)="$event.stopPropagation()">
            <div class="settings_header">
                <span
                    (click)="openDialog()"
                    class="material-icons float-right mr-sm text-muted c-pointer"
                >
                    settings
                </span>
            </div>
            <button (click)="checkActive(key)" *ngFor="let key of  optionKeys()" mat-menu-item>
                <span
                    [ngStyle]="{'background': statusData[key].color}"
                    class="status-color_label mr-sm"
                ></span>
                <span class="status-label"> {{statusData[key].label}} </span>
                <span class="status-icon material-icons ml-sm text-muted">
                    {{statusData[key].icon}}
                </span>
                <mat-icon *ngIf="key === value" class="active-check-label">check </mat-icon>
                <mat-icon *ngIf="key === default"> ac_unit </mat-icon>
            </button>
        </div>
    </ng-template>
</mat-menu>
