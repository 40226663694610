<ng-container *ngIf="fieldTypes$ | async as fieldTypes">
    <ng-container *ngIf="fields && record && selectedFolder">
        <div (click)="openCardDialog()" [class.open]="isMenuOpen" class="board-box">
            <div class="board-item-text w-100 mt-sm mb-md">
                <span>{{selectedFolder.name.value}}</span>
                <div class="mb-lg">
                    <app-board-card-header
                        *ngIf="assignee"
                        [fieldType]="fieldTypes[assignee.fieldType]"
                        [name]="name"
                        [field]="isVisible(assignee.fieldGuid) ? fields[assignee.fieldGuid] : undefined"
                        [value]="assignee.value"
                    ></app-board-card-header>
                </div>
                <div class="mb-md">
                    <app-board-card-subtitle
                        *ngIf="isDeal(record) && amount && vendorName"
                        [amount]="isVisible(amount.fieldGuid) ? amount : undefined"
                        [vendorName]="isVisible(vendorName.fieldGuid) ? vendorName : undefined"
                    ></app-board-card-subtitle>
                </div>
                <div
                    class="mb-md w-100 position-relative"
                    *ngIf="selectedFolder && selectedFolder.guid"
                >
                    <app-board-card-status
                        *ngIf="status"
                        [fieldType]="fieldTypes[status.fieldType]"
                        [status]="isVisible(status.fieldGuid) ? fields[status.fieldGuid] : undefined"
                        [value]="status.value"
                    ></app-board-card-status>
                </div>
                <app-board-card-main-fields
                    *ngIf="isVendor(record) && address"
                    [address]="isVisible(address.fieldGuid) ? address : undefined"
                ></app-board-card-main-fields>
            </div>

            <div class="board-item-footer w-100">
                <div class="card-field-table">
                    <app-board-card-fields
                        [fieldTypes]="fieldTypes"
                        [fields]="this.nonMainFields"
                        [fieldEntities]="fields"
                        [selectedFolder]="selectedFolder"
                        [cells]="cells"
                    ></app-board-card-fields>
                </div>
                <div
                    class="flex-start-center justify-content-between"
                    *transloco="let tButtons; read: 'buttons'"
                >
                    <div class="flex-start-center">
                        <app-board-card-update
                            *ngIf="updateAt"
                            [fieldType]="fieldTypes[updateAt.fieldType]"
                            [field]="isVisible(updateAt.fieldGuid) ? fields[updateAt.fieldGuid] : undefined"
                            [updateAt]="updateAt"
                        ></app-board-card-update>
                    </div>
                    <button
                        #boardItemMenuTrigger="matMenuTrigger"
                        (click)="openItem(); $event.stopPropagation()"
                        [matMenuTriggerFor]="boardItemMenu"
                        class="no-padd-btn"
                        mat-flat-button
                    >
                        <span class="material-icons" title="More options"> more_horiz</span>
                    </button>
                    <mat-menu #boardItemMenu="matMenu">
                        <ng-template matMenuContent>
                            <div (mouseleave)="closeBox();">
                                <button (click)="deleteCard()" mat-menu-item>
                                    {{tButtons('delete')}}
                                </button>
                            </div>
                        </ng-template>
                    </mat-menu>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
