import { Component, OnInit } from '@angular/core'
import {
    CellEntities,
    Contact,
    ContactFieldNames,
    GlobalFieldNames,
    prepareCellsForRecords,
} from '../../@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { RecordFacadeService } from '../../@core/services/store-facade'

export interface ContactItem {
    name: string
    email: string
    guid: string
}

@UntilDestroy()
@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.sass'],
})
export class ListComponent implements OnInit {
    contactList!: ContactItem[]
    records!: Contact[]
    selectedRecord!: Contact
    selectedContact!: ContactItem
    cells!: { [recordGuid: string]: CellEntities }

    constructor(private recordFacadeService: RecordFacadeService) {}

    ngOnInit(): void {
        this.recordFacadeService
            .selectViewData$()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                if (data && data.selectedView) {
                    let nameFieldGuid: string | undefined, emailFieldGuid: string | undefined

                    Object.values(data.fields).forEach((field) => {
                        switch (field.system_name) {
                            case GlobalFieldNames.NAME:
                                nameFieldGuid = field.guid
                                break
                            case ContactFieldNames.EMAIL:
                                emailFieldGuid = field.guid
                        }
                    })

                    if (!nameFieldGuid || !emailFieldGuid) {
                        console.error(new Error('invalid schema'))
                        return
                    }

                    this.records =
                        data && data.data instanceof Array ? (data.data as Contact[]) : []

                    this.cells = prepareCellsForRecords(this.records)
                }
            })
    }

    selectContact(guid: string) {
        this.selectedRecord = this.records.find((record) => record.guid == guid)!
    }
}
