import { Component, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState, selectCurrentUser, selectCurrentUserInfo } from '../@core/@ngrx'
import { CurrentUser, UserModel } from '../@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Observable } from 'rxjs'
import {
    ExpandMods,
    SideNavigationService,
} from '../@components-library/tb-side-navigation-layout/side-navigation.service'
import { MatBottomSheet } from '@angular/material/bottom-sheet'

@UntilDestroy()
@Component({
    selector: 'app-user-settings',
    templateUrl: './user-setting.component.html',
    styleUrls: ['./user-settings.component.sass'],
})
export class UserSettingsComponent {
    userInfo$: Observable<UserModel | undefined> = this.store.select(selectCurrentUserInfo)
    currentUser$: Observable<CurrentUser | null> = this.store.select(selectCurrentUser)
    hideName = false

    @ViewChild('templateBottomSheet') TemplateBottomSheet!: TemplateRef<any>

    constructor(
        private sideNavigationService: SideNavigationService,
        private store: Store<AppState>,
        private bottomSheet: MatBottomSheet,
    ) {}

    openBottomSheet() {
        this.bottomSheet.open(this.TemplateBottomSheet, { panelClass: 'bottomSheetCustom' })
    }

    ngOnInit(): void {
        this.sideNavigationService.state$.pipe(untilDestroyed(this)).subscribe((value) => {
            this.hideName = value.expand === ExpandMods.COLLAPSED
        })
    }
}
