<div
    *transloco="let tFilter; read: 'filter'"
    class="flex flex-col items-center w-full h-full mt-12"
>
    <mat-icon class="w-[328px] h-[362px]" svgIcon="filters_img"></mat-icon>
    <div class="max-w-[260px] text-sm text-primarySecondary">
        {{tFilter('no_results')}}

        <!--        filters dialog should open on click should be implemented-->
        <button class="text-secondaryMain underline" (click)="changeFilters.emit()">
            {{tFilter('change_filters')}}
        </button>
    </div>
</div>
