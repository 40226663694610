import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { SystemRecordsErrorService } from '../services/system-records-error.service'
import { map } from 'rxjs/operators'

export const MoveToRouteWithErrorConditionGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
): Observable<boolean | UrlTree> => {
    const systemRecordsErrorService = inject(SystemRecordsErrorService)
    const router = inject(Router)

    return systemRecordsErrorService.getApplicationErrorMessages().pipe(
        map((messages) => {
            const path = route.data.path || ['view']

            if (route.data?.condition === 'no-errors' && !messages.length) {
                return router.createUrlTree(path)
            }

            return true
        }),
    )
}
