import { ComponentRef, EventEmitter, Injectable } from '@angular/core'
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { ModalRenderComponent } from '../modal-render-container/modal-render.component'
import { ReplaySubject, Subject } from 'rxjs'
import { BaseContainer } from '../modal-containers/base-container'
import { ModalContainerComponent } from '../modal-container-component/modal-container.component'
import { take } from 'rxjs/operators'
import { ModalContainer } from '../modal-manager.service'

const ForcedData = Symbol('ForcedData')

@Injectable({
    providedIn: 'root',
})
export class BottomSheetInstanceService {
    private bottomSheetInstance: MatBottomSheetRef | null = null
    private renderComponent$ = new Subject<ComponentRef<unknown>>()
    public closed = new EventEmitter()

    constructor(private bottomSheet: MatBottomSheet) {}

    open(container: BaseContainer<ModalContainerComponent>) {
        this.reopen(container)

        return container.closed.asObservable().pipe(take(1))
    }

    reopen(container: BaseContainer<ModalContainerComponent>) {
        if (!this.bottomSheetInstance) {
            this.bottomSheetInstance = this.bottomSheet.open(ModalRenderComponent, {
                data: { renderComponent$: this.renderComponent$ },
                panelClass: 'bottomSheetCustom',
            })

            this.bottomSheetInstance.afterDismissed().subscribe((data) => {
                if (data === ForcedData) return

                this.closed.emit()
                this.clear()
            })
        }

        container.componentRef.setInput('containerLayout', ModalContainer.BottomSheet)
        this.renderComponent$.next(container.componentRef)
    }

    clear() {
        this.bottomSheetInstance?.dismiss()
        this.bottomSheetInstance = null
    }

    hide() {
        this.bottomSheetInstance?.dismiss(ForcedData)
        this.bottomSheetInstance = null
    }
}
