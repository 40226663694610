<app-loading></app-loading>
<app-pin-panel
    class="fixed bottom-4 right-4 sm:right-6 sm:bottom-6 z-[1001] opacity-80"
></app-pin-panel>

<ng-container *ngIf="menuItems$| async as menuItems">
    <app-tb-side-navigation-layout [menuItems]="menuItems">
        <app-header-top header class="w-100"></app-header-top>
        <router-outlet></router-outlet>
        <app-user-settings
            user-settings
            class="min-h-[48px] mt-1 overflow-hidden min-w-fit"
        ></app-user-settings>
    </app-tb-side-navigation-layout>
</ng-container>
