<div class="flex-column d-flex" *transloco="let tCardContent; read: 'card_content'">
    <label>{{tCardContent('description_label')}}</label>
    <div *ngIf="descriptionField" class="description-row">
        <app-text-multi-line
            (cellValueChanged)="updateRecord(record, record.description[folder.guid], $event)"
            [fieldType]="fieldTypes[descriptionField.field_type_code]"
            [field]="descriptionField"
            [record]="record"
            [value]="record.description[folder.guid].value"
            class="flex-start-center text-center"
        ></app-text-multi-line>
    </div>
    <label>{{tCardContent('rich_description_label')}}</label>
    <div *ngIf="descriptionField" class="description-row">
        <app-rich-text
            (cellValueChanged)="updateRecord(record, record.richTextDescription, $event)"
            [fieldType]="fieldTypes[richTextDescriptionField.field_type_code]"
            [field]="richTextDescriptionField"
            [isCard]="true"
            [record]="record"
            [value]="record.richTextDescription.value"
            class="flex-start-center text-center"
        ></app-rich-text>
    </div>
</div>
