import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TbSideNavigationLayoutComponent } from './tb-side-navigation-layout/tb-side-navigation-layout.component'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatIconModule } from '@angular/material/icon'
import { MatToolbarModule } from '@angular/material/toolbar'
import { TbStepperComponent } from './tb-stepper/tb-stepper.component'
import { CdkStep, CdkStepperModule } from '@angular/cdk/stepper'
import { TranslocoModule } from '@ngneat/transloco'
import { TbNavigationMenuItemComponent } from './tb-side-navigation-layout/tb-navigation-menu-item/tb-navigation-menu-item.component'
import { TbTabsComponent } from './tb-tabs/tb-tabs.component'
import { MatTabsModule } from '@angular/material/tabs'
import { TbTabComponent } from './tb-tab/tb-tab.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { ReactiveFormsModule } from '@angular/forms'
import { MatMenuModule } from '@angular/material/menu'
import { MatDividerModule } from '@angular/material/divider'
import { MatInputModule } from '@angular/material/input'
import { ModalManagerModule } from './tb-modal-manager/modal-manager.module'
import { TbMenuListComponent } from './tb-menu-list/tb-menu-list.component'
import { TbMenuListItemComponent } from './tb-menu-list-item/tb-menu-list-item.component'
import { TbMenuComponent } from './tb-menu/tb-menu.component'
import { TbDividerComponent } from './tb-divider/tb-divider.component'
import { TbIconModule } from './tb-icon/tb-icon.module'
import { TbButtonComponent } from './tb-button/tb-button.component'

const componentsForExport = [
    TbSideNavigationLayoutComponent,
    TbStepperComponent,
    TbTabsComponent,
    TbTabComponent,
    TbMenuListComponent,
    TbMenuListItemComponent,
    TbMenuComponent,
    TbDividerComponent,
    TbButtonComponent,
]

@NgModule({
    declarations: [...componentsForExport, TbNavigationMenuItemComponent],
    imports: [
        CommonModule,
        MatSidenavModule,
        MatIconModule,
        MatToolbarModule,
        CdkStepperModule,
        TranslocoModule,
        MatTabsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatDividerModule,
        MatInputModule,
        ReactiveFormsModule,
        ModalManagerModule,
        TbIconModule,
    ],
    exports: [...componentsForExport, CdkStep, TbIconModule],
})
export class ComponentsLibraryModule {}
