import { Component, Input, TemplateRef } from '@angular/core'
import { ModalContainerComponent } from '../modal-container-component/modal-container.component'

@Component({
    selector: 'app-template-container',
    templateUrl: './template-container.component.html',
})
export class TemplateContainerComponent extends ModalContainerComponent {
    @Input()
    template!: TemplateRef<any>

    boundCloseFn = this.close.bind(this)
}
