import { createFeature, createReducer, on } from '@ngrx/store'
import {
    dataInitialized,
    initAppSuccess,
    initSystemFields,
    setError,
    setLoading,
    setRoles,
    setSolution,
} from './common.actions'
import { ResponseFieldEntities, SolutionModel } from '../../models'
import { RoleEntities } from '../../models/response/role'

const commonFeatureKey: string = 'COMMON'

export interface CommonState {
    error: string | null
    wsConnected: boolean
    initLoaded: boolean
    loading: boolean
    solution: SolutionModel
    roles: RoleEntities
    dataInitialized: boolean
    systemFields: ResponseFieldEntities | null
}

const initialState: CommonState = {
    error: null,
    wsConnected: false,
    initLoaded: false,
    loading: true,
    dataInitialized: false,
    roles: { '': { name: '' } },
    solution: {
        guid: '',
        name: '',
        setting: {},
    },
    systemFields: null,
}

const commonReducer = createReducer(
    initialState,
    on(setError, (state: CommonState, { error }: { error: string | null }) => {
        return {
            ...state,
            error,
            loading: false,
        }
    }),
    on(initAppSuccess, (state: CommonState) => {
        return {
            ...state,
            wsConnected: true,
            initLoaded: true,
        }
    }),
    on(dataInitialized, (state: CommonState) => {
        return {
            ...state,
            dataInitialized: true,
        }
    }),
    on(setLoading, (state: CommonState, { loading }: { loading: boolean }) => {
        return {
            ...state,
            loading: loading,
        }
    }),
    on(setSolution, (state: CommonState, { solution }: { solution: SolutionModel }) => {
        return {
            ...state,
            solution,
        }
    }),
    on(setRoles, (state: CommonState, { roles }: { roles: RoleEntities }) => {
        return {
            ...state,
            roles,
        }
    }),
    on(
        initSystemFields,
        (state: CommonState, { systemFields }: { systemFields: ResponseFieldEntities }) => {
            return {
                ...state,
                systemFields,
            }
        },
    ),
)

export const commonFeature = createFeature({
    name: commonFeatureKey,
    reducer: commonReducer,
})
