import { NgModule } from '@angular/core'
import { AccessSelectorComponent } from './access-selector.component'
import { MatIconModule } from '@angular/material/icon'
import { TranslocoModule } from '@ngneat/transloco'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'

@NgModule({
    declarations: [AccessSelectorComponent],
    imports: [MatIconModule, TranslocoModule, MatFormFieldModule, MatSelectModule],
    exports: [AccessSelectorComponent],
})
export class AccessSelectorModule {}
