import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { CellComponent } from '../../cell.component'
import { FormControl, FormGroup } from '@angular/forms'
import { Field } from '../../../../@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-text-one-line-settings',
    templateUrl: './text-one-line-settings.component.html',
    styleUrls: ['./text-one-line-settings.component.sass'],
})
export class TextOneLineSettingsComponent extends CellComponent implements OnInit {
    @Output()
    fieldChanged: EventEmitter<Field> = new EventEmitter<Field>()

    formGroup!: FormGroup<{
        minLength: FormControl<string | null>
        maxLength: FormControl<string | null>
    }>

    ngOnInit(): void {
        console.log('field', this.field)
        this.formGroup = new FormGroup({
            minLength: new FormControl<string>(
                this.field.configure_json ? JSON.parse(this.field.configure_json).minLength : '',
            ),
            maxLength: new FormControl<string>(
                this.field.configure_json ? JSON.parse(this.field.configure_json).maxLength : '',
            ),
        })
        this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.fieldChanged.emit({
                ...this.field,
                configure_json: JSON.stringify({
                    minLength: value.minLength !== null ? value.minLength : '',
                    maxLength: value.maxLength !== null ? value.maxLength : '',
                }),
            })
        })
    }
}
