import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'

import { HeaderComponent } from './header.component'
import { TranslocoModule } from '@ngneat/transloco'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { AddWorkspaceDialogContentComponent } from './folder-controls/add-workspace-dialog-content/add-workspace-dialog-content.component'
import { AdvancedOptionsComponent } from './folder-controls/advanced-options/advanced-options.component'
import { AdvancedOptionsSectionComponent } from './folder-controls/advanced-options/advanced-options-section/advanced-options-section.component'
import { CreateFolderDialogContainerComponent } from './folder-controls/create-folder-dialog-content/create-folder-dialog-content.component'
import { SelectViewsSettingsComponent } from './folder-controls/advanced-options/select-views-settings/select-views-settings.component'
import { FieldConfigurationComponent } from './folder-controls/advanced-options/field-configuration/field-configuration.component'
import { AddFieldContentComponent } from './folder-controls/advanced-options/field-configuration/add-field-content/add-field-content.component'
import { StatusConfigurationComponent } from './folder-controls/advanced-options/status-configuration/status-configuration.component'
import { StatusElementComponent } from './folder-controls/advanced-options/status-configuration/status-element/status-element.component'
import { TemplateSelectorComponent } from './folder-controls/advanced-options/status-configuration/template-selector/template-selector.component'
import { FieldConfigurationSelectorComponent } from './folder-controls/advanced-options/field-configuration/field-configuration-selector/field-configuration-selector.component'
import { FieldConfigurationTypeComponent } from './folder-controls/advanced-options/field-configuration/field-configuration-type/field-configuration-type.component'
import { NgxColorsModule } from 'ngx-colors'
import { MatDialogModule } from '@angular/material/dialog'
import { MatInputModule } from '@angular/material/input'
import { MatRadioModule } from '@angular/material/radio'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { SearchModule } from './search/search.module'
import { MatMenuModule } from '@angular/material/menu'
import { CommonAppModule } from '../common/common.module'
import { ComponentsLibraryModule } from '../@components-library/components-library.module'
import { MatDividerModule } from '@angular/material/divider'
import { HeaderNameComponent } from './header-name/header-name.component'
import { NotificationsMenuComponent } from './notifications-menu/notifications-menu.component'
import { NotificationsMenuContentComponent } from './notifications-menu/notifications-menu-content/notifications-menu-content.component'
import { FolderComponent } from './folder-controls/folder/folder.component'
import { FolderControlsComponent } from './folder-controls/folder-controls/folder-controls.component'
import { FolderControlsContainerComponent } from './folder-controls/folder-controls-container.component'
import { ColorIconPickerComponent } from './color-icon-picker/color-icon-picker.component'
import { AccessSelectorModule } from '../access-selector/access-selector.module'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'

@NgModule({
    declarations: [
        HeaderComponent,
        FolderControlsComponent,
        CreateFolderDialogContainerComponent,
        AddWorkspaceDialogContentComponent,
        AdvancedOptionsComponent,
        AdvancedOptionsSectionComponent,
        SelectViewsSettingsComponent,
        StatusConfigurationComponent,
        StatusElementComponent,
        TemplateSelectorComponent,
        FieldConfigurationComponent,
        AddFieldContentComponent,
        FieldConfigurationSelectorComponent,
        FieldConfigurationTypeComponent,
        HeaderNameComponent,
        NotificationsMenuComponent,
        NotificationsMenuContentComponent,
        FolderComponent,
        FolderControlsContainerComponent,
        ColorIconPickerComponent,
    ],
    imports: [
        CommonModule,
        CommonAppModule,
        SearchModule,
        MatIconModule,
        TranslocoModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatButtonModule,
        NgxColorsModule,
        MatDialogModule,
        MatInputModule,
        MatRadioModule,
        MatCheckboxModule,
        MatMenuModule,
        ComponentsLibraryModule,
        MatDividerModule,
        AccessSelectorModule,
        MatExpansionModule,
        MatSlideToggleModule,
    ],
    exports: [HeaderComponent],
})
export class HeaderModule {}
