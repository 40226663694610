import { Component, Inject, OnInit } from '@angular/core'
import { Field } from '../../../../@core/models'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormControl, FormGroup } from '@angular/forms'
import { AddColumnComponent } from '../add-column/add-column.component'
import { FolderNameEntities } from '../../../../@core/models/response/folder-names'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { FolderFacadeService } from '../../../../@core/services/store-facade'

interface Option {
    name: string
    value: string
    checked: boolean
}
@UntilDestroy()
@Component({
    selector: 'app-change-field-folder',
    templateUrl: './change-field-folder.component.html',
    styleUrls: ['./change-field-folder.component.sass'],
})
export class ChangeFieldFolderComponent implements OnInit {
    field!: Field
    folders!: FolderNameEntities
    formGroup!: FormGroup<{ selectFolder: FormControl<string | null> }>
    folder_guids!: string[]
    radioButtonOptions: Option[] = []
    folder_guid!: string | undefined

    constructor(
        public dialogRef: MatDialogRef<AddColumnComponent, Field | undefined>,
        private folderFacadeService: FolderFacadeService,
        @Inject(MAT_DIALOG_DATA) public data: Field,
    ) {
        this.field = data
        this.folder_guid = data.folder_guid
    }

    ngOnInit(): void {
        this.folderFacadeService.selectFolderNames$
            .pipe(untilDestroyed(this))
            .subscribe((data) => (this.folders = data))
        this.folder_guids = Object.keys(this.folders)
        this.radioButtonOptions = this.folder_guids.map((guid) => {
            return {
                name: this.folders[guid].name,
                value: guid,
                checked: this.field.folder_guid === guid,
            }
        })
    }

    folderChange(folder: string): void {
        this.field = {
            ...this.field,
            folder_guid: folder,
        }
    }

    applyShare() {
        if (this.folder_guid === this.field.folder_guid) {
            this.cancelShare()
        } else {
            this.dialogRef.close(this.field)
        }
    }

    cancelShare() {
        this.dialogRef.close()
    }
}
