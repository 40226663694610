<div class="fixed bottom-4 right-4 sm:right-6 sm:bottom-6 z-[1000] opacity-80">
    <div
        class="flex-start-center justify-content-end w-100"
        *transloco="let tButtons; read: 'buttons'"
    >
        <ng-container *ngIf="pinObjects$| async as allObjects">
            <ng-container *ngIf="pinRecordsMore$ | async as pinRecordsMore">
                <ng-container *ngIf="pinRecordsDraftMore$ | async as pinDraftRecordsMore">
                    <app-pinned-record
                        (openRecord)="openPinItem(item.guid)"
                        (delete)="removePinItem(item.guid)"
                        *ngFor="let item of pinRecords$ | async"
                        [name]="item.name"
                    >
                    </app-pinned-record>
                    <app-pinned-record
                        (openRecord)="trigger.menuData = { item: item }; trigger.openMenu()"
                        (delete)="removePinItem(item.guid)"
                        *ngFor="let item of pinRecordsDraft$ | async"
                        [name]="item.name"
                    >
                    </app-pinned-record>

                    <ng-container *ngIf="showMoreBtn(pinRecordsMore, pinDraftRecordsMore)">
                        <button
                            #triggerMore="matMenuTrigger"
                            [matMenuTriggerFor]="moreRecordMenu"
                            mat-icon-button
                        >
                            <mat-icon>add</mat-icon>
                            {{ showRecordCount }}/{{ allObjects.length }}
                        </button>
                    </ng-container>

                    <mat-menu #moreRecordMenu="matMenu" class="px-2.5 py-5">
                        <ng-template matMenuContent>
                            <div (click)="$event.stopPropagation();$event.preventDefault()">
                                <app-pinned-record
                                    (openRecord)="trigger.openMenu()"
                                    (delete)="removePinItem(item.guid)"
                                    *ngFor="let item of pinDraftRecordsMore"
                                    [name]="item.name"
                                >
                                </app-pinned-record>
                                <app-pinned-record
                                    (openRecord)="openPinItem(item.guid)"
                                    (delete)="removePinItem(item.guid)"
                                    *ngFor="let item of pinRecordsMore"
                                    [name]="item.name"
                                >
                                </app-pinned-record>
                            </div>
                        </ng-template>
                    </mat-menu>
                </ng-container>
            </ng-container>
        </ng-container>

        <button
            class="flex cursor-pointer"
            #trigger="matMenuTrigger"
            [matMenuTriggerFor]="addTaskMenu"
            mat-button
        >
            Old record add
        </button>

        <mat-menu #addTaskMenu="matMenu" class="pinned-menu" [hasBackdrop]="false">
            <ng-template matMenuContent let-item="item">
                <div (click)="$event.stopPropagation();$event.preventDefault()">
                    <app-add-record-content-old
                        (closeMenu)="trigger.closeMenu(); trigger.menuData = null;"
                        [openedRecord]="item"
                        [isMenuOpen]="trigger.menuOpen"
                    ></app-add-record-content-old>
                </div>
            </ng-template>
        </mat-menu>

        <button
            class="flex items-center justify-center rounded-full sm:h-9 sm:w-9 h-14 w-14 min-w-0 p-0 bg-accentContrastLight text-white cursor-pointer hover:bg-accentContrast"
            mat-button
        >
            <div
                (click)="openDialog()"
                class="flex max-sm:hidden items-center justify-content w-full h-full"
            >
                <mat-icon class="m-0 p-0 h-10 w-10 text-4xl sm:text-2xl sm:h-6 sm:w-6 sm:leading-6"
                    >add</mat-icon
                >
            </div>
            <div
                (click)="openBottomSheetS()"
                class="flex sm:hidden items-center justify-content w-full h-full"
            >
                <mat-icon class="m-0 p-0 h-10 w-10 text-4xl sm:text-2xl sm:h-6 sm:w-6 sm:leading-6"
                    >add</mat-icon
                >
            </div>
        </button>

        <!--    needs to be moved to the creation dialog and the following 2 buttons should be removed-->
        <button class="max-sm:hidden" mat-button (click)="openDialogSettings()">DS</button>
        <button class="sm:hidden" mat-button (click)="openBottomSheetSettings()">BS</button>
    </div>
</div>
