<ng-container *ngIf="!isDefault; else defaultItem">
    <div
        [class.edit]="isFocused"
        #optionItem
        cdkDrag
        cdkDragBoundary=".status-container"
        [cdkDragData]="option.guid"
    >
        <ng-container *ngTemplateOutlet="optionItemTemplate"></ng-container>
    </div>
</ng-container>

<ng-template #defaultItem>
    <div [class.edit]="isFocused" #optionItem>
        <ng-container *ngTemplateOutlet="optionItemTemplate"></ng-container>
    </div>
</ng-template>

<ng-template #optionItemTemplate>
    <form [formGroup]="formGroup" class="flex-start-center c-pointer mb-sm">
        <mat-icon *ngIf="!isClosed && !isDefault" class="text-muted">drag_indicator</mat-icon>
        <div class="flex-start-center status-item">
            <button
                #colorPicker
                *ngIf="!isClosed"
                [hideColorPicker]="false"
                [hideTextInput]="true"
                [ngStyle]="{'background':formGroup.controls['color'].value}"
                class="status-color-label"
                formControlName="color"
                ngx-colors-trigger
            ></button>
            <input #optionLabelInput="matInput" formControlName="label" matInput placeholder="" />
            <mat-icon *ngIf="isSelected" class="active-check-label">check</mat-icon>
            <mat-icon *ngIf="isDefault" class="active-check-label">ac_unit </mat-icon>
            <mat-icon
                (click)="openIconDialog()"
                *ngIf="option.icon; else defaultIcon"
                class="text-success status-icon"
                >{{option.icon}}</mat-icon
            >
            <ng-template #defaultIcon>
                <mat-icon (click)="openIconDialog()" class="text-success status-icon"
                    >add_circle_outline
                </mat-icon>
            </ng-template>
            <div class="status-action flex-start-center">
                <button class="done-label" mat-button>
                    <mat-icon class="text-success">done</mat-icon>
                </button>
                <button [matMenuTriggerFor]="statusAction" class="action-btn" mat-button>
                    <span class="material-icons text-muted"> more_vert </span>
                </button>
                <mat-menu #statusAction="matMenu">
                    <ng-template
                        matMenuContent
                        *transloco="let tCellTypesSelectOptionItem; read: 'cell_types.select.option_item'"
                    >
                        <button (click)="optionLabelInput.focus()" mat-menu-item>
                            <span class="material-icons text-muted mr-sm"> mode_edit_outline </span>
                            <span> {{tCellTypesSelectOptionItem('rename')}} </span>
                        </button>
                        <button
                            (click)="setDefaultStatus()"
                            *ngIf="(!isClosed && isActive) || isDropdown"
                            class="flex-start-center"
                            mat-menu-item
                        >
                            <span class="material-icons text-muted mr-sm"> ac_unit </span>
                            <span> {{tCellTypesSelectOptionItem('default')}} </span>
                        </button>
                        <button *ngIf="!isClosed" (click)="openColorPicker()" mat-menu-item>
                            <span class="material-icons text-muted mr-sm"> colorize </span>
                            <span> {{tCellTypesSelectOptionItem('color')}} </span>
                        </button>
                        <button (click)="openIconDialog()" class="flex-start-center" mat-menu-item>
                            <mat-icon>add_circle_outline</mat-icon>
                            <span> {{tCellTypesSelectOptionItem('icon')}} </span>
                        </button>
                        <button
                            (click)="deleteStatus()"
                            *ngIf="!disableDelete && !isClosed"
                            class="flex-start-center"
                            mat-menu-item
                        >
                            <span class="material-icons text-muted mr-sm"> delete </span>
                            <span> {{tCellTypesSelectOptionItem('delete')}} </span>
                        </button>
                    </ng-template>
                </mat-menu>
            </div>
        </div>
    </form>
</ng-template>
