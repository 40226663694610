import { Component, Input } from '@angular/core'
import { AppRecord, CellEntities } from '../../../../@core/models'

@Component({
    selector: 'app-card-info',
    templateUrl: './list-card-info.component.html',
    styleUrls: ['./list-card-info.component.sass'],
})
export class ListCardInfoComponent {
    @Input()
    record!: AppRecord

    @Input()
    cells!: CellEntities

    get recordCells() {
        return Object.values(this.cells)
    }
}
