import {
    Component,
    ComponentRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import { BoardNewComponent } from '../board-new/board-new.component'
import { CdkDragDrop } from '@angular/cdk/drag-drop'
import { BusinessRecords, CellEntities, Field, FieldType, Folder } from '../../../@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'
import { MatDialog } from '@angular/material/dialog'
import {
    Buttons,
    CommonDialogComponent,
} from '../../../common/common-dialog/common-dialog.component'

@UntilDestroy()
@Component({
    selector: 'app-board-col',
    templateUrl: './board-col.component.html',
    styleUrls: ['./board-col.component.sass'],
})
export class BoardColComponent implements OnChanges {
    @Input() field!: Field
    @Input() fieldTypes!: Dictionary<FieldType> | null
    @Input() boardColData!: BusinessRecords[]
    @Input() visibleFields!: string[]
    @Input() columnName?: string
    @Input() selectedFolder!: Folder | null
    @Input() cells!: { [recordGuid: string]: CellEntities }
    @Input() isCreateOpened!: boolean
    @Input() cleanAll!: boolean
    @Input() nameNotEmpty: boolean = false
    @Input() newRecordHasData: boolean = false
    @Input() selectedRecords!: BusinessRecords[]
    @Input() allowCollapsing!: boolean

    @Output() createEnable: EventEmitter<boolean> = new EventEmitter()
    @Output() dropItem: EventEmitter<any> = new EventEmitter()
    @Output() createRecord: EventEmitter<any> = new EventEmitter()
    @Output() isCreateNewItemShown: EventEmitter<boolean> = new EventEmitter()
    @Output() clearAllCreate: EventEmitter<boolean> = new EventEmitter()
    @Output() nameFieldNotEmpty: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Output() editFields: EventEmitter<any> = new EventEmitter<any>()
    @Output() selectColumnRecords: EventEmitter<any> = new EventEmitter<any>()

    @ViewChild('createAreaTop', { read: ViewContainerRef }) createAreaTop!: ViewContainerRef
    @ViewChild('createAreaBottom', { read: ViewContainerRef }) createAreaBottom!: ViewContainerRef

    boardNewPlace: boolean = true
    boardItem = BoardNewComponent
    createHere: boolean = false
    isCollapsed: boolean = false

    constructor(private dialog: MatDialog) {}

    ngOnInit(): void {
        console.log('boardColData', this.boardColData)
        if (!this.boardColData.length) {
            this.isCollapsed = true
        }
    }

    ngOnChanges(change: SimpleChanges) {
        if (change.cleanAll && change.cleanAll.currentValue !== change.cleanAll.previousValue) {
            if (this.createAreaTop && this.createAreaBottom) {
                this.createAreaTop.clear()
                this.createAreaBottom.clear()
            }
            if (this.createHere) {
                this.addItem(this.boardNewPlace)
            }
        }
    }

    isAllRecordsSelected() {
        if (!this.boardColData.length) return false
        return this.boardColData.every((record) => this.selectedRecords.includes(record))
    }

    showGroupCollapsed() {
        return this.field !== null && this.fieldTypes !== null
    }

    addNewBoardItem(place: boolean) {
        this.createHere = true
        if (!this.isCreateOpened) {
            this.addItem(place)
            return
        }

        if (!this.newRecordHasData) {
            this.clearAllCreate.emit(!this.cleanAll)
            this.boardNewPlace = place
            return
        }

        const dialogRef = this.dialog.open<
            CommonDialogComponent,
            { i18Key: string; buttonKeys: Array<Buttons> },
            Buttons
        >(CommonDialogComponent, {
            width: '300px',
            data: {
                i18Key: 'drop_info_alert',
                buttonKeys: [Buttons.YES, Buttons.NO],
            },
        })
        dialogRef.afterClosed().subscribe((createNew) => {
            if (createNew === Buttons.YES) {
                this.clearAllCreate.emit(!this.cleanAll)
                this.boardNewPlace = place
                this.nameFieldNotEmpty.emit(false)
            }
        })
    }

    addItem(place: boolean) {
        this.createHere = false
        let component!: ComponentRef<BoardNewComponent>

        if (place) {
            component = this.createAreaTop.createComponent(this.boardItem)
        } else {
            component = this.createAreaBottom.createComponent(this.boardItem)
        }
        component.instance.place = place
        component.instance.folderName = this.selectedFolder?.name.value
        this.boardNewPlace = place
        component.instance.nameFieldNotEmpty.pipe(untilDestroyed(this)).subscribe((empty) => {
            this.nameFieldNotEmpty.emit(empty)
        })

        component.instance.upBoardItem.pipe(untilDestroyed(this)).subscribe(() => {
            component.instance.place = true
            this.boardNewPlace = true
            this.createAreaTop.insert(component.hostView)
            this.createAreaBottom.clear()
        })
        component.instance.downBoardItem.pipe(untilDestroyed(this)).subscribe(() => {
            component.instance.place = false
            this.boardNewPlace = false
            this.createAreaBottom.insert(component.hostView)
            this.createAreaTop.clear()
        })
        component.instance.saveBoardItem.pipe(untilDestroyed(this)).subscribe((data: string) => {
            if (data) this.createRecord.emit(data)
            if (this.boardNewPlace) {
                this.createAreaTop.clear()
            } else {
                this.createAreaBottom.clear()
            }
            this.isCreateNewItemShown.emit(false)
            this.clearAllCreate.emit(false)
            this.nameFieldNotEmpty.emit(false)
        })
    }

    drop(event: CdkDragDrop<BusinessRecords[]>) {
        this.dropItem.emit({ field: this.field, value: this.columnName, event })
    }

    unCollapseColumn() {
        if (this.isCollapsed) {
            this.isCollapsed = false
        }
    }

    selectAllColumnRecords() {
        return this.selectColumnRecords.emit([...this.boardColData])
    }
}
