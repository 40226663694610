import { APP_INITIALIZER, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { RootStoreModule } from './@core/@ngrx/root-store.module'
import { ViewsModule } from './views/views.module'
import { HttpClientModule } from '@angular/common/http'
import { LoadingComponent } from './loading/loading.component'
import { NotificationModule } from './notification/notification.module'
import { ViewControlsModule } from './views/view-controls/view-controls.module'
import { NotificationPageModule } from './views/notificationPage/notification-page.module'
import { UserSettingsModule } from './user-settings/user-settings.module'
import { PinPanelModule } from './pin-panel/pin-panel.module'
import { LogService, WsService } from './@core/services'
import { TranslocoRootModule } from './transloco-root.module'
import { initializeApp } from './app.initializer'
import { HeaderModule } from './header/header.module'
import { ComponentsLibraryModule } from './@components-library/components-library.module'

@NgModule({
    declarations: [AppComponent, LoadingComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ViewsModule,
        MatProgressSpinnerModule,
        RootStoreModule,
        AppRoutingModule,
        NotificationModule,
        NotificationPageModule,
        PinPanelModule,
        ViewControlsModule,
        TranslocoRootModule,
        HeaderModule,
        UserSettingsModule,
        ComponentsLibraryModule,
    ],
    providers: [
        WsService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [LogService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    exports: [],
})
export class AppModule {}
