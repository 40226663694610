import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { CellComponent } from '../cell.component'
import { getBoolean } from '../../../@core/global-util'
import { FormControl } from '@angular/forms'
import { debounceTime } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-boolean',
    templateUrl: './boolean.component.html',
    styleUrls: ['./boolean.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BooleanComponent extends CellComponent implements OnInit, AfterViewInit {
    checkboxFormControl!: FormControl<boolean | null>

    ngOnInit(): void {
        this.checkboxFormControl = new FormControl<boolean>({
            value: getBoolean(this.value),
            disabled: this.disabled,
        })
    }

    ngAfterViewInit(): void {
        this.checkboxFormControl.valueChanges
            .pipe(untilDestroyed(this), debounceTime(10))
            .subscribe((event) => {
                this.cellValueChanged.emit(event !== null ? Number(event) : -1)
            })
    }
}
