<ng-container *ngIf="fieldTypes$ | async as fieldTypes">
    <ng-container *ngIf="fieldTypes && record && fields; else error">
        <div class="w-100 board-header">
            <app-card-header
                [folderStatuses]="folderStatuses"
                [fieldTypes]="fieldTypes"
                [record]="record"
            ></app-card-header>
            <button (click)="pinRecord()" mat-icon-button>
                <mat-icon *ngIf="isRecordPinned">check</mat-icon>
                <mat-icon> minimize</mat-icon>
            </button>
        </div>

        <form>
            <div class="flex-start-center w-100 board-body align-items-start">
                <div class="board-side">
                    <div class="board-side-top">
                        <app-card-title
                            [schema]="schema"
                            [fieldTypes]="fieldTypes"
                            [record]="record"
                            [fields]="fields"
                            [folder]="selectedFolder"
                        ></app-card-title>
                    </div>
                    <div class="board-side-content" *ngIf="selectedFolder && selectedFolder.guid">
                        <app-card-content
                            [schema]="schema"
                            [description]="fields[record.description[selectedFolder.guid].fieldGuid]"
                            [richTextDescription]="fields[record.richTextDescription.fieldGuid]"
                            [fieldTypes]="fieldTypes"
                            [fields]="fields"
                            [folderStatuses]="folderStatusesArr"
                            [record]="record"
                            [status]="fields[record.status[selectedFolder.guid].fieldGuid]"
                            [selectedFolder]="selectedFolder"
                            [cells]="cells"
                        ></app-card-content>
                    </div>
                </div>

                <div class="board-side">
                    <div class="board-side-top">
                        <app-card-update
                            [fieldTypes]="fieldTypes"
                            [record]="record"
                            [fields]="fields"
                        ></app-card-update>
                    </div>
                    <div class="board-side-content"></div>
                </div>
            </div>
        </form>
    </ng-container>
</ng-container>

<ng-template #error>
    <!--    TODO: add UI for error-->
    error
</ng-template>
