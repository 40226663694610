import { Component, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { TextOneLineConfig } from '../../../../../@core/models'
import { FilterTypesComponent } from '../filter-types.component'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-text-one-line-filter',
    templateUrl: './text_one_line-filter.component.html',
    styleUrls: ['./text_one_line-filter.component.sass'],
})
export class TextOneLineFilterComponent extends FilterTypesComponent implements OnInit {
    textFormControl!: FormControl<string | string[] | undefined | null>
    minLength!: number
    maxLength!: number

    ngOnInit(): void {
        if (this.field.configure_json) {
            const textConfig: TextOneLineConfig = JSON.parse(this.field.configure_json)
            if (textConfig) {
                this.minLength = textConfig.minLength
                this.maxLength = textConfig.maxLength
            } else {
                this.minLength = 0
                this.maxLength = 1000
            }
        }

        this.textFormControl = this.form.controls['value'] as FormControl
        this.textFormControl.addValidators([
            Validators.maxLength(this.maxLength),
            Validators.minLength(this.minLength),
        ])
    }

    controlMaxLength() {
        if (this.textFormControl.value) {
            return !(this.textFormControl.value.length >= this.maxLength)
        }
        return true
    }

    controlLengthMaxLength() {
        if (this.textFormControl.value)
            if (
                this.textFormControl.value.length >= this.maxLength &&
                typeof this.textFormControl.value === 'string'
            ) {
                this.textFormControl.value.substr(this.maxLength)
                return false
            }
        return true
    }
}
