import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity'
import { Schema } from '../../models'
import { createFeature, createReducer, on } from '@ngrx/store'
import { initSchemas, setSelectedTableSchemaGuid, updateSchema, updateSchemas } from '../schema'

const schemaFeatureKey: string = 'SCHEMA'

export interface SchemaState extends EntityState<Schema> {
    selectedTableSchemaGuid: string | null
}

function selectSchemaId(schema: Schema): string {
    return schema.guid
}

export const schemaAdapter: EntityAdapter<Schema> = createEntityAdapter<Schema>({
    selectId: selectSchemaId,
})

const initialState: SchemaState = schemaAdapter.getInitialState({
    selectedTableSchemaGuid: null,
})

const schemaReducer = createReducer(
    initialState,
    on(initSchemas, (state: SchemaState, { schemas }) => {
        return schemaAdapter.setAll(schemas, {
            ...state,
            selectedTableSchemaGuid: state.selectedTableSchemaGuid,
        })
    }),
    on(setSelectedTableSchemaGuid, (state: SchemaState, { selectedSchemaGuid }) => {
        return {
            ...state,
            selectedTableSchemaGuid: selectedSchemaGuid,
        }
    }),
    on(updateSchema, (state: SchemaState, { schema }: { schema: Update<Schema> }) => {
        return schemaAdapter.updateOne(schema, state)
    }),
    on(updateSchemas, (state: SchemaState, { schemas }: { schemas: Update<Schema>[] }) => {
        return schemaAdapter.updateMany(schemas, state)
    }),
)

export const schemaFeature = createFeature({
    name: schemaFeatureKey,
    reducer: schemaReducer,
})
