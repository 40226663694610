import { UserState } from './user'
import { FieldTypeState } from './field-type'
import { CommonState } from './common'
import { FolderState } from './folder'
import { RecordState } from './record'
import { SchemaState } from './schema'
import { ViewState } from './view'
import { ConfigState } from './config'

export interface AppState {
    users: UserState
    FIELD_TYPE: FieldTypeState
    COMMON: CommonState
    CONFIG: ConfigState
    FOLDER: FolderState
    RECORD: RecordState
    SCHEMA: SchemaState
    VIEW: ViewState
}
