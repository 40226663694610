import { Component, OnInit } from '@angular/core'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import {
    AppRecord,
    BusinessRecords,
    CellEntities,
    Field,
    Folder,
    GlobalFieldNames,
    prepareCellsForRecords,
    prepareFromGroups,
    RecordGroup,
    ViewData,
} from '../../@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { filter, mergeMap, Observable, of } from 'rxjs'
import { map, switchMap, take } from 'rxjs/operators'
import { getBoolean, isNonNull } from '../../@core/global-util'
import { CrudRecordModelFactoryService } from '../../@core/services/crud-record-model-factory.service'
import { RecordsService } from '../../@core/services/records.service'
import {
    SchemaFacadeService,
    FieldTypeFacadeService,
    FolderFacadeService,
    RecordFacadeService,
} from '../../@core/services/store-facade'

@UntilDestroy()
@Component({
    selector: 'app-board',
    templateUrl: './board.component.html',
    styleUrls: ['./board.component.sass'],
})
export class BoardComponent implements OnInit {
    ViewData!: ViewData
    visibleFields: string[] = []
    fields: string[] = []
    folderGuids!: string
    selectedFolder$: Observable<Folder> = this.folderFacadeService.selectSelectedFolder$.pipe(
        filter(isNonNull),
    )
    fieldTypes$ = this.fieldTypeFacadeService.selectFieldTypeEntities$
    isCreateOpened: boolean = false
    cleanAll!: boolean
    newRecordHasData!: boolean
    cells!: { [recordGuid: string]: CellEntities }
    selectedRecords: BusinessRecords[] = []

    NO_GROUPED_RECORDS_KEY = 'NO_GROUPED_RECORDS_KEY'

    constructor(
        private crudRecordModelFactory: CrudRecordModelFactoryService,
        private recordService: RecordsService,
        private schemaFacadeService: SchemaFacadeService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private folderFacadeService: FolderFacadeService,
        private recordFacadeService: RecordFacadeService,
    ) {}

    get groups(): RecordGroup[] {
        if (this.ViewData.data instanceof Map) {
            return [...this.ViewData.data.values()]
        }

        return []
    }

    get records(): AppRecord[] {
        if (this.ViewData?.data instanceof Array) {
            return [...this.ViewData?.data]
        }
        return []
    }

    drop(moveData: { field: Field; value: string; event: CdkDragDrop<BusinessRecords[], any> }) {
        const field = moveData.field
        const event = moveData.event
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
        } else {
            this.moveCardToAnotherColumn(field, event, moveData.value)
        }
    }

    ngOnInit(): void {
        this.recordFacadeService
            .selectViewData$()
            .pipe(
                untilDestroyed(this),
                switchMap((viewData) => {
                    if (!viewData || viewData.data instanceof Map) return of(viewData)
                    return this.changeArrayDataToGroup(viewData)
                }),
            )
            .subscribe((ViewData: ViewData | undefined) => {
                if (ViewData) {
                    this.ViewData = ViewData
                    this.visibleFields = [...ViewData.columns.columns]
                    this.prepareCells()
                }
            })
        this.selectedFolder$
            .pipe(
                untilDestroyed(this),
                mergeMap((folder) => {
                    if (getBoolean(folder?.is_global.value)) {
                        return this.folderFacadeService.selectFolderEntities$.pipe(
                            map((names) => {
                                return Object.keys(names)
                                    .filter((guid) => guid !== folder.guid)
                                    .toString()
                            }),
                        )
                    }
                    return of(folder?.guid)
                }),
            )
            .subscribe((guids) => (this.folderGuids = guids as string))
    }

    createRecord(value: string, schemaGuid?: string, groupField?: Field, groupValue?: string) {
        if (!this.ViewData) return

        this.selectSchemaForCreateData(schemaGuid).subscribe((schema) => {
            if (!this.ViewData || !schema) return

            const nameField = Object.values(this.ViewData.fields).find(
                (field) => field.system_name == GlobalFieldNames.NAME,
            )!

            const fieldArr = [
                {
                    fieldGuid: nameField.guid,
                    value: value,
                },
            ]

            if (groupField && groupValue && groupValue !== this.NO_GROUPED_RECORDS_KEY) {
                fieldArr.push({
                    fieldGuid: groupField.guid,
                    value: groupValue,
                })
            }

            const createModel = this.crudRecordModelFactory.prepareCreateModelWithFields(
                '',
                schema.guid,
                this.folderGuids,
                fieldArr,
            )
            this.recordService.createRecord(createModel)
        })
    }

    fieldsEdit(field: Field) {
        this.schemaFacadeService.updateField(field)
    }

    selectRecords(records: BusinessRecords[]) {
        if (!this.selectedRecords.length) {
            this.selectedRecords = [...records]
            return
        }
        const updatedSelectedRecords = this.selectedRecords.filter((record) => {
            if (records.includes(record)) {
                const index = records.indexOf(record)
                records.splice(index, 1)
                return false
            }
            return true
        })
        this.selectedRecords = [...updatedSelectedRecords.concat(records)]
    }

    clearSelected() {
        this.selectedRecords = []
    }

    deleteSelected() {
        this.recordService.deleteRecord(this.selectedRecords)
        this.clearSelected()
    }

    editSelected(records: { value: string; field: Field }) {
        const data = this.selectedRecords.map((record) => {
            return {
                record: record,
                cell: this.cells[record.guid][records.field.guid],
                value: records.value,
            }
        })
        this.recordService.updateRecords(data)
        this.clearSelected()
    }

    private selectSchemaForCreateData(schemaGuid?: string) {
        if (!schemaGuid) {
            return this.schemaFacadeService.selectSelectedTableSchema$.pipe(take(1))
        }

        return this.schemaFacadeService
            .selectSchemaByGuid$(schemaGuid)
            .pipe(take(1), untilDestroyed(this))
    }

    private prepareCells() {
        if (this.records?.length) {
            this.cells = prepareCellsForRecords(this.records)
        } else if (this.groups?.length) {
            this.cells = prepareFromGroups(this.groups)
        }
    }

    private moveCardToAnotherColumn(
        field: Field,
        event: CdkDragDrop<BusinessRecords[], any>,
        value: string,
    ) {
        const moveCard = (event.previousContainer.data as BusinessRecords[]).find(
            (record) => record.guid === event.item.data,
        )

        if (!moveCard || this.isNoGroupedStatus(field.field_type_code, value)) return

        this.recordService.updateRecord({
            record: moveCard!,
            cell: this.cells[moveCard.guid][field.guid],
            value: value !== this.NO_GROUPED_RECORDS_KEY ? value : '',
        })
    }

    private changeArrayDataToGroup(viewData: ViewData) {
        return this.folderFacadeService.selectSelectedFolderStatusField$.pipe(
            switchMap((folderStatus) => {
                if (!folderStatus || !viewData) return of(viewData)

                return this.changeViewData(folderStatus, viewData)
            }),
        )
    }

    private changeViewData(folderStatus: Field, viewData: ViewData) {
        const data = this.recordFacadeService.generateGroup(
            viewData.data as BusinessRecords[],
            folderStatus.guid,
            folderStatus,
        )

        return of({
            ...viewData,
            data: data,
        })
    }

    private isNoGroupedStatus(fieldType: string, value: string) {
        return fieldType === 'field_type_status' && value === this.NO_GROUPED_RECORDS_KEY
    }
}
