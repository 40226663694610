import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { Field, Folder, getIconByFieldType } from '../../@core/models'
import { getBoolean } from '../../@core/global-util'

@Component({
    selector: 'app-field-name',
    templateUrl: './field-name.component.html',
    styleUrls: ['./field-name.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldNameComponent implements OnChanges {
    @Input()
    field!: Field

    @Input()
    selectedFolder!: Folder

    @Input()
    showIcon!: boolean

    showFolderName!: boolean

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedFolder) {
            if (this.selectedFolder && this.field) {
                if (getBoolean(this.selectedFolder.is_global.value)) {
                    this.showFolderName = !!this.field.folder_name?.is_global
                } else {
                    this.showFolderName = this.field.folder_guid === this.selectedFolder.guid
                }
            } else {
                this.showFolderName = false
            }
        }
    }

    getFieldIcon() {
        return getIconByFieldType(this.field.field_type_code)
    }
}
