import { FieldTypes } from './field-type.model'
import { SelectObjectOptions } from './select-object-options'
import { LinkDefinition } from '../ui'

export enum SystemTypeCode {
    FOLDER = 'folder',
    VIEW = 'view',
    TABLE = 'table',
    CONFIG = 'configuration',
}

export interface ResponseSchema {
    guid: string
    name: string
    is_system?: number
    system_object_type_code: SystemTypeCode | string
    revision: number
    field: ResponseFieldEntities
    object_type_code?: string
    acl?: number
}

export interface ResponseFieldEntities {
    [guid: string]: ResponseField
}

export interface ResponseField {
    name?: string
    is_required?: number
    is_primary?: number
    acl?: number
    field_type_code: FieldTypes | string
    configure_json?: string
    revision?: number
    frontend_validations?: string
    folder_guid?: string
    system_name?: string
    select_object_field?: SelectObjectOptions
    scope?: string
    operation_code?: string
    shared_with_folder?: string
    link_definition?: LinkDefinition
    virtual_link?: string
}
