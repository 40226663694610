import { Injectable } from '@angular/core'
import { Dictionary, Update } from '@ngrx/entity'
import {
    AppRecord,
    BusinessRecords,
    DataItem,
    generateFieldTypes,
    generateRecord,
    generateRecords,
    generateSchema,
    getRecordCells,
    ObjectResponseModel,
    ObjectType,
    regenerateCells,
    ResponseFieldEntities,
    ResponseFieldTypeEntities,
    ResponseRecord,
    ResponseSchema,
    Schema,
    SolutionModel,
    TableModel,
    UserModelEntities,
} from '../models'
import { map, take } from 'rxjs/operators'
import { SystemRecordService } from './system-record.service'
import { RoleEntities } from '../models/response/role'
import { GroupEntities } from '../models/response/group'
import { LinkEntities } from '../models/response/link'
import { FolderNameEntities } from '../models/response/folder-names'
import { NotificationService } from './notification.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { pickBy, reduce } from 'lodash-es'
import { combineLatest, of } from 'rxjs'
import {
    CommonFacadeService,
    FieldTypeFacadeService,
    FolderFacadeService,
    RecordFacadeService,
    SchemaFacadeService,
    UserFacadeService,
} from './store-facade'
import cloneDeep from 'lodash/cloneDeep'
import { LinkReferenceService } from './link-reference.service'
import { isNonNull } from '../global-util'
import { ResponseError } from '../models/response/response-error'

export interface DataObject {
    users?: UserModelEntities
    fieldTypes?: ResponseFieldTypeEntities
    solution?: SolutionModel
    tables: TableModel[]
    schemas: ResponseSchema[]
    role?: RoleEntities
    group?: GroupEntities
    systemFields?: ResponseFieldEntities
    link?: LinkEntities
    folderNames?: FolderNameEntities
    errors?: ResponseError[]
}

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class SaveDataService {
    constructor(
        private commonFacadeService: CommonFacadeService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private systemRecordService: SystemRecordService,
        private recordFacadeService: RecordFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private folderFacadeService: FolderFacadeService,
        private userFacadeService: UserFacadeService,
        private linkReferenceService: LinkReferenceService,
        private notificationService: NotificationService,
    ) {}

    public saveInitData(response: ObjectResponseModel) {
        const dataObject: DataObject = this.getDataObjectFromResponse(cloneDeep(response))

        this.saveUsers(dataObject)
        this.saveRoles(dataObject)
        this.saveFieldTypes(dataObject)
        this.saveSolution(dataObject)
        this.saveSystemFields(dataObject)
        this.saveCurrentUser(response)
        this.saveFolderNames(dataObject)

        const dataObjectWithVirtualFields = this.initVirtualFieldsInSchemasAndTables(dataObject)
        this.saveSchemas(dataObjectWithVirtualFields)
        this.saveRecords(dataObjectWithVirtualFields)

        this.commonFacadeService.dataInitialized()
    }

    private initVirtualFieldsInSchemasAndTables(dataObject: DataObject) {
        this.linkReferenceService.initLinkRefStore(dataObject.schemas, dataObject.tables)
        const schemasWithVirtualFields = this.linkReferenceService.setStoredVirtualFieldsToSchemas(
            dataObject.schemas,
        )
        const dataTablesWithVirtualFields = this.linkReferenceService.setStoredVirtualCellsToTables(
            schemasWithVirtualFields,
            dataObject.tables,
        )

        return {
            ...cloneDeep(dataObject),
            schemas: schemasWithVirtualFields,
            tables: dataTablesWithVirtualFields,
        }
    }

    public saveResetData(response: ObjectResponseModel) {
        const dataObject: DataObject = this.getDataObjectFromResponse(cloneDeep(response))

        this.resetRecords()

        this.commonFacadeService.selectSystemFields$
            .pipe(take(1), untilDestroyed(this))
            .subscribe((systemFields) => {
                if (systemFields) {
                    dataObject.systemFields = systemFields

                    this.saveCurrentUser(response)
                    this.saveFolderNames(dataObject)
                    this.saveSchemas(dataObject)
                    this.saveRecords(dataObject)
                } else {
                    console.error(new Error('no system fields on reset'))
                }
            })
    }

    public saveUpdatedData(response: ObjectResponseModel) {
        // TODO update logic for new types
        const dataObject: DataObject = this.getDataObjectFromResponse(cloneDeep(response))

        this.notificationService.open(dataObject)

        if (dataObject.tables.length) {
            dataObject.tables.forEach((table) => {
                this.updateTable(table)
            })
        } else if (dataObject.schemas.length) {
            this.saveUpdatedSchemas(dataObject)
        } else if (dataObject.users) {
            this.userFacadeService.setUsers(dataObject.users)
        } else if (dataObject.fieldTypes) {
            const fieldTypes = generateFieldTypes(dataObject.fieldTypes)
            this.fieldTypeFacadeService.setFieldTypes(fieldTypes)
        } else if (dataObject.solution) {
            console.log('dataObject.solution', dataObject.solution)
        }
    }

    private saveUpdatedSchemas(dataObject: DataObject) {
        combineLatest([
            dataObject.folderNames ? of(undefined) : this.folderFacadeService.selectFolderNames$,
            this.commonFacadeService.selectSystemFields$,
            this.schemaFacadeService.selectAllSchemas$,
        ])
            .pipe(take(1), untilDestroyed(this))
            .subscribe(
                ([folderNames, systemFields, allSchemas]: [
                    FolderNameEntities | undefined,
                    ResponseFieldEntities | null,
                    Schema[],
                ]) => {
                    if (!folderNames) {
                        throw Error('Unable to find folderNames!')
                    }

                    if (!systemFields) {
                        throw Error('Unable to find systemFields!')
                    }

                    dataObject.folderNames = folderNames
                    dataObject.systemFields = systemFields

                    const schemasWithSystemFields = this.createSchemasWithSystemFieldsAndVirtual(
                        dataObject,
                        allSchemas,
                    )

                    const schemas: Update<Schema>[] = this.saveUpdatedRecordsBySchemaUpdate(
                        schemasWithSystemFields,
                        dataObject,
                    )

                    this.linkReferenceService.updateSchemasWithVirtualFields(schemas)

                    console.log('schemas from save', schemas)

                    schemasWithSystemFields.forEach((schema) => {
                        this.updateSchemaRecords(schema)
                    })

                    this.schemaFacadeService.updateSchemas(schemas)
                },
            )
    }

    private createSchemasWithSystemFieldsAndVirtual(dataObject: DataObject, prevSchemas: Schema[]) {
        return dataObject.schemas
            .map((responseSchema: ResponseSchema) => {
                return {
                    ...responseSchema,
                    field: {
                        ...responseSchema.field,
                        ...this.selectSystemFieldsByScope(dataObject, responseSchema),
                    },
                }
            })
            .map((responseSchema) => {
                return this.fillSchemaWithVirtualFieldsFromPreviousSchema(
                    prevSchemas,
                    responseSchema,
                )
            })
    }

    private fillSchemaWithVirtualFieldsFromPreviousSchema(
        allSchemas: Schema[],
        newResponseSchema: ResponseSchema,
    ) {
        const prevSchema = allSchemas.find(
            (prevSchema) => prevSchema.guid === newResponseSchema.guid,
        )
        if (!prevSchema) return cloneDeep(newResponseSchema)

        const virtualFields = pickBy(prevSchema.fieldEntities, (field) => !!field.virtual_link)

        return reduce(
            virtualFields,
            (schema, field) => {
                schema.field[field.guid] = field
                return schema
            },
            cloneDeep(newResponseSchema),
        )
    }

    private saveUpdatedRecordsBySchemaUpdate(
        responseSchemas: ResponseSchema[],
        dataObject: DataObject,
    ) {
        return responseSchemas.map((responseSchema: ResponseSchema) => {
            return {
                id: responseSchema.guid,
                changes: generateSchema(responseSchema, dataObject.folderNames!),
            }
        })
    }

    private updateSchemaRecords(responseSchema: ResponseSchema) {
        this.recordFacadeService
            .selectDataTableRecordsBySchemaGuid$(responseSchema.guid)
            .pipe(take(1), untilDestroyed(this))
            .subscribe((recordsArr: AppRecord[]) => {
                const records = recordsArr
                    .map((record: AppRecord) => {
                        const recordModel: ResponseRecord = {
                            revision: record.revision,
                            folders_guid: record.folder_guids,
                            cells: getRecordCells(record),
                        }

                        return generateRecord(record.guid, responseSchema, recordModel)!
                    })
                    .map<Update<AppRecord>>((record) => {
                        regenerateCells(record)

                        return {
                            id: record.guid!,
                            changes: record,
                        }
                    })
                this.recordFacadeService.updateRecordsFromResponse(records)
            })
    }

    private saveRecords({ tables, schemas }: DataObject) {
        const schemaEntities: Dictionary<ResponseSchema> = schemas.reduce(
            (res: Dictionary<ResponseSchema>, next: ResponseSchema) => {
                return { [next.guid]: next, ...res }
            },
            {},
        )

        tables.forEach((tableModel: TableModel) => {
            const schema = schemaEntities[tableModel.guid]
            if (!schema) {
                console.error('incorrect schema guid', tableModel)
                return
            }
            if (schema.is_system) {
                this.systemRecordService.initSystemRecordByObjectType(schema, tableModel.record!)
            } else {
                const records = generateRecords(schema, tableModel.record!) as BusinessRecords[]

                this.recordFacadeService.initRecords(records)
            }
        })
    }

    private saveSchemas(dataObject: DataObject) {
        const schemas: Schema[] = dataObject.schemas.map((responseSchema: ResponseSchema) => {
            responseSchema.field = {
                ...responseSchema.field,
                ...this.selectSystemFieldsByScope(dataObject, responseSchema),
            }
            if (dataObject.folderNames)
                return generateSchema(responseSchema, dataObject.folderNames)
            else {
                throw Error('Unable to find folder names!')
            }
        })

        this.schemaFacadeService.initSchemas(schemas)
    }

    private saveCurrentUser(response: ObjectResponseModel) {
        if (response.user) {
            this.userFacadeService.setUser(response.user)
        } else {
            console.log(new Error(`No current user`))
        }
    }

    private saveRoles(dataObject: DataObject) {
        if (dataObject.role) {
            const roles = dataObject.role
            this.commonFacadeService.setRoles(roles)
        } else {
            console.log(new Error(`No roles`))
        }
    }

    private saveFolderNames(dataObject: DataObject) {
        if (dataObject.folderNames) {
            const folderNames = dataObject.folderNames
            this.folderFacadeService.initFolderNames(folderNames)
        } else {
            console.log(new Error('no folder object in response'))
        }
    }

    private saveUsers(dataObject: DataObject) {
        if (dataObject.users) {
            this.userFacadeService.setUsers(dataObject.users)
        } else {
            console.log(new Error('no users object in response'))
        }
    }

    private saveFieldTypes(dataObject: DataObject) {
        if (dataObject.fieldTypes) {
            const fieldTypes = generateFieldTypes(dataObject.fieldTypes)
            this.fieldTypeFacadeService.setFieldTypes(fieldTypes)
        } else {
            console.log(new Error('no fieldTypes object in response'))
        }
    }

    private saveSolution(dataObject: DataObject) {
        if (dataObject.solution) {
            const solution = dataObject.solution
            this.commonFacadeService.setSolution(solution)
        } else {
            console.log(new Error('no solution object in response'))
        }
    }

    private getDataObjectFromResponse(response: ObjectResponseModel): DataObject {
        const dataObject: DataObject = {
            tables: [],
            schemas: [],
        }

        response.data.forEach((item: DataItem) => {
            switch (item.type) {
                case ObjectType.SCHEMA:
                    dataObject.schemas.push(<ResponseSchema>item.object)
                    break
                case ObjectType.TABLE:
                    dataObject.tables.push(<TableModel>item.object)
                    break
                case ObjectType.USER:
                    dataObject.users = <UserModelEntities>item.object
                    break
                case ObjectType.FIELD_TYPE:
                    dataObject.fieldTypes = <ResponseFieldTypeEntities>item.object
                    break
                case ObjectType.SOLUTION:
                    dataObject.solution = <SolutionModel>item.object
                    break
                case ObjectType.ROLE:
                    dataObject.role = <RoleEntities>item.object
                    console.warn(new Error('save roles to store'))
                    break
                case ObjectType.GROUP:
                    dataObject.group = <GroupEntities>item.object
                    console.warn(new Error('save groups to store'))
                    break
                case ObjectType.SYSTEM_FIELDS:
                    dataObject.systemFields = <ResponseFieldEntities>item.object
                    break
                case ObjectType.FOLDER:
                    dataObject.folderNames = <FolderNameEntities>item.object
                    break
                case ObjectType.LINK:
                    dataObject.link = <LinkEntities>item.object
                    console.warn(new Error('save links to store'))
                    break
                case ObjectType.ACTION_TYPE:
                case ObjectType.AUTOMATION:
                case ObjectType.SOURCE_TYPE:
                    console.error(`Item type ${item.type} not implemented`)
                    break
                default:
                    console.error(`Incorrect type of DataItem "${item.type}"`)
            }
        })

        return dataObject
    }

    private selectSystemFieldsByScope(
        { systemFields }: DataObject,
        responseSchema: ResponseSchema,
    ): ResponseFieldEntities {
        if (!systemFields) {
            return {}
        }
        return pickBy(
            systemFields,
            (value, key) =>
                !systemFields[key].scope || systemFields[key].scope === responseSchema.guid,
        )
    }

    private updateTable(table: TableModel) {
        this.schemaFacadeService
            .selectSchemaByGuid$(table.guid)
            .pipe(
                untilDestroyed(this),
                take(1),
                map((schema: Schema | undefined) => {
                    return schema!
                }),
            )
            .subscribe((schema: Schema) => {
                if (schema.is_system) {
                    this.systemRecordService.updateSystemRecord(table, schema)
                } else {
                    this.cudRecord(table, schema)
                }
            })
    }

    private cudRecord(table: TableModel, schema: Schema) {
        if (table.deleted) {
            this.linkReferenceService.removeRecords(table.deleted, schema)
            this.recordFacadeService.deleteRecordsFromResponse(table.deleted)
        }
        if (table.added) {
            const records = generateRecords(schema, table.added) as BusinessRecords[]
            this.linkReferenceService.addRecords(records, schema)
            this.recordFacadeService.addRecordsFromResponse(records)
        }
        if (table.updated) {
            const records = Object.keys(table.updated!)
                .map((recordGuid: string) => {
                    return generateRecord(recordGuid, schema, table.updated![recordGuid])
                }, [])
                .filter(isNonNull) as AppRecord[]

            const updatedRecords = records.map((record) => ({ id: record.guid, changes: record }))

            this.linkReferenceService.updateRecords(records, schema)
            this.recordFacadeService.updateRecordsFromResponse(updatedRecords)
        }
    }

    private saveSystemFields({ systemFields }: DataObject) {
        if (systemFields) {
            this.commonFacadeService.initSystemFields(systemFields)
        } else {
            console.log(new Error('no systemFields in response'))
        }
    }

    private resetRecords() {
        this.recordFacadeService.resetRecords()
    }
}
