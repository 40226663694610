<ng-container [ngSwitch]="appearance">
    <button
        *ngSwitchCase="'primary'"
        class="flex w-inherit py-3 px-5 rounded-lg justify-center items-center sm:py-2 text-white transition-colors"
        [class]="[bgColor, 'hover:' + getDark(bgColor) ]"
        [class.pointer-events-none]="disabled"
    >
        <app-tb-icon *ngIf="icon" [icon]="icon" color="white" class="mr-2"></app-tb-icon>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </button>

    <button
        *ngSwitchCase="'outlined'"
        class="flex w-inherit py-3 px-5 rounded-lg justify-center items-center sm:py-2 transition-colors border border-solid"
        [class]="[borderColor, 'hover:' + getDark(borderColor), textColor, 'hover:' + getDark(textColor)]"
        [class.pointer-events-none]="disabled"
    >
        <app-tb-icon *ngIf="icon" [icon]="icon" class="mr-2"></app-tb-icon>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </button>

    <button
        *ngSwitchCase="'text'"
        class="flex w-inherit py-1 px-2 rounded-lg justify-center items-center transition-colors"
        [class]="[disabled ? textColor : getLight(textColor), 'hover:' + textColor]"
        [class.pointer-events-none]="disabled"
    >
        <app-tb-icon *ngIf="icon" [icon]="icon" class="mr-2"></app-tb-icon>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </button>

    <button
        *ngSwitchCase="'icon'"
        class="p-0.5 rounded-lg cursor-pointer hover:bg-newNeutral4"
        [class.pointer-events-none]="disabled"
        [class]="[disabled ? textColor: '']"
    >
        <app-tb-icon *ngIf="icon; else content" [color]="iconColor" [icon]="icon"></app-tb-icon>
    </button>

    <div *ngSwitchCase="'fab'" class="relative">
        <button
            class="relative p-2.5 sm:p-2 rounded-full cursor-pointer transition-colors"
            [class]="[bgColor, 'hover:' + getDark(bgColor)]"
            [class.pointer-events-none]="disabled"
        >
            <app-tb-icon color="white" class="icon-xlg sm:icon-lg" [icon]="icon"></app-tb-icon>
        </button>
    </div>
</ng-container>

<ng-template #content>
    <span class="body-2">
        <ng-content></ng-content>
    </span>
</ng-template>
