import { Injectable } from '@angular/core'
import {
    AppRecord,
    CreateRecordModel,
    ObjectResponseModel,
    RecordUpdate,
    UpdateLinkModel,
} from '../models'
import { take, tap } from 'rxjs/operators'
import { UpdateService } from './update.service'
import { Observable } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { CommonFacadeService } from './store-facade/common-facade.service'

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class RecordsService {
    constructor(
        private commonFacadeService: CommonFacadeService,
        private updateService: UpdateService,
    ) {}

    updateRecord(data: RecordUpdate) {
        this.callUpdateService(this.updateService.updateRecord(data))
    }

    updateLink(data: RecordUpdate) {
        const linkData: UpdateLinkModel = {
            record_guid: data.record.guid!,
            record_revision: data.record.revision!,
            object_field_guid: data.cell!.fieldGuid,
            revision: data.cell!.revision === 0 ? 1 : data.cell!.revision,
            add_guids: data.value!.add_guids.length ? data.value!.add_guids : [],
            delete_guids: data.value!.delete_guids.length ? data.value!.delete_guids : [],
        }
        this.callUpdateService(this.updateService.updateLink([linkData]))
    }

    updateRecords(data: RecordUpdate[]) {
        this.callUpdateService(this.updateService.updateRecord(data))
    }

    createRecord(record: CreateRecordModel) {
        this.callUpdateService(this.updateService.createRecord(record))
    }

    deleteRecord(data: AppRecord | Array<AppRecord>) {
        this.callUpdateService(this.updateService.deleteRecord(data))
    }

    private callUpdateService(observable: Observable<ObjectResponseModel>) {
        this.commonFacadeService.setLoading(true)

        observable
            .pipe(
                tap((data) => {
                    this.commonFacadeService.onMessage(data)
                    this.commonFacadeService.setLoading(false)
                }),
                take(1),
                untilDestroyed(this),
            )
            .subscribe()
    }
}
