import { Injectable } from '@angular/core'
import { OnInitEffects } from '@ngrx/effects'
import { Action } from '@ngrx/store'

@Injectable()
export class ViewEffects implements OnInitEffects {
    ngrxOnInitEffects(): Action {
        return { type: '[ViewEffects]: Init' }
    }
}
