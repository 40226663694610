import { createAction, props } from '@ngrx/store'
import { Deleted, View } from '../../models'
import { Update } from '@ngrx/entity'

export const initViews = createAction(
    '[View] Init views',
    props<{ views: View[]; selectedViewGuid?: string }>(),
)

export const viewsAreLoaded = createAction('[View] Views are loaded')

export const openViewFilterMenu = createAction('[View] Open filter menu')

export const setSelectedViewGuid = createAction(
    '[View] Set selected view',
    props<{ selectedViewGuid: string | null }>(),
)

export const addViews = createAction('[View] Add views', props<{ views: View[] }>())

export const updateViews = createAction('[View] Update views', props<{ views: Update<View>[] }>())

export const deleteViews = createAction('[View] Delete views', props<{ views: Deleted[] }>())
