import { Cell } from './cell.model'
import { AppRecord } from './base-record.model'

export interface BusinessRecord extends AppRecord {
    name: Cell
    watch: Cell
    richTextDescription: Cell
    status: { [folderGuid: string]: Cell }
    assignee: { [folderGuid: string]: Cell }
    description: { [folderGuid: string]: Cell }
}

export interface Vendor extends BusinessRecord {
    address: Cell
}

export interface Deal extends BusinessRecord {
    vendor_name: Cell
    amount: Cell
}

export interface Contact extends BusinessRecord {
    email: Cell
    phone: Cell
}

export enum GlobalFieldNames {
    NAME = 'name',
    WATCH = 'watch',
    STATUS = 'status',
    ASSIGNEE = 'assignee',
    DESCRIPTION = 'description',
    RICH_TEXT_DESCRIPTION = 'richTextDescription',
}

export enum VendorFieldNames {
    ADDRESS = 'address',
}

export enum DealFieldNames {
    VENDOR_NAME = 'vendor_name',
    AMOUNT = 'amount',
}

export enum ContactFieldNames {
    EMAIL = 'email',
    PHONE = 'phone',
}
