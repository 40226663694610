import { Injectable } from '@angular/core'
import { combineLatest, filter } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { map, switchMap, take } from 'rxjs/operators'
import { TranslocoService } from '@ngneat/transloco'
import { FolderFacadeService, CommonFacadeService, SchemaFacadeService } from './store-facade'

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class SystemRecordsErrorService {
    constructor(
        private commonFacadeService: CommonFacadeService,
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private translation: TranslocoService,
    ) {}

    getApplicationErrorMessages() {
        return this.commonFacadeService.selectLoaded$.pipe(
            filter(Boolean),
            switchMap(() => this.getErrorMessages()),
        )
    }

    private getErrorMessages() {
        return combineLatest([
            this.folderFacadeService.selectFoldersAreLoaded$,
            this.folderFacadeService.selectFoldersIsError$,
            this.schemaFacadeService.selectSelectedTableSchema$,
            this.schemaFacadeService.selectSelectedTableSchemaGuid$,
            this.translation.selectTranslateObject('errors'),
        ]).pipe(
            untilDestroyed(this),
            take(1),
            map(([folderLoaded, folderError, schema, selectedSchemaGuid, errorsText]) => {
                const errorMessages: string[] = []
                if (!folderLoaded || folderError) {
                    errorMessages.push(errorsText['folder_error'])
                }

                if (selectedSchemaGuid && !schema) {
                    errorMessages.push(errorsText['schema_error'])
                }

                if (selectedSchemaGuid && schema?.acl === -1) {
                    errorMessages.push(errorsText['schema_access_error'])
                }

                return errorMessages
            }),
        )
    }
}
