import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { isNumber } from 'lodash-es'
import { MatSort, Sort } from '@angular/material/sort'

export type TableRow = {
    item: any
}

export type TableColumn = {
    isPinned: boolean
    item: any
    colGroup?: {
        colspan: number
        subheaders: string[]
    }
    colWidth?: number
}

export type DropColumns = { columns: TableColumn[]; previousIndex: number; currentIndex: number }
export type MovedRow = {
    item: any
    previousRows: TableRow[]
    rows: TableRow[]
    previousIndex: number
    currentIndex: number
}

export type ResizeColumn = { column: TableColumn; width: number }

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, OnChanges {
    @ViewChild(MatSort, { static: false }) sort!: MatSort

    @Input()
    rows!: TableRow[]

    @Input()
    columns!: TableColumn[]

    @Input()
    lockFirstColumn = false

    @Input()
    isGrouped = false

    @Input()
    uniqueKey!: string

    @Input()
    columnCell!: TemplateRef<any>

    @Input()
    rowCell!: TemplateRef<any>

    @Input()
    rowMenu!: TemplateRef<any>

    @Input()
    selectedRowItems: TableRow[] = []

    @Output()
    changedOrderColumn: EventEmitter<DropColumns> = new EventEmitter()

    @Output()
    movedRowFromAnotherTable: EventEmitter<MovedRow> = new EventEmitter()

    @Output()
    resizeColumn: EventEmitter<ResizeColumn> = new EventEmitter()

    @Output()
    sortData: EventEmitter<Sort> = new EventEmitter()

    @Output()
    clearSorting: EventEmitter<void> = new EventEmitter()

    @Output()
    selectedRow: EventEmitter<TableRow> = new EventEmitter()

    gridTemplateColumnWidth!: string
    draggableColumns!: TableColumn[]
    fixedColumns!: TableColumn[]

    DEFAULT_COLUMN_WIDTH = 300
    doesHaveColGroups = false
    sortedBy = ''

    ngOnInit(): void {
        this.doesHaveColGroups = this.columns.some((column) => !!column.colGroup)
        this.setFixedAndDraggableColumns()
        this.setGridTemplate()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.columns) {
            this.setFixedAndDraggableColumns()
            this.setGridTemplate()
        }
    }

    dropRow(event: CdkDragDrop<TableRow[], any>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
            return
        }

        this.movedRowFromAnotherTable.emit({
            previousRows: event.previousContainer.data,
            item: event.item.data.item,
            rows: this.rows,
            previousIndex: event.previousIndex,
            currentIndex: event.currentIndex,
        })
    }

    dropColumnFn({ previousIndex, currentIndex }: CdkDragDrop<TableColumn[], any>) {
        if (previousIndex === currentIndex) return

        this.changedOrderColumn.emit({
            columns: this.draggableColumns,
            previousIndex,
            currentIndex,
        })
    }

    sortColumnClear() {
        this.sort.direction = 'asc'
        this.sort.active = ''
        this.sortedBy = ''
        this.clearSorting.emit()
    }

    selectRow(row: TableRow) {
        this.selectedRow.emit(row)
    }

    isRowSelected(row: TableRow) {
        return !!this.selectedRowItems.find(
            (selectedRow) => selectedRow.item[this.uniqueKey] === row.item[this.uniqueKey],
        )
    }

    resizeTableColumn(column: TableColumn, width: number) {
        if (column.colWidth === width) return
        this.resizeColumn.emit({ column: column, width })
    }

    resizeGridTemplateColumns(gridTemplateChanged: string) {
        this.gridTemplateColumnWidth = gridTemplateChanged
    }

    sortTableData(sort: Sort) {
        this.sortedBy = sort.active
        this.sortData.emit(sort)
    }

    private setGridTemplate() {
        const columnWidth = this.draggableColumns.reduce((gridTemplate, column, index) => {
            return gridTemplate + ' ' + this.getColumnWidth(index)
        }, '')
        this.gridTemplateColumnWidth = columnWidth + ' 60px' //this is for the last button that is not present in the header row
    }

    private getColumnWidth(index: number) {
        const group = this.draggableColumns[index].colGroup
        const colWidth = this.draggableColumns[index].colWidth
        const groupWidth = group
            ? group.colspan * this.DEFAULT_COLUMN_WIDTH
            : this.DEFAULT_COLUMN_WIDTH

        return `${isNumber(colWidth) ? colWidth : groupWidth}px`
    }

    private setFixedAndDraggableColumns() {
        const firstOrPinned = (column: TableColumn, index: number) => index === 0 || column.isPinned

        this.fixedColumns = this.columns.filter((column, index) => firstOrPinned(column, index))
        this.draggableColumns = this.columns.filter(
            (column, index) => !firstOrPinned(column, index),
        )
    }
}
