import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AssignComponent } from './people/assign/assign.component'
import { DateTimeComponent } from './date-time/date-time.component'
import {
    DropdownComponent,
    DropdownContentComponent,
    DropdownDialogComponent,
} from './select/dropdown'
import { StatusComponent, StatusDialogComponent } from './select/status'
import { ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { NgxColorsModule } from 'ngx-colors'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { BooleanComponent } from './boolean/boolean.component'
import { NumberComponent } from './number/number.component'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { RatingComponent } from './rating/rating.component'
import { MatSliderModule } from '@angular/material/slider'
import { CellContainerComponent } from './cell-container.component'
import { PipesModule } from '../../@core/pipes/pipes.module'
import { StatusContentComponent } from './select/status/status-dialog/status-content/status-content.component'
import { OptionItemComponent } from './select/option-item.component'
import { RatingSettingsComponent } from './rating/rating-settings/rating-settings.component'
import { IconDialogComponent } from './select/status-icon-dialog/icon-dialog.component'
import { MoneyComponent } from './money/money.component'
import { UrlComponent } from './url/url.component'
import { TextOneLineComponent } from './text-one-line/text-one-line.component'
import { EmailComponent } from './email/email.component'
import { DateComponent } from './date/date.component'
import { TextOneLineSettingsComponent } from './text-one-line/text-one-line-settings/text-one-line-settings.component'
import { MoneySettingsComponent } from './money/money-settings/money-settings.component'
import { NameComponent } from './name/name.component'
import { RangeComponent } from './range/range.component'
import { MatSelectModule } from '@angular/material/select'
import { WatchComponent } from './people/watch/watch.component'
import { MatBadgeModule } from '@angular/material/badge'
import { TextMultiLineComponent } from './text-multiline/text-multi-line.component'
import { PeopleMenuComponent } from './people/people-menu.component'
import { AutoFocusDirective } from './focus.directive'
import { TranslocoModule } from '@ngneat/transloco'
import { LinkComponent } from './link/link.component'
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material'
import { LinkSettingsComponent } from './link/link-settings/link-settings.component'
import { CommonAppModule } from '../../common/common.module'
import { RichTextComponent } from './rich-text/rich-text.component'
import { LinkReferenceComponent } from './link-reference/link-reference.component'

const cellComponentsForExport = [
    AssignComponent,
    DropdownComponent,
    StatusComponent,
    NumberComponent,
    BooleanComponent,
    CellContainerComponent,
    MoneyComponent,
    TextOneLineComponent,
    EmailComponent,
    NameComponent,
    TextMultiLineComponent,
    WatchComponent,
    AutoFocusDirective,
    RatingComponent,
    RichTextComponent,
    LinkReferenceComponent,
]

@NgModule({
    declarations: [
        ...cellComponentsForExport,
        DateComponent,
        DateTimeComponent,
        UrlComponent,
        TextOneLineSettingsComponent,
        MoneySettingsComponent,
        StatusDialogComponent,
        PeopleMenuComponent,
        DropdownDialogComponent,
        OptionItemComponent,
        DropdownContentComponent,
        StatusContentComponent,
        RatingSettingsComponent,
        IconDialogComponent,
        RangeComponent,
        LinkComponent,
        LinkSettingsComponent,
    ],
    imports: [
        CommonModule,
        CommonAppModule,
        DragDropModule,
        MatInputModule,
        MatMenuModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatCheckboxModule,
        MatSliderModule,
        MatSelectModule,
        MatBadgeModule,
        NgxDaterangepickerMd.forRoot(),
        NgxColorsModule,
        ReactiveFormsModule,
        TranslocoModule,
        PipesModule,
    ],
    exports: [...cellComponentsForExport],
})
export class CellTypesModule {}
