import { ActivatedRouteSnapshot, Params } from '@angular/router'

export function getBoolean(val: any): boolean {
    if (!val) return false

    const num = +val
    return !isNaN(num)
        ? !!num
        : !!String(val)
              .toLowerCase()
              .replace(!!0 + '', '')
}

export function collectParams(root: ActivatedRouteSnapshot): Params {
    let params: Params = {}
    ;(function mergeParamsFromSnapshot(snapshot: ActivatedRouteSnapshot) {
        Object.assign(params, snapshot.params)
        snapshot.children.forEach(mergeParamsFromSnapshot)
    })(root)
    return params
}

export function isNonNull<T>(value: T): value is NonNullable<T> {
    return value !== null && value !== undefined
}

export function generateUuid() {
    return crypto.randomUUID()
}

export const emailValidationPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const numericValidationPattern = /^[0-9]*$/

export function urlValidator(urlString: string) {
    if (!urlString) return false

    try {
        const url = new URL(urlString)

        if (!checkProtocol(url.protocol)) return false
    } catch (_) {
        return false
    }

    return true
}

function checkProtocol(protocol: string) {
    return protocol === 'http:' || protocol === 'https:'
}
