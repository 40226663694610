<div class="flex-start-center justify-content-between">
    <div class="fs-md">
        <ng-container *ngIf="vendorName">
            <ng-container *ngIf="vendorName.value, else noValueVendor">
                {{ vendorName.value }}
            </ng-container>
            <ng-template #noValueVendor> {{ vendorName.fieldName }} </ng-template>
        </ng-container>
    </div>

    <div *ngIf="amount">
        <ng-container *ngIf="amount.value, else noValueAmount"> ${{ amount.value }} </ng-container>
        <ng-template #noValueAmount> {{ amount.fieldName }} </ng-template>
    </div>
</div>
