import { Component, Input } from '@angular/core'
import { FolderStatus } from './folder-status.model'
import { BusinessRecords, Field, FieldType } from '../../../@core/models'
import { Dictionary } from '@ngrx/entity'

@Component({
    selector: 'app-card-header',
    templateUrl: './card-header.component.html',
    styleUrls: ['./card-header.component.sass'],
})
export class CardHeaderComponent {
    @Input() folderStatuses!: { folderStatus: FolderStatus; field: Field }[]
    @Input() record!: BusinessRecords
    @Input() fieldTypes!: Dictionary<FieldType>

    constructor() {}
}
