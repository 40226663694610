import { Component } from '@angular/core'
import { FilterGroup, View } from '../../../@core/models'
import { UntilDestroy } from '@ngneat/until-destroy'
import { TbMenuState } from '../../../@components-library/tb-menu'

@UntilDestroy()
@Component({
    selector: 'app-sort',
    templateUrl: './sort.component.html',
})
export class SortComponent {
    //code copied from filters for testing
    filterGroups!: FilterGroup[]
    selectedView!: View

    menuState: TbMenuState = TbMenuState.Closed

    protected readonly TbMenuStateEnum = TbMenuState

    get filterCountText() {
        let text: string = this.filterGroups ? this.filterGroups.length + ' groups' : ''
        if (this.filterGroups && this.filterGroups.length === 1) {
            if (this.filterGroups[0].values) {
                const keys = Object.keys(this.filterGroups[0].values)
                if (keys.length) {
                    text = keys.length.toString()
                }
            } else {
                text = '1'
            }
        }
        return text
    }

    onMenuStateChanged(menuState: TbMenuState) {
        this.menuState = menuState
    }
}
