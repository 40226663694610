<ng-container *ngIf="selectedView">
    <ng-container *ngIf="selectedFolder$ | async as folder">
        <div
            class="hidden sm:flex items-center h-100 px-2 mx-6 p-0 rounded-lg h-9 bg-white"
            *transloco="let tViewList; read: 'view_list'"
        >
            <app-tb-tabs
                [tabs]="viewTabs"
                [icon]="icon"
                [moreMenu]="moreMenuTab"
                [activeTab]="selectedTab"
                (tabOpened)="selectView($event)"
            ></app-tb-tabs>

            <ng-template #icon let-tab="tab">
                <app-tb-icon class="mr-2" [icon]="tab.icon"></app-tb-icon>
            </ng-template>

            <ng-template #moreMenuTab let-tab="tab">
                <app-tb-menu-list *transloco="let tButtons; read: 'buttons'">
                    <app-tb-menu-list-item icon="custom_copy"
                        >{{ tButtons('duplicate') }}</app-tb-menu-list-item
                    >
                    <app-tb-menu-list-item icon="custom_share"
                        >{{ tButtons('share_not_implemented') }}</app-tb-menu-list-item
                    >

                    <app-tb-divider></app-tb-divider>

                    <app-tb-menu-list-item (click)="deleteView(tab)">
                        <app-tb-icon color="red-200" icon="custom_trash" item-icon></app-tb-icon>
                        <span class="text-red-600 body-1" item-title>{{ tButtons('delete') }}</span>
                    </app-tb-menu-list-item>
                </app-tb-menu-list>
            </ng-template>

            <button
                #trigger="matMenuTrigger"
                [mat-menu-trigger-for]="allViews"
                class="flex items-center pl-3 pr-2 py-0.5 bg-primaryBackground rounded-lg font-semibold text-secondaryMainLight hover:text-secondaryMain"
            >
                <span class="mr-2">{{tViewList('all')}}</span>
                <mat-icon>{{ trigger.menuOpen ? 'expand_less' : 'expand_more' }}</mat-icon>
            </button>
            <mat-menu #allViews="matMenu">
                <div class="flex flex-col py-2">
                    <ng-template #templateBottomSheet matMenuContent>
                        <div class="max-sm:flex hidden mb-6 text-primaryDark text-xl font-semibold">
                            {{tViewList('views')}}
                        </div>
                        <div (click)="navigate(itemView)" *ngFor="let itemView of views">
                            <div
                                class="flex items-center text-primaryDark mb-2 max-sm:mb-4 allViewHolder"
                            >
                                <button class="flex">
                                    <mat-icon
                                        class="max-sm:w-5 w-4 max-sm:visible text-primarySecondary"
                                        svgIcon="custom_drag"
                                    ></mat-icon>
                                </button>
                                <div
                                    class="flex items-center w-full max-sm:h-12 py-1.5 sm:mr-4 max-sm:p-0 rounded cursor-pointer max-sm:bg-primaryBackground hover:bg-primaryBackground"
                                >
                                    <div
                                        [ngClass]="{'text-secondaryMain font-semibold':IsSelected(itemView)}"
                                        class="flex items-center px-[7px] max-sm:w-full"
                                    >
                                        <mat-icon
                                            class="h-4 max-sm:h-6 max-sm:text-2xl max-sm:leading-6 text-base leading-4 mr-2"
                                            >{{itemView.icon}}</mat-icon
                                        >
                                        <span class="mr-7 max-sm:mr-4 leading-4">
                                            {{itemView.name.value}}
                                        </span>
                                        <mat-icon
                                            *ngIf="IsSelected(itemView)"
                                            class="items-center justify-content-center mr-[-9px] sm:ml-auto text-secondaryMain text-lg leading-5"
                                        >
                                            check
                                        </mat-icon>
                                        <button
                                            [class.visible]="trigger.menuOpen"
                                            class="sm:absolute right-[20px] flex max-sm:visible max-sm:ml-auto"
                                            (click)="$event.stopPropagation()"
                                            #trigger="matMenuTrigger"
                                            [matMenuTriggerFor]="itemViewMenu"
                                        >
                                            <mat-icon
                                                class="rounded bg-primaryBackground max-sm:h-8 max-sm:w-8 max-sm:text-2xl h-4 w-4 text-base leading-4 text-inherit hover:text-secondaryMain hover:bg-white allViewsOptions"
                                            >
                                                more_vert
                                            </mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <mat-menu #itemViewMenu="matMenu">
                                <ng-template matMenuContent>
                                    <div class="flex-start-center content-start">
                                        <div class="dropdown-holder pt-1 px-3.5">
                                            <ng-container
                                                *transloco="let tButtons; read: 'buttons'"
                                            >
                                                <div>
                                                    <mat-icon
                                                        class="text-primarySecondary mr-2"
                                                        svgIcon="custom_edit"
                                                    ></mat-icon>
                                                    <span> {{tButtons('rename')}} </span>
                                                </div>
                                                <div>
                                                    <mat-icon
                                                        class="text-primarySecondary mr-2"
                                                        svgIcon="custom_copy"
                                                    ></mat-icon>
                                                    <span> {{tButtons('duplicate')}} </span>
                                                </div>
                                                <div>
                                                    <mat-icon
                                                        class="text-primarySecondary mr-2"
                                                        svgIcon="custom_share"
                                                    ></mat-icon>
                                                    <span> {{tButtons('share')}} </span>
                                                </div>

                                                <mat-divider
                                                    class="mb-2 h-0.5 border-primaryLight bg-primaryLight"
                                                ></mat-divider>

                                                <div class="mb-1">
                                                    <mat-icon
                                                        class="text-red-200 mr-2"
                                                        svgIcon="custom_trash"
                                                    ></mat-icon>
                                                    <span class="text-red-600">
                                                        {{tButtons('delete')}}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-template>
                            </mat-menu>
                        </div>

                        <!--                        currently no logic for the cancel/save buttons-->
                        <div class="max-sm:hidden block">
                            <button
                                (click)="openDialog()"
                                class="flex items-center pl-5 font-semibold text-secondaryMainLight hover:text-secondaryMain"
                                (click)="$event.stopPropagation()"
                            >
                                <mat-icon class="w-5 h-5 mr-2 text-xl leading-5"
                                    >add_circle_outline</mat-icon
                                >
                                <span class="text-sm">{{tViewList('view_add')}}</span>
                            </button>
                            <div
                                *transloco="let tButtons; read 'buttons'"
                                class="flex items-center gap-4 w-full px-4 mt-4 text-sm font-semibold"
                            >
                                <button
                                    class="flex flex-1 justify-center px-5 py-2 text-secondaryMain rounded-lg border border-solid border-secondaryMain"
                                >
                                    {{tButtons('cancel')}}
                                </button>
                                <button
                                    class="flex flex-1 justify-center px-5 py-2 rounded-lg bg-secondaryMain text-white"
                                >
                                    {{tButtons('save')}}
                                </button>
                            </div>
                        </div>

                        <button
                            (click)="openBottomSheet()"
                            class="sm:hidden flex items-center justify-center w-full px-4 py-3 mt-10 border border-solid border-secondaryMain rounded-lg font-semibold text-secondaryMain"
                            (click)="$event.stopPropagation()"
                        >
                            <mat-icon class="w-4 h-4 mr-2 text-base leading-4"
                                >add_circle_outline</mat-icon
                            >
                            <span class="text-sm">{{tViewList('view_add')}}</span>
                        </button>
                    </ng-template>
                </div>
            </mat-menu>
            <button
                (click)="openDialog()"
                class="max-sm:hidden flex items-center ml-3 text-secondaryMainLight hover:text-secondaryMain"
            >
                <mat-icon class="flex items-center">add</mat-icon>
            </button>
        </div>

        <!--        small-->
        <div class="sm:hidden flex w-inherit h-10 cursor-pointer mx-4">
            <div
                (click)="openBottomSheetSm()"
                class="flex items-center justify-between w-100 h-10 px-2 mr-4 bg-white rounded-lg text-secondaryMain"
            >
                <div class="flex items-center font-semibold">
                    <mat-icon class="mr-2">{{selectedView.icon}}</mat-icon>
                    <div>{{selectedView.name.value}}</div>
                </div>
                <mat-icon class="w-8 h-8 text-3xl leading-8">expand_more</mat-icon>
            </div>

            <!--            needs to be removed from here when bottom sheet logic will be done-->
            <button
                (click)="openBottomSheet()"
                class="sm:hidden flex items-center ml-3 text-secondaryMainLight hover:text-secondaryMain"
            >
                <mat-icon class="flex items-center">add</mat-icon>
            </button>
            <div
                (click)="showFilterBar()"
                class="flex items-center justify-center h-10 w-10 min-w-[40px] cursor-pointer rounded-lg bg-white text-primaryDark"
            >
                <mat-icon svgIcon="custom_slider" class="w-8 h-8 text-3xl leading-8"></mat-icon>
            </div>
        </div>

        <div
            [class.hidden]="toolbarIsInvisible"
            class="relative sm:flex w-100 sm:h-fit h-14 overflow-hidden"
        >
            <mat-toolbar-row
                class="table-toolbar h-fit w-inherit max-w-inherit overflow-y-hidden overflow-x-auto sm:mr-6 mr-4 sm:mt-2 mt-6 ml-4 p-0"
            >
                <app-filter class="ml-auto"></app-filter>
                <app-sort></app-sort>
                <app-group-by></app-group-by>
                <app-show [selectedFolder]="folder" [selectedView]="selectedView"></app-show>
                <app-manage-fields></app-manage-fields>
            </mat-toolbar-row>
        </div>
    </ng-container>
</ng-container>
