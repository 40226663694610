import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MoneyConfig } from '../../../../../@core/models'
import { FilterTypesComponent } from '../filter-types.component'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-money-filter',
    templateUrl: './money-filter.component.html',
    styleUrls: ['./money-filter.component.sass'],
})
export class MoneyFilterComponent extends FilterTypesComponent implements OnInit {
    numberFormControl!: FormControl<number | null>
    currencySymbol!: string

    ngOnInit(): void {
        if (this.field.configure_json) {
            const MoneyConfig: MoneyConfig = JSON.parse(this.field.configure_json)
            if (MoneyConfig) {
                this.currencySymbol = MoneyConfig.currencySymbol
            }
        }

        this.numberFormControl = this.form.controls['value'] as FormControl
    }
}
