import { UntilDestroy } from '@ngneat/until-destroy'
import { SelectViewsSettingsComponent } from '../select-views-settings/select-views-settings.component'
import { MatDialog } from '@angular/material/dialog'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { Component, Input, OnInit } from '@angular/core'
import { FieldConfigurationComponent } from '../field-configuration/field-configuration.component'
import { ComponentType } from '@angular/cdk/overlay'
import { StatusConfigurationComponent } from '../status-configuration/status-configuration.component'

@UntilDestroy()
@Component({
    selector: 'app-advanced-options-section',
    templateUrl: './advanced-options-section.component.html',
})
export class AdvancedOptionsSectionComponent implements OnInit {
    @Input()
    sectionName!: string

    componentToOpen!: ComponentType<
        SelectViewsSettingsComponent | FieldConfigurationComponent | StatusConfigurationComponent
    >

    constructor(private dialog: MatDialog, private bottomSheet: MatBottomSheet) {}

    ngOnInit(): void {
        switch (this.sectionName) {
            case 'views':
                this.componentToOpen = SelectViewsSettingsComponent
                break

            case 'fields':
                this.componentToOpen = FieldConfigurationComponent
                break

            case 'statuses':
                this.componentToOpen = StatusConfigurationComponent
                break
        }
    }

    openConfDialog(event: MouseEvent) {
        event.stopPropagation()
        if (!this.componentToOpen) return

        this.dialog.open(this.componentToOpen)
    }

    openConfBottom() {
        if (!this.componentToOpen) return

        this.bottomSheet.open(this.componentToOpen, {
            panelClass: 'bottomSheetCustom',
        })
    }
}
