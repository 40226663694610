<ng-container *ngIf="fieldTypes$ | async as fieldTypes">
    <ng-container *ngIf="!tableData">
        <app-view-error [errorTypeNames]="['Table']"></app-view-error>
    </ng-container>
    <ng-container *transloco="let tTable; read: 'table'">
        <ng-container *ngIf="!isDataShown; else templateToShow">
            <app-filter-no-results (changeFilters)="openFiltersMenu()"></app-filter-no-results>
        </ng-container>
        <ng-template #templateToShow>
            <div *ngIf="tableData;" cdkDropListGroup class="global-table">
                <ng-container *ngIf="isGrouped; else data">
                    <div class="group" *ngFor="let item of groups">
                        <ng-container *ngIf="item.value; else unknown">
                            <ng-container *ngIf="item.field">
                                <app-grouping-values
                                    [fieldType]="fieldTypes[item.field.field_type_code]"
                                    [field]="item.field"
                                    [value]="item.value"
                                ></app-grouping-values>
                            </ng-container>
                        </ng-container>
                        <ng-template #unknown>
                            <div class="group">
                                <span class="sticky"> {{tTable('template')}} </span>
                            </div>
                        </ng-template>
                        <app-business-record-table
                            (abandonCreateRecord)="isNewRecord = $event"
                            (copyField)="copyField($event)"
                            (deleteColumn)="deleteField($event)"
                            (deleteRecord)="deleteRecords($event)"
                            (dropColumn)="dropColumn($event)"
                            (hideColumn)="hideColumn($event)"
                            (moveColumnToEnd)="moveColumnToEnd($event)"
                            (moveColumnToStart)="moveColumnToStart($event)"
                            (pinColumn)="pinColumn($event)"
                            (saveCreateRecord)="saveCreateRecord()"
                            (sharedField)="shareField($event)"
                            (showColumn)="showColumn($event)"
                            (sortColumn)="sortColumn($event)"
                            (selectRecord)="selectRecord($event)"
                            (sortColumnClear)="sortColumnClear()"
                            (unPinColumn)="unPinColumn($event)"
                            (updateRecord)="updateRecord($event)"
                            (updatedField)="updateField($event)"
                            (moveFieldToFolder)="moveFieldToFolder($event)"
                            (moveRow)="moveRow($event)"
                            (resetColumnWidth)="resetColumnWidth($event)"
                            (resizeColumn)="resizeColumn($event)"
                            [dropRowData]="item.data"
                            [group]="item"
                            [isGrouped]="isGrouped"
                            [isNewRecordRow]="isNewRecordRow()"
                            [records]="item.data"
                            [sortActive]="sortActive"
                            [tableData]="tableData"
                            [selectedRecords]="selectedRecords"
                            [selectedFolder]="selectedFolder$ | async"
                            [currentUser]="userGuid"
                            [newRecordGroup]="newRecordGroup"
                            [cells]="cells"
                            [fieldTypes]="fieldTypes"
                            [isFilter]="isFilter()"
                        ></app-business-record-table>
                    </div>
                </ng-container>
                <ng-template #data>
                    <app-business-record-table
                        (abandonCreateRecord)="isNewRecord = $event"
                        (copyField)="copyField($event)"
                        (deleteColumn)="deleteField($event)"
                        (deleteRecord)="deleteRecords($event)"
                        (dropColumn)="dropColumn($event)"
                        (hideColumn)="hideColumn($event)"
                        (moveColumnToEnd)="moveColumnToEnd($event)"
                        (moveColumnToStart)="moveColumnToStart($event)"
                        (pinColumn)="pinColumn($event)"
                        (saveCreateRecord)="saveCreateRecord()"
                        (selectRecord)="selectRecord($event)"
                        (sharedField)="shareField($event)"
                        (sharedRecord)="shareRecord($event)"
                        (showColumn)="showColumn($event)"
                        (sortColumn)="sortColumn($event)"
                        (sortColumnClear)="sortColumnClear()"
                        (unPinColumn)="unPinColumn($event)"
                        (updateRecord)="updateRecord($event)"
                        (updatedField)="updateField($event)"
                        (moveFieldToFolder)="moveFieldToFolder($event)"
                        (resetColumnWidth)="resetColumnWidth($event)"
                        (resizeColumn)="resizeColumn($event)"
                        [dropRowData]="records!"
                        [group]=""
                        [isGrouped]="isGrouped"
                        [isNewRecordRow]="isNewRecordRow()"
                        [records]="records"
                        [selectedRecords]="selectedRecords"
                        [sortActive]="sortActive"
                        [tableData]="tableData"
                        [selectedFolder]="selectedFolder$ | async"
                        [currentUser]="userGuid"
                        [cells]="cells"
                        [fieldTypes]="fieldTypes"
                        [isFilter]="isFilter()"
                    ></app-business-record-table>
                </ng-template>
            </div>
        </ng-template>
    </ng-container>

    <app-actions-panel
        (clearSelected)="clearSelected()"
        (deleteRecords)="deleteRecords()"
        (editRecords)="editRecords($event)"
        *ngIf="selectedRecords.length"
        [cells]="cells"
        [fieldTypes]="fieldTypes"
        [fields]="fields"
        [selectedCount]="selectedRecords.length"
        [selectedFolder]="selectedFolder$ | async"
        [selectedRecords]="selectedRecords"
    ></app-actions-panel>
</ng-container>
