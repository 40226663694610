import { Component, Input } from '@angular/core'
import { Cell, Field, FieldType } from '../../../../@core/models'

@Component({
    selector: 'app-board-card-update',
    templateUrl: './board-card-update.component.html',
    styleUrls: ['./board-card-update.component.sass'],
})
export class BoardCardUpdateComponent {
    @Input()
    updateAt?: Cell

    @Input()
    fieldType?: FieldType

    @Input()
    field?: Field
}
