import {
    AggregateFunctionsModel,
    FieldTypes,
    ResponseFieldType,
    ResponseFieldTypeEntities,
} from '../response'
import { Field } from './field.model'
import { Dictionary } from '@ngrx/entity'

export const fieldTypeCodesIcons: { [key: string]: string } = {
    '': 'block',
    [FieldTypes.NAME]: 'text_snippet',
    [FieldTypes.TEXT]: 'text_snippet',
    [FieldTypes.MONEY]: 'attach_money',
    [FieldTypes.EMAIL]: 'alternate_email',
    [FieldTypes.WEBSITE]: 'link',
    [FieldTypes.NUMBER]: 'numbers',
    [FieldTypes.STATUS]: 'military_tech',
    [FieldTypes.DROPDOWN]: 'arrow_drop_down',
    [FieldTypes.ASSIGNEE]: 'people',
    [FieldTypes.PEOPLE]: 'people',
    [FieldTypes.BOOL]: 'circle',
    [FieldTypes.RATING]: 'stars',
    [FieldTypes.DATE_TIME]: 'calendar_today',
    [FieldTypes.DATE_RANGE]: 'calendar_today',
    [FieldTypes.DATE]: 'calendar_today',
    [FieldTypes.WATCH]: 'visibility',
    [FieldTypes.MULTILINE_TEXT]: 'text_snippet',
    [FieldTypes.LINK]: 'link',
    [FieldTypes.RICH_TEXT]: 'text_snippet',
}

export interface OperationCode {
    multiple?: boolean
    create?: boolean
    update?: boolean
    delete?: boolean
    system?: boolean
    global?: boolean
    freeze?: boolean
}

enum OperationCodeOptions {
    M = 'multiple',
    C = 'create',
    U = 'update',
    D = 'delete',
    S = 'system',
    G = 'global',
    F = 'freeze',
}

export interface FieldType {
    guid: string
    name: string
    icon: string
    is_select?: number
    is_agg_function?: number
    agg_function?: Array<AggregateFunctionsModel | string>
    frontend_validations?: string[]
    type_data_validations?: string[]
    filterOptions: string[]
    operationCode: OperationCode
}

export function getOperationCode(field: Field, fieldType: FieldType | undefined): OperationCode {
    if (!field.operationCode) {
        if (fieldType && fieldType.operationCode) {
            return fieldType.operationCode
        } else {
            console.log(new Error('invalid operation code'))
            return {
                system: true,
            }
        }
    } else {
        return field.operationCode
    }
}

export function getOperationCodeFromFieldTypeEntities(
    field: Field,
    fieldTypes: Dictionary<FieldType>,
): OperationCode {
    return getOperationCode(field, fieldTypes[field.field_type_code])
}

export function getIconByFieldType(fieldTypeGuid: string): string {
    return fieldTypeCodesIcons[fieldTypeGuid]
}

function isValidOperationCodeOption(letter: string): letter is keyof typeof OperationCodeOptions {
    return letter in OperationCodeOptions
}

export function prepareOperationCode(
    operation_code: string | undefined,
): OperationCode | undefined {
    if (operation_code) {
        if (operation_code.includes('S') && operation_code.length !== 1) {
            console.log(new Error('incorrect operation code'))
        }
        return Array.from(operation_code).reduce((acc: OperationCode, letter: string) => {
            if (isValidOperationCodeOption(letter)) {
                const operationCodeOption = OperationCodeOptions[letter]
                acc[operationCodeOption] = true
            } else {
                console.log(new Error('not implemented operation code'))
            }
            return acc
        }, {} as OperationCode)
    }
    return
}

export function generateFieldTypes(
    responseFieldTypeEntities: ResponseFieldTypeEntities,
): FieldType[] {
    return Object.keys(responseFieldTypeEntities).map((fieldTypeGuid: string) => {
        const responseFieldType: ResponseFieldType = responseFieldTypeEntities[fieldTypeGuid]
        return {
            guid: fieldTypeGuid,
            ...responseFieldType,
            icon: getIconByFieldType(fieldTypeGuid),
            filterOptions: responseFieldType.filter_conditions || [],
            operationCode: prepareOperationCode(responseFieldType.operation_code)!,
        }
    })
}
