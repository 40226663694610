import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import {
    addFoldersFromResponse,
    AppState,
    createFolderRequest,
    deleteFoldersFromResponse,
    foldersAreLoaded,
    initFolderNames,
    initFolders,
    selectAllFolders,
    selectFolderEntities,
    selectFolderNames,
    selectFoldersAreLoaded,
    selectFoldersIsError,
    selectSelectedFolder,
    selectSelectedFolderGuid,
    selectSelectedFolderStatusField,
    setSelectedFolderGuid,
    updateFoldersFromResponse,
} from '../../@ngrx'
import { map } from 'rxjs/operators'
import { Dictionary, Update } from '@ngrx/entity'
import { CreateFolderAcl, CreateFolderViews, Deleted, Folder } from '../../models'
import { FolderNameEntities } from '../../models/response/folder-names'

@Injectable({
    providedIn: 'root',
})
export class FolderFacadeService {
    constructor(private store: Store<AppState>) {}

    selectFolderEntities$ = this.store.select(selectFolderEntities)

    selectAllFolders$ = this.store.select(selectAllFolders)

    selectFoldersAreLoaded$ = this.store.select(selectFoldersAreLoaded)

    selectFoldersIsError$ = this.store.select(selectFoldersIsError)

    selectSelectedFolderGuid$ = this.store.select(selectSelectedFolderGuid)

    selectSelectedFolder$ = this.store.select(selectSelectedFolder)

    selectSelectedFolderStatusField$ = this.store.select(selectSelectedFolderStatusField)

    selectFolderNames$ = this.store.select(selectFolderNames)

    selectFolderByGuid$ = (guid: string) => {
        return this.selectFolderEntities$.pipe(
            map((folderDictionary: Dictionary<Folder>) => folderDictionary[guid] || null),
        )
    }

    initFolders(folders: Folder[], selectedFolderGuid?: string) {
        this.store.dispatch(initFolders({ folders, selectedFolderGuid }))
    }

    initFolderNames(folderNames: FolderNameEntities) {
        this.store.dispatch(initFolderNames({ folderNames }))
    }

    foldersAreLoaded() {
        this.store.dispatch(foldersAreLoaded())
    }

    setSelectedFolderGuid(selectedFolderGuid: string | null) {
        this.store.dispatch(setSelectedFolderGuid({ selectedFolderGuid }))
    }

    createFolderRequest(folder: Partial<Folder>, views: CreateFolderViews, acl: CreateFolderAcl) {
        this.store.dispatch(createFolderRequest({ folder, views, acl }))
    }

    addFoldersFromResponse(folders: Folder[]) {
        this.store.dispatch(addFoldersFromResponse({ folders }))
    }

    updateFoldersFromResponse(folders: Update<Folder>[]) {
        this.store.dispatch(updateFoldersFromResponse({ folders }))
    }

    deleteFoldersFromResponse(folders: Deleted[]) {
        this.store.dispatch(deleteFoldersFromResponse({ folders }))
    }
}
