import { Injectable } from '@angular/core'
import { BreakpointService, DisplaySize } from '../breakpoint.service'
import { MatDrawerMode } from '@angular/material/sidenav'
import { BehaviorSubject } from 'rxjs'

export interface MenuItem {
    id: string
    name: string
    icon: string
    selected: boolean
    action: () => void
}

enum Mode {
    SIDE = 'side',
    OVER = 'over',
    PUSH = 'push',
}

export enum ExpandMods {
    DISABLED,
    EXPANDED,
    COLLAPSED,
}

export interface SideNavState {
    mode: MatDrawerMode
    opened: boolean
    expand: ExpandMods
    canClose: boolean
    changeModeOnExpand: false | MatDrawerMode
}

@Injectable({
    providedIn: 'root',
})
export class SideNavigationService {
    private state$$ = new BehaviorSubject<SideNavState>(this.getState())
    state$ = this.state$$.asObservable()

    constructor(private breakpointService: BreakpointService) {
        breakpointService.displaySize$.subscribe((size: DisplaySize) => {
            switch (size) {
                case DisplaySize.XSmall:
                case DisplaySize.Small:
                    this.setState({
                        mode: Mode.OVER,
                        opened: false,
                        expand: ExpandMods.DISABLED,
                        canClose: true,
                    })
                    break
                case DisplaySize.Medium:
                    this.setState({ expand: ExpandMods.COLLAPSED, changeModeOnExpand: Mode.OVER })
                    break
                case DisplaySize.Large:
                case DisplaySize.XLarge:
                    this.setState({})
                    break
                default:
                    console.error(new Error(`unexpected screen size ${size}`))
            }
        })
    }

    toggleExpand() {
        const value = this.state$$.value
        if (value.expand === ExpandMods.DISABLED) return

        value.expand =
            value.expand === ExpandMods.EXPANDED ? ExpandMods.COLLAPSED : ExpandMods.EXPANDED
        if (value.changeModeOnExpand) {
            const newMode = value.changeModeOnExpand
            value.changeModeOnExpand = value.mode
            value.mode = newMode
        }
        this.setState(value)
    }

    toggleOpened() {
        const value = this.state$$.value
        value.opened = !value.opened
        this.setState(value)
    }

    private setState({
        mode,
        opened,
        expand,
        canClose,
        changeModeOnExpand,
    }: Partial<SideNavState>): void {
        this.state$$.next(this.getState(mode, opened, expand, canClose, changeModeOnExpand))
    }

    private getState(
        mode: MatDrawerMode = Mode.SIDE,
        opened: boolean = true,
        expand: ExpandMods = ExpandMods.EXPANDED,
        canClose: boolean = false,
        changeModeOnExpand: false | MatDrawerMode = false,
    ): SideNavState {
        return {
            mode,
            opened,
            expand,
            canClose,
            changeModeOnExpand,
        }
    }
}
