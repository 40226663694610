<ng-container *ngIf="fieldTypes$ | async as fieldTypes">
    <ng-container *ngIf="cells && fields">
        <div class="w-full d-flex flex-col" *transloco="let tTable; read: 'table'">
            <div class="w-full d-flex" *ngFor="let record of records">
                <div class="w-6/12 d-flex content-center h-[48px]" *ngFor="let field of fields">
                    <app-cell-container
                        [fieldTypes]="fieldTypes"
                        [field]="field"
                        [guid]="field.guid"
                        [record]="record"
                        [value]="cells[record.guid][field.guid].value"
                        class="text-start"
                        [disabled]="true"
                    ></app-cell-container>
                </div>
            </div>
            <div *ngIf="deletedRecords.length" class="w-100 d-flex content-center h-[48px]">
                <span class="w-6/12 flex-start-center">
                    {{tTable(('deleted_records'), { count: deletedRecords.length })}}
                </span>
                <button (click)="cleanDeletedRecords()" class="w-6/12 flex-start-center" mat-button>
                    Clean deleted
                </button>
            </div>
            <mat-select
                [formControl]="recordsControl"
                (openedChange)="updateValue($event)"
                class="w-6/12 max-w-[180px]"
                [placeholder]="tTable('select_record')"
                multiple
            >
                <mat-select-trigger> {{ tTable('select_records') }} </mat-select-trigger>
                <mat-option *ngFor="let record of allSchemaRecords" [value]="record.guid">
                    {{ record.name.value }}
                </mat-option>
            </mat-select>
        </div>
    </ng-container>
</ng-container>
