import { createAction, props } from '@ngrx/store'
import { CreateFolderAcl, CreateFolderViews, Deleted, Folder } from '../../models'
import { Update } from '@ngrx/entity'
import { FolderNameEntities } from '../../models/response/folder-names'

export const initFolders = createAction(
    '[Folder] Init folders',
    props<{ folders: Folder[]; selectedFolderGuid?: string }>(),
)

export const foldersAreLoaded = createAction('[Folder] Folders are loaded')

export const setSelectedFolderGuid = createAction(
    '[Folder] Set selected folder',
    props<{ selectedFolderGuid: string | null }>(),
)

export const createFolderRequest = createAction(
    '[Create Folder Request] CREATE_FOLDER',
    props<{ folder: Partial<Folder>; views: CreateFolderViews; acl: CreateFolderAcl }>(),
)

export const createFolderRequestSuccess = createAction(
    '[Create Folder Request Success] CREATE_FOLDER_SUCCESS',
)

export const addFoldersFromResponse = createAction(
    '[Folder] Add folders',
    props<{ folders: Folder[] }>(),
)

export const updateFoldersFromResponse = createAction(
    '[Folder] Update folders',
    props<{ folders: Update<Folder>[] }>(),
)

export const deleteFoldersFromResponse = createAction(
    '[Folder] Delete folders',
    props<{ folders: Deleted[] }>(),
)

export const initFolderNames = createAction(
    '[Folder] Init folder names',
    props<{ folderNames: FolderNameEntities }>(),
)
