export interface ContactActivity {
    type: string
    value: string
}

export interface ContactJson {
    arguments: ContactActivity[]
}

export const contactActivity: ContactJson = {
    arguments: [
        {
            type: 'task',
            value: 'task1',
        },
        {
            type: 'task',
            value: 'task2',
        },
        {
            type: 'message',
            value: 'message1',
        },
        {
            type: 'message',
            value: 'message2',
        },
        {
            type: 'task',
            value: 'task3',
        },
        {
            type: 'message',
            value: 'message3',
        },
    ],
}
