import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import { Field, FieldTypes } from '../../../../../@core/models'
import { StatusContentComponent } from '../../../../cell-types/select/status/status-dialog/status-content/status-content.component'
import { DropdownContentComponent } from '../../../../cell-types/select/dropdown'
import { RatingSettingsComponent } from '../../../../cell-types/rating/rating-settings/rating-settings.component'
import { TextOneLineSettingsComponent } from '../../../../cell-types/text-one-line/text-one-line-settings/text-one-line-settings.component'
import { MoneySettingsComponent } from '../../../../cell-types/money/money-settings/money-settings.component'
import { LinkSettingsComponent } from '../../../../cell-types/link/link-settings/link-settings.component'
import cloneDeep from 'lodash/cloneDeep'

export const fieldTypeColumnSettingsComponents: { [key: string]: Type<any> } = {
    [FieldTypes.STATUS]: StatusContentComponent,
    [FieldTypes.DROPDOWN]: DropdownContentComponent,
    [FieldTypes.RATING]: RatingSettingsComponent,
    [FieldTypes.TEXT]: TextOneLineSettingsComponent,
    [FieldTypes.MONEY]: MoneySettingsComponent,
    [FieldTypes.LINK]: LinkSettingsComponent,
}

@Component({
    selector: 'app-add-column-content',
    templateUrl: './add-column-content.component.html',
    styleUrls: ['./add-column-content.component.sass'],
})
export class AddColumnContentComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() field!: Field
    @Input() fieldType!: string

    @Output() fieldChanged: EventEmitter<Field> = new EventEmitter()

    @ViewChild('addColumnArea', { read: ViewContainerRef, static: true })
    addColumnArea!: ViewContainerRef

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fieldType) {
            if (!changes.fieldType.currentValue) {
                changes.fieldType.currentValue = changes.fieldType.previousValue
            }
            this.addFieldColumn(changes.fieldType.currentValue)
        }
    }

    ngOnInit() {
        this.addFieldColumn(this.fieldType)
    }

    ngAfterViewInit() {
        this.cdr.detectChanges()
    }

    addFieldColumn(fieldType: string) {
        console.log('fieldType', fieldType)
        this.addColumnArea.clear()
        const componentName = fieldTypeColumnSettingsComponents[fieldType]
        if (componentName) {
            const componentFactory =
                this.componentFactoryResolver.resolveComponentFactory(componentName)
            const component = this.addColumnArea.createComponent(componentFactory)

            component.instance.field = this.field
            component.instance.fieldChanged = this.fieldChanged
            if (fieldType === 'field_type_status') {
                component.instance.statusStatuses = cloneDeep(this.field.select_object_field)
            }
            if (fieldType === 'field_type_rating') {
                component.instance.field = {
                    ...this.field,
                    configure_json: JSON.stringify({ count: 5 }),
                }
            }
        } else {
            console.log(this.field.field_type_code)
        }
        this.cdr.detectChanges()
    }
}
