import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import {
    AppState,
    selectCurrentUser,
    selectCurrentUserInfo,
    selectUserEntities,
    selectUsers,
    setUser,
    setUsers,
} from '../../@ngrx'
import { CurrentUser, UserModelEntities } from '../../models'

@Injectable({
    providedIn: 'root',
})
export class UserFacadeService {
    constructor(private store: Store<AppState>) {}

    selectUserEntities$ = this.store.select(selectUserEntities)

    selectUsers$ = this.store.select(selectUsers)

    selectCurrentUser$ = this.store.select(selectCurrentUser)

    selectCurrentUserInfo$ = this.store.select(selectCurrentUserInfo)

    setUsers(users: UserModelEntities) {
        this.store.dispatch(setUsers({ users }))
    }

    setUser(currentUser: CurrentUser | null) {
        this.store.dispatch(setUser({ currentUser }))
    }
}
