<div *transloco="let tCommonDialog; read: 'common_dialog'">
    <p>{{tCommonDialog(data.i18Key)}}</p>
    <ng-container *ngFor="let button of data.buttonKeys">
        <button
            (click)="this.dialogRef.close(button)"
            [class.yes-button]="button.includes('Yes')"
            [class.no-button]="button.includes('No')"
            class="mt-lg"
            mat-button
        >
            <span class="text-muted"> {{button}} </span>
        </button>
    </ng-container>
</div>
