<div class="content-start" *transloco="let tUserSettings; read: 'user_settings'">
    <div
        class="flex items-center sm:px-4 py-2 max-sm:pt-0 max-sm:pb-4 sm:bg-primaryDark text-primaryDark sm:text-white text-xl sm:text-sm"
    >
        <!--        needs to be changed for dynamic names-->
        <div
            class="flex items-center justify-center w-5 h-5 rounded-full mr-2 text-sm text-white bg-secondaryMain"
        >
            S
        </div>
        <div>$Solution name</div>
    </div>
    <div class="dropdown-holder">
        <div>
            <mat-icon>account_circle</mat-icon>
            <span> {{tUserSettings('my_account')}} </span>
        </div>

        <div>
            <mat-icon class="text-primarySecondary mr-2">grade</mat-icon>
            <span> {{tUserSettings('subscription')}}</span>
        </div>

        <div (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span> {{tUserSettings('log_out')}} </span>
        </div>
        <div (click)="resetSolution()">
            <mat-icon>refresh</mat-icon>
            <span> {{tUserSettings('reset_solution')}} </span>
        </div>
        <div class="mb-2">
            <mat-icon></mat-icon>
            <app-version></app-version>
        </div>
    </div>
</div>
