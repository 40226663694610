<button
    (click)="setActive(1)"
    [matMenuTriggerFor]="addMenu"
    [class.w-100]="isShowFieldAdd"
    mat-flat-button
>
    <span class="material-icons add-col-btn text-muted"> add </span>
</button>
<mat-menu #addMenu="matMenu" class="table-menu" xPosition="before">
    <ng-template matMenuContent>
        <div (click)="$event.stopPropagation()">
            <app-field-add-content
                [activeTab]="selectedTab"
                [fields]="fields"
                [isNewColumn]="false"
            >
            </app-field-add-content>
        </div>
    </ng-template>
</mat-menu>
