import { Component } from '@angular/core'
import { map, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { FolderFacadeService } from '../../../@core/services/store-facade'
import { FolderControlsContainerComponent } from '../folder-controls-container.component'

@Component({
    selector: 'app-folder-controls',
    templateUrl: './folder-controls.component.html',
    styleUrls: ['./folder-controls.component.sass'],
})
export class FolderControlsComponent {
    constructor(private folderFacadeService: FolderFacadeService) {}

    menuComponent = FolderControlsContainerComponent

    selectedFolder$ = this.folderFacadeService.selectSelectedFolder$.pipe(
        switchMap((folder) => {
            if (folder) return of(folder)

            return this.folderFacadeService.selectAllFolders$.pipe(
                map((folders) => folders[0]),
                tap((folder) => this.folderFacadeService.setSelectedFolderGuid(folder.guid)),
            )
        }),
    )
}
