import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-record-settings-content',
    templateUrl: './record-settings-content.component.html',
})
export class RecordSettingsContentComponent {
    //fake data
    isActive: boolean = true
}
