<ng-container *transloco="let tAdOptions; read: 'advanced_options'">
    <mat-expansion-panel class="xsm:mb-6 mb-2" #advancedOptions hideToggle="true" [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title
                [class.text-secondaryMain]="advancedOptions.expanded"
                class="text-primaryDark text-sm"
            >
                <mat-icon>{{advancedOptions.expanded ? 'expand_less' : 'expand_more'}}</mat-icon>
                {{tAdOptions(advancedOptions.expanded ? 'hide_options' : 'show_options')}}
            </mat-panel-title>
        </mat-expansion-panel-header>

        <!--        maybe should be dynamic?-->
        <app-advanced-options-section [sectionName]="'views'"></app-advanced-options-section>
        <app-advanced-options-section [sectionName]="'fields'"></app-advanced-options-section>
        <app-advanced-options-section [sectionName]="'statuses'"></app-advanced-options-section>
    </mat-expansion-panel>
</ng-container>
