<ng-container *transloco="let tHeader; read: 'nav_menu'">
    <ng-container *ngIf="selectedSchema$ | async as schema">
        <div class="text-primaryDark text-2xl sm:text-4xl font-bold primaryColorBG">
            {{tHeader(schema.name)}}
        </div>
    </ng-container>
    <button
        class="flex items-center w-fit h-7 p-1 ml-2 rounded-md text-secondaryMainLight hover:text-secondaryMain"
    >
        <mat-icon class="material-icons w-fit h-fit text-xl leading-5 mr-xs"
            >add_circle_outline</mat-icon
        >
        <span class="font-semibold">{{tHeader('add_new')}}</span>
    </button>
</ng-container>
