<ng-container *ngIf="tableData">
    <ng-container *transloco="let tTable; read: 'table'">
        <app-table
            [columnCell]="headerCell"
            [columns]="columns"
            [lockFirstColumn]="true"
            [rowCell]="rowCell"
            [rowMenu]="rowMenu"
            [rows]="rows"
            [isGrouped]="isGrouped"
            [selectedRowItems]="selectedRowItems"
            [uniqueKey]="'guid'"
            (selectedRow)="selectRecord.emit($event.item)"
            (clearSorting)="clearSorting()"
            (changedOrderColumn)="dropColumnFn($event)"
            (movedRowFromAnotherTable)="dropRow($event)"
            (resizeColumn)="resizeColumnFn($event)"
            (sortData)="sortData($event)"
        >
            <ng-container addRecord>
                <app-table-create-record
                    [currentUser]="currentUser"
                    [group]="group"
                    [objectTypeCode]="tableData.schema?.object_type_code"
                    class="flex-start-center text-center"
                ></app-table-create-record>
            </ng-container>
        </app-table>
    </ng-container>

    <ng-template #rowCell let-header="header" let-row="row">
        <app-cell-container
            (cellValueChanged)="updateRecordFn(row.item, cells[row.item.guid][header.item.guid], $event)"
            [fieldTypes]="fieldTypes"
            [field]="tableData.fields[header.item.guid]"
            [guid]="header.item.guid"
            [record]="row.item"
            [value]="cells[row.item.guid][header.item.guid].value"
            [valueJson]="cells[row.item.guid][header.item.guid].value_json"
            class="flex-start-center text-center"
        ></app-cell-container>
    </ng-template>
    <ng-template #rowMenu let-row>
        <app-table-row-menu
            (deleteRecord)="deleteRecordFn($event)"
            (shareRecord)="shareRecord($event)"
            [record]="row.item"
        ></app-table-row-menu>
    </ng-template>

    <ng-template #headerCell let-header>
        <button
            [matMenuTriggerFor]="menuHeaderCell"
            class="table-column-top-button"
            mat-icon-button
        >
            <app-field-name
                [field]="this.tableData!.fields[header.item.guid]"
                [selectedFolder]="this.selectedFolder!"
                [showIcon]="false"
            ></app-field-name>
        </button>
        <mat-menu #menuHeaderCell="matMenu">
            <ng-template matMenuContent>
                <app-column-menu
                    (copyColumn)="copyFieldFn(header.item.guid)"
                    (deleteColumn)="deleteFieldFn(header.item.guid)"
                    (hideColumn)="hideColumnFn($event)"
                    (moveColumnToEnd)="moveColumnToEndFn($event)"
                    (moveColumnToStart)="moveColumnToStartFn($event)"
                    (moveFieldToFolder)="movedFieldToFolder($event)"
                    (pinColumn)="pinColumnFn(header.item.guid)"
                    (shareWith)="shareField($event)"
                    (sortColumn)="sortColumnFn($event)"
                    (unPinColumn)="unPinColumnFn(header.item.guid)"
                    (updateField)="updateField($event)"
                    (resetWidth)="resetColumnWidth.emit($event)"
                    [columnsOrder]="tableData.columns.columnsOrder"
                    [fieldTypes]="fieldTypes"
                    [field]="tableData.fields[header.item.guid]"
                    [fields]="tableData.fields"
                    [guid]="header.item.guid"
                    [hiddenFieldGuids]="tableData.columns.hiddenColumns"
                    [isPinned]="header.isPinned"
                    [isPinAllowed]="this.isPinAvailable"
                    [visibleFieldGuids]="tableData.columns.columns"
                    [isCustomWidth]="isColumnWidth(header.item.guid)"
                ></app-column-menu>
            </ng-template>
        </mat-menu>
    </ng-template>
</ng-container>
