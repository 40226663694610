import {
    ResponseFieldEntities,
    ResponseRecord,
    ResponseRecordEntities,
    ResponseSchema,
    SystemTypeCode,
} from '../response'
import {
    AppRecord,
    Cell,
    Contact,
    ContactFieldNames,
    Deal,
    DealFieldNames,
    Folder,
    generateGlobalTypeRelatedCellEntities,
    generateTypeRelatedCellEntities,
    generateViewFields,
    GlobalFieldNames,
    ObjectTypes,
    Schema,
    SystemObjectTypes,
    Vendor,
    VendorFieldNames,
    View,
} from '../ui'
import {
    ConfigFieldNames,
    FolderFieldNames,
    RecordSystemFieldNames,
    ViewFieldNames,
} from '../system-record-fields.model'
import { Config } from '../ui/config.model'

export function generateRecord(
    recordGuid: string,
    schema: ResponseSchema | Schema,
    responseRecord: ResponseRecord,
): AppRecord | void {
    const sot =
        schema.system_object_type_code === SystemTypeCode.TABLE
            ? schema.object_type_code
            : schema.system_object_type_code
    const fields = 'field' in schema ? schema.field : schema.fieldEntities

    switch (sot) {
        case ObjectTypes.CRM_COMPANY: {
            return vendorFactory(schema.guid, recordGuid, fields, responseRecord)
        }
        case ObjectTypes.CRM_CONTACT: {
            return contactFactory(schema.guid, recordGuid, fields, responseRecord)
        }
        case ObjectTypes.CRM_DEAL: {
            return dealFactory(schema.guid, recordGuid, fields, responseRecord)
        }
        case SystemTypeCode.FOLDER: {
            return folderFactory(schema.guid, recordGuid, fields, responseRecord)
        }
        case SystemTypeCode.VIEW: {
            return viewFactory(schema.guid, recordGuid, fields, responseRecord)
        }
        case SystemTypeCode.CONFIG: {
            return configFactory(schema.guid, recordGuid, fields, responseRecord)
        }
        default: {
            console.error('Object type is not valid', schema.object_type_code)
            break
        }
    }
}

function recordFactory(
    schemaGuid: string,
    guid: string,
    responseRecord?: ResponseRecord | undefined,
): Partial<AppRecord> {
    return {
        schemaGuid: schemaGuid,
        guid: guid,
        folder_guids: responseRecord?.folders_guid,
        revision: responseRecord?.revision || 0,
        parent_sot_guid: responseRecord?.parent_sot_guid,
    }
}

function vendorFactory(
    schemaGuid: string,
    guid: string,
    fields: ResponseFieldEntities,
    responseRecord: ResponseRecord,
): Vendor {
    const record = recordFactory(schemaGuid, guid, responseRecord)
    const recordFields = generateGlobalTypeRelatedCellEntities(fields, responseRecord, [
        ...Object.values(RecordSystemFieldNames),
        ...Object.values(GlobalFieldNames),
        ...Object.values(VendorFieldNames),
    ])

    return { ...record, ...recordFields, type: SystemObjectTypes.Vendor } as Vendor
}

function dealFactory(
    schemaGuid: string,
    guid: string,
    fields: ResponseFieldEntities,
    responseRecord: ResponseRecord,
): Deal {
    const record = recordFactory(schemaGuid, guid, responseRecord)
    const recordFields = generateGlobalTypeRelatedCellEntities(fields, responseRecord, [
        ...Object.values(RecordSystemFieldNames),
        ...Object.values(GlobalFieldNames),
        ...Object.values(DealFieldNames),
    ])

    return { ...record, ...recordFields, type: SystemObjectTypes.Deal } as Deal
}

function contactFactory(
    schemaGuid: string,
    guid: string,
    fields: ResponseFieldEntities,
    responseRecord: ResponseRecord,
): Contact {
    const record = recordFactory(schemaGuid, guid, responseRecord)
    const recordFields = generateGlobalTypeRelatedCellEntities(fields, responseRecord, [
        ...Object.values(RecordSystemFieldNames),
        ...Object.values(GlobalFieldNames),
        ...Object.values(ContactFieldNames),
    ])

    return { ...record, ...recordFields, type: SystemObjectTypes.Contact } as Contact
}

export function generateRecords(
    schema: ResponseSchema | Schema,
    responseRecords: ResponseRecordEntities,
): AppRecord[] {
    return Object.keys(responseRecords).reduce((records, recordGuid) => {
        const newRecord = generateRecord(recordGuid, schema, responseRecords[recordGuid])
        return newRecord ? [...records, newRecord] : records
    }, [] as AppRecord[])
}

export function folderFactory(
    schemaGuid: string,
    guid: string,
    fields: ResponseFieldEntities,
    responseRecord: ResponseRecord,
) {
    const record = recordFactory(schemaGuid, guid, responseRecord)
    const typeRecord = generateTypeRelatedCellEntities(fields, responseRecord, [
        ...Object.values(FolderFieldNames),
        ...Object.values(RecordSystemFieldNames),
    ])

    return {
        ...record,
        ...typeRecord,
        type: SystemObjectTypes.Folder,
    } as Folder
}

export function viewFactory(
    schemaGuid: string,
    guid: string,
    fields: ResponseFieldEntities,
    responseRecord: ResponseRecord,
) {
    const record = recordFactory(schemaGuid, guid, responseRecord)

    const typeRecord = generateTypeRelatedCellEntities(fields, responseRecord, [
        ...Object.values(ViewFieldNames),
        ...Object.values(RecordSystemFieldNames),
    ])
    const viewType = (<Cell>typeRecord[ViewFieldNames.VIEW_TYPE]).value

    return {
        ...record,
        ...typeRecord,
        ...generateViewFields(viewType),
        type: SystemObjectTypes.View,
    } as View
}

function configFactory(
    schemaGuid: string,
    guid: string,
    fields: ResponseFieldEntities,
    responseRecord: ResponseRecord,
) {
    const record = recordFactory(schemaGuid, guid, responseRecord)
    const typeRecord = generateTypeRelatedCellEntities(fields, responseRecord, [
        ...Object.values(ConfigFieldNames),
        ...Object.values(RecordSystemFieldNames),
    ])

    return {
        ...record,
        ...typeRecord,
        type: SystemObjectTypes.Config,
    } as Config
}
