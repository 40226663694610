<div
    *ngIf="activeOptions.length >0; else defaultStatus"
    [matMenuTriggerFor]="dropdownMenu"
    class="flex-start-center status-dropdown"
>
    <span
        *ngFor="let option of activeOptions"
        [ngStyle]="{'background':getBgColor(option)}"
        class="option-item"
    ></span>
</div>
<ng-template #defaultStatus>
    <div
        class="status-dropdown"
        [matMenuTriggerFor]="dropdownMenu"
        *transloco="let tFilter; read: 'filter'"
    >
        {{tFilter('status_default_filter')}}
    </div>
</ng-template>
<mat-menu #dropdownMenu="matMenu">
    <ng-template matMenuContent>
        <form [formGroup]="formGroup">
            <cdk-accordion class="example-accordion" formArrayName="statusListChbx">
                <cdk-accordion-item
                    #accordionItem="cdkAccordionItem"
                    *ngFor="let item of statusList; let index = index;"
                    [attr.aria-controls]="'accordion-body-' + index"
                    [attr.aria-expanded]="accordionItem.expanded"
                    [attr.id]="'accordion-header-' + index"
                    [formGroupName]="index"
                    class="example-accordion-item"
                    role="button"
                    tabindex="0"
                >
                    <div
                        (click)="$event.stopPropagation()"
                        class="example-accordion-item-header flex-start-center justify-content-between ml-sm"
                    >
                        <mat-checkbox
                            (change)="this.setAll($event.checked, item)"
                            [formControlName]="'label'"
                            [indeterminate]="someComplete(item)"
                            class="example-margin text-primary"
                        >
                            {{ item }}
                        </mat-checkbox>

                        <span
                            (click)="accordionItem.toggle(); $event.stopPropagation()"
                            class="example-accordion-item-description material-icons"
                        >
                            {{ accordionItem.expanded ? 'expand_less' : 'expand_more' }}
                        </span>
                    </div>
                    <div
                        [attr.aria-labelledby]="'accordion-header-' + index"
                        [attr.id]="'accordion-body-' + index"
                        [style.display]="accordionItem.expanded ? '' : 'none'"
                        class="example-accordion-item-body"
                        formArrayName="statusListInnerChbx"
                        role="region"
                    >
                        <div *ngFor="let option of optionValuesItem(item); let index = index;">
                            <div class="accordion-row flex-start-center ml-lg">
                                <mat-checkbox
                                    (change)="updateAllComplete($event.checked, item, option)"
                                    (click)="$event.stopPropagation()"
                                    [formControlName]="index"
                                >
                                    <span
                                        [style]="{backgroundColor: option.color}"
                                        class="color-label mr-xs"
                                    ></span>
                                    {{option.label}}
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                </cdk-accordion-item>
            </cdk-accordion>
        </form>
    </ng-template>
</mat-menu>
