import { ModalContainerComponent } from '../modal-container-component/modal-container.component'
import { BaseContainer } from './base-container'
import { ComponentType } from '@angular/cdk/overlay'
import { BottomSheetInstanceService } from '../modal-instances/bottom-sheet-instance.service'
import { DialogInstanceService } from '../modal-instances/dialog-instance.service'
import { ModalContainerFactoryService } from '../modal-container-factory.service'
import { DisplaySize } from '../../breakpoint.service'
import { ModalContainer } from '../modal-manager.service'

export class DialogContainer<
    T extends ModalContainerComponent,
    ContainerData = unknown,
    Result = unknown,
> extends BaseContainer<T, ContainerData, Result> {
    constructor(
        modalContainerFactoryService: ModalContainerFactoryService,
        component: ComponentType<T>,
        data?: ContainerData,
    ) {
        super(modalContainerFactoryService, component, data)
    }

    displaySizeToRelatedContainer(displaySize: DisplaySize): ModalContainer {
        if (displaySize === DisplaySize.XSmall || displaySize === DisplaySize.Small) {
            return ModalContainer.BottomSheet
        }
        return ModalContainer.Dialog
    }
}
