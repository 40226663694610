import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { AppState } from '../app.state'
import { UpdateService } from '../../services'
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators'
import { onMessage, setError, setLoading } from '../common'
import { ObjectResponseModel } from '../../models'
import { of } from 'rxjs'
import { createConfigs, deleteConfigs, updateConfigs } from './config.actions'
import { ConfigUpdateModel } from '../../models/ui/config.model'

@Injectable()
export class ConfigEffects implements OnInitEffects {
    createConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createConfigs),
            tap(() => {
                console.log('createConfig$ Effect')
                this.store.dispatch(setLoading({ loading: true }))
            }),
            switchMap((action: { configs: Map<string, string> }) => {
                console.log('createConfig$ Effect switchMap')
                return this.updateService.createConfig(action.configs).pipe(
                    mergeMap((data: ObjectResponseModel) => {
                        return [onMessage({ response: data }), setLoading({ loading: false })]
                    }),
                )
            }),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        ),
    )

    updateConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateConfigs),
            tap(() => {
                console.log('updateConfig$ Effect')
                this.store.dispatch(setLoading({ loading: true }))
            }),
            switchMap((action: { configs: ConfigUpdateModel }) => {
                console.log('updateConfig$ Effect switchMap')
                return this.updateService.updateConfig(action.configs).pipe(
                    mergeMap((data: ObjectResponseModel) => {
                        return [onMessage({ response: data }), setLoading({ loading: false })]
                    }),
                )
            }),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        ),
    )

    deleteConfigs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteConfigs),
            tap(() => {
                console.log('updateConfig$ Effect')
                this.store.dispatch(setLoading({ loading: true }))
            }),
            switchMap((action: { configs: ConfigUpdateModel }) => {
                console.log('updateConfig$ Effect switchMap')
                return this.updateService.deleteConfig(action.configs).pipe(
                    mergeMap((data: ObjectResponseModel) => {
                        return [onMessage({ response: data }), setLoading({ loading: false })]
                    }),
                )
            }),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        ),
    )

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private updateService: UpdateService,
    ) {}
    ngrxOnInitEffects(): Action {
        return { type: '[ConfigEffects]: Init' }
    }
}
