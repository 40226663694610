<div
    class="sm:h-7 h-8 sm:bg-transparent bg-white rounded text-primarySecondary hover:text-primaryDark ml-4"
>
    <app-tb-menu
        [template]="sortMenu"
        (stateChanged)="onMenuStateChanged($event)"
        xPosition="before"
    >
        <div
            trigger
            class="flex h-100"
            [class.bg-white]="menuState === TbMenuStateEnum.Open"
            [class.text-primaryDark]="menuState === TbMenuStateEnum.Open"
        >
            <ng-container *ngTemplateOutlet="buttonInside"></ng-container>
        </div>
    </app-tb-menu>

    <ng-template #buttonInside>
        <div class="flex-start-center h-100 c-pointer sm:p-1 p-2 pr-2 rounded-lg">
            <div
                *transloco="let tButtons; read: 'buttons'"
                [class.text-accentContrastLight]="filterGroups && filterGroups.length !== 0"
                class="flex items-center"
            >
                <ng-container *ngIf="filterGroups && filterGroups.length === 0; else sortPrimary">
                    <mat-icon
                        class="w-5 h-5 text-xl leading-5 mr-2"
                        svgIcon="custom_sort"
                    ></mat-icon>
                    <span class="font-semibold"> {{tButtons('sort_by')}} </span>
                </ng-container>
                <ng-template #sortPrimary>
                    <mat-icon
                        class="w-5 h-5 text-xl leading-5 mr-2"
                        svgIcon="custom_sort"
                    ></mat-icon>
                    <span class="font-semibold mr-2">{{tButtons('sort_by')}}</span>
                    <span
                        class="max-sm:hidden flex items-center justify-center w-4 h-4 text-xs leading-3 rounded-full bg-accentContrastLight text-white"
                    >
                        {{filterCountText}}
                    </span>
                </ng-template>
            </div>
        </div>
    </ng-template>

    <ng-template #sortMenu>
        <app-sort-menu></app-sort-menu>
    </ng-template>
</div>
