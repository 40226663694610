import { Component, Input } from '@angular/core'
import {
    AppRecord,
    BusinessRecords,
    Cell,
    CellEntities,
    Field,
    FieldType,
    Folder,
    RecordUpdate,
} from '../../../../../@core/models'
import { Dictionary } from '@ngrx/entity'
import { RecordsService } from '../../../../../@core/services/records.service'

@Component({
    selector: 'app-card-field',
    templateUrl: './card-field.component.html',
    styleUrls: ['./card-field.component.sass'],
})
export class CardFieldComponent {
    @Input()
    record!: BusinessRecords

    @Input()
    field!: Field

    @Input()
    visibleFields!: string[]

    @Input()
    cells!: CellEntities

    @Input()
    fieldTypes!: Dictionary<FieldType>

    @Input()
    selectedFolder!: Folder | undefined | null

    constructor(private recordsService: RecordsService) {}

    updateRecord(data: AppRecord, cell: Cell, newValue: any) {
        const dataRecord: RecordUpdate = {
            record: data,
            cell: cell,
            value: newValue,
        }
        this.recordsService.updateRecord(dataRecord)
    }
}
