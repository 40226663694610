import { Cell, CellEntities } from './cell.model'
import {
    Contact,
    ContactFieldNames,
    Deal,
    DealFieldNames,
    GlobalFieldNames,
    Vendor,
    VendorFieldNames,
} from './business-record.model'
import {
    FolderFieldNames,
    RecordSystemFieldNames,
    ViewFieldNames,
} from '../system-record-fields.model'
import { isNumber, reduce } from 'lodash-es'
import { AppRecord, BusinessRecords, RecordGroup, SystemObjectTypes } from './base-record.model'
import { View } from './view.model'
import { Folder } from './folder.model'

let generatedCells: { [recordGuid: string]: { revision: number; cells: CellEntities } } = {}

// todo: doesn't work correctly when we add virtual-cell, returns cached value without virtual-cell
export function getRecordCells<T extends Partial<AppRecord>>(record: T): CellEntities {
    if (!record.guid || !isNumber(record.revision)) return generateRecordCells(record)

    if (generatedCells[record.guid]?.revision === record.revision)
        return generatedCells[record.guid].cells

    generatedCells[record.guid] = {
        revision: record.revision,
        cells: generateRecordCells(record),
    }

    return generatedCells[record.guid].cells
}

export function regenerateCells<T extends Partial<AppRecord>>(record: T) {
    if (!record.guid || !isNumber(record.revision)) return

    generatedCells[record.guid] = {
        revision: record.revision,
        cells: generateRecordCells(record),
    }
}

export function clearCachedGeneratedCells() {
    generatedCells = {}
}

function generateRecordCells(record: Partial<AppRecord>): CellEntities {
    switch (record.type) {
        case SystemObjectTypes.Contact: {
            return generateCells(record as Contact, [
                ...Object.values(RecordSystemFieldNames),
                ...Object.values(GlobalFieldNames),
                ...Object.values(ContactFieldNames),
            ])
        }
        case SystemObjectTypes.Deal: {
            return generateCells(record as Deal, [
                ...Object.values(RecordSystemFieldNames),
                ...Object.values(GlobalFieldNames),
                ...Object.values(DealFieldNames),
            ])
        }
        case SystemObjectTypes.Vendor: {
            return generateCells(record as Vendor, [
                ...Object.values(RecordSystemFieldNames),
                ...Object.values(GlobalFieldNames),
                ...Object.values(VendorFieldNames),
            ])
        }
        case SystemObjectTypes.View:
            return generateCells(record as View, [
                ...Object.values(RecordSystemFieldNames),
                ...Object.values(ViewFieldNames),
            ])
        case SystemObjectTypes.Folder:
            return generateCells(record as Folder, [
                ...Object.values(RecordSystemFieldNames),
                ...Object.values(FolderFieldNames),
            ])
        default: {
            throw new Error(`Record type is not valid or doesn't have cells`)
        }
    }
}

function generateCells<T extends Partial<BusinessRecords>>(
    record: T,
    cells: (keyof T | string)[],
): CellEntities {
    const recordCells = cells.reduce((dictionary, fieldName) => {
        if (!record[fieldName as keyof T]) return dictionary

        const cell = record[fieldName as keyof T] as Cell | unknown

        if (isRecordCell(cell)) {
            return { ...dictionary, [cell.fieldGuid]: cell }
        } else if (isCellsWithFolders(cell)) {
            const folderRelatedFields = reduce(
                cell,
                (fields, cell) => {
                    return { ...fields, [cell.fieldGuid]: cell }
                },
                {},
            )

            return { ...dictionary, ...folderRelatedFields }
        }

        return dictionary
    }, {}) as CellEntities

    const noNameCells = record.noNameCells || {}

    return {
        ...recordCells,
        ...noNameCells,
    }
}

function isRecordCell(cell: unknown): cell is Cell {
    return typeof cell === 'object' && cell !== null && 'fieldGuid' in cell
}

function isCellsWithFolders(cell: unknown): cell is { [folderGuid: string]: Cell } {
    return !isRecordCell(cell) && typeof cell === 'object'
}

export function prepareCellsForRecords(records: AppRecord[]) {
    return records.reduce((acc, record: AppRecord) => {
        return { ...acc, [record.guid]: getRecordCells(record) }
    }, {} as { [recordGuid: string]: CellEntities })
}

export function prepareFromGroups(groups: RecordGroup[]) {
    return groups.reduce((acc, group) => {
        return { ...acc, ...prepareCellsForRecords(group.data) }
    }, {} as { [recordGuid: string]: CellEntities })
}
