<app-board-title
    *ngIf="!isCollapsed; else unCollapseButton"
    (addBoardItem)="addNewBoardItem(true)"
    (isCreateNewItemShown)="isCreateNewItemShown.emit(true)"
    (collapseColumn)="isCollapsed=$event"
    (editFields)="editFields.emit($event)"
    (selectColumnRecords)="selectAllColumnRecords()"
    [columnItemsCount]="boardColData.length || 0"
    [columnName]="columnName"
    [field]="field"
    [item]="{ data: boardColData, value: columnName, field: field }"
    [fieldType]="fieldTypes"
    [record]="boardColData[0]"
    [folder]="selectedFolder"
    [isCreateOpened]="isCreateOpened"
    [cells]="cells"
    [allowCollapsing]="allowCollapsing"
    [isAllRecordsSelected]="isAllRecordsSelected()"
></app-board-title>
<ng-template #unCollapseButton>
    <button (click)="unCollapseColumn()" class="column-collapse-title">
        <mat-icon>arrow_right_ios_new</mat-icon>
    </button>
</ng-template>

<ng-container *ngIf="!isCollapsed; else collapsedView">
    <div
        (cdkDropListDropped)="drop($event)"
        [cdkDropListData]="boardColData"
        cdkDropList
        class="board-list"
        *transloco="let tButtons; read: 'buttons'"
    >
        <div #createAreaTop></div>
        <div [cdkDragData]="item.guid" *ngFor="let item of boardColData" cdkDrag class="w-100">
            <app-board-card
                [record]="item"
                [selectedFolder]="selectedFolder"
                [visibleFields]="visibleFields"
                [cells]="cells[item.guid]"
            ></app-board-card>
        </div>
        <div #createAreaBottom></div>

        <button
            (click)="addNewBoardItem(false); isCreateNewItemShown.emit(true)"
            class="mt-lg"
            mat-button
        >
            <span class="material-icons text-size-sm text-muted"> add</span>
            <span class="text-muted">{{tButtons('new_task')}}</span>
        </button>
    </div>
</ng-container>
<ng-template #collapsedView>
    <div class="board-list-collapsed">
        <h2 class="float-left w-100">
            <div
                class="flex-start-center position-relative collapse-inner w-100"
                *ngIf="showGroupCollapsed(); else noGroup"
                matBadge="{{boardColData.length || 0}}"
                matBadgePosition="below after"
                matBadgeOverlap="false"
            >
                <app-grouping-values
                    *ngIf="field; else unchecked"
                    class="w-100 h-100"
                    [isCollapsed]="true"
                    [fieldType]="fieldTypes![field.field_type_code]"
                    [field]="field"
                    [value]="columnName ?? ''"
                ></app-grouping-values>
                <ng-template #unchecked>
                    <span *transloco="let tTable; read: 'table'"> {{tTable('template')}} </span>
                </ng-template>
            </div>
            <ng-template #noGroup>
                <span
                    matBadge="{{boardColData.length || 0}}"
                    matBadgePosition="below after"
                    matBadgeOverlap="false"
                    *ngIf="field === null"
                    >{{columnName}}</span
                >
            </ng-template>
        </h2>
    </div>
</ng-template>
