import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FieldEntities, FieldType, Folder } from '../../../../@core/models'
import { FormGroup } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { FormFilterService } from '../form-filter.service'

@UntilDestroy()
@Component({
    selector: 'app-filter-item',
    templateUrl: './filter-item.component.html',
})
export class FilterItemComponent implements OnInit {
    @Input()
    selectedFolder!: Folder

    @Input()
    fields!: FieldEntities

    @Input()
    fieldGuids!: string[]

    @Input()
    fieldTypes!: FieldType[]

    @Input()
    totalItems!: number

    @Input()
    itemIndex!: number

    @Input()
    groupIndex!: number

    @Output()
    deleteGroup: EventEmitter<void> = new EventEmitter()

    @Output()
    deleteFilter: EventEmitter<number> = new EventEmitter()

    get formItem() {
        return this.formFilterService.getFilterItem(this.groupIndex, this.itemIndex) as FormGroup
    }

    get fieldGuid() {
        return this.formItem.controls['fieldGuid'].value
    }

    constructor(public formFilterService: FormFilterService) {}

    ngOnInit(): void {
        this.setOptionIfEmpty()

        this.formItem.controls['fieldGuid'].valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setOptionDefault()
            })
    }

    fieldFilterOptions(fieldGuid: string) {
        const fieldType = this.fieldTypes.find(
            (type) => type.guid === this.fields[fieldGuid]?.field_type_code,
        )
        return fieldType?.filterOptions ?? []
    }

    removeFilter() {
        if (this.totalItems <= 1) {
            return this.deleteGroup.emit()
        }

        this.deleteFilter.emit(this.itemIndex)
    }

    private setOptionIfEmpty() {
        const option = this.formItem.controls['option']
        if (option.value) return

        this.setOptionDefault(option)
    }

    private setOptionDefault(option = this.formItem.controls['option']) {
        const filterOptions = this.fieldFilterOptions(this.formItem.controls['fieldGuid'].value)
        if (filterOptions && filterOptions.length) {
            option.setValue(filterOptions[0])
        }
    }
}
