import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { CellComponent } from '../cell.component'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { map, tap } from 'rxjs/operators'
import {
    BusinessRecords,
    CellEntities,
    Field,
    getRecordCells,
    LinkOptions,
    ValueJson,
} from '../../../@core/models'
import { filter, Observable } from 'rxjs'
import { isNonNull } from '../../../@core/global-util'
import { FormControl } from '@angular/forms'
import { Dictionary } from '@ngrx/entity'
import { FieldTypeFacadeService } from '../../../@core/services/store-facade'
import { RecordFacadeService } from '../../../@core/services/store-facade'
import { SchemaFacadeService } from '../../../@core/services/store-facade'

@UntilDestroy()
@Component({
    selector: 'app-link',
    styleUrls: ['link.component.sass'],
    templateUrl: './link.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent extends CellComponent implements OnInit {
    fields!: Field[]
    cells!: { [recordGuid: string]: CellEntities }
    records!: BusinessRecords[]
    allSchemaRecords!: BusinessRecords[]
    deletedRecords!: string[]

    fieldTypes$ = this.fieldTypeFacadeService.selectFieldTypeEntities$

    recordsControl = new FormControl([''])

    constructor(
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private recordFacadeService: RecordFacadeService,
        private schemaFacadeService: SchemaFacadeService,
    ) {
        super()
    }

    ngOnInit() {
        if (!this.valueJson) {
            this.valueJson = {}
        }

        this.getColRecords(this.valueJson)
        this.deletedRecords = this.getDeletedRecords()
        this.loadOtherSchemaFields()
        this.recordsControl.setValue(Object.keys(this.valueJson))
    }

    updateValue(isOpened: boolean) {
        if (isOpened || !this.recordsControl.value) return

        const values = this.recordsControl.value ? this.recordsControl.value.sort() : []
        const prevValues = this.value ? this.value.split(',').sort() : []

        if (values === prevValues) return

        const addedValues = values?.filter((value) => !prevValues.includes(value))
        const deletedValues = prevValues.filter((value) => !values.includes(value))

        const valueToObject = {
            add_guids: addedValues,
            delete_guids: deletedValues,
        }

        this.cellValueChanged.emit(valueToObject)
    }

    cleanDeletedRecords() {
        this.cellValueChanged.emit({
            add_guids: [],
            delete_guids: this.deletedRecords,
        })
    }

    private getColRecords(linkedRecords: ValueJson) {
        const recordsGuids = Object.keys(linkedRecords)

        this.recordFacadeService.selectRecordEntities$
            .pipe(
                tap((entities) => {
                    this.allSchemaRecords = this.prepareAllSchemaRecords(entities)
                }),
                map((entities) => {
                    return recordsGuids.map((guid) => entities[guid]).filter(isNonNull)
                }),
                untilDestroyed(this),
            )
            .subscribe((records) => {
                this.cells = this.prepareRecordsCell(records)
                this.records = records
            })
    }

    private loadOtherSchemaFields(): Observable<Field[]> | void {
        const schemaGuid = this.field.link_definition?.target_solution_object_type_guid
        const fieldGuids = this.field.link_definition?.target_object_field_guids

        if (!schemaGuid || !fieldGuids) return

        this.schemaFacadeService.selectSchemaEntities$
            .pipe(
                map((schemaEntities) => schemaEntities[schemaGuid]),
                filter(isNonNull),
                map((schema) => fieldGuids.map((guid) => schema.fieldEntities[guid])),
                untilDestroyed(this),
            )
            .subscribe((fields) => (this.fields = fields))
    }

    private prepareRecordsCell(records: BusinessRecords[]) {
        return records.reduce((acc, record) => {
            return { ...acc, [record.guid]: getRecordCells(record) }
        }, {} as { [recordGuid: string]: CellEntities })
    }

    private prepareAllSchemaRecords(entities: Dictionary<BusinessRecords>) {
        const schemaGuid = this.field.link_definition?.target_solution_object_type_guid

        return Object.keys(entities)
            .filter((guid) => entities[guid]?.schemaGuid === schemaGuid)
            .map((guid) => entities[guid]!)
    }

    private getDeletedRecords() {
        return Object.keys(this.valueJson).filter(
            (guid) =>
                this.valueJson[guid] === LinkOptions.delete ||
                !this.records.find((record) => record.guid === guid),
        )
    }
}
