import { createAction, props } from '@ngrx/store'
import { CurrentUser, UserModelEntities } from '../../models'

export const setUsers = createAction(
    '[Set Users(App)] SEt_USERS',
    props<{ users: UserModelEntities }>(),
)
export const setUser = createAction(
    '[Set User(App)] SEt_USER',
    props<{ currentUser: CurrentUser | null }>(),
)
