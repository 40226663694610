import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { EMPTY, Observable, of } from 'rxjs'
import { catchError, switchMap, tap } from 'rxjs/operators'
import { DOCUMENT } from '@angular/common'

interface ConfigResponse {
    env: string
    server_base_url: string
}

export interface AuthResponse {
    data: AuthResponseData
    status: AuthResponseStatus
}

enum AuthResponseStatus {
    SUCCESS = 'success',
    AUTH = 'auth',
    ERROR = 'error',
}

interface AuthResponseData {
    token?: string
    ws?: string
    url?: string
}

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient, @Inject(DOCUMENT) private document: Document) {}

    logout(): Observable<Response> {
        return this.httpClient
            .get('https://tikibase-admin-dev-product.anveo.com/logout', {
                withCredentials: true,
                responseType: 'text',
                headers: {
                    'solution.logout': '',
                },
            })
            .pipe(
                catchError((error: any) => {
                    return of(error)
                }),
            )
    }

    auth(): Observable<AuthResponse> {
        return this.httpClient.get<ConfigResponse>('/assets/config.json').pipe(
            catchError((error: any) => {
                console.log(error)
                return EMPTY
            }),
            tap((res) => console.log(res)),
            switchMap((res: ConfigResponse) => {
                return this.httpClient
                    .get<AuthResponse>(`${res.server_base_url}/api/v1/auth`, {
                        withCredentials: true,
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                        },
                    })
                    .pipe(
                        tap((res) => console.log(res)),
                        catchError((error: any) => {
                            console.log(error)
                            return this.auth()
                        }),
                        switchMap((auth: AuthResponse) => {
                            if (auth.status === AuthResponseStatus.SUCCESS) {
                                return of(auth)
                            }

                            if (auth.status === AuthResponseStatus.AUTH) {
                                this.document.location.href = `${res.server_base_url}${auth.data.url}&redirectUrl=${this.document.location.host}${this.document.location.pathname}`
                            }
                            return EMPTY
                        }),
                    )
            }),
        )
    }
}
