<div *transloco="let t">
    <ng-container *ngIf="errorTypeNames">
        <span *ngFor="let error of errorTypeNames">{{t('view_error', {error: error})}}</span>
    </ng-container>
    <ng-container *ngIf="currentUser$ | async as user">
        <app-create-view
            *ngIf="!errorTypeNames"
            [selectedView]="view"
            (created)="navigateToSOT()"
        ></app-create-view>
    </ng-container>
</div>
