import { AppRecord, BusinessRecord, Contact, Deal, SystemObjectTypes, Vendor } from '../ui'

export function isBusinessRecord(record: AppRecord): record is BusinessRecord {
    const globalTypes = [
        SystemObjectTypes.Vendor,
        SystemObjectTypes.Deal,
        SystemObjectTypes.Contact,
    ]
    return globalTypes.includes(record.type)
}

export function isContact(record: AppRecord): record is Contact {
    return record.type === SystemObjectTypes.Contact
}

export function isVendor(record: AppRecord): record is Vendor {
    return record.type === SystemObjectTypes.Vendor
}

export function isDeal(record: AppRecord): record is Deal {
    return record.type === SystemObjectTypes.Deal
}
