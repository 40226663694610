<div
    class="sm:h-7 h-8 sm:bg-transparent bg-white rounded text-primarySecondary hover:text-primaryDark ml-4"
>
    <app-tb-menu
        [template]="groupMenu"
        (stateChanged)="onMenuStateChanged($event)"
        xPosition="before"
    >
        <div
            trigger
            class="flex h-100"
            [class.bg-white]="menuState === TbMenuStateEnum.Open"
            [class.text-primaryDark]="menuState === TbMenuStateEnum.Open"
        >
            <ng-container *ngTemplateOutlet="buttonInside"></ng-container>
        </div>
    </app-tb-menu>

    <ng-template #buttonInside>
        <div
            *transloco="let tGroup; read: 'group'"
            class="flex-start-center h-100 c-pointer group-name sm:p-1 p-2 pr-2 rounded-lg font-semibold"
        >
            <div [class.text-accentContrastLight]="this.groupFieldGuid" class="flex items-center">
                <mat-icon class="w-5 h-5 text-xl leading-5 mr-2" svgIcon="custom_group"></mat-icon>
                <span class="flex items-center"
                    >{{tGroup('group_by_btn')}}
                    <span *ngIf="this.groupFieldGuid">:&nbsp;</span>
                    <app-field-name
                        *ngIf="groupField"
                        [field]="groupField"
                        [selectedFolder]="selectedFolder"
                        [showIcon]="false"
                        class="group-name"
                    ></app-field-name>
                </span>
            </div>
        </div>
    </ng-template>

    <ng-template #groupMenu>
        <app-group-menu
            (groupValueUpdated)="groupValueSaved($event)"
            [fields]="fields"
            [selectedFolder]="selectedFolder"
            [selectedView]="selectedView"
            [groupValue]="groupFieldGuid"
        ></app-group-menu>
    </ng-template>
</div>
