import { Component, OnInit, ViewChild } from '@angular/core'
import { FilterGroup, View } from '../../../@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ViewFacadeService } from '../../../@core/services/store-facade'
import { TranslocoService } from '@ngneat/transloco'
import { combineLatest } from 'rxjs'
import { FilterStorageService } from '../../../@core/services/session-storage/filter-storage.service'
import { FilterService } from './filter.service'
import { TbMenuComponent, TbMenuState } from '../../../@components-library/tb-menu'
import { Actions, ofType } from '@ngrx/effects'
import { openViewFilterMenu } from '../../../@core/@ngrx'

@UntilDestroy()
@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.sass'],
})
export class FilterComponent implements OnInit {
    filterGroups!: FilterGroup[]
    selectedView!: View

    sessionFilterCount: string = ''
    isSessionFilterSet: boolean = false

    menuState: TbMenuState = TbMenuState.Closed

    @ViewChild(TbMenuComponent) menuComponent!: TbMenuComponent

    protected readonly TbMenuStateEnum = TbMenuState

    constructor(
        private viewFacadeService: ViewFacadeService,
        private filterStorageService: FilterStorageService,
        private filterService: FilterService,
        private translocoService: TranslocoService,
        private actions$: Actions,
    ) {
        this.subscribeOnOpenAction()
    }

    //TODO refactor this getter latter
    get filterCountText() {
        if (this.isSessionFilterSet) {
            return this.sessionFilterCount
        }
        if (this.filterGroups && this.filterGroups.length === 1) {
            if (!this.filterGroups[0].values) return '1'
            const keys = Object.keys(this.filterGroups[0].values)
            if (keys.length) {
                return keys.length.toString()
            }
        }
        return this.filterGroups ? this.filterGroups.length.toString() : ''
    }

    ngOnInit(): void {
        this.filterStorageService
            .isSessionFilterSet$()
            .pipe(untilDestroyed(this))
            .subscribe((state) => (this.isSessionFilterSet = state))

        combineLatest([
            this.viewFacadeService.selectSelectedView$,
            this.translocoService.selectTranslate('filter.session_count'),
        ])
            .pipe(untilDestroyed(this))
            .subscribe(([view, sessionFilterCount]) => {
                this.sessionFilterCount = sessionFilterCount
                if (!view) return

                this.selectedView = view
                console.log('this.selectedView', this.selectedView)
                this.filterGroups = this.filterService.getFilterGroupByView(view)
                this.filterStorageService.isSessionFilterSetUpdate(view)
            })
    }

    onMenuStateChanged(menuState: TbMenuState) {
        this.menuState = menuState

        if (menuState === TbMenuState.Closed) {
            this.filterGroups = this.filterService.getFilterGroupByView(this.selectedView)
            this.filterStorageService.isSessionFilterSetUpdate(this.selectedView)
        }
    }

    isFilterTextSessionOrNotApplied() {
        if (!this.selectedView?.filter?.value && this.isSessionFilterSet) {
            return false
        }

        return this.filterGroups && this.filterGroups.length !== 0
    }

    subscribeOnOpenAction() {
        this.actions$.pipe(ofType(openViewFilterMenu), untilDestroyed(this)).subscribe(() => {
            this.menuComponent?.openMenu()
        })
    }
}
