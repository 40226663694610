import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Contact } from '../../../@core/models'

@Component({
    selector: 'app-list-contacts',
    templateUrl: './list-contacts.component.html',
    styleUrls: ['./list-contacts.component.sass'],
})
export class ListContactsComponent {
    @Input()
    contacts!: Contact[]

    @Output()
    selectContact: EventEmitter<string> = new EventEmitter()

    selectContactById(guid: string) {
        this.selectContact.emit(guid)
    }
}
