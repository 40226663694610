import { Component, Input, OnInit } from '@angular/core'
import {
    BusinessRecord,
    Field,
    FieldEntities,
    FieldType,
    Folder,
    GlobalFieldNames,
    RecordGroup,
    Schema,
} from '../../../../@core/models'
import { Dictionary } from '@ngrx/entity'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { map, tap } from 'rxjs/operators'
import { combineLatest, EMPTY, of, switchMap } from 'rxjs'
import { RecordsService } from '../../../../@core/services/records.service'
import {
    CrudRecordModelFactoryService,
    FieldValue,
} from '../../../../@core/services/crud-record-model-factory.service'
import { FormControl } from '@angular/forms'
import {
    RecordFacadeService,
    FieldTypeFacadeService,
    FolderFacadeService,
    SchemaFacadeService,
    UserFacadeService,
} from '../../../../@core/services/store-facade'
import { getBoolean } from '../../../../@core/global-util'

interface createRecordDataModel {
    folderGuid: string
    folder: Folder
    guids: string
    fields: FieldEntities
    schema?: Schema
    status?: Field
}

@UntilDestroy()
@Component({
    selector: 'app-table-create-record',
    templateUrl: './table-create-record.component.html',
    styleUrls: ['./table-create-record.component.sass'],
})
export class TableCreateRecordComponent implements OnInit {
    @Input() group!: RecordGroup | undefined

    @Input() currentUser?: string

    @Input() objectTypeCode?: string

    nameField!: Field
    assigneeField!: Field
    statusField!: Field
    watchField!: Field
    record!: BusinessRecord
    folder!: Folder
    statusValue: string = ''
    assigneeValue: string = ''
    watchValue: string = ''
    fields!: FieldEntities
    fieldTypes!: Dictionary<FieldType>
    folderGuids!: string
    schema!: Schema
    folderListControl!: FormControl
    folderList!: string[]
    folders!: Dictionary<Folder>
    isMyFolder: boolean = false
    textFormControl!: FormControl<string | null>
    isFormOpened = false
    constructor(
        private crudRecordModelFactoryService: CrudRecordModelFactoryService,
        private recordService: RecordsService,
        private recordFacadeService: RecordFacadeService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private userFacadeService: UserFacadeService,
    ) {}

    ngOnInit() {
        this.textFormControl = new FormControl<string>('')
        if (this.currentUser) {
            this.assigneeValue = this.currentUser
        }
        this.folderFacadeService.selectSelectedFolderGuid$
            .pipe(
                untilDestroyed(this),
                switchMap((guid) => {
                    if (guid === null) {
                        this.isMyFolder = true
                        return this.folderFacadeService.selectFolderEntities$.pipe(
                            tap((folders) => {
                                const globalGuid = Object.keys(folders).find((folder) =>
                                    getBoolean(folders[folder]?.is_global.value),
                                )
                                this.folderListControl = new FormControl(globalGuid)
                                this.folderList = Object.keys(folders)
                                this.folders = folders
                            }),
                            map((folders) => {
                                if (folders) {
                                    let folderGuid = Object.keys(folders).find((guid) =>
                                        getBoolean(folders[guid]?.is_global.value),
                                    ) as string
                                    let folder = folders[folderGuid] as Folder
                                    let guids = Object.keys(folders)
                                        .filter(
                                            (guid) => !getBoolean(folders[guid]?.is_global.value),
                                        )
                                        .toString()
                                    return { folderGuid, folder, guids } as createRecordDataModel
                                }
                                return
                            }),
                            switchMap((folderObject: createRecordDataModel | undefined) => {
                                if (folderObject) {
                                    return combineLatest([
                                        of(folderObject),
                                        this.recordFacadeService.selectNewRecordCreateData$(
                                            folderObject.folderGuid,
                                            this.objectTypeCode as string,
                                        ),
                                    ])
                                }
                                return EMPTY
                            }),
                            map(([folderObject, recordCreateData]) => {
                                if (recordCreateData) {
                                    return {
                                        ...folderObject,
                                        fields: {
                                            assignee: recordCreateData.assignee,
                                            watch: recordCreateData.watch,
                                            name: recordCreateData.name,
                                        },
                                        schema: recordCreateData.schema,
                                        status: recordCreateData.status,
                                    } as createRecordDataModel
                                }
                                return
                            }),
                        )
                    }
                    return combineLatest([
                        this.folderFacadeService.selectFolderEntities$,
                        this.folderFacadeService.selectSelectedFolderStatusField$,
                        this.schemaFacadeService.selectSelectedTableSchemaFieldEntities$,
                        this.schemaFacadeService.selectSelectedTableSchema$,
                        this.folderFacadeService.selectSelectedFolder$,
                        this.userFacadeService.selectCurrentUser$,
                    ]).pipe(
                        map(([folders, folderStatus, fields, schema, folder, user]) => {
                            this.currentUser = user!.guid
                            return {
                                folderGuid: '',
                                guids: Object.keys(folders)
                                    .filter((guid) => !getBoolean(folders[guid]?.is_global.value))
                                    .toString(),
                                status: folderStatus,
                                fields,
                                schema,
                                folder,
                            } as createRecordDataModel
                        }),
                    )
                }),
            )
            .subscribe((data) => {
                if (!data) {
                    console.log(new Error('empty data for new record'))
                    return
                }

                if (!data.fields || !data.schema || !data.status) {
                    return
                }

                this.folderGuids = data.guids
                this.folder = data.folder
                this.statusField = data.status as Field
                this.schema = data.schema as Schema
                if (data.folderGuid !== '') {
                    this.nameField = data.fields.name
                    this.watchField = data.fields.watch
                    this.assigneeField = data.fields.assignee
                } else {
                    this.fields = data.fields as FieldEntities
                    Object.values(this.fields).forEach((field) => {
                        if (field.system_name === GlobalFieldNames.NAME) {
                            this.nameField = field
                        }
                        if (field.system_name === GlobalFieldNames.ASSIGNEE) {
                            if (
                                field.folder_guid === this.folder.guid ||
                                (getBoolean(this.folder.is_global.value) &&
                                    !!field.folder_name?.is_global)
                            ) {
                                this.assigneeField = field
                            }
                        }
                        if (field.system_name === GlobalFieldNames.WATCH) {
                            this.watchField = field
                        }
                    })
                }
                this.assigneeValue = this.currentUser || ''

                this.newRecordDataCreate()
            })
        this.fieldTypeFacadeService.selectFieldTypeEntities$
            .pipe(untilDestroyed(this))
            .subscribe((types) => {
                if (types) {
                    this.fieldTypes = types
                }
            })
        this.folderFacadeService.selectSelectedFolder$
            .pipe(untilDestroyed(this))
            .subscribe((folder) => {
                if (folder) this.folder = folder
            })
    }

    newRecordDataCreate() {
        const fieldArr = [
            {
                fieldGuid: this.nameField.guid,
                value: this.textFormControl.value,
            },
            {
                fieldGuid: this.assigneeField.guid,
                value: this.assigneeValue,
            },
            {
                fieldGuid: this.statusField.guid,
                value: this.statusValue,
            },
            {
                fieldGuid: this.watchField.guid,
                value: this.watchValue,
            },
        ]

        if (this.group?.field && this.group?.value) {
            if (this.group.field.guid === this.statusField.guid) {
                this.statusValue = this.group.value
                return
            }
            fieldArr.push({
                fieldGuid: this.group.field.guid,
                value: this.group.value,
            })
        }
    }

    newRecordDataUpdateOnFolderChange() {
        this.recordFacadeService
            .selectNewRecordCreateData$(this.folderListControl.value, this.objectTypeCode as string)
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                if (!data) {
                    console.log('no data for update record found!')
                    return
                }
                this.folder = this.folders[this.folderListControl.value]!
                this.schema = data.schema
                this.assigneeField = data.assignee
                this.nameField = data.name
                this.watchField = data.watch
                this.statusField = data.status
                this.newRecordDataCreate()
            })
    }

    saveStatusCreateRecord(val: string) {
        this.statusValue = val
    }

    saveAssigneeCreateRecord(val: string) {
        this.assigneeValue = val
    }

    saveWatchCreateRecord(val: string) {
        this.watchValue = val
    }

    createRecord() {
        //TODO redo in https://chat.anveo.com/git/AnveoTikibase_ui/issues/164
        const folderGuids = this.folder.guid
        const createRecord = this.crudRecordModelFactoryService.prepareCreateModelWithFields(
            '',
            this.schema.guid,
            folderGuids,
            this.prepareFieldValues(),
        )
        // todo: [table-ref] remove this call, this component should be dumb
        this.recordService.createRecord(createRecord)
        this.resetForm()
    }

    resetForm() {
        this.textFormControl.reset()
        this.statusValue = ''
        this.assigneeValue = ''
        this.isFormOpened = false
    }

    private prepareFieldValues() {
        return [
            {
                fieldGuid: this.nameField?.guid,
                value: this.textFormControl.value,
            },
            {
                fieldGuid: this.statusField?.guid,
                value: this.statusValue,
            },
            {
                fieldGuid: this.assigneeField?.guid,
                value: this.assigneeValue,
            },
            {
                fieldGuid: this.watchField.guid,
                value: this.watchValue,
            },
        ]
            .filter((item) => item.fieldGuid)
            .filter((item) => item.value) as FieldValue[]
    }
}
