<form class="edit-mode-form flex-start-center">
    <ng-container *ngIf="!isEdit">
        <ng-container *ngIf="!disabled, else disabledContent">
            <div *ngIf="value; else placeholder" class="input-label flex-start-center">
                <span (click)="showCard()"> {{value}} </span>
                <mat-icon
                    (click)="editMode()"
                    class="edit-mode-label text-size-xs text-muted ml-sm"
                >
                    edit
                </mat-icon>
            </div>
            <ng-template #placeholder>
                <div (click)="editMode()" class="input-label flex-start-center">
                    <span *ngIf="textFormControl.valid" class="text-muted material-icons">
                        remove
                    </span>
                    <mat-icon
                        (click)="editMode()"
                        class="edit-mode-label text-size-xs text-muted ml-sm"
                    >
                        edit
                    </mat-icon>
                </div>
            </ng-template>
        </ng-container>
        <ng-template #disabledContent>
            <div *ngIf="value; else placeholder" class="input-label flex-start-center">
                <span> {{value}} </span>
            </div>
            <ng-template #placeholder>
                <div class="input-label flex-start-center">
                    <span class="text-muted material-icons"> remove </span>
                </div>
            </ng-template>
        </ng-template>
    </ng-container>
    <div [class.active]="isEdit" class="edit-mode-area">
        <mat-form-field class="text-input">
            <div class="flex-start-center justify-content-between">
                <input
                    (keyup)="saveOnEnter($event)"
                    [appAutoFocus]="isEdit"
                    [formControl]="textFormControl"
                    autocomplete="off"
                    matInput
                    name="name"
                    type="text"
                />
                <mat-icon (click)="saveValue()" *ngIf="isEdit" class="text-success">
                    check_circle
                </mat-icon>
            </div>
        </mat-form-field>
    </div>
    <mat-error *ngIf="textFormControl.hasError('required')">
        <ng-container *transloco="let tCellTypesErrors; read: 'cell_types.errors'">
            {{tCellTypesErrors('value_is')}}
            <strong>{{tCellTypesErrors('required')}}</strong>
        </ng-container>
    </mat-error>
</form>
