import { Component, Input, OnInit } from '@angular/core'
import { ContactActivity } from '../../contact-activity-json'

@Component({
    selector: 'app-card-activity',
    templateUrl: './list-card-activity.component.html',
    styleUrls: ['./list-card-activity.component.sass'],
})
export class ListCardActivityComponent implements OnInit {
    @Input()
    groupActivities!: ContactActivity[]

    groupActivitiesMore!: ContactActivity[]
    isShowMore: boolean = false

    ngOnInit(): void {
        if (this.groupActivities.length) {
            this.groupActivitiesMore = this.groupActivities.slice(1)
        }
    }

    showMoreActivities() {
        this.isShowMore = !this.isShowMore
    }
}
