import { UntilDestroy } from '@ngneat/until-destroy'
import { Component } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { AddFieldContentComponent } from './add-field-content/add-field-content.component'

@UntilDestroy()
@Component({
    selector: 'app-field-configuration',
    templateUrl: './field-configuration.component.html',
})
export class FieldConfigurationComponent {
    constructor(private dialog: MatDialog, private bottomSheet: MatBottomSheet) {}

    openAddFieldDialog() {
        this.dialog.open<AddFieldContentComponent>(AddFieldContentComponent)
    }

    openAddFieldBottom() {
        this.bottomSheet.open(AddFieldContentComponent, {
            panelClass: 'bottomSheetCustom',
        })
    }
}
