import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

export enum Buttons {
    YES = 'Yes',
    NO = 'No',
}

@Component({
    selector: 'app-common-dialog',
    templateUrl: './common-dialog.component.html',
    styleUrls: ['./common-dialog.component.sass'],
})
export class CommonDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<boolean>,
        @Inject(MAT_DIALOG_DATA)
        public data: { i18Key: string; buttonKeys: Array<Buttons> },
    ) {}
}
