<div
    *ngIf="!disabled, else disabledContent"
    [matMenuTriggerFor]="dropdownMenu"
    [ngStyle]="{'background':getTitleColor(value),'color': getTitleColor(value)|getContrastColor}"
    class="c-pointer dropdown-title_wrap"
>
    <mat-icon class="status-icon"> {{getIcon(value)}} </mat-icon>
    <span class="dropdown-title"> {{getTitle(value)}} </span>
</div>
<ng-template #disabledContent>
    <div
        [ngStyle]="{'background':getTitleColor(value),'color': getTitleColor(value)|getContrastColor}"
        class="c-pointer dropdown-title_wrap"
    >
        <mat-icon class="status-icon"> {{getIcon(value)}} </mat-icon>
        <span class="dropdown-title"> {{getTitle(value)}} </span>
    </div>
</ng-template>
<mat-menu #dropdownMenu="matMenu">
    <ng-template matMenuContent>
        <div class="settings_header">
            <span
                (click)="openDialog()"
                class="material-icons float-right mr-sm text-muted c-pointer"
            >
                settings
            </span>
        </div>
        <button
            *ngIf="!field.is_required"
            (click)="checkActive('')"
            class="text-center"
            mat-menu-item
        >
            <span class="material-icons text-muted"> remove </span>
            <mat-icon *ngIf="!value" class="active-check-label">check </mat-icon>
        </button>
        <button
            (click)="checkActive(key)"
            *ngFor="let key of optionKeys()"
            [ngStyle]="{'background':dropdownData[key].color,'color': key |getContrastColor}"
            class="text-center"
            mat-menu-item
        >
            {{dropdownData[key].label}}
            <mat-icon [ngStyle]="{'color': key |getContrastColor}" class="dropdown-icon"
                >{{dropdownData[key].icon}}</mat-icon
            >
            <mat-icon
                *ngIf="key == value"
                [ngStyle]="{'color': key |getContrastColor}"
                class="active-check-label"
                >check
            </mat-icon>
            <mat-icon
                *ngIf="key == default"
                [ngStyle]="{'color': key |getContrastColor}"
                class="active-check-label"
                >ac_unit
            </mat-icon>
        </button>
    </ng-template>
</mat-menu>
