import { Component } from '@angular/core'
import { SystemRecordsFacadeService } from '../../@core/services/system-records-facade.service'
import { UntilDestroy } from '@ngneat/until-destroy'
import { MatDialog } from '@angular/material/dialog'
import { NavigatorService } from '../../@core/services/navigator.service'
import { map, take, tap } from 'rxjs/operators'
import { LogService } from '../../@core/services'
import { getBoolean } from '../../@core/global-util'
import {
    FolderFacadeService,
    SchemaFacadeService,
    ViewFacadeService,
} from '../../@core/services/store-facade'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { Folder } from '../../@core/models'
import { AddWorkspaceDialogContentComponent } from './add-workspace-dialog-content/add-workspace-dialog-content.component'
import { RecordsService } from '../../@core/services/records.service'
import { ModalContainerComponent } from '../../@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import {
    ModalContainer,
    ModalManagerService,
} from '../../@components-library/tb-modal-manager/modal-manager.service'
import {
    CreateFolderDialogContainerComponent,
    FolderSettingsDialogInput,
    FolderSettingsDialogResult,
} from './create-folder-dialog-content/create-folder-dialog-content.component'

@UntilDestroy()
@Component({
    selector: 'app-folder-controls-container',
    templateUrl: './folder-controls-container.component.html',
})
export class FolderControlsContainerComponent extends ModalContainerComponent {
    modalContainer = ModalContainer

    constructor(
        private systemRecordsFacadeService: SystemRecordsFacadeService,
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private recordsService: RecordsService,
        private dialog: MatDialog,
        private navigatorService: NavigatorService,
        private logService: LogService,
        private bottomSheet: MatBottomSheet,
        private viewFacadeService: ViewFacadeService,
        protected modalManagerService: ModalManagerService,
    ) {
        super(modalManagerService)
    }

    folders$ = this.systemRecordsFacadeService.selectAllFolders$.pipe(
        map((folders) => {
            const global = folders.find((folder) => getBoolean(folder.is_global))
            const nonGlobal = folders.filter((folder) => folder !== global)

            return { global, nonGlobal }
        }),
        tap((value) => {
            if (!value.global) {
                this.logService.componentError(new Error('no global folder'))
            }
        }),
    )
    selectedFolder$ = this.folderFacadeService.selectSelectedFolder$

    selectFolder(folder: Folder) {
        this.folderFacadeService.setSelectedFolderGuid(folder.guid)
        this.navigatorService.goToFolder(folder.guid)
    }

    openFolderDialogNew(folder: Folder | undefined) {
        const dialogRef = this.modalManagerService.openDialog<
            AddWorkspaceDialogContentComponent,
            void,
            FolderSettingsDialogResult
        >(AddWorkspaceDialogContentComponent)

        dialogRef.subscribe((res: FolderSettingsDialogResult | undefined) => {
            console.log(res)
            if (res && !folder) {
                if (res.acl && res.views) {
                    this.folderFacadeService.createFolderRequest(res.folder, res.views, res.acl)
                } else {
                    console.error('Invalid data for folder creation')
                }
            } else {
                if (res) {
                    this.recordsService.updateRecord({
                        record: res.folder as Folder,
                    })
                    console.log(res)
                }
            }
        })
    }

    openFolderDialog(folder: Folder | undefined, folderExample?: Folder) {
        const dialogInput: FolderSettingsDialogInput = {
            folder,
        }

        if (!folder) {
            dialogInput.folderExample = folderExample
        }

        const dialogRef = this.modalManagerService.openDialog<
            CreateFolderDialogContainerComponent,
            FolderSettingsDialogInput,
            FolderSettingsDialogResult | undefined
        >(CreateFolderDialogContainerComponent, dialogInput)

        dialogRef.subscribe((res) => {
            console.log(res)
            if (res && !folder) {
                if (res.acl && res.views) {
                    this.folderFacadeService.createFolderRequest(res.folder, res.views, res.acl)
                } else {
                    console.error('Invalid data for folder creation')
                }
            } else {
                if (res) {
                    this.recordsService.updateRecord({
                        record: res.folder as Folder,
                    })
                    console.log(res)
                }
            }
        })
    }

    deleteFolder(folder: Folder) {
        this.folderFacadeService.selectSelectedFolderGuid$.pipe(take(1)).subscribe((guid) => {
            this.recordsService.deleteRecord(folder)
            if (guid === folder.guid) {
                this.schemaFacadeService.setSelectedTableSchemaGuid(null)
                this.viewFacadeService.setSelectedViewGuid(null)
            }
        })
    }
}
