<form class="example-form">
    <label class="w-100 flex-start-center"> Choose a currency </label>
    <mat-form-field class="w-100 flex-start-center">
        <mat-select [formControl]="selectFormControl">
            <mat-option *ngFor="let currency of currencyListKeys()" value="{{currency}}">
                {{currency}} ({{currencies[currency][4]}})
            </mat-option>
        </mat-select>
    </mat-form-field>
</form>
