import { SelectOption } from './response/select-object-options'
import { LinkDefinition } from './ui'
import { ConfigFields } from './ui/config.model'

export interface RecordCellModel {
    value: string
    field_guid: string
    revision?: string
}

export interface UpdateRecordModel {
    solution_object_type_guid: string
    records: UpdateRecordInnerModel[]
}

export interface UpdateRecordInnerModel {
    record_guid: string
    record_revision: string
    parent_sot_guid?: string
    cells: RecordCellModel[]
    folders_guid: string
}

export interface CreateRecordModel {
    solution_object_type_guid: string
    parent_sot_guid: string | undefined
    folders_guid: string
    cells: RecordCellModel[]
}

export interface DeleteRecordModel {
    records: DeleteRecordInfo[]
    solution_object_type_guid: string
}

export interface DeleteRecordInfo {
    record_guid: string
    record_revision: string
}

export interface UpdateFieldModel {
    solution_object_type_guid: string
    solution_object_type_revision: number
    field_guid: string
    system_name?: string
    revision?: number
    configure_json?: string
    is_required?: number
    acl: any
    name?: string
    frontend_validations: any
    select_object_field?: SelectOption[]
    shared_with_folder?: string
    folder_guid?: string
    link_definition?: LinkDefinition
}

export interface CreateFieldModel {
    solution_object_type_guid: string
    solution_object_type_revision: number
    field_type_code: string
    configure_json: string
    is_required: number
    acl: any
    name: string
    frontend_validations: any
    folder_guid?: string
    select_object_field?: SelectOption[]
    link_definition?: LinkDefinition
}

export interface DeleteFieldModel {
    solution_object_type_guid: string
    solution_object_type_revision: number
    field_guid: string
    revision?: number
}

export interface CreateFolderModelViewType {
    [view_type_code: string]: boolean | null
}

export interface CreateFolderViews {
    [child_sot_type: string]: CreateFolderModelViewType
}

export interface CreateFolderAcl {
    roles: string[]
}

export interface CreateFolderModelFieldSettings {
    [field_guid: string]: string
}

export interface CreateFolderModel {
    solution_object_type_guid: string
    fields: CreateFolderModelFieldSettings
    views: CreateFolderViews
    acl: CreateFolderAcl
}

export interface UpdateLinks {
    solution_object_type_guid: string
    records: UpdateLinkModel[]
}

export interface UpdateLinkModel {
    record_guid: string
    record_revision: number
    object_field_guid: string
    revision: number
    add_guids?: string[]
    delete_guids?: string[]
}

export interface UpdateConfigUpdateModel {
    record_guid: string
    record_revision: number
    configuration: ConfigFields
}

export interface DeleteConfigUpdateModel {
    record_guid: string
    record_revision: number
}
