import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity'
import { Deleted, View } from '../../models'
import { createFeature, createReducer, on } from '@ngrx/store'
import {
    addViews,
    deleteViews,
    initViews,
    setSelectedViewGuid,
    updateViews,
    viewsAreLoaded,
} from './view.actions'

const viewFeatureKey: string = 'VIEW'

export interface ViewState extends EntityState<View> {
    selectedViewGuid: string | null
    areLoaded: boolean
}

function selectViewId(view: View): string {
    return view.guid
}

export const viewAdapter: EntityAdapter<View> = createEntityAdapter<View>({
    selectId: selectViewId,
})

const initialState: ViewState = viewAdapter.getInitialState({
    selectedViewGuid: null,
    areLoaded: false,
})

const viewReducer = createReducer(
    initialState,
    on(initViews, (state: ViewState, { views, selectedViewGuid }) => {
        return viewAdapter.setAll(views, {
            ...state,
            selectedViewGuid: selectedViewGuid || state.selectedViewGuid,
        })
    }),
    on(setSelectedViewGuid, (state: ViewState, { selectedViewGuid }) => {
        return {
            ...state,
            selectedViewGuid,
        }
    }),
    on(viewsAreLoaded, (state: ViewState) => {
        return {
            ...state,
            areLoaded: true,
        }
    }),
    on(addViews, (state: ViewState, { views }: { views: View[] }) => {
        return viewAdapter.addMany(views, state)
    }),
    on(updateViews, (state: ViewState, { views }: { views: Update<View>[] }) => {
        return viewAdapter.updateMany(views, state)
    }),
    on(deleteViews, (state: ViewState, { views }: { views: Deleted[] }) => {
        return viewAdapter.removeMany(
            views.map((deleted) => deleted.guid),
            state,
        )
    }),
)

export const viewFeature = createFeature({
    name: viewFeatureKey,
    reducer: viewReducer,
})
