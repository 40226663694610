import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root',
})
export class SessionStorageService {
    constructor() {}

    getSessionValue(valueKey: string) {
        return sessionStorage.getItem(valueKey)
    }

    setSessionValue(valueKey: string, valueToSave: string) {
        sessionStorage.setItem(valueKey, valueToSave)
    }

    removeSessionValue(valueKey: string) {
        sessionStorage.removeItem(valueKey)
    }
}
