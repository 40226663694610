import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FilterComponent, FilterItemComponent } from './filter'
import { SortComponent } from './sort/sort.component'
import { SortMenuComponent } from './sort/sort-menu/sort-menu.component'
import { ManageFieldsComponent } from './manage-fields/manage-fields.component'
import { GroupByComponent } from './group/group.component'
import { ViewsListComponent } from './views-list/views-list.component'
import { MatListModule } from '@angular/material/list'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { ReactiveFormsModule } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatInputModule } from '@angular/material/input'
import { MatRadioModule } from '@angular/material/radio'
import { MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { ShowItemComponent } from './show/show-item/show-item.component'
import { ShowComponent } from './show/show.component'
import { BoardModule } from '../board/board.module'
import { ViewsListMenuComponent } from './views-list/views-list-menu/views-list-menu.component'
import { CreateViewComponent } from './views-list/create-view/create-view.component'
import { FilterGroupComponent } from './filter/filter-group/filter-group.component'
import { FilterMenuComponent } from './filter/filter-menu/filter-menu.component'
import { StatusFilterComponent } from './filter/filter-types/status-filter/status-filter.component'
import { FilterTypesContainerComponent } from './filter/filter-types/filter-types-container.component'
import { CdkAccordionModule } from '@angular/cdk/accordion'
import { DropdownFilterComponent } from './filter/filter-types/dropdown-filter/dropdown-filter.component'
import { PipesModule } from '../../@core/pipes/pipes.module'
import { AssignFilterComponent } from './filter/filter-types/assign-filter/assign-filter.component'
import { MoneyFilterComponent } from './filter/filter-types/money-filter/money-filter.component'
import { RatingFilterComponent } from './filter/filter-types/rating-filter/rating-filter.component'
import { NumberFilterComponent } from './filter/filter-types/number-filter/number-filter.component'
import { TextOneLineFilterComponent } from './filter/filter-types/text_one_line-filter/text_one_line-filter.component'
import { EmailFilterComponent } from './filter/filter-types/email-filter/email-filter.component'
import { UrlFilterComponent } from './filter/filter-types/url-filter/url-filter.component'
import { NameFilterComponent } from './filter/filter-types/name-filter/name-filter.component'
import { BooleanFilterComponent } from './filter/filter-types/boolean-filter/boolean-filter.component'
import { GroupMenuComponent } from './group/group-menu/group-menu.component'
import { ViewErrorComponent } from '../view-error/view-error.component'
import { TranslocoModule } from '@ngneat/transloco'
import { CellTypesModule } from '../cell-types/cell-types.module'
import { CommonAppModule } from '../../common/common.module'
import { FieldAddComponent } from './show/field-add/field-add.component'
import { FieldAddContentComponent } from './show/field-add/field-add-content/field-add-content.component'
import { ViewsRenameComponent } from './views-list/views-rename/views-rename.component'
import { FilterCombinationToggleComponent } from './filter/filter-combination-toggle/filter-combination-toggle.component'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { ComponentsLibraryModule } from '../../@components-library/components-library.module'
import { MatTabsModule } from '@angular/material/tabs'

@NgModule({
    declarations: [
        FilterComponent,
        FilterItemComponent,
        SortComponent,
        SortMenuComponent,
        ManageFieldsComponent,
        GroupByComponent,
        ViewsListComponent,
        ShowItemComponent,
        ShowComponent,
        ViewsListMenuComponent,
        ViewsRenameComponent,
        FilterGroupComponent,
        FilterMenuComponent,
        CreateViewComponent,
        StatusFilterComponent,
        FilterTypesContainerComponent,
        DropdownFilterComponent,
        AssignFilterComponent,
        MoneyFilterComponent,
        RatingFilterComponent,
        NumberFilterComponent,
        TextOneLineFilterComponent,
        EmailFilterComponent,
        UrlFilterComponent,
        NameFilterComponent,
        BooleanFilterComponent,
        CreateViewComponent,
        GroupMenuComponent,
        ViewErrorComponent,
        FieldAddComponent,
        FieldAddContentComponent,
        FilterCombinationToggleComponent,
    ],
    imports: [
        CommonModule,
        MatListModule,
        MatToolbarModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCheckboxModule,
        MatInputModule,
        MatRadioModule,
        MatBottomSheetModule,
        BoardModule,
        ReactiveFormsModule,
        CdkAccordionModule,
        PipesModule,
        TranslocoModule,
        CellTypesModule,
        CommonAppModule,
        MatButtonToggleModule,
        CommonAppModule,
        ComponentsLibraryModule,
        MatTabsModule,
    ],
    exports: [
        FilterComponent,
        GroupByComponent,
        ViewsListComponent,
        CreateViewComponent,
        ViewErrorComponent,
        FieldAddContentComponent,
    ],
})
export class ViewControlsModule {}
