import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core'
import { combineLatest, combineLatestWith, Observable, of } from 'rxjs'
import { FormControl, FormGroup } from '@angular/forms'
import {
    Field,
    FieldTypes,
    findBySystemNameInFolderOrGlobalFolder,
    findFieldByType,
    Folder,
    GlobalFieldNames,
    Schema,
} from '../../@core/models'
import { mergeMap, take } from 'rxjs/operators'
import { PinDraftRecord, PinRecordService } from '../pin-record.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
    CrudRecordModelFactoryService,
    FieldValue,
} from '../../@core/services/crud-record-model-factory.service'
import { RecordsService } from '../../@core/services/records.service'
import {
    FolderFacadeService,
    SchemaFacadeService,
    FieldTypeFacadeService,
    CommonFacadeService,
} from '../../@core/services/store-facade'

@UntilDestroy()
@Component({
    selector: 'app-add-record-content',
    templateUrl: './add-record-content.component.html',
    styleUrls: ['./add-record-content.component.sass'],
})
export class AddRecordContentComponent implements OnChanges, OnInit {
    @Input()
    isMenuOpen!: boolean

    @Input()
    openedRecord!: PinDraftRecord | null

    @Output()
    closeMenu: EventEmitter<void> = new EventEmitter()

    //fake data
    isGlobalWorkspace: boolean = false
    workspaces = [
        { name: 'Workspace', color: 'secondaryMain' },
        { name: 'Managers', color: 'accentContrast' },
        { name: 'Shared', color: 'secondaryMain' },
    ]

    formGroup = new FormGroup({
        name: new FormControl<string>('', { nonNullable: true }),
        description: new FormControl<string>('', { nonNullable: true }),
        sotCode: new FormControl<string>('', { nonNullable: true }),
        folder: new FormControl<string>('', { nonNullable: true }),
    })

    nameField!: Field | null
    statusField!: Field | null
    descriptionField!: Field | null
    assigneeField!: Field | null
    watchField!: Field | null

    statusFieldValue!: string
    watchFieldValue!: string
    assigneeFieldValue!: string

    schema!: Schema
    folder!: Folder

    filedTypes$ = this.fieldTypeFacadeService.selectFieldTypeEntities$
    sotCodes$ = this.commonFacadeService.selectAllAvailableSolutionObjectTypes$
    folders$ = this.folderFacadeService.selectAllFolders$
    selectedFolder$ = this.folderFacadeService.selectSelectedFolder$
    selectedSchema$ = this.schemaFacadeService.selectSelectedTableSchema$

    get isRecordPinned() {
        return this.openedRecord && this.pinRecordService.isRecordPinned(this.openedRecord?.guid)
    }

    constructor(
        public pinRecordService: PinRecordService,
        private recordService: RecordsService,
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private commonFacadeService: CommonFacadeService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private crudRecordModelFactory: CrudRecordModelFactoryService,
    ) {}

    ngOnInit() {
        this.formGroup.controls.folder.valueChanges
            .pipe(
                combineLatestWith(this.formGroup.controls.sotCode.valueChanges),
                untilDestroyed(this),
            )
            .subscribe(([folder, schema]) => {
                if (folder && schema) {
                    this.updateFields(folder, schema)
                }
            })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.isMenuOpen || !changes.isMenuOpen.currentValue) return

        if (this.openedRecord) {
            const { name, description, sotCode, folder, status, watch, assignee } =
                this.openedRecord.data

            this.statusFieldValue = status
            this.watchFieldValue = watch
            this.assigneeFieldValue = assignee

            this.formGroup.setValue({
                name,
                description,
                sotCode,
                folder,
            })
        } else {
            this.setDefaultFolderAndType()
        }
    }

    saveAssigneeValue(assignee: string) {
        this.assigneeFieldValue = assignee
    }

    saveStatusValue(status: string) {
        this.statusFieldValue = status
    }

    saveWatchValue(watch: string) {
        this.watchFieldValue = watch
    }

    addRecord() {
        const record = this.crudRecordModelFactory.prepareCreateModelWithFields(
            '',
            this.schema.guid,
            this.folder.guid,
            this.prepareFieldValues(),
        )
        if (!record) return
        this.recordService.createRecord(record)

        this.clearForm()
        this.removePinRecord()
        this.closeMenu.emit()
    }

    pinDraftRecord() {
        if (this.openedRecord) {
            this.pinRecordService.updateDraftRecord(this.openedRecord.guid, {
                ...this.formGroup.value,
                status: this.statusFieldValue,
                watch: this.watchFieldValue,
                assignee: this.assigneeFieldValue,
            })
        } else {
            this.pinRecordService.pinDraftRecord({
                ...this.formGroup.value,
                status: this.statusFieldValue,
                watch: this.watchFieldValue,
                assignee: this.assigneeFieldValue,
            })
        }

        this.closeMenu.emit()
        this.clearForm()
    }

    removePinRecord() {
        if (this.openedRecord) {
            this.pinRecordService.removePinRecord(this.openedRecord?.guid)
            this.closeMenu.emit()
            this.clearForm()
        }
    }

    private clearForm() {
        this.formGroup.reset()

        this.statusFieldValue = ''
        this.watchFieldValue = ''
        this.assigneeFieldValue = ''

        this.nameField = null
        this.watchField = null
        this.assigneeField = null
        this.descriptionField = null
        this.statusField = null
    }

    private setDefaultFolderAndType() {
        combineLatest([this.sotCodes$, this.folders$, this.selectedFolder$, this.selectedSchema$])
            .pipe(take(1), untilDestroyed(this))
            .subscribe(([types, folders, selectedFolder, selectedSchema]) => {
                this.formGroup.controls.sotCode.setValue(
                    selectedSchema?.object_type_code || types[0],
                )
                this.formGroup.controls.folder.setValue(selectedFolder?.guid || folders[0].guid)
            })
    }

    private updateFields(folderGuid: string, sotCode: string) {
        this.schemaFacadeService
            .selectSchemaBySystemObjectTypeCode$(sotCode)
            .pipe(
                combineLatestWith(this.folderFacadeService.selectFolderByGuid$(folderGuid)),
                take(1),
                untilDestroyed(this),
            )
            .subscribe(([schema, folder]) => {
                if (!schema || !folder) return

                const fields = schema.fieldEntities

                this.schema = schema
                this.folder = folder

                this.nameField = findBySystemNameInFolderOrGlobalFolder(
                    fields,
                    GlobalFieldNames.NAME,
                    folder,
                )
                this.watchField = findBySystemNameInFolderOrGlobalFolder(
                    fields,
                    GlobalFieldNames.WATCH,
                    folder,
                )
                this.assigneeField = findBySystemNameInFolderOrGlobalFolder(
                    fields,
                    GlobalFieldNames.ASSIGNEE,
                    folder,
                )
                this.descriptionField = findBySystemNameInFolderOrGlobalFolder(
                    fields,
                    GlobalFieldNames.DESCRIPTION,
                    folder,
                )

                this.statusField = findFieldByType(folder, fields, FieldTypes.STATUS)
            })
    }

    private prepareFieldValues() {
        return [
            {
                fieldGuid: this.nameField?.guid,
                value: this.formGroup.controls.name.value,
            },
            {
                fieldGuid: this.statusField?.guid,
                value: this.statusFieldValue,
            },
            {
                fieldGuid: this.watchField?.guid,
                value: this.watchFieldValue,
            },
            {
                fieldGuid: this.descriptionField?.guid,
                value: this.formGroup.controls.description.value,
            },
            {
                fieldGuid: this.assigneeField?.guid,
                value: this.assigneeFieldValue,
            },
        ]
            .filter((item) => item.fieldGuid)
            .filter((item) => item.value) as FieldValue[]
    }
}
