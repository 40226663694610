import { createFeatureSelector, createSelector } from '@ngrx/store'
import { UserState } from './user.state'
import { AppState } from '../app.state'
import { CurrentUser, User, UserModel, UserModelEntities } from '../../models'

export const selectUsersState = createFeatureSelector<UserState>('users')

export const selectUserEntities = createSelector<AppState, [UserState], UserModelEntities>(
    selectUsersState,
    (state: UserState) => {
        return state.users
    },
)

export const selectUsers = createSelector<AppState, [UserModelEntities], User[]>(
    selectUserEntities,
    (userModels: UserModelEntities) => {
        return Object.keys(userModels).map((guid) => new User(guid, userModels[guid]))
    },
)

export const selectCurrentUser = createSelector<AppState, [UserState], CurrentUser | null>(
    selectUsersState,
    (state: UserState) => {
        return state.currentUser
    },
)

export const selectCurrentUserInfo = createSelector<AppState, [UserState], UserModel | undefined>(
    selectUsersState,
    (state: UserState) => {
        if (state.currentUser!.guid) {
            return state.users[state.currentUser!.guid]
        }
        return undefined
    },
)
