import { Component, EventEmitter, Input, Output } from '@angular/core'
import {
    AddFieldData,
    AppRecord,
    CellEntities,
    Field,
    FieldType,
    Folder,
    RecordGroup,
} from '../../../@core/models'
import { Dictionary } from '@ngrx/entity'
import { MatDialog } from '@angular/material/dialog'
import { AddColumnComponent } from '../../table/table/add-column/add-column.component'

@Component({
    selector: 'app-board-title',
    templateUrl: './board-title.component.html',
    styleUrls: ['./board-title.component.sass'],
})
export class BoardTitleComponent {
    @Input() item!: RecordGroup
    @Input() field!: Field
    @Input() fieldType!: Dictionary<FieldType> | null
    @Input() record?: AppRecord
    @Input() columnName?: string
    @Input() columnItemsCount!: number
    @Input() boardStatus!: string
    @Input() isCreateOpened!: boolean
    @Input() folder!: Folder | null
    @Input() cells!: { [recordGuid: string]: CellEntities }
    @Input() isAllRecordsSelected: boolean = false
    @Input() allowCollapsing!: boolean

    @Output() addBoardItem: EventEmitter<any> = new EventEmitter()
    @Output() isCreateNewItemShown: EventEmitter<boolean> = new EventEmitter()
    @Output() collapseColumn: EventEmitter<boolean> = new EventEmitter()
    @Output() editFields: EventEmitter<any> = new EventEmitter<any>()
    @Output() selectColumnRecords: EventEmitter<any> = new EventEmitter<any>()

    constructor(public dialog: MatDialog) {}

    editField(field: Field) {
        const dialogRef = this.dialog.open<AddColumnComponent, AddFieldData, Field | undefined>(
            AddColumnComponent,
            {
                width: '400px',
                data: {
                    ...field,
                },
            },
        )

        dialogRef.afterClosed().subscribe((res: Field | undefined) => {
            if (res) {
                this.editFields.emit(res)
            }
        })
    }

    addNewBoardItem() {
        this.addBoardItem.emit()
        this.isCreateNewItemShown.emit(true)
    }
}
