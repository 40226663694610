<form class="edit-mode-form">
    <ng-container *ngIf="!isEdit">
        <ng-container *ngIf="!disabled, else disabledContent">
            <span
                (click)="editMode()"
                *ngIf="value; else placeholder"
                class="input_label flex-start-center"
            >
                {{currencySymbol}} {{value}}
            </span>
            <ng-template #placeholder>
                <span (click)="editMode()" class="input_label text-muted material-icons">
                    remove
                </span>
            </ng-template>
        </ng-container>
        <ng-template #disabledContent>
            <span *ngIf="value; else placeholder" class="input_label flex-start-center">
                {{currencySymbol}} {{value}}
            </span>
            <ng-template #placeholder>
                <span class="input_label text-muted material-icons"> remove </span>
            </ng-template>
        </ng-template>
    </ng-container>
    <div [class.active]="isEdit" class="edit-mode-area">
        <mat-form-field class="text-input">
            <div class="flex-start-center justify-content-between">
                <input
                    (focusout)="saveValue()"
                    (keyup)="saveOnEnter($event)"
                    [appAutoFocus]="isEdit"
                    [formControl]="numberFormControl"
                    autocomplete="off"
                    matInput
                    name="number"
                    type="number"
                />
                <mat-icon (click)="saveValue()" *ngIf="isEdit" class="text-success">
                    check_circle
                </mat-icon>
            </div>
        </mat-form-field>
    </div>
    <mat-error *ngIf="numberFormControl && numberFormControl.hasError('required')">
        <ng-container *transloco="let tCellTypesErrors; read: 'cell_types.errors'">
            {{tCellTypesErrors('email_is')}}
            <strong>{{tCellTypesErrors('required')}}</strong>
        </ng-container>
    </mat-error>
</form>
