<div
    class="mb-2 p-2 sm:p-1 rounded cursor-pointer max-sm:bg-primaryBackground hover:bg-primaryBackground workSpHolder"
    (click)="selectFolder.emit(folder)"
>
    <div class="flex items-center">
        <!--Todo: // [desing-ref] icon-->
        <mat-icon class="mr-2" [style.color]="folder.color.value"> {{folder.icon.value}} </mat-icon>
        <span class="mr-2" [class]="{ 'font-semibold': folder.guid === selectedFolderGuid }">
            {{folder.name.value}}
        </span>
        <!--Todo: // [desing-ref] icon-->
        <mat-icon
            class="sm:absolute sm:right-[20px] flex items-center justify-content-center w-5 h-5 text-secondaryMain sm:bg-white text-lg leading-5"
            *ngIf="folder.guid === selectedFolderGuid"
        >
            check
        </mat-icon>
        <mat-icon
            (click)="edit.emit()"
            svgIcon="custom_settings"
            class="flex items-center justify-content-center w-5 h-5 text-primarySecondary ml-auto workSpSettings"
        ></mat-icon>
    </div>
</div>
