import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ViewType } from '../../../view-error/view-error.component'
import { CrudRecordModelFactoryService } from '../../../../@core/services/crud-record-model-factory.service'
import { RecordsService } from '../../../../@core/services/records.service'
import { FolderFacadeService, ViewFacadeService } from '../../../../@core/services/store-facade'
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

type CreateViewComponentData = {
    selectedView: ViewType
}

@UntilDestroy()
@Component({
    selector: 'app-create-view',
    templateUrl: './create-view.component.html',
})
export class CreateViewComponent implements OnInit {
    @Input() selectedView!: ViewType

    @Output() created: EventEmitter<void> = new EventEmitter<void>()

    viewTypes!: string[]
    formGroup!: FormGroup<{
        viewName: FormControl<string | null>
        viewType: FormControl<string | null>
    }>
    selectedFolderGuid!: string | null
    viewType: string = 'table'

    constructor(
        private crudRecordModelFactoryService: CrudRecordModelFactoryService,
        private recordsService: RecordsService,
        private viewFacadeService: ViewFacadeService,
        private folderFacadeService: FolderFacadeService,

        @Optional()
        @Inject(MAT_BOTTOM_SHEET_DATA)
        public bottomSheetData: CreateViewComponentData,

        @Optional()
        @Inject(MAT_DIALOG_DATA)
        public dialogData: CreateViewComponentData,

        @Optional()
        public dialogRef: MatDialogRef<CreateViewComponentData>,

        @Optional()
        private bottomSheetRef: MatBottomSheetRef<CreateViewComponentData>,
    ) {}

    ngOnInit(): void {
        this.viewFacadeService.selectAvailableViewTypes$
            .pipe(untilDestroyed(this))
            .subscribe((types) => (this.viewTypes = types.length ? types : ['table']))

        this.folderFacadeService.selectSelectedFolderGuid$
            .pipe(untilDestroyed(this))
            .subscribe((selectedFolderGuid) => (this.selectedFolderGuid = selectedFolderGuid))

        this.formGroup = new FormGroup({
            viewName: new FormControl<string>('', Validators.required),
            viewType: new FormControl<string>(this.viewTypes[0]),
        })
    }

    selectViewType(type: string) {
        this.viewType = type
    }

    addNewView() {
        const { schemaGuid, parent_sot_guid, folder_guids } = this.selectedViewData
        const record = this.crudRecordModelFactoryService.prepareCreateModel(
            parent_sot_guid || '',
            schemaGuid,
            folder_guids || '',
            this.generateCells(this.selectedViewData),
        )

        this.formGroup.reset()
        this.recordsService.createRecord(record)
        this.created.emit()
        this.closeModal()
    }

    closeModal() {
        this.dialogRef?.close()
        this.bottomSheetRef?.dismiss()
    }

    generateCells(view: ViewType) {
        const { name, type_code } = view
        const fields = []

        const controls = this.formGroup.controls

        if (name?.fieldGuid && controls.viewName.value) {
            fields.push(
                this.crudRecordModelFactoryService.generateResponseCell(
                    name?.fieldGuid,
                    controls.viewName.value,
                ),
            )
        }

        if (type_code?.fieldGuid && controls.viewType.value) {
            fields.push(
                this.crudRecordModelFactoryService.generateResponseCell(
                    type_code?.fieldGuid,
                    controls.viewType.value,
                ),
            )
        }

        return fields
    }

    get selectedViewData() {
        return (
            this.bottomSheetData?.selectedView || this.dialogData?.selectedView || this.selectedView
        )
    }
}
