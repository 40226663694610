import { Component, Input } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-color-icon-picker',
    templateUrl: 'color-icon-picker.component.html',
    styleUrls: ['color-icon-picker.component.sass'],
})
export class ColorIconPickerComponent {
    @Input() colorName!: string
    @Input() icon!: string
    @Input() customIcon!: string
}
