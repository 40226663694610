import { Injectable } from '@angular/core'
import { View } from '../../models'
import { SessionStorageService } from './session-storage.service'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class GroupStorageService {
    constructor(private sessionStorageService: SessionStorageService) {}
    private readonly isSessionGroupSet$$ = new BehaviorSubject<boolean>(false)

    getSessionGroup(viewGuid: string): string | undefined {
        return this.sessionStorageService.getSessionValue(this.getGroupKey(viewGuid)) ?? undefined
    }

    setSessionGroup(viewGuid: string, groupFieldGuid: string) {
        this.sessionStorageService.setSessionValue(this.getGroupKey(viewGuid), groupFieldGuid)
    }

    removeSessionGroup(viewGuid: string) {
        this.sessionStorageService.removeSessionValue(this.getGroupKey(viewGuid))
    }

    isSessionGroupSet$(): Observable<boolean> {
        return this.isSessionGroupSet$$.asObservable()
    }

    isSessionGroupSetUpdate(view: View) {
        this.isSessionGroupSet$$.next(!!this.getSessionGroup(view.guid))
    }

    private getGroupKey(value: string) {
        return `grouping-${value}`
    }
}
