<!--custom icons are not working in stories-->
<!--selection dropdown should be done-->

<div
    *transloco="let tPickers; read: 'pickers'"
    class="shadow-light flex items-center justify-between h-5 box-content text-sm font-semibold text-primaryDark py-3.5 px-4 bg-white rounded-lg border-2 border-solid border-transparent hover:border-primaryLight"
>
    <div *ngIf="icon || customIcon">{{tPickers('icon')}}</div>
    <div *ngIf="!icon && !customIcon">{{tPickers('color')}}</div>
    <div
        class="h-9 w-9 p-1 rounded-lg border-2 border-solid border-primaryLight cursor-pointer isOpened"
    >
        <div *ngIf="!icon && !customIcon" class="h-6 w-6 rounded bg-{{colorName}}"></div>
        <mat-icon *ngIf="icon" class="text-{{colorName}}">{{icon}}</mat-icon>
        <mat-icon *ngIf="customIcon" svgIcon="{{customIcon}}" class="text-{{colorName}}"></mat-icon>
    </div>
</div>
