<button
    [matMenuTriggerFor]="notificationsMenu"
    class="d-flex flex-wrap items-center justify-content-center h-8 w-8 rounded-lg text-primaryDark hover:bg-white"
>
    <mat-icon>notifications_none</mat-icon>
</button>
<mat-menu #notificationsMenu="matMenu">
    <ng-template matMenuContent>
        <app-notifications-menu-content></app-notifications-menu-content>
    </ng-template>
</mat-menu>
