import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import {
    BusinessRecords,
    CellEntities,
    Field,
    FieldEntities,
    FieldType,
    Folder,
    formAllFields,
    Schema,
} from '../../../@core/models'
import { Dictionary } from '@ngrx/entity'
import { FolderStatus } from '../header/folder-status.model'

@Component({
    selector: 'app-card-content',
    templateUrl: './card-content.component.html',
    styleUrls: ['./card-content.component.sass'],
})
export class CardContentComponent implements OnChanges, OnInit {
    @Input()
    fields!: FieldEntities

    @Input()
    record!: BusinessRecords

    @Input()
    schema!: Schema

    @Input()
    fieldTypes!: Dictionary<FieldType>

    @Input()
    status!: Field | undefined

    @Input()
    description!: Field | undefined

    @Input()
    richTextDescription!: Field

    @Input()
    folderStatuses!: FolderStatus[]

    @Input()
    selectedFolder!: Folder

    @Input()
    cells!: CellEntities

    subFields: string[] = []

    virtualFields: Field[] = []

    ngOnInit() {
        this.setVirtualFields()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.record) {
            this.record = changes.record.currentValue
            this.subFields = formAllFields(this.record, this.selectedFolder)
            this.setVirtualFields()
        }
    }

    setVirtualFields() {
        this.virtualFields = Object.keys(this.schema.fieldEntities)
            .filter((guid) => !!this.schema.fieldEntities[guid].virtual_link)
            .map((guid) => this.schema.fieldEntities[guid])
    }
}
