<div class="container" *transloco="let tTable; read: 'table'">
    <h2>{{tTable('change_field_title')}}</h2>
    <mat-radio-group>
        <div *ngFor="let option of radioButtonOptions">
            <mat-radio-button
                [value]="option.value"
                [checked]="option.checked"
                (click)="folderChange(option.value)"
            >
                {{ option.name }}
            </mat-radio-button>
        </div>
    </mat-radio-group>
    <div class="bottom" *transloco="let tButtons; read: 'buttons'">
        <button (click)="cancelShare()" mat-button>{{tButtons('cancel')}}</button>
        <button (click)="applyShare()" color="primary" mat-button>{{tButtons('apply')}}</button>
    </div>
</div>
