<div
    (click)="$event.stopPropagation()"
    class="max-sm:mt-4 filter-area text-sm leading-4"
    *transloco="let tGroup; read: 'group'"
>
    <div class="flex justify-between items-center mb-4">
        <span class="text-primaryDark font-semibold">{{tGroup('group_by')}}</span>
    </div>

    <div class="flex items-center mb-4 dense-1">
        <mat-form-field class="w-full noHintError" appearance="outline">
            <mat-select #select placeholder="Select item" [formControl]="selectControl">
                <mat-option value="">{{tGroup('none')}}</mat-option>
                <mat-option *ngFor="let field of fields" [value]="field.guid">
                    <app-field-name
                        [field]="field"
                        [selectedFolder]="selectedFolder"
                        [showIcon]="false"
                    ></app-field-name>
                </mat-option>
            </mat-select>
            <mat-icon class="matSelectIconCustom cursor-pointer" matSuffix
                >{{ select.panelOpen ? 'expand_less' : 'expand_more' }}</mat-icon
            >
        </mat-form-field>
        <div class="h-8 w-8 px-2">
            <mat-icon
                svgIcon="custom_sort_up"
                class="cursor-pointer h-4 w-4 text-base leading-4 text-primarySecondary"
            ></mat-icon>
            <mat-icon
                svgIcon="custom_sort_down"
                class="cursor-pointer h-4 w-4 text-base leading-4 text-secondaryMain"
            ></mat-icon>
        </div>
    </div>

    <div class="flex max-sm:flex-col items-center">
        <button
            [class.invisible]="!this.applyForSession"
            (click)="resetToDefault()"
            class="flex items-center justify-center w-100 sm:w-fit h-11 sm:h-7 py-3 px-6 sm:p-1 max-sm:mb-4 sm:mr-auto whitespace-nowrap rounded-lg max-sm:border max-sm:border-solid max-sm:border-accentContrast cursor-pointer text-accentContrastLight hover:text-accentContrast"
        >
            <span class="material-icons w-5 h-5 text-xl leading-5 mr-1">close</span>
            <span class="font-semibold">{{tGroup('reset')}}</span>
        </button>
        <div class="flex items-center justify-end w-100">
            <button
                (click)="onApplyForSession()"
                [class.applyBtn]="isSelectControlChanged()"
                [disabled]="!isSelectControlChanged()"
                class="mx-4 max-sm:ml-0 max-sm:p-0 py-2.5 px-5 font-semibold w-100 sm:w-fit max-sm:h-11 text-primarySecondary rounded-lg border border-primarySecondary border-solid applyBtn"
            >
                {{tGroup('apply_session')}}
            </button>
            <button
                (click)="onSaveGroup()"
                [class.saveBtn]="isSaveButtonActive()"
                [disabled]="!isSaveButtonActive()"
                class="max-sm:p-0 py-2.5 px-5 font-semibold w-100 sm:w-fit max-sm:h-11 text-white rounded-lg bg-primarySecondary saveBtn"
            >
                {{tGroup('save')}}
            </button>
        </div>
    </div>
</div>
