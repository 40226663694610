import { Component, Input, OnInit } from '@angular/core'
import { svgIcons } from './tb-icon.model'

@Component({
    selector: 'app-tb-icon',
    templateUrl: './tb-icon.component.html',
    styleUrls: ['tb-icon.component.sass'],
})
export class TbIconComponent implements OnInit {
    @Input()
    color!: string

    @Input()
    icon!: string

    isSvg = false

    ngOnInit(): void {
        this.isSvg = !!svgIcons[this.icon as keyof typeof svgIcons]
    }

    get colorCss() {
        return this.color ? `text-${this.color}` : ''
    }
}
