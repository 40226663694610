<div
    (cdkDropListDropped)="dropRow($event)"
    [cdkDropListData]="rows"
    cdkDropList
    cdkDropListOrientation="vertical"
    class="table-overlay"
    (matSortChange)="sortTableData($event)"
    [matSortDisableClear]="true"
    matSort
>
    <div class="rows">
        <div
            (cdkDropListDropped)="dropColumnFn($event)"
            [cdkDropListData]="draggableColumns"
            cdkDropList
            cdkDropListOrientation="horizontal"
            class="row"
        >
            <div
                *ngFor="let header of fixedColumns; let colIndex = index"
                [ngStyle]="{
                    'width.px': header.colWidth ?? DEFAULT_COLUMN_WIDTH,
                    'left.px': colIndex === 0 ? 0 : fixedColumns[colIndex - 1].colWidth
                }"
                [appResizeStandaloneColumn]="true"
                [appResizeIndex]="colIndex"
                (resizedWidth)="resizeTableColumn(header, $event)"
                class="column ml-[5px]"
            >
                <div class="flex items-center justify-between w-full">
                    <mat-icon class="flex" *ngIf="header.isPinned">push_pin</mat-icon>
                    <div
                        [ngClass]="{
                            'w-[calc(100%-70px)]': !header.isPinned,
                            'w-50': header.isPinned
                        }"
                        class="flex items-center"
                    >
                        <ng-container
                            *ngTemplateOutlet="columnCell; context: { $implicit: header }"
                        ></ng-container>

                        <div class="flex">
                            <div [mat-sort-header]="header.item[uniqueKey]">
                                <mat-icon class="text-muted sort-icon">sort</mat-icon>
                            </div>
                            <mat-icon
                                (click)="sortColumnClear()"
                                *ngIf="sortedBy === this.header.item[uniqueKey]"
                                class="text-muted clear-icon"
                            >
                                close
                            </mat-icon>
                        </div>
                    </div>
                    <div class="resizer"></div>
                </div>
            </div>
            <div class="column-container" [style.grid-template-columns]="gridTemplateColumnWidth">
                <div
                    *ngFor="let header of draggableColumns; let thIndex = index"
                    [appResizeColumn]="true"
                    [appResizeIndex]="thIndex.toString()"
                    [attr.data-columnIndex]="thIndex.toString()"
                    (resizedWidth)="resizeTableColumn(header, $event)"
                    (gridTemplateResize)="resizeGridTemplateColumns($event)"
                    class="column w-full"
                >
                    <div class="flex items-center justify-between w-full">
                        <div
                            cdkDrag
                            cdkDragLockAxis="x"
                            cdkDragBoundary=".column-container"
                            class="grow flex"
                        >
                            <div class="flex items-center">
                                <mat-icon>drag_indicator</mat-icon>
                            </div>
                            <div class="text-center flex items-center w-[calc(100%-70px)]">
                                <div *cdkDragPlaceholder></div>
                                <div class="flex items-center w-full">
                                    <div class="w-full" *ngIf="header.colGroup; else noGroup">
                                        <div class="flex justify-around">
                                            <div *ngFor="let header of header.colGroup.subheaders">
                                                <div>{{ header }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-template #noGroup>
                                        <ng-container
                                            *ngTemplateOutlet="columnCell; context: { $implicit: header }"
                                        ></ng-container>
                                    </ng-template>
                                    <div class="flex">
                                        <div [mat-sort-header]="header.item[uniqueKey]">
                                            <mat-icon class="text-muted sort-icon">sort</mat-icon>
                                        </div>
                                        <mat-icon
                                            (click)="sortColumnClear()"
                                            *ngIf="sortedBy === header.item[uniqueKey]"
                                            class="text-muted clear-icon"
                                        >
                                            close
                                        </mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="resizer"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngFor="let row of rows" [cdkDragData]="row" cdkDrag>
            <div
                *ngFor="let header of fixedColumns; let cellIndex = index"
                [ngStyle]="{
                    'width.px': header.colWidth ?? DEFAULT_COLUMN_WIDTH,
                    'left.px': cellIndex === 0 ? 0 : fixedColumns[cellIndex - 1].colWidth
                }"
                class="column"
            >
                <ng-container>
                    <mat-checkbox
                        *ngIf="!header.isPinned"
                        (change)="selectRow(row)"
                        [checked]="isRowSelected(row)"
                    ></mat-checkbox>
                    <mat-icon *ngIf="isGrouped && !header.isPinned" class="text-muted">
                        drag_indicator
                    </mat-icon>
                    <ng-container
                        *ngTemplateOutlet="rowCell; context: { header: header, row }"
                    ></ng-container>
                </ng-container>
            </div>
            <div class="column-container" [style.grid-template-columns]="gridTemplateColumnWidth">
                <div class="column" *ngFor="let header of draggableColumns">
                    <ng-container
                        *ngTemplateOutlet="rowCell; context: { header, row }"
                    ></ng-container>
                </div>
                <div class="column">
                    <ng-container
                        *ngTemplateOutlet="rowMenu; context: { $implicit: row }"
                    ></ng-container>
                </div>
            </div>
        </div>
    </div>
    <ng-content select="[addRecord]"></ng-content>
</div>
