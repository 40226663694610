<h2 mat-dialog-title>Status settings</h2>
<mat-dialog-content class="">
    <app-status-content
        (defaultChanged)="defaultChangedDialog($event)"
        (fieldChanged)="cellValueChangedDialog($event)"
        [StatusData]="field"
        [activeStatus]="active"
        [defaultStatus]="default"
    ></app-status-content>
</mat-dialog-content>
<mat-dialog-actions *transloco="let tButtons; read: 'buttons'">
    <button (click)="saveStatusSettings()" color="primary" mat-button>{{tButtons('apply')}}</button>
    <button (click)="closeDialog()" mat-button>{{tButtons('cancel')}}</button>
</mat-dialog-actions>
