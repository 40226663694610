import { Injectable } from '@angular/core'
import {
    AppRecord,
    BusinessRecords,
    Deleted,
    Field,
    FieldEntities,
    FieldTypes,
    isBusinessSchema,
    regenerateCells,
    ResponseField,
    ResponseFieldEntities,
    ResponseRecord,
    ResponseSchema,
    Schema,
    TableModel,
} from '../models'
import { difference } from 'lodash-es'
import cloneDeep from 'lodash/cloneDeep'
import { LinkReferenceStoreService } from './link-reference-store.service'
import { Update } from '@ngrx/entity'
import { take } from 'rxjs/operators'
import { RecordFacadeService, SchemaFacadeService } from './store-facade'

@Injectable({
    providedIn: 'root',
})
export class LinkReferenceService {
    constructor(
        private linkReferenceStoreService: LinkReferenceStoreService,
        private recordFacadeService: RecordFacadeService,
        private schemaFacadeService: SchemaFacadeService,
    ) {}

    initLinkRefStore(allSchemas: ResponseSchema[], tables: TableModel[]) {
        const businessSotSchemas = allSchemas.filter((schema) => isBusinessSchema(schema))

        businessSotSchemas.forEach((schema) => {
            const fields = schema.field

            const linkFields: Field[] = this.getLinkFields(fields).map((fieldGuid) => ({
                ...fields[fieldGuid],
                guid: fieldGuid,
            }))

            const table = tables.find((table) => table.guid === schema.guid)
            const records = table?.record
            if (!table || !records) return

            Object.keys(records).forEach((recordGuid: string) => {
                this.setToStoreRecordCells(linkFields, records[recordGuid], recordGuid)
            })
        })
    }

    setStoredVirtualFieldsToSchemas(allSchemas: ResponseSchema[]) {
        const linkedFields = this.getLinkedFieldInfo(allSchemas)

        return allSchemas.map((schema) => {
            const linkedField = linkedFields[schema.guid]
            if (!linkedField) return cloneDeep(schema)

            const schemaFields = linkedField.reduce((fields, storedValue) => {
                const virtualFieldGuid = this.getVirtualGuidByFieldGuid(storedValue.fieldGuid)
                return {
                    ...fields,
                    [virtualFieldGuid]: this.generateVirtualFieldAsResponse(
                        storedValue.fieldGuid,
                        storedValue.linkName,
                    ),
                }
            }, cloneDeep(schema.field))

            return { ...cloneDeep(schema), field: schemaFields }
        })
    }

    setStoredVirtualCellsToTables(allSchemas: ResponseSchema[], tableModel: TableModel[]) {
        const linkedFields = this.getLinkedFieldInfo(allSchemas)

        return tableModel.map((table) => {
            const linkedField = linkedFields[table.guid]
            if (!linkedField || !table.record) return table

            const tableRecords = Object.keys(table.record).reduce((records, recordGuid) => {
                if (!table.record) return records

                const updatedRecord = this.createVirtualCellInRecord(
                    linkedField,
                    table.record[recordGuid],
                )

                return { ...records, [recordGuid]: updatedRecord }
            }, {})

            return { ...cloneDeep(table), record: tableRecords }
        })
    }

    updateRecords(records: AppRecord[], schema: Schema) {
        const linkFields = this.getLinkFields(schema.fieldEntities)

        linkFields.forEach((linkGuid) => {
            records.forEach((record) => {
                this.linkReferenceStoreService.updateRecordValue(linkGuid, record)
            })
        })
    }

    addRecords(records: AppRecord[], schema: Schema) {
        this.updateRecords(records, schema)
    }

    removeRecords(records: Deleted[], schema: Schema) {
        const linkFields = this.getLinkFields(schema.fieldEntities)

        linkFields.forEach((linkGuid) => {
            this.linkReferenceStoreService.removeRecords(linkGuid, records)
        })
    }

    updateSchemasWithVirtualFields(schemas: Update<Schema>[]) {
        schemas.forEach((schema) => {
            if (!schema.changes.guid || !schema.changes.fieldEntities) return

            this.getSchemaByGuid(schema.changes.guid).subscribe((prevSchema) => {
                if (!schema.changes || !prevSchema?.fieldEntities || !schema.changes.fieldEntities)
                    return

                const { firstSchema, secondSchema, fieldSchemaKeys, prevFieldSchemaKeys } =
                    this.getFirstAndSecondSchema(schema.changes as Schema, prevSchema) || {}
                if (!firstSchema || !secondSchema || !fieldSchemaKeys || !prevFieldSchemaKeys)
                    return

                const differentFields = difference(
                    Object.keys(firstSchema.fieldEntities),
                    Object.keys(secondSchema.fieldEntities),
                )

                this.addOrRemoveFieldFromSchema(
                    differentFields,
                    firstSchema,
                    fieldSchemaKeys,
                    prevFieldSchemaKeys,
                )
            })
        })
    }

    private addOrRemoveFieldFromSchema(
        fields: string[],
        schema: Schema,
        fieldSchemaKeys: string[],
        prevFieldSchemaKeys: string[],
    ) {
        fields.forEach((fieldGuid) => {
            const field = schema.fieldEntities[fieldGuid]
            this.getSchemaFromLinkField(schema, fieldGuid)?.subscribe((linkedSchema) => {
                if (!linkedSchema) return

                if (fieldSchemaKeys.length < prevFieldSchemaKeys.length) {
                    this.removeVirtualFields(field, linkedSchema)
                } else if (fieldSchemaKeys.length > prevFieldSchemaKeys.length) {
                    this.addVirtualField(field, linkedSchema)
                }
            })
        })
    }

    private getLinkedFieldInfo(allSchemas: ResponseSchema[]) {
        return Object.keys(this.linkReferenceStoreService.getRecordsValueStore()).reduce(
            (sots, storedLinkGuid) => {
                const schema = allSchemas.find((schema) => schema.field[storedLinkGuid])
                if (!schema) return sots

                const linkField = schema.field[storedLinkGuid]
                const targetSot = linkField.link_definition?.target_solution_object_type_guid
                if (!targetSot || !linkField) return sots

                const sotInArray = sots[targetSot] || []

                return {
                    ...sots,
                    [targetSot]: [
                        ...sotInArray,
                        {
                            linkName: schema.field[storedLinkGuid].name || '',
                            fieldGuid: storedLinkGuid,
                        },
                    ],
                }
            },
            {} as Record<string, { linkName: string; fieldGuid: string }[]>,
        )
    }

    private setToStoreRecordCells(linkFields: Field[], record: ResponseRecord, recordGuid: string) {
        linkFields.forEach((link) => {
            const recordLinkCell = record.cells[link.guid]
            if (!recordLinkCell) return

            const values = recordLinkCell.value ? recordLinkCell.value?.split(',') : []

            if (!values.length || !link.link_definition?.target_solution_object_type_guid) return

            this.linkReferenceStoreService.addRecordValues(link.guid, recordGuid, values)
        })
    }

    private getFirstAndSecondSchema(newSchema: Schema, prevSchema: Schema) {
        const fieldSchemaKeys = Object.keys(newSchema.fieldEntities)
        const prevFieldSchemaKeys = Object.keys(prevSchema.fieldEntities)

        let firstSchema: Schema
        let secondSchema: Schema

        if (fieldSchemaKeys.length < prevFieldSchemaKeys.length) {
            firstSchema = prevSchema
            secondSchema = newSchema
        } else if (fieldSchemaKeys.length > prevFieldSchemaKeys.length) {
            firstSchema = newSchema
            secondSchema = prevSchema
        } else {
            return
        }

        return {
            firstSchema,
            secondSchema,
            fieldSchemaKeys,
            prevFieldSchemaKeys,
        }
    }

    private getSchemaFromLinkField(schema: Schema, fieldGuid: string) {
        const field = schema.fieldEntities[fieldGuid]

        if (field.field_type_code !== FieldTypes.LINK) return
        const sotGuid = field.link_definition?.target_solution_object_type_guid
        if (!sotGuid) return

        return this.getSchemaByGuid(sotGuid)
    }

    private removeVirtualFields(field: Field, linkedSchema: Schema) {
        const virtualGuid = this.getVirtualGuidByFieldGuid(field.guid)
        const schemaCopy = cloneDeep(linkedSchema)

        delete schemaCopy?.fieldEntities[virtualGuid]

        this.schemaFacadeService.updateSchemas([
            {
                id: schemaCopy.guid,
                changes: schemaCopy,
            },
        ])
        this.linkReferenceStoreService.removeLinkFromStore(field.guid)
    }

    private addVirtualField(field: Field, linkedSchema: Schema) {
        const virtualGuid = this.getVirtualGuidByFieldGuid(field.guid)
        const schemaCopy = cloneDeep(linkedSchema)

        schemaCopy.fieldEntities[virtualGuid] = this.generateVirtualFieldByFieldGuid(
            field.guid,
            field.name || '',
        )

        this.schemaFacadeService.updateSchemas([
            {
                id: schemaCopy.guid,
                changes: schemaCopy,
            },
        ])

        this.addVirtualFieldToSchemaRecords(schemaCopy.guid, virtualGuid)
    }

    private addVirtualFieldToSchemaRecords(schemaGuid: string, virtualFieldGuid: string) {
        this.recordFacadeService.selectRecordsBySchemaId(schemaGuid).subscribe((records) => {
            const newRecords: Update<BusinessRecords>[] = records.map((record) => {
                const copyNoNameCells = cloneDeep(record.noNameCells)
                const newRecord = {
                    ...cloneDeep(record),
                    noNameCells: {
                        ...copyNoNameCells,
                        [virtualFieldGuid]: {
                            revision: 0,
                            fieldType: FieldTypes.LINK_REFERENCE,
                            value: '',
                            fieldGuid: virtualFieldGuid,
                        },
                    },
                }
                regenerateCells(newRecord)

                return {
                    id: record.guid,
                    changes: newRecord,
                }
            })

            this.recordFacadeService.updateRecordsFromResponse(newRecords)
        })
    }

    private createVirtualCellInRecord(
        linkedField: { linkName: string; fieldGuid: string }[],
        record: ResponseRecord,
    ) {
        const updatedCells = linkedField.reduce((cells, link) => {
            return {
                ...cells,
                [this.getVirtualGuidByFieldGuid(link.fieldGuid)]: {
                    revision: 0,
                    value: '',
                },
            }
        }, {})

        const copyRecord = cloneDeep(record)
        return {
            ...copyRecord,
            cells: { ...copyRecord.cells, ...updatedCells },
        }
    }

    private getSchemaByGuid(schemaGuid: string) {
        return this.schemaFacadeService.selectSchemaByGuid$(schemaGuid).pipe(take(1))
    }

    private getLinkFields(fields: FieldEntities | ResponseFieldEntities) {
        return Object.keys(fields).filter((fieldGuid) => {
            return fields[fieldGuid].field_type_code === FieldTypes.LINK
        })
    }

    private getVirtualGuidByFieldGuid(fieldGuid: string) {
        return `link-ref-${fieldGuid}`
    }

    private generateVirtualFieldByFieldGuid(fieldGuid: string, name: string): Field {
        return {
            guid: this.getVirtualGuidByFieldGuid(fieldGuid),
            name: `${name} - Ref Count`,
            field_type_code: FieldTypes.LINK_REFERENCE,
            operationCode: { system: true },
            virtual_link: fieldGuid,
        }
    }

    private generateVirtualFieldAsResponse(fieldGuid: string, name: string): ResponseField {
        return {
            name: `${name} - Ref Count`,
            field_type_code: FieldTypes.LINK_REFERENCE,
            operation_code: 'S',
            virtual_link: fieldGuid,
        }
    }
}
