import { AfterViewChecked, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core'

@Directive({
    selector: '[appResizeColumn]',
})
export class ResizeColumnDirective implements AfterViewChecked {
    @Input('appResizeColumn') resizable!: boolean
    @Input() appResizeIndex!: string
    @Output() resizedWidth: EventEmitter<number> = new EventEmitter<number>()
    @Output() gridTemplateResize: EventEmitter<string> = new EventEmitter<string>()
    column!: HTMLElement
    gridContainer: HTMLElement[] = []
    resizer!: HTMLElement

    MIN_COLUMN_WIDTH = 225
    xPosition: number = 0
    width: number = 0
    columnsIndex!: number

    constructor(el: ElementRef) {
        this.column = el.nativeElement
    }

    ngAfterViewChecked() {
        if (this.resizable) {
            this.initResizer()
        }
    }

    initResizer() {
        this.gridContainer = document.querySelectorAll(
            '.column-container',
        ) as unknown as HTMLElement[]
        this.resizer = this.column.querySelectorAll('.resizer')[0] as unknown as HTMLElement
        this.resizer.addEventListener('mousedown', this.mouseDown)
    }

    mouseDown = (event: MouseEvent) => {
        this.xPosition = event.clientX
        const styles = window.getComputedStyle(this.column)
        this.width = parseInt(styles.width, 10)
        this.columnsIndex = Number(this.column.getAttribute('data-columnIndex'))
        document.addEventListener('mousemove', this.mouseMove)
        document.addEventListener('mouseup', this.mouseUp)
    }

    mouseMove = (event: MouseEvent) => {
        const positionDifference = event.clientX - this.xPosition
        const finalWidth = this.width + positionDifference

        if (finalWidth < this.MIN_COLUMN_WIDTH) return

        this.gridContainer.forEach((container) => {
            const gridContainerColumnsArr = container.style.gridTemplateColumns.split(' ')
            gridContainerColumnsArr[this.columnsIndex] = `${finalWidth}px`
            container.style.gridTemplateColumns = gridContainerColumnsArr.join(' ')
        })
    }

    mouseUp = (event: MouseEvent) => {
        document.removeEventListener('mousemove', this.mouseMove)
        document.removeEventListener('mouseup', this.mouseUp)
        const resizeToWidth = this.width + (event.clientX - this.xPosition)
        this.resizedWidth.emit(
            resizeToWidth > this.MIN_COLUMN_WIDTH ? resizeToWidth : this.MIN_COLUMN_WIDTH,
        )
    }
}
