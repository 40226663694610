import { Component, Input, OnInit } from '@angular/core'
import { CellEntities, Contact } from '../../../@core/models'
import { ContactItem } from '../list.component'
import { ContactActivity, contactActivity } from '../contact-activity-json'

@Component({
    selector: 'app-list-card',
    templateUrl: './list-card.component.html',
    styleUrls: ['./list-card.component.sass'],
})
export class ListCardComponent implements OnInit {
    @Input()
    record!: Contact

    @Input()
    contact!: ContactItem

    @Input()
    cells!: CellEntities

    contactActivities!: ContactActivity[]
    contactActivitiesGrouped!: { [key: string]: ContactActivity[] }
    isGrouped: boolean = false

    get contactActivitiesSortedKeys() {
        return Object.keys(this.contactActivitiesGrouped)
    }

    ngOnInit(): void {
        this.contactActivities = contactActivity.arguments
        this.contactActivitiesGrouped = this.contactActivities.reduce(
            (res: { [key: string]: ContactActivity[] }, activity: ContactActivity) => {
                if (res[activity.type]) {
                    res[activity.type].push(activity)
                } else {
                    res[activity.type] = [activity]
                }
                return res
            },
            {},
        )
    }

    sortActivities() {
        this.isGrouped = true
    }

    unSortActivities() {
        this.isGrouped = false
    }
}
