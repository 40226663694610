import { Component } from '@angular/core'
import { filter, Observable } from 'rxjs'
import { isNonNull } from '../../@core/global-util'
import { Schema } from '../../@core/models'
import { SchemaFacadeService } from '../../@core/services/store-facade'

@Component({
    selector: 'app-header-name',
    templateUrl: './header-name.component.html',
})
export class HeaderNameComponent {
    readonly selectedSchema$: Observable<Schema> =
        this.schemaFacadeService.selectSelectedTableSchema$.pipe(filter(isNonNull))

    constructor(private schemaFacadeService: SchemaFacadeService) {}
}
