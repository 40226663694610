<div
    class="sm:h-7 h-8 sm:bg-transparent bg-white rounded text-primarySecondary hover:text-primaryDark"
>
    <app-tb-menu
        [template]="filterMenu"
        (stateChanged)="onMenuStateChanged($event)"
        xPosition="before"
    >
        <div
            trigger
            class="flex h-100 cursor-pointer p-1 pr-2 rounded-lg"
            [class.bg-white]="menuState === TbMenuStateEnum.Open"
            [class.text-primaryDark]="menuState === TbMenuStateEnum.Open"
        >
            <ng-container *ngTemplateOutlet="buttonInside"></ng-container>
        </div>
    </app-tb-menu>

    <ng-template #filterMenu>
        <app-filter-menu
            class="sm:relative"
            [filterGroups]="filterGroups"
            [selectedView]="selectedView"
        ></app-filter-menu>
    </ng-template>

    <ng-template #buttonInside>
        <div
            *transloco="let tFilter; read: 'filter'"
            [class.text-accentContrastLight]="isFilterTextSessionOrNotApplied()"
            class="flex items-center"
        >
            <ng-container *ngIf="filterGroups && filterGroups.length === 0; else filterPrimary">
                <span class="material-icons w-5 h-5 text-xl leading-5 mr-2"> filter_list </span>
                <span class="font-semibold">{{tFilter('filter')}}</span>
            </ng-container>
            <ng-template #filterPrimary>
                <span class="material-icons w-5 h-5 text-xl leading-5 mr-2"> filter_list </span>
                <span class="font-semibold mr-2">{{tFilter('filter')}}</span>
                <span
                    class="flex items-center justify-center py-0 px-1.5 h-4 text-xs leading-3 rounded-full bg-accentContrastLight text-white"
                >
                    {{filterCountText}}
                </span>
            </ng-template>
        </div>
    </ng-template>
</div>
