import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core'
import { MatSort, MatSortable, Sort } from '@angular/material/sort'
import {
    AppRecord,
    BusinessRecords,
    Cell,
    CellEntities,
    CurrentUser,
    Field,
    FieldEntities,
    FieldType,
    FilterGroup,
    Folder,
    prepareCellsForRecords,
    prepareFromGroups,
    RecordGroup,
    RecordUpdate,
    View,
    ViewData,
} from '../../@core/models'
import { MatDialog } from '@angular/material/dialog'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Observable } from 'rxjs'
import { ShareRecordComponent } from './table/share-record/share-record.component'
import { RecordsService } from '../../@core/services/records.service'
import { Dictionary } from '@ngrx/entity'
import {
    FieldTypeFacadeService,
    FolderFacadeService,
    RecordFacadeService,
    SchemaFacadeService,
} from '../../@core/services/store-facade'
import { FilterStorageService } from '../../@core/services/session-storage/filter-storage.service'
import { FilterService } from '../view-controls/filter/filter.service'
import { Store } from '@ngrx/store'
import { openViewFilterMenu } from '../../@core/@ngrx'

@UntilDestroy()
@Component({
    selector: 'app-table-container',
    templateUrl: './table-container.component.html',
    styleUrls: ['./table-container.component.sass'],
})
export class TableContainerComponent implements OnInit, AfterViewInit {
    @ViewChild(MatSort, { static: false }) sort!: MatSort
    @Input()
    currentUser?: CurrentUser | null
    @Input()
    tableDataIn?: ViewData

    selectedRecords: BusinessRecords[] = []
    tableData!: ViewData | undefined
    sortedData!: BusinessRecords[] | Map<string, RecordGroup>
    filterGroups!: FilterGroup[]
    sortActive!: string | undefined
    fields!: FieldEntities
    isNewRecord: boolean = false
    newRecordRowGuid!: string | undefined
    userGuid?: string
    newRecordGroup!: RecordGroup
    cells!: { [recordGuid: string]: CellEntities }
    isDataShown = true

    selectedFolder$: Observable<Folder | undefined> = this.folderFacadeService.selectSelectedFolder$
    fieldTypes$: Observable<Dictionary<FieldType>> =
        this.fieldTypeFacadeService.selectFieldTypeEntities$

    constructor(
        private schemaFacadeService: SchemaFacadeService,
        private recordsService: RecordsService,
        public dialog: MatDialog,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private folderFacadeService: FolderFacadeService,
        private recordFacadeService: RecordFacadeService,
        private filterStorageService: FilterStorageService,
        private filterService: FilterService,
        private store: Store,
    ) {}

    get isGrouped(): boolean {
        return !!this.tableData && !(this.tableData.data instanceof Array)
    }

    get groups(): RecordGroup[] | undefined {
        if (this.tableData && this.tableData.data instanceof Map) {
            return [...this.tableData.data.values()]
        }
        return
    }

    get records(): BusinessRecords[] | undefined {
        if (this.tableData && this.tableData.data instanceof Array) {
            return this.tableData.data
        }
        return
    }

    recordForStatusField(key: string) {
        let data = this.tableData!.data as Map<string, RecordGroup>
        return data.get(key)!.data[0]
    }

    ngOnInit() {
        if (this.currentUser) {
            this.userGuid = this.currentUser.guid
        }
        if (!this.tableDataIn) {
            this.recordFacadeService
                .selectViewData$()
                .pipe(untilDestroyed(this))
                .subscribe((data) => {
                    if (data && data.selectedView) {
                        this.tableData = data
                        this.fields = data.fields
                        this.sortedData = data.data ?? []
                        this.filterGroups = this.filterService.getFilterGroupByView(
                            data.selectedView,
                        )
                        this.filterStorageService.isSessionFilterSetUpdate(data.selectedView)
                        this.isNewRecord = false
                        console.log('this.tableData.data', this.tableData)
                        this.prepareCells()
                    }
                })
        } else {
            this.tableData = this.tableDataIn
            this.fields = this.tableData.fields
            this.sortedData = this.tableDataIn.data ?? []
            if (this.tableDataIn.selectedView) {
                this.filterGroups = this.filterService.getFilterGroupByView(
                    this.tableDataIn.selectedView,
                )
                this.filterStorageService.isSessionFilterSetUpdate(this.tableDataIn.selectedView)
            }
            this.prepareCells()
        }
    }

    ngAfterViewInit() {
        if (this.sort) {
            this.sortActive = this.sort.active
        }
    }

    isFilter() {
        return this.filterGroups && this.filterGroups.length > 0
    }

    openFiltersMenu() {
        this.store.dispatch(openViewFilterMenu())
    }

    isNewRecordRow() {
        return this.isNewRecord
    }

    moveFieldToFolder(field: Field) {
        this.schemaFacadeService.updateField(field)
    }

    updateRecord(props: { data: BusinessRecords; cell: Cell; value: any }) {
        const dataRecord: RecordUpdate = {
            record: props.data,
            cell: props.cell,
            value: props.value,
        }

        if (dataRecord.cell?.fieldType === 'field_type_link') {
            this.recordsService.updateLink(dataRecord)
            return
        }

        this.recordsService.updateRecord(dataRecord)
    }

    editRecords(dataRecord: { value: string; field: Field }) {
        let data = this.selectedRecords.map((record) => {
            return {
                record: record,
                cell: this.cells[record.guid][dataRecord.field.guid],
                value: dataRecord.value,
            }
        })
        this.recordsService.updateRecords(data)
        this.clearSelected()
    }

    // todo: [table-ref] these two methods the same
    shareField(field: Field) {
        this.schemaFacadeService.updateField(field)
    }

    updateField(field: Field) {
        this.schemaFacadeService.updateField(field)
    }

    shareRecord(record: AppRecord) {
        const dialogRef = this.dialog.open<ShareRecordComponent, AppRecord>(ShareRecordComponent, {
            width: '400px',
            height: '200px',
            data: record,
        })
        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                record = res ? res : record
                console.log(res)
                this.recordsService.updateRecord({ record })
            }
        })
    }

    selectRecord(selectedRecord: BusinessRecords) {
        if (this.selectedRecords.find((record) => selectedRecord.guid === record.guid)) {
            this.selectedRecords = this.selectedRecords.filter(
                (selectedRow) => selectedRow !== selectedRecord,
            )
            return
        }
        this.selectedRecords = [...this.selectedRecords, selectedRecord]
    }

    // todo: [table-ref] clearSelected, and deleteRecords related to state, move them to the State service
    clearSelected() {
        this.selectedRecords = []
    }

    deleteRecords(record?: BusinessRecords) {
        this.recordsService.deleteRecord(record ?? this.selectedRecords)
        this.clearSelected()
    }

    createRecord(rowGuid?: string) {
        this.isNewRecord = true
        this.newRecordRowGuid = rowGuid
    }

    createRecordInGroup(item: RecordGroup) {
        this.isNewRecord = true
        this.newRecordRowGuid = item.value
        this.newRecordGroup = item
    }

    saveCreateRecord() {
        this.newRecordRowGuid = undefined
        this.isNewRecord = false
    }

    copyField(field: Field) {
        this.createField(field)
    }

    createField(field: Field) {
        this.schemaFacadeService.createField(field)
    }

    deleteField(guid: string) {
        this.schemaFacadeService.deleteField(guid)
    }

    // todo: [table-ref] moveColumnToStart and moveColumnToEnd the same functionality
    // todo: [table-ref] 231-307 we can refactor and move common code
    moveColumnToStart(data: string[]) {
        if (this.tableData && this.tableData.selectedView) {
            const dataRecord: RecordUpdate = {
                record: this.tableData.selectedView,
                cell: this.tableData.selectedView.columns_order,
                value: data.join(','),
            }
            this.recordsService.updateRecord(dataRecord)
        }
    }

    moveColumnToEnd(data: string[]) {
        if (this.tableData && this.tableData.selectedView) {
            const dataRecord: RecordUpdate = {
                record: this.tableData.selectedView,
                cell: this.tableData.selectedView.columns_order,
                value: data.join(','),
            }
            this.recordsService.updateRecord(dataRecord)
        }
    }

    showColumn(data: string[]) {
        if (this.tableData && this.tableData.selectedView) {
            const dataRecord: RecordUpdate = {
                record: this.tableData.selectedView,
                cell: this.tableData.selectedView.columns_hide,
                value: data.join(','),
            }
            this.recordsService.updateRecord(dataRecord)
        }
    }

    pinColumn(data: string) {
        if (this.tableData && this.tableData.selectedView) {
            const dataRecord: RecordUpdate = {
                record: this.tableData.selectedView,
                cell: this.tableData.selectedView.columns_pinned,
                value: data,
            }
            this.recordsService.updateRecord(dataRecord)
        }
    }

    unPinColumn(data: string[]) {
        if (this.tableData && this.tableData.selectedView) {
            const dataRecord: RecordUpdate = {
                record: this.tableData.selectedView,
                cell: this.tableData.selectedView.columns_pinned,
                value: data.join(','),
            }
            this.recordsService.updateRecord(dataRecord)
        }
    }

    hideColumn(value: string) {
        if (this.tableData && this.tableData.selectedView) {
            const dataRecord: RecordUpdate = {
                record: this.tableData.selectedView,
                cell: this.tableData.selectedView.columns_hide,
                value: value,
            }
            this.recordsService.updateRecord(dataRecord)
        }
    }

    sortColumn(guid: string) {
        this.sort.sort({ id: guid, start: 'asc' } as MatSortable)
        this.sortActive = this.sort.active
    }

    sortColumnClear() {
        this.sort.direction = 'asc'
        this.sort.active = ''
        this.sortData(this.sort)
        this.sortActive = undefined
    }

    // todo: [table-ref] sorting in the Table-container instead of Table
    sortData(sort: Sort) {
        const [sortGuid, groupGuid] = sort.active.split(',')

        let data: BusinessRecords[] = groupGuid
            ? [...(this.tableData!.data as Map<string, RecordGroup>).get(groupGuid)!.data]
            : [...(this.tableData!.data as BusinessRecords[])]

        if (!sort.active || sort.direction === '') {
            this.sortedData = this.tableData!.data
            return
        }

        this.sortedData = data.sort((a: BusinessRecords, b: BusinessRecords) => {
            const isAsc = sort.direction === 'asc'
            return this.compare(
                this.cells[a.guid][sortGuid].value.toLowerCase(),
                this.cells[b.guid][sortGuid].value.toLowerCase(),
                isAsc,
            )
        })
        this.sortActive = this.sort.active

        if (!groupGuid) {
            this.tableData!.data = [...this.sortedData]
            return
        }

        const sortedGroup = (this.tableData!.data as Map<string, RecordGroup>).get(
            groupGuid,
        ) as RecordGroup
        ;(this.tableData!.data as Map<string, RecordGroup>).set(groupGuid, {
            data: [...this.sortedData],
            field: sortedGroup.field,
            value: sortedGroup.value,
        })
    }

    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1)
    }

    // todo: [table-ref] should be in the table.component
    dropColumn(data: { columnOrder?: string[]; columnPinned?: string[] }) {
        if (this.tableData && this.tableData.selectedView) {
            if (data.columnOrder) {
                const dataRecord: RecordUpdate = {
                    record: this.tableData.selectedView,
                    cell: this.tableData.selectedView.columns_order,
                    value: data.columnOrder.join(','),
                }
                this.recordsService.updateRecord(dataRecord)
            } else if (data.columnPinned) {
                const dataRecord: RecordUpdate = {
                    record: this.tableData.selectedView,
                    cell: this.tableData.selectedView.columns_pinned,
                    value: data.columnPinned.join(','),
                }
                this.recordsService.updateRecord(dataRecord)
            }
        }
    }

    moveRow(data: RecordUpdate) {
        this.recordsService.updateRecord(data)
    }

    resizeColumn(value: { guid: string; width: number }) {
        const columnsWidthValue = this.tableData?.columns.columnsWidth
            ? {
                  ...this.tableData?.columns.columnsWidth,
                  [value.guid]: value.width,
              }
            : { [value.guid]: value.width }
        this.recordsService.updateRecord({
            record: this.tableData?.selectedView as Partial<View>,
            cell: this.tableData?.selectedView?.columns_width,
            value: JSON.stringify(columnsWidthValue),
        })
    }

    resetColumnWidth(guid: string) {
        let columnsWidthValue = { ...this.tableData?.columns.columnsWidth }
        delete columnsWidthValue[guid]
        this.recordsService.updateRecord({
            record: this.tableData?.selectedView as Partial<View>,
            cell: this.tableData?.selectedView?.columns_width,
            value: JSON.stringify(columnsWidthValue),
        })
    }

    private prepareCells() {
        this.showOrHideFilteredTable()

        if (!this.isDataShown) {
            return
        }

        if (this.records?.length) {
            this.cells = prepareCellsForRecords(this.records)
            return
        }
        if (this.groups?.length) {
            this.cells = prepareFromGroups(this.groups)
            return
        }
    }

    private showOrHideFilteredTable() {
        if (this.filterGroups.length > 0 && this.isGroupsAndRecordsEmpty()) {
            this.isDataShown = false
            return
        }

        this.isDataShown = true
    }

    private isGroupsAndRecordsEmpty() {
        return (
            (!this.records || this.records.length === 0) &&
            (!this.groups || this.groups.length === 0)
        )
    }
}
