import { Injectable, TemplateRef } from '@angular/core'
import { BreakpointService, DisplaySize } from '../breakpoint.service'
import { ComponentType } from '@angular/cdk/overlay'
import { take, takeUntil } from 'rxjs/operators'
import { filter, merge, Observable, of, ReplaySubject, Subject } from 'rxjs'
import { ModalContainerComponent } from './modal-container-component/modal-container.component'
import { MatMenuTrigger } from '@angular/material/menu'
import { DialogInstanceService } from './modal-instances/dialog-instance.service'
import { BottomSheetInstanceService } from './modal-instances/bottom-sheet-instance.service'
import { BaseContainer } from './modal-containers/base-container'
import { last } from 'lodash-es'
import { MenuContainer } from './modal-containers/menu-container'
import { DialogContainer } from './modal-containers/dialog-container'
import { ModalContainerFactoryService } from './modal-container-factory.service'
import { MenuInstanceService } from './modal-instances/menu-instance'

export enum ModalContainer {
    Dialog = 'Dialog',
    BottomSheet = 'BottomSheet',
    Menu = 'Menu',
}

@Injectable({
    providedIn: 'root',
})
export class ModalManagerService {
    containers: BaseContainer<ModalContainerComponent>[] = []
    currentDisplaySize!: DisplaySize
    containerLayout: ModalContainer | null = null

    clearAllMenus$ = new Subject<void>()
    hideMenu$ = new Subject<void>()

    constructor(
        private breakpointService: BreakpointService,
        private dialogInstance: DialogInstanceService,
        private bottomSheetInstance: BottomSheetInstanceService,
        private modalContainerFactoryService: ModalContainerFactoryService,
    ) {
        merge(dialogInstance.closed, bottomSheetInstance.closed, this.clearAllMenus$).subscribe(
            () => {
                this.containers[0]?.close()
                this.clear()
            },
        )

        breakpointService.displaySize$.subscribe((size) => {
            this.currentDisplaySize = size

            if (!this.activeContainer) return

            if (
                this.containerLayout &&
                this.containerLayout !== this.activeContainer.displaySizeToRelatedContainer(size)
            ) {
                this.hideByLayout(this.containerLayout)
            }

            this.reopenByLayout(this.activeContainer, size)
        })
    }

    get activeContainer() {
        return last(this.containers)
    }

    openMenu<T extends ModalContainerComponent, ContainerData = unknown, Result = unknown>(
        menuTrigger: MatMenuTrigger,
        component: ComponentType<T>,
        template: TemplateRef<any> | null,
        data?: ContainerData,
    ) {
        const menuInstanceService = new MenuInstanceService(menuTrigger)

        const menuContainer = new MenuContainer(
            menuInstanceService,
            this.modalContainerFactoryService,
            component,
            template,
            data,
        )

        if (this.activeContainer) {
            this.hidePreviousContainer(this.activeContainer, menuContainer)
        }

        this.containers.push(menuContainer)

        this.menuSubscriptions(menuInstanceService)

        return this.openByLayout(
            menuContainer,
            this.currentDisplaySize,
            menuInstanceService,
        ) as Observable<Result>
    }

    openDialog<T extends ModalContainerComponent, ContainerData = unknown, Result = unknown>(
        component: ComponentType<T>,
        data?: ContainerData,
    ) {
        const dialogContainer = new DialogContainer(
            this.modalContainerFactoryService,
            component,
            data,
        )

        if (this.activeContainer) {
            this.hidePreviousContainer(this.activeContainer, dialogContainer)
        }

        this.containers.push(dialogContainer)

        return this.openByLayout(dialogContainer, this.currentDisplaySize) as Observable<Result>
    }

    close(data?: unknown) {
        if (!this.activeContainer) return

        this.activeContainer.close(data)
        const currentContainer = this.containers.pop()
        const previousContainer = last(this.containers)

        if (currentContainer && previousContainer) {
            this.hidePreviousContainer(currentContainer, previousContainer)
        }

        if (!this.containers.length) {
            this.clear()
            return
        }
        this.reopenByLayout(this.activeContainer, this.currentDisplaySize)
    }

    getComponentRef() {
        return last(this.containers)?.componentRef
    }

    private menuSubscriptions(menuInstanceService: MenuInstanceService) {
        this.hideMenu$.pipe(takeUntil(this.clearAllMenus$)).subscribe(() => {
            menuInstanceService.hide()
        })

        menuInstanceService.closed.pipe(takeUntil(this.clearAllMenus$)).subscribe(() => {
            this.clearAllMenus$.next()
        })
    }

    private hidePreviousContainer(
        previousContainer: BaseContainer<ModalContainerComponent>,
        currentContainer: BaseContainer<ModalContainerComponent>,
    ) {
        if (!this.isPreviousModalTheSameLayout(previousContainer, currentContainer)) {
            this.hideByLayout(
                previousContainer.displaySizeToRelatedContainer(this.currentDisplaySize),
            )
        }
    }

    private isPreviousModalTheSameLayout(
        previousContainer: BaseContainer<ModalContainerComponent>,
        currentContainer: BaseContainer<ModalContainerComponent>,
    ) {
        return (
            previousContainer.displaySizeToRelatedContainer(this.currentDisplaySize) ===
            currentContainer.displaySizeToRelatedContainer(this.currentDisplaySize)
        )
    }

    private openByLayout(
        container: BaseContainer<ModalContainerComponent>,
        displaySize: DisplaySize,
        menuInstance?: MenuInstanceService,
    ) {
        switch (container.displaySizeToRelatedContainer(displaySize)) {
            case ModalContainer.BottomSheet: {
                this.containerLayout = ModalContainer.BottomSheet
                return this.bottomSheetInstance.open(container)
            }
            case ModalContainer.Dialog: {
                this.containerLayout = ModalContainer.Dialog
                return this.dialogInstance.open(container)
            }
            case ModalContainer.Menu: {
                this.containerLayout = ModalContainer.Menu
                return menuInstance!.open()
            }
            default: {
                throw new Error('Wrong layout')
            }
        }
    }

    private reopenByLayout(
        container: BaseContainer<ModalContainerComponent>,
        displaySize: DisplaySize,
    ) {
        switch (container.displaySizeToRelatedContainer(displaySize)) {
            case ModalContainer.BottomSheet: {
                this.containerLayout = ModalContainer.BottomSheet
                this.bottomSheetInstance.reopen(container)
                break
            }
            case ModalContainer.Dialog: {
                this.containerLayout = ModalContainer.Dialog
                this.dialogInstance.reopen(container)
                break
            }
            case ModalContainer.Menu: {
                this.containerLayout = ModalContainer.Menu
                ;(container as MenuContainer<ModalContainerComponent>).menuInstanceService.reopen()
                break
            }
        }
    }

    private hideByLayout(layout: ModalContainer) {
        switch (layout) {
            case ModalContainer.BottomSheet: {
                this.bottomSheetInstance.hide()
                break
            }
            case ModalContainer.Dialog: {
                this.dialogInstance.hide()
                break
            }
            case ModalContainer.Menu: {
                this.hideMenu$.next()
                break
            }
        }
        this.containerLayout = null
    }

    private clear() {
        this.containers.forEach((container) => {
            container.destroy()
        })
        this.containers = []
        this.containerLayout && this.hideByLayout(this.containerLayout)
        this.containerLayout = null
    }
}
