import { Component, Input } from '@angular/core'
import { Field, FieldType } from '../../../@core/models'

@Component({
    selector: 'app-grouping-values',
    templateUrl: './grouping-values.component.html',
    styleUrls: ['./grouping-values.component.sass'],
})
export class GroupingValuesComponent {
    @Input() fieldType?: FieldType
    @Input() field!: Field
    @Input() value!: string
    @Input() isCollapsed!: boolean
    constructor() {}

    isAssignee() {
        return this.field?.field_type_code === 'field_type_assignee'
    }

    isNoGrouped() {
        return this.value === 'NO_GROUPED_RECORDS_KEY'
    }
}
