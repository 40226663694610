import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core'
import { Field, Folder, View } from '../../../../@core/models'
import { FormControl } from '@angular/forms'
import { RecordFacadeService } from '../../../../@core/services/store-facade'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { GroupStorageService } from '../../../../@core/services/session-storage/group-storage.service'

@UntilDestroy()
@Component({
    selector: 'app-group-menu',
    templateUrl: './group-menu.component.html',
    styleUrls: ['./group-menu.component.sass'],
})
export class GroupMenuComponent implements OnInit, OnChanges {
    @Input() groupValue?: string
    @Input() fields?: Field[]

    @Input()
    selectedFolder!: Folder
    @Input()
    selectedView!: View

    @Output()
    groupValueUpdated: EventEmitter<string> = new EventEmitter<string>()

    applyForSession: boolean = false

    selectControl!: FormControl
    isSessionValueSet: boolean = false

    constructor(
        private groupStorageService: GroupStorageService,
        private recordFacadeService: RecordFacadeService,
    ) {}

    ngOnInit() {
        if (this.groupStorageService.getSessionGroup(this.selectedView.guid)) {
            this.applyForSession = true
        }
        this.selectControl = new FormControl(this.groupValue)
        this.groupStorageService
            .isSessionGroupSet$()
            .pipe(untilDestroyed(this))
            .subscribe((state) => (this.isSessionValueSet = state))
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.selectControl && changes.groupValue) {
            this.selectControl.setValue(this.groupValue)
        }
    }

    onApplyForSession() {
        this.applyForSession = true
        this.groupStorageService.setSessionGroup(this.selectedView.guid, this.selectControl.value)
        this.recordFacadeService.refreshViewData()
    }
    onSaveGroup() {
        this.applyForSession = false
        this.groupStorageService.removeSessionGroup(this.selectedView.guid)
        this.groupValueUpdated.emit(this.selectControl.value)
    }

    resetToDefault() {
        this.applyForSession = false
        this.groupStorageService.removeSessionGroup(this.selectedView.guid)
        this.recordFacadeService.refreshViewData()
        this.selectControl.setValue(this.groupValue)
    }

    isSelectControlChanged() {
        return this.selectControl.dirty && this.selectControl.value !== this.groupValue
    }

    isSaveButtonActive() {
        return this.isSelectControlChanged() || this.isValueSession()
    }

    private isValueSession() {
        return this.selectedView.group.value !== this.groupValue && this.isSessionValueSet
    }
}
