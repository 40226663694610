<div *ngIf="field" class="flex items-center">
    <span
        *ngIf="showIcon"
        class="material-icons h-5 w-5 mr-2 text-lg leading-5 text-primarySecondary"
    >
        {{getFieldIcon()}}
    </span>
    <span
        *ngIf="!showFolderName && field.folder_name?.name "
        class="w-full overflow-hidden text-ellipsis whitespace-nowrap"
        >{{field.folder_name!.name + "/" + field.name}}</span
    >
    <span
        *ngIf="showFolderName || !field.folder_name?.name "
        class="w-full overflow-hidden text-ellipsis whitespace-nowrap"
        >{{ field.name }}</span
    >
</div>
