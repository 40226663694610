import { Component, OnInit } from '@angular/core'
import { NavigationMenuService } from './navigation-menu.service'
import { CommonFacadeService } from './@core/services/store-facade/common-facade.service'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: [NavigationMenuService],
})
export class AppComponent implements OnInit {
    menuItems$ = this.menuService.menu$

    constructor(
        private commonFacadeService: CommonFacadeService,
        private menuService: NavigationMenuService,
    ) {}

    ngOnInit(): void {
        this.commonFacadeService.initApp()
    }
}
