<ng-container *transloco="let tVersion; read: 'version'">
    <button [matMenuTriggerFor]="versionContent">version</button>
    <mat-menu #versionContent="matMenu">
        <ng-template matMenuContent>
            {{tVersion('branch')}}{{version.branch}}
            <br />
            {{tVersion('commit')}}{{version.commit}}
            <br />
            {{tVersion('date')}}{{version.date}}
        </ng-template>
    </mat-menu>
</ng-container>
