import { Component, Input } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-views-rename',
    templateUrl: './views-rename.component.html',
    styleUrls: ['./views-rename.component.sass'],
})
export class ViewsRenameComponent {
    @Input() viewName!: string

    isAmended: boolean = false

    onInputChange(newViewName: string) {
        if (newViewName !== this.viewName) {
            this.isAmended = true
            return
        }

        this.isAmended = false
    }
}
