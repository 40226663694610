<div
    *ngIf="activeOptions.length >0; else defaultStatus"
    [matMenuTriggerFor]="dropdownMenu"
    class="dropdown flex-start-center"
>
    <span
        *ngFor="let option of activeOptions"
        [ngStyle]="{'background':getBgColor(option)}"
        class="option-item"
    ></span>
</div>
<ng-template #defaultStatus>
    <div class="dropdown" [matMenuTriggerFor]="dropdownMenu">select</div>
</ng-template>
<mat-menu #dropdownMenu="matMenu">
    <ng-template matMenuContent>
        <button
            (click)="checkActive(key); $event.stopPropagation();"
            *ngFor="let key of optionKeys()"
            [ngStyle]="{'background':dropdownData[key].color,'color': key |getContrastColor}"
            class="text-center"
            mat-menu-item
        >
            {{dropdownData[key].label}}
            <mat-icon [ngStyle]="{'color': key |getContrastColor}" class="dropdown-icon"
                >{{dropdownData[key].icon}}</mat-icon
            >
            <mat-icon
                *ngIf="this.activeOptions.includes(key)"
                [ngStyle]="{'color': key |getContrastColor}"
                class="active-check-label"
                >check
            </mat-icon>
        </button>
    </ng-template>
</mat-menu>
