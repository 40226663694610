<form *ngIf="textFormControl" class="edit-mode-form">
    <mat-form-field class="noHintError" appearance="outline">
        <input
            [formControl]="textFormControl"
            autocomplete="off"
            matInput
            name="name"
            type="text"
        />
    </mat-form-field>
    <mat-error *ngIf="textFormControl.hasError('required')"></mat-error>
</form>
