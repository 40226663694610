import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop'
import { Field } from '../../../../../../@core/models'
import {
    SelectObjectOptions,
    SelectOption,
} from '../../../../../../@core/models/response/select-object-options'
import cloneDeep from 'lodash/cloneDeep'
import { generateUuid } from '../../../../../../@core/global-util'

type StatusOptionsByTags = {
    active: SelectOption[]
    done: SelectOption[]
    close: SelectOption[]
}

@Component({
    selector: 'app-status-content',
    templateUrl: './status-content.component.html',
    styleUrls: ['./status-content.component.sass'],
})
export class StatusContentComponent implements OnInit {
    @Input()
    StatusData!: Field

    @Input()
    activeStatus!: string | undefined

    @Input()
    defaultStatus!: string | undefined

    @Input()
    statusStatuses!: SelectObjectOptions

    @Output()
    fieldChanged: EventEmitter<Field> = new EventEmitter<Field>()
    @Output()
    defaultChanged: EventEmitter<string> = new EventEmitter<string>()

    statusesByTags!: StatusOptionsByTags
    statusesTmp!: SelectOption[]

    constructor() {}

    ngOnInit(): void {
        if (this.StatusData && !this.statusStatuses) {
            this.statusStatuses = cloneDeep(this.StatusData.select_object_field!)
        }
        this.completeStatusesToArray()
    }

    completeStatusesToArray() {
        if (this.statusStatuses) {
            const statusesByTagsTmp = {
                active: [],
                done: [],
                close: [],
            }

            this.statusesTmp = Object.keys(this.statusStatuses).map((guid) => {
                return {
                    guid,
                    ...this.statusStatuses[guid],
                }
            })

            this.statusesByTags = this.statusesTmp.reduce((acc, status) => {
                if (status.tags) {
                    return {
                        ...acc,
                        [status.tags]: [...acc[status.tags as keyof StatusOptionsByTags], status],
                    }
                }
                return acc
            }, statusesByTagsTmp)
        }

        this.setDefaultGuid()
        const defaultOption = this.statusesByTags.active[this.getDefaultIndex()]

        const activeWithoutDefault = this.statusesByTags.active.filter(
            (option) => option.guid !== defaultOption.guid,
        )

        this.statusesByTags.active = [defaultOption].concat(activeWithoutDefault)
    }

    addOption() {
        const newIndex = generateUuid()
        const newOption: SelectOption = {
            label: '',
            guid: newIndex,
            color: '#cccccc',
            tags: 'active',
            icon: '',
        }
        this.statusesByTags.active.push(newOption)
        this.statusStatuses = {
            ...this.statusStatuses,
            [newIndex]: {
                label: '',
                color: '#cccccc',
                tags: 'active',
                is_new: true,
            },
        }
    }

    drop(event: CdkDragDrop<SelectOption[]>, isActive = false) {
        const currentIndex = !isActive ? event.currentIndex : event.currentIndex + 1
        const previousIndex = !isActive ? event.previousIndex : event.previousIndex + 1
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, previousIndex, currentIndex)
            return
        }

        if (event.previousContainer.data.length === 1) return

        transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            previousIndex,
            currentIndex,
        )
        this.updateOptionsOnDrop(event)
        this.sendData()
    }

    deleteStatus(guid?: string) {
        if (!guid || this.statusStatuses[guid].tags === 'close') return

        delete this.statusStatuses[guid]
        this.sendData()
    }

    optionChanged(optionValue: SelectOption, guid?: string) {
        if (!guid) return

        this.statusStatuses[guid] = {
            ...this.statusStatuses[guid],
            color: optionValue.color,
            label: optionValue.label,
        }

        this.sendData()
    }

    changeIcon(optionValue: string, guid?: string) {
        if (!guid) return

        this.statusStatuses[guid as string] = {
            ...this.statusStatuses[guid as string],
            icon: optionValue,
        }
        this.sendData()
    }

    setDefaultOption(guid?: string) {
        if (!guid) return

        this.updateStatusDefault(guid)
        this.defaultChanged.emit(guid)
        this.sendData()
    }

    sendData() {
        this.completeStatusesToArray()
        this.fieldChanged.emit({ ...this.StatusData, select_object_field: this.statusStatuses })
    }

    private updateStatusDefault(guid: string) {
        if (!this.statusStatuses || !this.defaultStatus) return

        this.statusStatuses[guid].is_default = 1
        this.statusStatuses[this.defaultStatus].is_default = 0
        this.defaultStatus = guid
    }

    private updateOptionsOnDrop(event: CdkDragDrop<SelectOption[]>) {
        if (!this.statusStatuses) {
            return
        }
        const updatedTagName = event.container.id as keyof StatusOptionsByTags
        this.statusStatuses[event.item.data].tags = updatedTagName

        this.statusesByTags[updatedTagName] = this.statusesByTags[updatedTagName].map((item) => {
            return item.tags !== updatedTagName ? { ...item, tags: updatedTagName } : item
        })
    }

    private getDefaultIndex() {
        return this.statusesByTags.active.map((option) => option.guid).indexOf(this.defaultStatus)
    }

    private setDefaultGuid() {
        if (!this.defaultStatus) {
            this.defaultStatus = this.statusesTmp.find((status) => status.is_default === 1)?.guid
        }
    }
}
