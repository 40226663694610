<div
    class="sm:pt-4 sm:pb-6 sm:px-1 sm:w-screen max-w-[568px]"
    *transloco="let tNewWorkspace; read: 'new_workspace'"
>
    <div class="py-0 px-5 max-sm:px-0 mb-6 max-sm:pt-4 text-xl font-semibold text-primaryDark">
        {{tNewWorkspace('create_new_workspace')}}
        <div class="flex absolute sm:top-[8px] top-[16px] sm:right-[6px] right-[12px]">
            <mat-icon
                mat-dialog-close
                class="flex items-center w-5 h-5 box-content p-0.5 text-xl leading-4 rounded hover:bg-primaryLight cursor-pointer"
            >
                close
            </mat-icon>
        </div>
    </div>

    <div
        class="overflow-auto sm:max-h-[calc(100vh-180px)] max-h-[calc(100vh-395px)] py-0 px-5 max-sm:px-0"
    >
        <div class="mb-6">
            <div class="mb-1 text-primaryDark">{{tNewWorkspace('workspace_name')}}</div>
            <mat-form-field class="w-full noHintError" appearance="outline">
                <input matInput />
            </mat-form-field>
        </div>
        <div class="flex gap-4 w-full mb-6">
            <app-color-icon-picker class="w-full"></app-color-icon-picker>
            <app-color-icon-picker [icon]="'folder'" class="w-full"></app-color-icon-picker>
        </div>
        <app-access-selector class="mb-10"></app-access-selector>
        <app-advanced-options></app-advanced-options>
    </div>

    <div
        class="flex justify-end mt-4 py-0 px-5 max-sm:px-0"
        *transloco="let tButtons; read: 'buttons'"
    >
        <app-tb-button mat-dialog-close class="flex-1 sm:flex-none mr-4" appearance="outlined">
            {{tButtons('cancel')}}
        </app-tb-button>
        <div class="flex sm:gap-1 gap-2 flex-1 sm:flex-none">
            <app-tb-button class="w-full">{{tButtons('apply')}}</app-tb-button>

            <button
                class="flex items-center justify-center rounded-lg bg-secondaryMain font-semibold text-white sm:h-9 h-11 sm:min-w-[36px] min-w-[44px] shadowedBtn"
            >
                <mat-icon>expand_less</mat-icon>
            </button>
        </div>
    </div>
</div>
