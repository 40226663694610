import { ChangeDetectionStrategy, Component } from '@angular/core'
import { CellComponent } from '../cell.component'
import { LinkReferenceStoreService } from '../../../@core/services/link-reference-store.service'

@Component({
    selector: 'app-link-reference',
    templateUrl: './link-reference.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkReferenceComponent extends CellComponent {
    constructor(private linkReferenceStoreService: LinkReferenceStoreService) {
        super()
    }

    getValue() {
        if (!this.record?.guid || !this.field.virtual_link) return

        return this.linkReferenceStoreService.getSourceCount(
            this.record?.guid,
            this.field.virtual_link,
        )
    }
}
