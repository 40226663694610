<section *transloco="let t" class="example-container">
    <header>
        <h2>{{t('step')}} {{ selectedIndex + 1 }}/{{ steps.length }}</h2>
    </header>

    <div>
        <div [ngTemplateOutlet]="selected ? selected.content : null"></div>
    </div>

    <footer class="example-step-navigation-bar">
        <button cdkStepperPrevious class="example-nav-button">&larr;</button>
        <button
            (click)="selectStepByIndex(i)"
            *ngFor="let step of steps; let i = index"
            [class.example-active]="selectedIndex === i"
            class="example-step"
        >
            {{t('step')}} {{ i + 1 }}
        </button>
        <button cdkStepperNext class="example-nav-button">&rarr;</button>
    </footer>
</section>
