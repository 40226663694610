import { Cell } from './cell.model'
import { AppRecord } from './base-record.model'
import { Schema } from './schema.model'

export interface Folder extends AppRecord {
    name: Cell
    is_global: Cell
    icon: Cell
    color: Cell
    roles: Cell
}

export interface MenuNode {
    name: string
    info: Folder | Schema | string
    children?: MenuNode[]
}

export interface MenuFlatNode {
    expandable: boolean
    name: string
    level: number
    info: Folder | Schema | string
}
