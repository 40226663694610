import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState, selectAllFieldTypes, selectFieldTypeEntities, setFieldTypes } from '../../@ngrx'
import { FieldEntities, FieldType } from '../../models'
import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { FolderFacadeService } from './folder-facade.service'
import { SchemaFacadeService } from './schema-facade.service'
import { getBoolean } from '../../global-util'

@Injectable({
    providedIn: 'root',
})
export class FieldTypeFacadeService {
    constructor(
        private store: Store<AppState>,
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
    ) {}

    selectFieldTypeEntities$ = this.store.select(selectFieldTypeEntities)

    selectAllFieldTypes$ = this.store.select(selectAllFieldTypes)

    selectAvailableFieldTypeByFolder$ = combineLatest([
        this.selectAllFieldTypes$,
        this.folderFacadeService.selectSelectedFolder$,
        this.schemaFacadeService.selectSelectedTableSchemaFieldEntities$,
    ]).pipe(
        map(([types, selectedFolder, fields]) => {
            if (!fields || !types || !selectedFolder) return []

            const createTypes = this.getCreateTypes(types, fields, selectedFolder.guid)

            if (selectedFolder && getBoolean(selectedFolder.is_global.value)) {
                return createTypes
            }
            return createTypes.filter((type) => !type.operationCode.global)
        }),
    )

    setFieldTypes(fieldTypes: FieldType[]) {
        this.store.dispatch(setFieldTypes({ fieldTypes }))
    }

    private getCreateTypes(types: FieldType[], fields: FieldEntities, folderGuid: string) {
        const multipleCreateTypes: FieldType[] = types.filter(
            (type: FieldType) => type.operationCode.multiple && type.operationCode.create,
        )
        const singleCreateTypes: FieldType[] = types.filter((type) =>
            this.singleTypeCanBeCreated(type, fields, folderGuid),
        )

        return [...multipleCreateTypes, ...singleCreateTypes]
    }

    private singleTypeCanBeCreated(type: FieldType, fields: FieldEntities, folderGuid: string) {
        return (
            type.operationCode.create &&
            !type.operationCode.multiple &&
            !this.fieldWithTypeExists(type, fields, folderGuid)
        )
    }

    private fieldWithTypeExists(type: FieldType, fields: FieldEntities, folderGuid: string) {
        return !!Object.values(fields)
            .filter((field) => field.folder_guid === folderGuid)
            .find((field) => field.field_type_code === type.guid)
    }
}
