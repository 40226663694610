<div class="flex-start-center justify-content-between">
    <div *ngIf="name" class="fs-lg">
        <ng-container> {{name.fieldName}} </ng-container>
        <span *ngIf="name.value">{{name.value}}</span>
    </div>
    <app-assign
        *ngIf="field && fieldType"
        [disabled]="true"
        [fieldType]="fieldType"
        [field]="field"
        [value]="value ?? ''"
    >
    </app-assign>
</div>
