<form
    [formGroup]="formGroup"
    class="flex-start-center formGroup-form"
    *transloco="let tCellTypesTextOneLine; read: 'cell_types.text_one_line'"
>
    <mat-form-field class="text-input">
        <input
            autocomplete="off"
            formControlName="minLength"
            matInput
            name="minLength"
            placeholder="{{tCellTypesTextOneLine('min_length_placeholder')}}"
            type="number"
            value="value"
        />
    </mat-form-field>
    <mat-form-field class="text-input">
        <input
            autocomplete="off"
            formControlName="maxLength"
            matInput
            name="maxLength"
            placeholder="{{tCellTypesTextOneLine('max_length_placeholder')}}"
            type="number"
            value="value"
        />
    </mat-form-field>
</form>
