import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
    initApp,
    initAppSuccess,
    onMessage,
    resetSolutionToTemplate,
    setError,
    setLoading,
} from './common.actions'
import { UpdateService, WsProcessingService } from '../../services'
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators'
import { filter, of } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from '../app.state'
import { selectDataInitialized } from './common.selector'

@Injectable()
export class CommonEffects {
    initApp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(initApp),
            tap(() => this.store.dispatch(setLoading({ loading: true }))),
            switchMap(() => {
                this.objectResponseService.init()
                return this.store.select(selectDataInitialized).pipe(filter(Boolean))
            }),
            mergeMap(() => {
                return [initAppSuccess(), setLoading({ loading: false })]
            }),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        ),
    )

    resetSolution$ = createEffect(() =>
        this.actions$.pipe(
            ofType(resetSolutionToTemplate),
            tap(() => this.store.dispatch(setLoading({ loading: true }))),
            switchMap(() => {
                return this.updateService.resetSolutionToTemplate().pipe(
                    mergeMap((data) => {
                        return [onMessage({ response: data })]
                    }),
                )
            }),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        ),
    )

    onMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onMessage),
            tap((res) => {
                this.store.dispatch(setLoading({ loading: true }))
                this.objectResponseService.onMessage(res.response)
            }),
            mergeMap(() => {
                return [setLoading({ loading: false })]
            }),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        )
    })

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private objectResponseService: WsProcessingService,
        private updateService: UpdateService,
    ) {}
}
