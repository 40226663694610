import { Component, Inject, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { AddFieldData, Field, FieldType } from '../../../../@core/models'
import { FormControl, FormGroup } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { FieldTypeFacadeService } from '../../../../@core/services/store-facade'

@UntilDestroy()
@Component({
    selector: 'app-add-column',
    templateUrl: './add-column.component.html',
    styleUrls: ['./add-column.component.sass'],
})
export class AddColumnComponent implements OnInit {
    field!: Field
    showTypeSelector = false

    formGroup!: FormGroup<{
        selectFieldType: FormControl<string | null>
        columnName: FormControl<any>
    }>

    fieldTypes$: Observable<FieldType[]> =
        this.fieldTypeFacadeService.selectAvailableFieldTypeByFolder$

    constructor(
        private fieldTypeFacadeService: FieldTypeFacadeService,
        public dialogRef: MatDialogRef<AddColumnComponent, Field | undefined>,
        @Inject(MAT_DIALOG_DATA) public data: AddFieldData,
    ) {}

    ngOnInit() {
        this.fieldTypes$.pipe(untilDestroyed(this)).subscribe((types) => {
            if (types.find((type) => type.guid === this.data.field_type_code)) {
                this.showTypeSelector = true
            }
        })

        this.formGroup = new FormGroup({
            columnName: new FormControl(this.data.name ? this.data.name : ''),
            selectFieldType: new FormControl<string>(this.data.field_type_code),
        })

        this.setDefaultState()
    }

    changeFieldType() {
        if (this.formGroup.controls['selectFieldType'].value) {
            this.field.field_type_code = this.formGroup.controls['selectFieldType'].value
            this.setDefaultState()
        }
    }

    creteColumn() {
        this.field.name = this.formGroup.controls['columnName'].value
        console.log('this.field', this.field)
        this.dialogRef.close(this.field)
    }

    closeDialog() {
        this.dialogRef.close()
    }

    changedValue(field: Field) {
        if (!field.guid) {
            this.field = {
                ...this.field,
                select_object_field: field.select_object_field,
                configure_json: field.configure_json,
                link_definition: field.link_definition,
            }
            return
        }
        this.field = field
    }

    setDefaultState() {
        this.field = {
            ...this.data,
            field_type_code:
                this.formGroup.controls.selectFieldType.value || this.data.field_type_code,
            guid: this.data.guid ? this.data.guid : '',
            select_object_field: this.data.select_object_field,
            configure_json: this.data.configure_json,
            link_definition: this.data.link_definition,
        }
    }
}
