<div
    (click)="$event.stopPropagation()"
    class="max-sm:mt-4 sm:min-w-[540px]"
    *transloco="let tFilter; read: 'filter'"
>
    <div class="flex justify-between mb-2 max-sm:pr-2">
        <div class="text-sm text-primaryDark font-semibold">{{tFilter('active_filters')}}</div>
        <span
            (click)="clearAll()"
            class="font-semibold text-sm text-secondaryMainLight hover:text-secondaryMain cursor-pointer"
            *ngIf="isClearAll()"
        >
            {{tFilter('clear_all')}}
        </span>
    </div>

    <ng-container *ngIf="(fields$|async) || { fieldTypes: [], fields: {}}; let fieldData">
        <div class="max-sm:max-h-[calc(100vh-477px)] overflow-auto max-sm:pr-2 max-sm:mb-2">
            <app-filter-group
                class="p-0"
                (deleteGroup)="deleteGroup(groupIndex)"
                (clearAll)="clearAll()"
                *ngFor="let group of formGroups; index as groupIndex"
                [selectedFolder]="selectedFolder"
                [fieldTypes]="fieldData.fieldTypes"
                [fields]="fieldData.fields"
                [groupIndex]="groupIndex"
                [single]="formFilterService.getFilterFormArray.length === 1"
                [isEmpty]="isFilterFormEmpty"
            ></app-filter-group>
            <div *ngIf="!isFilterFormEmpty">
                <!-- TODO: reuse tb-button in the future and make sure disabled state shows according to the UI Kit design-->
                <button
                    *transloco="let tFilter; read: 'filter'"
                    class="flex items-center w-fit p-1 mt-6 sm:mb-1 text-primaryDark"
                    [class.hover:text-secondaryMain]="canAddFilterGroup"
                    [disabled]="!canAddFilterGroup"
                    (click)="addGroupAction($event)"
                >
                    <!--Todo: // [desing-ref] icon-->
                    <mat-icon class="material-icons w-fit h-fit text-xl leading-5 mr-xs"
                        >add_circle_outline</mat-icon
                    >
                    <span class="font-semibold text-sm">{{tFilter('add_group')}}</span>
                </button>
            </div>
        </div>
    </ng-container>
</div>
<div
    class="sm:absolute sm:right-0 sm:bottom-0 flex max-sm:flex-col sm:items-center justify-between max-sm:pr-2 mt-6 max-sm:mt-4"
>
    <div *transloco="let tButtons; read: 'buttons'" class="flex max-xsm:flex-col">
        <div class="flex max-sm:w-[66%] max-xsm:w-full">
            <button
                (click)="resetToDefault()"
                *ngIf="showResetDefault"
                class="flex items-center max-sm:justify-center max-sm:flex-1 sm:h-9 h-11 max-sm:min-h-[44px] sm:w-fit w-full max-sm:mb-4 p-1 rounded-lg max-sm:border max-sm:border-solid max-sm:border-secondaryMain text-secondaryMainLight hover:text-secondaryMain max-sm:text-secondaryMain"
            >
                <!--Todo: // [desing-ref] icon-->
                <span class="font-semibold text-sm">{{tButtons('reset_to_default')}}</span>
            </button>
            <button
                (click)="applyFilterForSession()"
                *ngIf="showApplyButton"
                [disabled]="isDisabledApplyAction"
                class="flex items-center max-sm:justify-center max-sm:flex-1 sm:h-9 h-11 ml-4 max-sm:mr-4 max-xsm:mr-0 px-5 font-semibold text-sm text-secondaryMain rounded-lg border border-secondaryMain border-solid"
            >
                <span>{{tButtons('preview')}}</span>
            </button>
        </div>
        <button
            (click)="saveFilter()"
            *ngIf="showApplyButton"
            [disabled]="isDisabledApplyAction"
            class="max-sm:w-[33%] max-xsm:w-full flex items-center max-sm:justify-center max-xsm:flex-1 sm:h-9 h-11 max-sm:min-h-[44px] sm:ml-4 px-5 font-semibold text-sm text-white rounded-lg bg-secondaryMain"
        >
            <span>{{tButtons('apply')}}</span>
        </button>
    </div>
</div>
