import { CurrentUser, UserModelEntities } from '../../models'

export interface UserState {
    readonly users: UserModelEntities
    readonly loading: boolean
    readonly loaded: boolean
    readonly error: Error | string | null
    readonly currentUser: CurrentUser | null
}

export const initialUserState: UserState = {
    users: {},
    loading: false,
    loaded: false,
    error: null,
    currentUser: null,
}
