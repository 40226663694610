import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ListComponent } from './list.component'
import { MatListModule } from '@angular/material/list'
import { ListCardComponent } from './list-card/list-card.component'
import { ListContactsComponent } from './list-contacts/list-contacts.component'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatMenuModule } from '@angular/material/menu'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { ListCardActivityComponent } from './list-card/list-card-activity/list-card-activity.component'
import { MatCardModule } from '@angular/material/card'
import { ListCardInfoComponent } from './list-card/list-card-info/list-card-info.component'
import { ListCardActivityItemComponent } from './list-card/list-card-activity/list-card-activity-item/list-card-activity-item.component'
import { TranslocoModule } from '@ngneat/transloco'

@NgModule({
    declarations: [
        ListComponent,
        ListCardComponent,
        ListContactsComponent,
        ListCardActivityComponent,
        ListCardInfoComponent,
        ListCardActivityItemComponent,
    ],
    imports: [
        CommonModule,
        MatListModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatButtonToggleModule,
        MatCardModule,
        TranslocoModule,
    ],
    exports: [ListComponent],
})
export class ListModule {}
