import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Folder } from '../../models'
import { createFeature, createReducer, on } from '@ngrx/store'
import {
    addFoldersFromResponse,
    createFolderRequest,
    createFolderRequestSuccess,
    deleteFoldersFromResponse,
    foldersAreLoaded,
    initFolderNames,
    initFolders,
    setSelectedFolderGuid,
    updateFoldersFromResponse,
} from './folder.actions'
import { FolderNameEntities } from '../../models/response/folder-names'

const folderFeatureKey: string = 'FOLDER'

export interface FolderState extends EntityState<Folder> {
    selectedFolderGuid: string | null
    areLoaded: boolean
    isError: boolean
    folderNames: FolderNameEntities | {}
}

function selectFolderId(folder: Folder): string {
    return folder.guid
}

export const folderAdapter: EntityAdapter<Folder> = createEntityAdapter<Folder>({
    selectId: selectFolderId,
})

const initialState: FolderState = folderAdapter.getInitialState({
    selectedFolderGuid: null,
    areLoaded: false,
    isError: false,
    folderNames: {},
})

const folderReducer = createReducer(
    initialState,
    on(initFolders, (state: FolderState, { folders, selectedFolderGuid }) => {
        console.info('initFolders')
        const error = folders.length === 0 ? { isError: true } : {}

        return folderAdapter.setAll(folders, {
            ...state,
            ...error,
            selectedFolderGuid: selectedFolderGuid || state.selectedFolderGuid,
        })
    }),
    on(setSelectedFolderGuid, (state: FolderState, { selectedFolderGuid }) => {
        console.info('setSelectedFolderGuid')
        return {
            ...state,
            selectedFolderGuid,
        }
    }),
    on(foldersAreLoaded, (state: FolderState) => {
        console.info('foldersAreLoaded')
        return {
            ...state,
            areLoaded: true,
        }
    }),
    on(createFolderRequest, createFolderRequestSuccess, (state: FolderState) => {
        console.info('createFolder,\n' + '                createFieldSuccess,')
        return state
    }),
    on(addFoldersFromResponse, (state: FolderState, { folders }) => {
        return folderAdapter.addMany(folders, state)
    }),
    on(updateFoldersFromResponse, (state: FolderState, { folders }) => {
        return folderAdapter.updateMany(folders, state)
    }),
    on(deleteFoldersFromResponse, (state: FolderState, { folders }) => {
        return folderAdapter.removeMany(
            folders.map((deleted) => deleted.guid),
            state,
        )
    }),
    on(initFolderNames, (state: FolderState, { folderNames }) => {
        console.info('initFolderNames')
        return {
            ...state,
            folderNames,
        }
    }),
)

export const folderFeature = createFeature({
    name: folderFeatureKey,
    reducer: folderReducer,
})
