<form *ngIf="numberFormControl" class="edit-mode-form">
    <mat-form-field class="noHintError" appearance="outline">
        <div class="flex-start-center">
            <span class="mr-xs"> {{currencySymbol}} </span>
            <input
                [formControl]="numberFormControl"
                autocomplete="off"
                matInput
                name="number"
                type="number"
            />
        </div>
        <mat-error *ngIf="numberFormControl.hasError('required')"></mat-error>
    </mat-form-field>
</form>
