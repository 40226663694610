<div
    class="sm:pt-4 sm:pb-6 sm:px-1 sm:w-screen max-w-[568px]"
    *transloco="let tRecordSettings; read: 'record_settings'"
>
    <div class="py-0 px-5 max-sm:px-0 mb-6 max-sm:pt-4 text-xl font-semibold text-primaryDark">
        {{tRecordSettings('record_settings')}}
        <div class="flex absolute sm:top-[8px] top-[16px] sm:right-[6px] right-[12px]">
            <mat-icon
                mat-dialog-close
                class="flex items-center w-5 h-5 box-content p-0.5 text-xl leading-4 rounded hover:bg-primaryLight cursor-pointer"
            >
                close
            </mat-icon>
        </div>
    </div>

    <div
        class="overflow-auto sm:max-h-[calc(100vh-180px)] max-h-[calc(100vh-361px)] py-0 px-5 max-sm:px-0"
    >
        <!--        selects will need to be changed for proper mat-form-fields after global class pr approval-->
        <div class="mb-6">
            <div class="mb-1 text-primaryDark">{{tRecordSettings('workspace')}}</div>
            <mat-form-field appearance="outline" class="w-full noHintError">
                <mat-select #workSelect>
                    <mat-option value="values">$Values here</mat-option>
                    <mat-option>$Values here</mat-option>
                    <mat-option>$Values here</mat-option>
                </mat-select>
                <mat-icon class="matSelectIconCustom cursor-pointer" matSuffix
                    >{{workSelect.panelOpen ? 'expand_less' : 'expand_more'}}</mat-icon
                >
            </mat-form-field>
        </div>
        <div class="mb-6">
            <div class="mb-1 text-primaryDark">{{tRecordSettings('type')}}</div>
            <mat-form-field appearance="outline" class="w-full noHintError">
                <mat-select #typeSelect>
                    <mat-option value="values">$Values here</mat-option>
                    <mat-option>$Values here</mat-option>
                    <mat-option>$Values here</mat-option>
                </mat-select>
                <mat-icon class="matSelectIconCustom cursor-pointer" matSuffix
                    >{{typeSelect.panelOpen ? 'expand_less' : 'expand_more'}}</mat-icon
                >
            </mat-form-field>
        </div>
        <div class="mb-6">
            <div class="mb-1 text-primaryDark">{{tRecordSettings('template')}}</div>
            <mat-form-field appearance="outline" class="w-full noHintError">
                <mat-select #templateSelect>
                    <mat-option value="values">$Values here</mat-option>
                    <mat-option>$Values here</mat-option>
                    <mat-option>$Values here</mat-option>
                </mat-select>
                <mat-icon class="matSelectIconCustom cursor-pointer" matSuffix
                    >{{templateSelect.panelOpen ? 'expand_less' : 'expand_more'}}</mat-icon
                >
            </mat-form-field>
        </div>

        <app-access-selector [isActive]="true"></app-access-selector>
    </div>

    <div class="flex justify-end py-0 px-5 max-sm:px-0" *transloco="let tButtons; read: 'buttons'">
        <button
            class="flex-1 xsm:flex-none mr-5 py-1 px-5 rounded-lg font-semibold text-secondaryMain border border-secondaryMain border-solid h-9 shadowedBtn"
        >
            {{tButtons('cancel')}}
        </button>
        <button
            class="flex-1 xsm:flex-none py-1 px-5 rounded-lg bg-secondaryMain font-semibold text-white h-9 shadowedBtn"
        >
            {{tButtons('apply')}}
        </button>
    </div>
</div>
