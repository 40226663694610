import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BoardCardComponent } from '../board/board-card/board-card.component'
import { combineLatest, Observable, of } from 'rxjs'
import {
    BusinessRecords,
    CellEntities,
    Field,
    FieldEntities,
    FieldType,
    FieldTypes,
    Folder,
    getRecordCells,
    Schema,
} from '../../@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'
import { FolderStatus } from './header/folder-status.model'
import { getBoolean } from '../../@core/global-util'
import { PinRecordService } from '../../pin-panel/pin-record.service'
import { switchMap } from 'rxjs/operators'
import {
    RecordFacadeService,
    SchemaFacadeService,
    FieldTypeFacadeService,
    FolderFacadeService,
} from '../../@core/services/store-facade'
import { SelectObjectOptions } from '../../@core/models/response/select-object-options'

export interface DialogCardInput {
    recordGuid: string
    isFolder: boolean
}

@UntilDestroy()
@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.sass'],
})
export class CardComponent implements OnInit {
    folderStatuses!: { folderStatus: FolderStatus; field: Field }[]
    record!: BusinessRecords
    schema!: Schema
    folderStatusesArr!: FolderStatus[]
    fields?: FieldEntities
    fieldTypes$: Observable<Dictionary<FieldType>> =
        this.fieldTypeFacadeService.selectFieldTypeEntities$
    isRecordPinned: boolean = false
    selectedFolder!: Folder

    cells!: CellEntities

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogCardInput,
        public pinRecordService: PinRecordService,
        private dialogRef: MatDialogRef<BoardCardComponent, CellEntities | undefined>,
        private recordFacadeService: RecordFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private folderFacadeService: FolderFacadeService,
    ) {}

    ngOnInit(): void {
        if (this.data.isFolder) {
            this.selectCardData().subscribe(
                ([record, folder, folders, folderNames, fields, schema]) => {
                    if (!record || !folder || !fields || !schema) return

                    this.selectedFolder = folder
                    this.schema = schema

                    this.record = record
                    this.cells = getRecordCells(record)

                    this.isRecordPinned = this.pinRecordService.isRecordPinned(this.record!.guid)

                    let folderGuids = record.folder_guids?.split(',')

                    const globalFolderGuid: string | undefined = Object.keys(folderNames).find(
                        (guid) => folderNames[guid]?.is_global,
                    )

                    if (!globalFolderGuid) {
                        console.log(new Error('no globalFolder guid in folder object'))
                    }

                    if (globalFolderGuid) {
                        if (!folderGuids) {
                            folderGuids = [globalFolderGuid]
                        } else if (!folderGuids.includes(globalFolderGuid)) {
                            folderGuids.push(globalFolderGuid)
                        }
                    }

                    const statusFields = Object.values(fields).filter(
                        (field) => field.field_type_code === FieldTypes.STATUS,
                    )

                    if (statusFields && folderGuids) {
                        this.fields = fields
                        this.folderStatuses = this.setFolderStatuses(
                            folderGuids,
                            folders,
                            statusFields,
                        )

                        if (!this.folderStatuses.length) {
                            this.folderStatuses = []
                            console.warn(new Error('empty folder statuses'))
                        }

                        this.sortFolderStatuses()
                    }

                    this.folderStatusesArr = this.folderStatuses.map(
                        (status) => status.folderStatus,
                    )
                },
            )
        } else {
            this.selectCardDataForUndefinedFolder()
        }
    }

    closeDialog() {
        this.dialogRef.close()
    }

    pinRecord() {
        const guid = this.record.guid

        if (this.pinRecordService.isRecordPinned(guid)) {
            this.pinRecordService.removePinRecord(guid)
        } else {
            this.pinRecordService.pinRecord(guid, this.record.name.value)
        }

        this.closeDialog()
    }

    private selectCardData() {
        return combineLatest([
            this.recordFacadeService.selectRecordById$(this.data.recordGuid),
            this.folderFacadeService.selectSelectedFolder$,
        ]).pipe(
            switchMap(([record, folder]) => {
                if (!record || !folder) return of([])
                return combineLatest([
                    of(record),
                    of(folder),
                    this.folderFacadeService.selectFolderEntities$,
                    this.folderFacadeService.selectFolderNames$,
                    this.schemaFacadeService.selectSchemaFieldsByFolder$(record.schemaGuid, folder),
                    this.schemaFacadeService.selectSchemaByGuid$(record.schemaGuid),
                ])
            }),
            untilDestroyed(this),
        )
    }

    private selectCardDataForUndefinedFolder() {
        this.recordFacadeService
            .selectRecordById$(this.data.recordGuid)
            .pipe(
                switchMap((record) => {
                    if (!record) return of([record])

                    return combineLatest([
                        of(record),
                        this.schemaFacadeService.selectSchemaByGuid$(record.schemaGuid),
                    ])
                }),
                untilDestroyed(this),
            )
            .subscribe(([record, schema]) => {
                if (!record || !schema) return

                this.fields = schema.fieldEntities
                this.schema = schema
                this.record = record
                this.cells = getRecordCells(record)
                this.isRecordPinned = this.pinRecordService.isRecordPinned(this.record!.guid)
            })
    }

    private sortFolderStatuses() {
        this.folderStatuses.sort((a, b) => {
            if (a.folderStatus.is_global) return -1
            else if (b.folderStatus.is_global) return 1
            else return Number(b.folderStatus.selected) - Number(a.folderStatus.selected)
        })
    }

    private setFolderStatuses(
        folderGuids: string[],
        folders: Dictionary<Folder>,
        statusFields: Field[],
    ) {
        return folderGuids
            .filter((guid) => guid)
            .map((guid) => folders[guid])
            .filter((folder): folder is Folder => !!folder)
            .map((folder) => {
                const statusField = statusFields.find(
                    (field) =>
                        (field.folder_guid?.includes(folder!.guid) ||
                            (getBoolean(folder.is_global.value) && !field.folder_guid)) &&
                        field.field_type_code === FieldTypes.STATUS,
                )
                if (statusField) {
                    //todo: remove mapped global field
                    const statusOptionGuid = this.record.status[this.selectedFolder.guid].value

                    const folderName = folder.name.value

                    if (folderName && statusField.select_object_field) {
                        const folderStatus: FolderStatus = this.setFolderStatus(
                            folder,
                            folderName,
                            statusOptionGuid,
                            statusField.select_object_field,
                        )
                        return { folderStatus, field: statusField }
                    }
                }
                return
            })
            .filter((status): status is { folderStatus: FolderStatus; field: Field } => !!status)
    }

    private setFolderStatus(
        folder: Folder,
        folderName: string,
        statusOptionGuid: string,
        selectObjects: SelectObjectOptions,
    ) {
        return {
            folderGuid: folder.guid,
            folderName: folderName,
            selected: folder!.guid === this.selectedFolder.guid,
            status: statusOptionGuid
                ? selectObjects[statusOptionGuid]
                : {
                      color: '#909090',
                      label: 'unknown',
                      tags: 'unavailable',
                  },
            is_global: getBoolean(folder.is_global.value),
        }
    }
}
