import { Injectable } from '@angular/core'
import {
    addRecordsFromResponse,
    AppState,
    deleteRecordsFromResponse,
    initRecords,
    NO_GROUPED_RECORDS_KEY,
    resetRecords,
    selectAllRecords,
    selectRecordEntities,
    selectSchemaEntities,
    selectSelectedFolder,
    selectSelectedView,
    selectSelectedTableSchemaDataRecords,
    selectSelectedTableSchema,
    selectTableDataBySchemasAssignedCurrenUser,
    updateRecordsFromResponse,
} from '../../@ngrx'
import { Store } from '@ngrx/store'
import { BehaviorSubject, combineLatest, map } from 'rxjs'
import {
    AppRecord,
    BusinessRecords,
    ColGroup,
    Deleted,
    Field,
    FieldEntities,
    FieldTypes,
    findFieldByType,
    Folder,
    getRecordCells,
    RecordGroup,
    Schema,
    View,
} from '../../models'
import { Dictionary, Update } from '@ngrx/entity'
import { FolderFacadeService } from './folder-facade.service'
import { SchemaFacadeService } from './schema-facade.service'
import { FilterService } from '../../../views/view-controls/filter/filter.service'
import { SelectObjectOptions } from '../../models/response/select-object-options'
import { take } from 'rxjs/operators'
import { FilterStorageService } from '../session-storage/filter-storage.service'
import { GroupStorageService } from '../session-storage/group-storage.service'
import { GroupService } from '../../../views/view-controls/group/group.service'
import { getBoolean } from '../../global-util'

interface NewRecordCreateData {
    assignee: Field
    watch: Field
    name: Field
    status: Field
    schema: Schema
}

@Injectable({
    providedIn: 'root',
})
export class RecordFacadeService {
    constructor(
        private store: Store<AppState>,
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private filterService: FilterService,
        private filterStorageService: FilterStorageService,
        private groupService: GroupService,
        private groupStorageService: GroupStorageService,
    ) {}

    private readonly refreshViewData$$ = new BehaviorSubject<void>(undefined)

    selectRecordEntities$ = this.store.select(selectRecordEntities)

    selectAllRecords$ = this.store.select(selectAllRecords)

    refreshViewData$ = this.refreshViewData$$.asObservable()

    refreshViewData() {
        this.refreshViewData$$.next()
    }

    selectViewData$ = () => {
        return combineLatest([
            this.store.select(selectSelectedTableSchema),
            this.store.select(selectSelectedTableSchemaDataRecords),
            this.store.select(selectSelectedView),
            this.store.select(selectSchemaEntities),
            this.store.select(selectSelectedFolder),
            this.refreshViewData$,
        ]).pipe(
            map(([schema, records, view, allSchemas, folder]) => {
                if (!schema || !view || !folder) return

                console.log('schema', schema)
                console.log('records', records)

                // TODO: [table-ref] I would keep only one Data Structure
                let data: BusinessRecords[] | Map<string, RecordGroup> = this.filterRecords(
                    view,
                    records,
                )

                let first_column!: string
                const columnsOrderString = view.columns_order.value
                const pinedColumnsString = view.columns_pinned.value
                const hiddenColumnsString = view.columns_hide.value
                const columnsWidthString = view.columns_width.value

                const columnsWidth: { [p: string]: number } | undefined = columnsWidthString
                    ? JSON.parse(columnsWidthString)
                    : undefined

                const hiddenColumns: string[] = hiddenColumnsString
                    ? hiddenColumnsString.split(',')
                    : []
                const pinnedColumns: string[] = pinedColumnsString
                    ? pinedColumnsString.split(',')
                    : []
                let columnsOrder: string[] = columnsOrderString ? columnsOrderString.split(',') : []

                Object.keys(schema.fieldEntities).forEach((fieldGuid: string) => {
                    if (schema.fieldEntities[fieldGuid].field_type_code === 'field_type_name') {
                        first_column = schema.fieldEntities[fieldGuid].guid
                    }

                    if (!columnsOrder.includes(fieldGuid)) {
                        columnsOrder.push(fieldGuid)
                    }
                })

                const pinnedNotHide: string[] = pinnedColumns.filter(
                    (item) => !hiddenColumns.includes(item),
                )
                const displayColumns: string[] = columnsOrder.filter(
                    (item) => !hiddenColumns.includes(item) && !pinnedNotHide.includes(item),
                )
                columnsOrder = pinnedColumns.concat(
                    columnsOrder.filter((item) => !pinnedColumns.includes(item)),
                )
                const columns: string[] = pinnedNotHide.concat(displayColumns)

                let sortedColumns: string[] = []
                let sortedFields: FieldEntities = {}

                columns.concat(hiddenColumns).forEach((guid) => {
                    if (schema.fieldEntities[guid]) {
                        if (
                            schema.fieldEntities[guid].folder_guid &&
                            !getBoolean(folder.is_global.value)
                        ) {
                            if (
                                schema.fieldEntities[guid].folder_guid === folder.guid ||
                                schema.fieldEntities[guid].shared_with_folder?.includes(
                                    folder.guid,
                                ) ||
                                schema.fieldEntities[guid].folder_name?.is_global
                            ) {
                                if (!hiddenColumns.includes(guid)) sortedColumns.push(guid)
                                sortedFields[guid] = schema.fieldEntities[guid]
                            }
                        } else {
                            if (!hiddenColumns.includes(guid)) sortedColumns.push(guid)
                            sortedFields[guid] = schema.fieldEntities[guid]
                        }
                    }
                })

                sortedColumns.splice(sortedColumns.indexOf(first_column), 1)
                sortedColumns.splice(0, 0, first_column)

                const groupFieldGuid = this.groupService.getGroupByView(view)
                this.groupStorageService.isSessionGroupSetUpdate(view)
                data = this.generateGroup(
                    data,
                    groupFieldGuid,
                    schema.fieldEntities[groupFieldGuid],
                )

                return {
                    fields: sortedFields,
                    data,
                    selectedView: view,
                    columns: {
                        columns: sortedColumns,
                        columnsOrder,
                        hiddenColumns,
                        pinnedColumns,
                        columnsWidth,
                        colGroups: this.generateColGroup(
                            sortedColumns,
                            schema.fieldEntities,
                            allSchemas,
                        ),
                    },
                }
            }),
        )
    }

    selectTableDataBySchemasAssignedCurrenUser$ = this.store.select(
        selectTableDataBySchemasAssignedCurrenUser,
    )

    selectRecordsBySchemaId(schemaGuid: string) {
        return this.selectAllRecords$.pipe(
            take(1),
            map((records) => records.filter((record) => record.schemaGuid === schemaGuid)),
        )
    }

    selectDataTableRecordsBySchemaGuid$ = (schemaGuid: string) => {
        return this.selectAllRecords$.pipe(
            map((records: BusinessRecords[]) => {
                if (!schemaGuid) {
                    return []
                }
                return records.filter((record) => record.schemaGuid === schemaGuid)
            }),
        )
    }

    selectRecordById$ = (recordGuid: string) => {
        return this.selectRecordEntities$.pipe(
            map((records: Dictionary<BusinessRecords>) => records[recordGuid]),
        )
    }

    selectNewRecordCreateData$ = (folderGuid: string, type: string) => {
        return combineLatest([
            this.folderFacadeService.selectFolderEntities$,
            this.schemaFacadeService.selectSchemaEntities$,
        ]).pipe(
            map(([folderDictionary, dictionarySchema]) => {
                if (!folderDictionary || !dictionarySchema) {
                    return undefined
                }
                const selectedFolder = folderDictionary[folderGuid]!
                const fields = Object.keys(dictionarySchema)
                    .filter((guid) => dictionarySchema[guid]?.object_type_code === type)
                    .map((guid) => dictionarySchema[guid]?.fieldEntities)[0] as FieldEntities
                return this.recordDataObject(selectedFolder, fields, dictionarySchema, type)
            }),
        )
    }

    initRecords(records: BusinessRecords[]) {
        this.store.dispatch(initRecords({ records }))
    }

    resetRecords() {
        this.store.dispatch(resetRecords())
    }

    addRecordsFromResponse(records: BusinessRecords[]) {
        this.store.dispatch(addRecordsFromResponse({ records }))
    }

    updateRecordsFromResponse(records: Update<AppRecord>[]) {
        this.store.dispatch(updateRecordsFromResponse({ records }))
    }

    deleteRecordsFromResponse(records: Deleted[]) {
        this.store.dispatch(deleteRecordsFromResponse({ records }))
    }

    private recordDataObject(
        folder: Folder,
        fields: FieldEntities,
        schemas: Dictionary<Schema>,
        type: string,
    ): NewRecordCreateData | undefined {
        const schemaGuid = Object.keys(schemas).find(
            (guid) => schemas[guid]?.object_type_code === type,
        )
        if (!schemaGuid) return

        const selectedSchema = schemas[schemaGuid]
        if (!selectedSchema) return

        return {
            watch: findFieldByType(folder, fields, FieldTypes.WATCH),
            status: findFieldByType(folder, fields, FieldTypes.STATUS),
            assignee: findFieldByType(folder, fields, FieldTypes.ASSIGNEE),
            name: findFieldByType(folder, fields, FieldTypes.NAME),
            schema: selectedSchema,
        }
    }

    private filterRecords(view: View, records: BusinessRecords[]) {
        const filterGroups = this.filterService.getFilterGroupByView(view)
        this.filterStorageService.isSessionFilterSetUpdate(view)
        let filteredRecords: BusinessRecords[] | Map<string, RecordGroup> = records
        if (filterGroups?.length) {
            filteredRecords = this.filterService.applyFilter(filterGroups, records)
        }
        return filteredRecords
    }

    generateGroup(data: BusinessRecords[], groupFieldGuid: string, field: Field) {
        if (!groupFieldGuid || !field) return data
        const reducedData = data.reduce(
            (map: Map<string, RecordGroup>, record: BusinessRecords) => {
                const cells = getRecordCells(record)
                if (!cells[groupFieldGuid]) return map

                let groupFieldValue: string
                if (field.select_object_field) {
                    groupFieldValue = Object.keys(
                        field.select_object_field as SelectObjectOptions,
                    ).includes(cells[groupFieldGuid].value)
                        ? cells[groupFieldGuid].value
                        : NO_GROUPED_RECORDS_KEY
                } else {
                    groupFieldValue =
                        cells[groupFieldGuid].value !== ''
                            ? cells[groupFieldGuid].value
                            : NO_GROUPED_RECORDS_KEY
                }

                if (groupFieldValue.includes(',')) {
                    groupFieldValue = groupFieldValue.split(',').sort().join()
                }
                const group = map.get(groupFieldValue)
                if (!group) {
                    map.set(groupFieldValue, {
                        data: [record],
                        field: field,
                        value: groupFieldValue,
                    })
                } else {
                    group.data.push(record)
                }

                return map
            },
            new Map(),
        )

        return this.generateEmptyStatusGroups(field, reducedData)
    }

    private generateColGroup(
        columns: string[],
        fields: FieldEntities,
        allSchemas: Dictionary<Schema>,
    ) {
        return columns.reduce((acc, fieldGuid) => {
            const colGropedFields: string[] = [FieldTypes.LINK]

            if (colGropedFields.includes(String(fields[fieldGuid].field_type_code))) {
                const schemaGuid =
                    fields[fieldGuid].link_definition?.target_solution_object_type_guid
                const fieldGuids = fields[fieldGuid].link_definition?.target_object_field_guids

                if (!fieldGuids?.length || !schemaGuid || !allSchemas[schemaGuid]) return acc

                return {
                    ...acc,
                    [fieldGuid]: {
                        colspan: fieldGuids?.length,
                        subheaders: fieldGuids.map(
                            (guid) =>
                                allSchemas[schemaGuid]!.fieldEntities[guid]?.name || 'unknown',
                        ),
                    },
                }
            }

            return acc
        }, {} as { [colGuid: string]: ColGroup })
    }
    private generateEmptyStatusGroups(field: Field, data: Map<string, RecordGroup>) {
        const options = field.select_object_field
        if (!options) return data

        return Object.keys(options).reduce((map, key) => {
            const group = map.get(key)
            if (group) return map
            map.set(key, {
                data: [],
                field: field,
                value: key,
            })
            return map
        }, new Map(data))
    }
}
