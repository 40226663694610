import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Field, FieldEntities, FieldType, OperationCode } from '../../../../@core/models'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Dictionary } from '@ngrx/entity'

@UntilDestroy()
@Component({
    selector: 'app-column-menu',
    templateUrl: './column-menu.component.html',
    styleUrls: ['./column-menu.component.sass'],
})
export class ColumnMenuComponent implements OnInit {
    @Input() field!: Field
    @Input() guid!: string
    @Input() visibleFieldGuids!: string[]
    @Input() hiddenFieldGuids?: string[]
    @Input() isPinned!: boolean
    @Input() isPinAllowed!: boolean
    @Input() fields!: FieldEntities
    @Input() fieldTypes!: Dictionary<FieldType>
    @Input() columnsOrder?: string[]
    @Input() isCustomWidth!: boolean

    @Output() moveFieldToFolder: EventEmitter<any> = new EventEmitter<any>()
    @Output() sortColumn: EventEmitter<any> = new EventEmitter()
    @Output() moveColumnToStart: EventEmitter<any> = new EventEmitter()
    @Output() moveColumnToEnd: EventEmitter<any> = new EventEmitter()
    @Output() hideColumn: EventEmitter<any> = new EventEmitter()
    @Output() updateField: EventEmitter<any> = new EventEmitter()
    @Output() copyColumn: EventEmitter<any> = new EventEmitter()
    @Output() deleteColumn: EventEmitter<any> = new EventEmitter()
    @Output() pinColumn: EventEmitter<any> = new EventEmitter()
    @Output() unPinColumn: EventEmitter<any> = new EventEmitter()
    @Output() shareWith: EventEmitter<any> = new EventEmitter<any>()
    @Output() resetWidth: EventEmitter<any> = new EventEmitter<any>()

    // todo: [table-ref] create some directive that would handle all operation code operations
    operationCode?: OperationCode

    get isUpdateAvailable(): boolean | undefined {
        return this.operationCode?.update
    }

    get isDeleteAvailable(): boolean | undefined {
        return this.operationCode?.delete
    }

    get canInsertRight() {
        return this.isFirstColumn || this.isMiddleColumn
    }

    get canInsertLeft() {
        return this.isLastColumn || this.isMiddleColumn
    }

    get isPinAvailable() {
        return !this.isFirstColumn && !this.isPinAllowed && !this.isPinned
    }

    // todo: instead of this getter pass index of the field
    get isFirstColumn() {
        return this.visibleFieldGuids[0] === this.field.guid
    }

    get isMiddleColumn() {
        return (
            this.visibleFieldGuids[this.visibleFieldGuids.length - 1] !== this.field.guid &&
            this.visibleFieldGuids[0] !== this.field.guid
        )
    }

    get isLastColumn() {
        return this.visibleFieldGuids.slice(-1)[0] === this.field.guid
    }

    get isShareAvailable() {
        return !this.field.folder_name?.is_global
    }
    get isMoveAvailable() {
        return (
            this.operationCode?.multiple &&
            !this.operationCode?.freeze &&
            !this.field.folder_name?.is_global
        )
    }

    get isSystemOrUniqueField() {
        if (!this.operationCode) return false

        return this.operationCode.system || !this.operationCode.multiple
    }

    ngOnInit() {
        if (!this.field.operationCode) {
            this.operationCode = this.fieldTypes[this.field.field_type_code]?.operationCode
        } else {
            this.operationCode = this.field.operationCode
        }
    }
}
