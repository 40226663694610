import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { CurrentUser, Folder, Schema, View } from '../../../../@core/models'
import { filter, Observable } from 'rxjs'
import { isNonNull } from '../../../../@core/global-util'
import { NavigatorService } from '../../../../@core/services/navigator.service'
import { RecordsService } from '../../../../@core/services/records.service'
import {
    FolderFacadeService,
    SchemaFacadeService,
    UserFacadeService,
    ViewFacadeService,
} from '../../../../@core/services/store-facade'
import { MatBottomSheet } from '@angular/material/bottom-sheet'

@UntilDestroy()
@Component({
    selector: 'app-views-list-menu',
    templateUrl: './views-list-menu.component.html',
    styleUrls: ['./views-list-menu.component.sass'],
})
export class ViewsListMenuComponent implements OnInit {
    //copied for component testing
    views!: View[]
    selectedView!: View
    isViewMenuOpened!: boolean
    user!: CurrentUser

    @Input() viewsKey!: any
    @ViewChild('templateBottomSheetEdit') TemplateBottomSheetEdit!: TemplateRef<any>

    readonly selectedFolder$: Observable<Folder> =
        this.folderFacadeService.selectSelectedFolder$.pipe(filter(isNonNull))

    constructor(
        private navigatorService: NavigatorService,
        private recordsService: RecordsService,
        private folderFacadeService: FolderFacadeService,
        private userFacadeService: UserFacadeService,
        private viewFacadeService: ViewFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private bottomSheet: MatBottomSheet,
    ) {}

    openBottomSheetEdit(e: Event) {
        e.stopPropagation()
        this.bottomSheet.open(this.TemplateBottomSheetEdit, { panelClass: 'bottomSheetCustom' })
    }

    ngOnInit(): void {
        this.isViewMenuOpened = false
        this.viewFacadeService.selectSelectedWorkspaceViews$
            .pipe(untilDestroyed(this))
            .subscribe((views) => {
                this.views = views
            })

        this.viewFacadeService.selectSelectedView$
            .pipe(untilDestroyed(this), filter(isNonNull))
            .subscribe((view) => {
                this.selectedView = view
            })

        this.userFacadeService.selectCurrentUser$
            .pipe(untilDestroyed(this), filter(isNonNull))
            .subscribe((user) => {
                this.user = user
            })
    }

    navigate(view: View) {
        this.navigatorService.goToView(view)
    }

    IsSelected(view: View): boolean {
        return view.guid === this.selectedView.guid
    }
}
