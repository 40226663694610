import { Component, Input } from '@angular/core'
import { ModalContainer, ModalManagerService } from '../modal-manager.service'

@Component({
    template: '',
})
export class ModalContainerComponent {
    @Input()
    containerLayout!: ModalContainer

    constructor(protected modalManagerService: ModalManagerService) {}

    close(data?: unknown) {
        this.modalManagerService.close(data)
    }
}
