import { Component, OnInit } from '@angular/core'
import { RatingConfig } from '../../../../../@core/models'
import { FilterTypesComponent } from '../filter-types.component'

@Component({
    selector: 'app-rating-filter',
    templateUrl: './rating-filter.component.html',
    styleUrls: ['./rating-filter.component.sass'],
})
export class RatingFilterComponent extends FilterTypesComponent implements OnInit {
    selectValue!: number
    countStarsInArray!: number[]
    sliderValue: number = 0

    ngOnInit(): void {
        if (this.field.configure_json) {
            const ratingConfig: RatingConfig = JSON.parse(this.field.configure_json)
            if (ratingConfig) {
                this.sliderValue = ratingConfig.count
            } else {
                this.sliderValue = 0
            }
        }
        this.selectValue = Number(this.form.controls['value']?.value)
        this.getArrayFromValue(this.sliderValue)
    }

    getArrayFromValue(val: number) {
        this.countStarsInArray = [...Array(Number(val)).keys()]
    }

    setRating(val: number) {
        const changedValue = String(val)
        this.form.controls['value'].setValue(changedValue)
        this.form.controls['value'].markAsTouched()
    }

    changeHoverStyles(event: any, val: number) {
        this.selectValue = val
    }

    backHoverStyles() {
        this.selectValue = Number(this.form.controls['value']?.value)
    }
}
