<div class="sm:pt-4 sm:pb-6 sm:px-1 sm:w-screen max-w-[568px] sm:max-w-[80vw]" *transloco="let t;">
    <div class="py-0 px-5 max-sm:px-0 mb-5 max-sm:pt-4 text-xl font-semibold text-primaryDark">
        <div class="flex">
            {{t('add_field')}}
            <div class="flex absolute sm:top-[8px] top-[16px] sm:right-[6px] right-[12px]">
                <mat-icon
                    mat-dialog-close
                    class="flex items-center w-5 h-5 box-content p-0.5 text-xl leading-4 rounded hover:bg-primaryLight cursor-pointer"
                >
                    close
                </mat-icon>
            </div>
        </div>
    </div>

    <div
        class="overflow-auto sm:max-h-[calc(100vh-180px)] max-h-[calc(100vh-395px)] py-0 px-5 max-sm:px-0"
    >
        <div class="flex flex-wrap gap-4 mb-2">
            <!--            these should be dynamically inserted, all text starting with $ and the icon shoul dbe dynamic as well-->
            <div
                class="flex flex-col items-center justify-between w-[176px] gap-2 py-5 px-4 rounded-2xl shadow-light cursor-pointer"
            >
                <mat-icon class="h-10 w-10 text-4xl leading-10 text-primarySecondary"
                    >add_circle_outline</mat-icon
                >
                <div class="leading-6 font-semibold">$Name</div>
                <div class="text-center text-xs text-primarySecondary">$Description</div>
            </div>
        </div>
    </div>
</div>
