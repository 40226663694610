import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { FilterTypesComponent } from '../filter-types.component'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-url',
    templateUrl: './url-filter.component.html',
    styleUrls: ['./url-filter.component.sass'],
})
export class UrlFilterComponent extends FilterTypesComponent implements OnInit {
    textFormControl!: FormControl

    ngOnInit(): void {
        this.textFormControl = this.form.controls['value'] as FormControl
    }
}
