<div class="flex-start-center">
    <form>
        <mat-checkbox (change)="checkItemField()" [formControl]="checkItemControl">
            <app-field-name
                [field]="field"
                [selectedFolder]="selectedFolder"
                [showIcon]="false"
            ></app-field-name>
        </mat-checkbox>
    </form>
</div>
