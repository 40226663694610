import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    Renderer2,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'

import { Field, FieldTypes } from '../../../../@core/models'
import { StatusFilterComponent } from './status-filter/status-filter.component'
import { DropdownFilterComponent } from './dropdown-filter/dropdown-filter.component'
import { AssignFilterComponent } from './assign-filter/assign-filter.component'
import { MoneyFilterComponent } from './money-filter/money-filter.component'
import { RatingFilterComponent } from './rating-filter/rating-filter.component'
import { NumberFilterComponent } from './number-filter/number-filter.component'
import { TextOneLineFilterComponent } from './text_one_line-filter/text_one_line-filter.component'
import { EmailFilterComponent } from './email-filter/email-filter.component'
import { UrlFilterComponent } from './url-filter/url-filter.component'
import { NameFilterComponent } from './name-filter/name-filter.component'
import { BooleanFilterComponent } from './boolean-filter/boolean-filter.component'
import { FormGroup } from '@angular/forms'

export const filterFieldTypesComponents: { [key: string]: Type<any> } = {
    [FieldTypes.TEXT]: TextOneLineFilterComponent,
    [FieldTypes.NUMBER]: NumberFilterComponent,
    [FieldTypes.STATUS]: StatusFilterComponent,
    [FieldTypes.DROPDOWN]: DropdownFilterComponent,
    [FieldTypes.ASSIGNEE]: AssignFilterComponent,
    [FieldTypes.PEOPLE]: AssignFilterComponent,
    [FieldTypes.BOOL]: BooleanFilterComponent,
    [FieldTypes.RATING]: RatingFilterComponent,
    [FieldTypes.EMAIL]: EmailFilterComponent,
    [FieldTypes.MONEY]: MoneyFilterComponent,
    [FieldTypes.MULTILINE_TEXT]: TextOneLineFilterComponent,
    [FieldTypes.NAME]: NameFilterComponent,
    [FieldTypes.WEBSITE]: UrlFilterComponent,
    [FieldTypes.WATCH]: AssignFilterComponent,
}

@Component({
    selector: 'app-filter-types-container',
    templateUrl: './filter-types-container.component.html',
})
export class FilterTypesContainerComponent implements AfterViewInit, OnChanges, OnDestroy {
    @Input()
    field!: Field

    @Input()
    form!: FormGroup

    @ViewChild('filterArea', { read: ViewContainerRef }) filterArea!: ViewContainerRef
    cmpRef!: ComponentRef<any>
    private isViewInitialized: boolean = false

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private cdr: ChangeDetectorRef,
        private _renderer: Renderer2,
    ) {}

    ngOnChanges() {
        this.updateComponent()
    }

    updateComponent() {
        if (!this.isViewInitialized) {
            return
        }
        if (this.cmpRef) {
            this.cmpRef.destroy()
        }
        const componentName = filterFieldTypesComponents[this.field.field_type_code]
        if (componentName) {
            const componentFactory =
                this.componentFactoryResolver.resolveComponentFactory(componentName)
            this.cmpRef = this.filterArea.createComponent(componentFactory)

            this.cmpRef.instance.field = this.field
            this.cmpRef.instance.form = this.form
        } else {
            console.log(this.field.field_type_code)
        }
    }

    ngAfterViewInit() {
        this.isViewInitialized = true
        this.updateComponent()
        this.cdr.detectChanges()
    }

    ngOnDestroy() {
        if (this.cmpRef) {
            this.cmpRef.destroy()
        }
    }
}
