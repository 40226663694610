import { Component, OnInit } from '@angular/core'
import {
    Field,
    FieldEntities,
    FieldTypes,
    Folder,
    getIconByFieldType,
    View,
} from '../../../@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { combineLatest, of, switchMap } from 'rxjs'
import {
    FolderFacadeService,
    RecordFacadeService,
    SchemaFacadeService,
    ViewFacadeService,
} from '../../../@core/services/store-facade'
import { GroupStorageService } from '../../../@core/services/session-storage/group-storage.service'
import { GroupService } from './group.service'
import { TbMenuState } from '../../../@components-library/tb-menu'

@UntilDestroy()
@Component({
    selector: 'app-group-by',
    templateUrl: './group.component.html',
})
export class GroupByComponent implements OnInit {
    groupFieldGuid?: string
    fieldEntities?: FieldEntities
    selectedFolder!: Folder
    selectedView!: View

    menuState: TbMenuState = TbMenuState.Closed

    protected readonly TbMenuStateEnum = TbMenuState

    constructor(
        private viewFacadeService: ViewFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private folderFacadeService: FolderFacadeService,
        private recordFacadeService: RecordFacadeService,
        private groupStorageService: GroupStorageService,
        private groupService: GroupService,
    ) {}

    get icon() {
        const fieldTypeGuid = this.groupField?.field_type_code ?? ''
        return getIconByFieldType(fieldTypeGuid)
    }

    get groupField(): Field | undefined {
        if (this.fieldEntities && this.groupFieldGuid) {
            return this.fieldEntities[this.groupFieldGuid]
        }
        return
    }

    get fields(): Field[] | undefined {
        if (this.fieldEntities) {
            return Object.values(this.fieldEntities)
                .filter(
                    (field) =>
                        field.field_type_code === FieldTypes.DROPDOWN ||
                        field.field_type_code === FieldTypes.ASSIGNEE ||
                        field.field_type_code === FieldTypes.STATUS ||
                        field.field_type_code === FieldTypes.BOOL ||
                        field.field_type_code === FieldTypes.PEOPLE ||
                        field.field_type_code === FieldTypes.RATING,
                )
                .filter((field) => !field.operationCode?.system)
        }
        return
    }

    ngOnInit(): void {
        this.folderFacadeService.selectSelectedFolder$
            .pipe(
                untilDestroyed(this),
                switchMap((folder) => {
                    return combineLatest([
                        of(folder),
                        this.schemaFacadeService.selectSelectedTableSchemaFieldEntitiesFiltered$(
                            folder!,
                        ),
                    ])
                }),
            )
            .subscribe(([folder, fields]) => {
                this.selectedFolder = folder as Folder
                this.fieldEntities = fields
            })

        combineLatest([
            this.viewFacadeService.selectSelectedView$,
            this.recordFacadeService.refreshViewData$,
        ])
            .pipe(untilDestroyed(this))
            .subscribe(([view]) => {
                if (!view) return

                this.selectedView = view
                this.groupFieldGuid = this.groupService.getGroupByView(view)
                this.groupStorageService.isSessionGroupSetUpdate(view)
            })
    }

    groupValueSaved(guid: string) {
        this.groupService.saveGroupData(this.selectedView, guid)
    }

    onMenuStateChanged(menuState: TbMenuState) {
        this.menuState = menuState
    }
}
