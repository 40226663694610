import { Component, Input } from '@angular/core'
import { Field, FieldType } from '../../../../@core/models'

@Component({
    selector: 'app-board-card-status',
    templateUrl: './board-card-status.component.html',
    styleUrls: ['./board-card-status.component.sass'],
})
export class BoardCardStatusComponent {
    @Input()
    status!: Field | undefined

    @Input()
    fieldType?: FieldType

    @Input()
    value?: string
}
