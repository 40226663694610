import { WsService } from './ws.service'
import { Injectable } from '@angular/core'
import { ActionReducer } from '@ngrx/store'
import { BreakpointState } from '@angular/cdk/layout'

type WindowLog = Window &
    typeof globalThis & {
        logService: {
            wsLog: () => void
        }
    }

@Injectable({
    providedIn: 'root',
})
export class LogService {
    constructor(private ws: WsService) {}

    static logStoreActions(reducer: ActionReducer<any>): ActionReducer<any> {
        return function (state, action) {
            LogService.printDebug('action', action, 'state', state)
            return reducer(state, action)
        }
    }

    wsLog() {
        this.ws.sendMessage('loggingView')
    }

    private static printError(error: Error) {
        console.error(error)
    }

    private static printDebug(...params: any) {
        console.debug(...params)
    }

    init() {
        const windowLog = <WindowLog>window
        windowLog.logService = {
            wsLog: this.wsLog.bind(this),
        }
    }

    resolutionChanged(breakpointState: BreakpointState) {
        LogService.printDebug(breakpointState)
    }

    componentError(error: Error) {
        LogService.printError(error)
    }
}
