import { Cell, TypeRelatedCellEntities } from './cell.model'
import { AppRecord } from './base-record.model'

export interface View extends AppRecord {
    name: Cell
    type_code: Cell
    columns_order: Cell
    columns_pinned: Cell
    columns_hide: Cell
    columns_width: Cell
    group: Cell
    filter: Cell
    icon: string
}

export enum ViewTypeCodes {
    BOARD = 'kanban',
    TABLE = 'table',
    LIST = 'list',
}

export const viewTypeCodesIcons: { [key: string]: string } = {
    [ViewTypeCodes.BOARD]: 'view_quilt',
    [ViewTypeCodes.TABLE]: 'table_view',
    [ViewTypeCodes.LIST]: 'contacts',
}

export function generateViewFields(type: string): TypeRelatedCellEntities {
    return {
        icon: viewTypeCodesIcons[type],
    }
}
