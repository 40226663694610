export const environment = {
    production: false,
    env: 'dev',
    api_host: 'http://10.0.0.129:80',
    version: {
        branch: import.meta?.env?.NG_APP_BRANCH_NAME,
        commit: import.meta?.env?.NG_APP_COMMIT,
        date: import.meta?.env?.NG_APP_DATE,
    },
    ws: {
        endpoints: {
            on_update: 'send',
            init: 'initialize',
            log: 'loggingView',
            create_field: 'fieldCreate',
            update_field: 'fieldUpdate',
            delete_field: 'fieldDelete',
            create_record: 'recordCreate',
            update_record: 'recordUpdate',
            delete_record: 'recordDelete',
            create_folder: 'folderCreate',
            update_link: 'linkOperation',
            create_config: 'configurationCreate',
            update_config: 'configurationUpdate',
            delete_config: 'configurationDelete',
            reset: 'reset',
        },
        response_types: {
            init: 'initialize',
            record_update: 'object.record.update',
            field_update: 'solution.object.field',
            record_delete: 'object.record.delete',
            solution_reset: 'solution.reset',
            folder_create: 'solution.object.folder.create',
            folder_delete: 'solution.object.folder.delete',
            config_create: 'solution.object.configuration.create',
            config_update: 'solution.object.configuration.update',
            config_delete: 'solution.object.configuration.delete',
        },
        status: {
            success: 'success',
            error: 'error',
        },
    },
}
