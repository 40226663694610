<ng-container *ngIf="operationCode; else operationCodeError">
    <ng-container *transloco="let tTableColumnMenu; read: 'table.column_menu'">
        <button (click)="sortColumn.emit(field.guid)" mat-menu-item>
            <mat-icon>sort</mat-icon>
            <span>{{tTableColumnMenu('sort')}}</span>
        </button>
        <!--            todo: [table-ref] templates are the same-->
        <button (click)="pinColumn.emit(field.guid)" *ngIf="isPinAvailable" mat-menu-item>
            <mat-icon>pin_drop</mat-icon>
            <span>{{tTableColumnMenu('pin')}}</span>
        </button>

        <button *ngIf="isPinned" (click)="unPinColumn.emit(field.guid)" mat-menu-item>
            <mat-icon>pin_drop</mat-icon>
            <span>{{tTableColumnMenu('unpin')}}</span>
        </button>

        <button (click)="moveColumnToStart.emit(field.guid)" mat-menu-item>
            <mat-icon>drag_indicator</mat-icon>
            <span>{{tTableColumnMenu('start_move')}}</span>
        </button>
        <button (click)="moveColumnToEnd.emit(field.guid)" mat-menu-item>
            <mat-icon>drag_indicator</mat-icon>
            <span>{{tTableColumnMenu('end_move')}}</span>
        </button>
        <button
            *ngIf="canInsertLeft"
            (click)="$event.stopPropagation()"
            [matMenuTriggerFor]="InsertLeft"
            mat-menu-item
        >
            <mat-icon>drag_indicator</mat-icon>
            <span>{{tTableColumnMenu('left_insert')}}</span>
        </button>
        <mat-menu #InsertLeft="matMenu">
            <!--            todo: [table-ref] templates are the same InsertRight and InsertLeft-->
            <ng-template matMenuContent>
                <app-field-add-content
                    [fields]="fields"
                    [guid]="guid"
                    [insertPlace]="'InsertLeft'"
                    [isNewColumn]="true"
                    [isPinned]="isPinned"
                    [columnsOrder]="columnsOrder"
                ></app-field-add-content>
            </ng-template>
        </mat-menu>
        <button
            *ngIf="canInsertRight"
            (click)="$event.stopPropagation()"
            [matMenuTriggerFor]="InsertRight"
            mat-menu-item
        >
            <mat-icon>drag_indicator</mat-icon>
            <span>{{tTableColumnMenu('right_insert')}}</span>
        </button>
        <mat-menu #InsertRight="matMenu">
            <ng-template matMenuContent>
                <app-field-add-content
                    [fields]="fields"
                    [guid]="guid"
                    [insertPlace]="'InsertRight'"
                    [isNewColumn]="true"
                    [isPinned]="isPinned"
                    [columnsOrder]="columnsOrder"
                ></app-field-add-content>
            </ng-template>
        </mat-menu>

        <button (click)="hideColumn.emit(guid)" mat-menu-item>
            <mat-icon>remove_red_eye</mat-icon>
            <span>{{tTableColumnMenu('hide')}}</span>
        </button>
        <button (click)="shareWith.emit(field)" *ngIf="isShareAvailable" mat-menu-item>
            <mat-icon>folder_shared</mat-icon>
            <span>{{tTableColumnMenu('share')}}</span>
        </button>
        <button (click)="moveFieldToFolder.emit(field)" *ngIf="isMoveAvailable" mat-menu-item>
            <mat-icon>folder</mat-icon>
            <span>{{tTableColumnMenu('move')}}</span>
        </button>
        <button (click)="updateField.emit(field)" *ngIf="isUpdateAvailable" mat-menu-item>
            <mat-icon>settings</mat-icon>
            <span>{{tTableColumnMenu('settings')}}</span>
        </button>
        <button (click)="resetWidth.emit(guid)" *ngIf="isCustomWidth" mat-menu-item>
            <mat-icon>update</mat-icon>
            <span>{{tTableColumnMenu('reset')}}</span>
        </button>
        <button (click)="copyColumn.emit(field.guid)" *ngIf="!isSystemOrUniqueField" mat-menu-item>
            <mat-icon>file_copy</mat-icon>
            <span>{{tTableColumnMenu('copy')}}</span>
        </button>
        <button (click)="deleteColumn.emit(field.guid)" *ngIf="isDeleteAvailable" mat-menu-item>
            <mat-icon>delete</mat-icon>
            <span>{{tTableColumnMenu('delete')}}</span>
        </button>
    </ng-container>
</ng-container>

<ng-template #operationCodeError>
    <!--    TODO: add UI for operationCodeError-->
    error
</ng-template>
