<div
    class="sm:h-7 h-8 sm:bg-transparent bg-white rounded text-primarySecondary hover:text-primaryDark ml-4"
>
    <div
        [matMenuTriggerFor]="showMenu"
        [class]="{'bg-white text-primaryDark': trigger.menuOpen}"
        #trigger="matMenuTrigger"
        class="flex-start-center h-100 c-pointer sm:p-1 p-2 pr-2 rounded-lg font-semibold"
        *transloco="let tShow; read: 'show'"
    >
        <div [class.text-accentContrastLight]="fieldTypes$" class="flex items-center">
            <mat-icon class="w-5 h-5 text-xl leading-5 mr-2" svgIcon="custom_settings"></mat-icon>
            <span class="">{{tShow('show_title')}}</span>
        </div>
    </div>

    <mat-menu #showMenu="matMenu" class="show-by">
        <div class="position-relative h-100">
            <app-field-add
                (click)="$event.stopPropagation()"
                class="w-100 add-field-sticky"
                [fields]="fields"
                [isShowFieldAdd]="true"
                [isNewColumn]="false"
                [selectedFolder]="selectedFolder"
            ></app-field-add>
            <ng-template *ngIf="fieldTypes$ | async as types" matMenuContent>
                <div class="text-muted mb-0 p-l-r-sm mt-md text-size-xs">VISIBLE FIELDS</div>
                <ng-container *ngFor="let fieldGuid of visibleFields">
                    <button
                        *ngIf="fields[fieldGuid]!.field_type_code !== 'field_type_name'"
                        (click)="changeFieldState(fieldGuid, false)"
                        mat-menu-item
                    >
                        <app-field-name
                            *ngIf="fields[fieldGuid] && selectedFolder"
                            [field]="fields[fieldGuid]"
                            [selectedFolder]="selectedFolder"
                            [showIcon]="true"
                        ></app-field-name>
                        <span class="material-icons text-muted text-size-sm"> done </span>
                    </button>
                </ng-container>
                <div *ngIf="hiddenFields" class="hr-row"></div>
                <div *ngIf="hiddenFields" class="text-muted mb-0 p-l-r-sm mt-md text-size-xs">
                    FIELDS IN THE SPACE
                </div>
                <button
                    (click)="changeFieldState(fieldGuid, true)"
                    *ngFor="let fieldGuid of hiddenFields"
                    mat-menu-item
                >
                    <app-field-name
                        [field]="fields[fieldGuid]!"
                        [selectedFolder]="selectedFolder!"
                        [showIcon]="true"
                    ></app-field-name>
                </button>
            </ng-template>
        </div>
    </mat-menu>
</div>
