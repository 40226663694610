import { Injectable } from '@angular/core'
import {
    FieldInsertionOptions,
    ObjectResponseModel,
    RecordUpdate,
    ResponseFieldEntities,
    View,
} from '../models'
import { map, take } from 'rxjs/operators'
import { RecordsService } from './records.service'
import { ViewFacadeService } from './store-facade'

@Injectable({
    providedIn: 'root',
})
export class ViewService {
    constructor(
        private recordsService: RecordsService,
        private viewFacadeService: ViewFacadeService,
    ) {}

    updateViewOnCreateField(data: ObjectResponseModel, options: FieldInsertionOptions) {
        this.generateViewForInjection(data, options)
            .pipe(take(1))
            .subscribe((record) => {
                if (record) {
                    this.recordsService.updateRecord(record)
                }
            })
    }

    generateViewForInjection(data: ObjectResponseModel, options: FieldInsertionOptions) {
        return this.viewFacadeService.selectSelectedView$.pipe(
            take(1),
            map((selectedView: View | undefined) => {
                if (!selectedView || !('field' in data.data[0].object)) {
                    return
                }
                if (options.isPinned) {
                    return {
                        record: selectedView,
                        ...this.pinnedColumnsInjectionView(selectedView, data, options),
                    } as RecordUpdate
                }

                return {
                    record: selectedView,
                    ...this.columnsInjectionData(
                        data.data[0].object.field as ResponseFieldEntities,
                        selectedView,
                        options,
                    ),
                } as RecordUpdate
            }),
        )
    }

    changeColumnsOrderInView(view: View, columnsOrder: string[], insertionIndex: number) {
        let columnsArr: string[] = []
        if (!view.columns_order.value) {
            columnsArr = columnsOrder.slice(0, insertionIndex)
        }
        let viewColumns = columnsArr.length ? columnsArr : view.columns_order.value.split(',')
        if (columnsOrder.length > viewColumns.length) {
            viewColumns = columnsOrder.reduce((viewColumns, guid, index) => {
                if (index < insertionIndex && !viewColumns.includes(guid)) {
                    return [...viewColumns, guid]
                }
                return viewColumns
            }, viewColumns)
        }
        return {
            ...view,
            columns_order: {
                ...view.columns_order,
                value: viewColumns.join(','),
            },
        }
    }

    private columnsInjectionData(
        fields: ResponseFieldEntities,
        selectedView: View,
        options: FieldInsertionOptions,
    ) {
        const view = this.updateViewColumnsOrder(selectedView, options)
        const columnsArr = this.getColumnsArrForFieldFilter(view, options)

        const field = Object.keys(fields).find((guid) => !columnsArr.includes(guid))
        if (!field) {
            return
        }
        const saveColumnsArr = view.columns_order.value.split(',')

        saveColumnsArr.splice(options.insertionIndex, 0, field)

        return {
            cell: view.columns_order,
            value: saveColumnsArr.join(','),
        }
    }

    private pinnedColumnsInjectionView(
        view: View,
        data: ObjectResponseModel,
        options: FieldInsertionOptions,
    ) {
        if (!('field' in data.data[0].object)) return {} as RecordUpdate
        const field = Object.keys(data.data[0].object.field).find(
            (guid) => !options.displayedColumnsOrder.includes(guid),
        )
        if (!field) {
            return {} as RecordUpdate
        }
        const saveColumnsArr = view.columns_pinned.value.split(',')

        saveColumnsArr.splice(options.insertionIndex, 0, field)

        return {
            cell: view.columns_pinned,
            value: saveColumnsArr.join(','),
        }
    }

    private updateViewColumnsOrder(selectedView: View, options: FieldInsertionOptions) {
        return selectedView.columns_order.value.split(',').length < options.insertionIndex
            ? this.changeColumnsOrderInView(
                  selectedView,
                  options.displayedColumnsOrder,
                  options.insertionIndex,
              )
            : selectedView
    }

    private getColumnsArrForFieldFilter(view: View, options: FieldInsertionOptions) {
        return options.displayedColumnsOrder.length > view.columns_order.value.split(',').length
            ? options.displayedColumnsOrder
            : view.columns_order.value.split(',')
    }
}
