<div [style.border-color]="boardStatus" class="board-title">
    <h2 class="float-left">
        <span matBadge="{{columnItemsCount}}" matBadgeOverlap="false" *ngIf="field === null"
            >{{columnName}}</span
        >
        <div
            class="flex-start-center position-relative"
            matBadge="{{columnItemsCount}}"
            matBadgeOverlap="false"
            *ngIf="field !== null && fieldType !== null"
        >
            <app-grouping-values
                *ngIf="field; else unchecked"
                class="w-100"
                [fieldType]="fieldType[field.field_type_code]"
                [field]="field"
                [value]="item.value ?? ''"
            ></app-grouping-values>
            <ng-template #unchecked>
                <span *transloco="let tBoard; read: 'board'"> {{tBoard('template')}} </span>
            </ng-template>
        </div>
    </h2>
    <div class="flex-start-center float-right title-menu">
        <div class="title-menu-buttons">
            <button
                (click)="addNewBoardItem()"
                class="material-icons add-task-btn c-pointer text-primary"
            >
                add
            </button>
            <button (click)="editField(field)" class="material-icons">edit_square</button>
            <button
                (click)="collapseColumn.emit(true)"
                *ngIf="!allowCollapsing"
                class="material-icons-outlined"
            >
                keyboard_double_arrow_left
            </button>
        </div>
        <mat-checkbox
            (change)="selectColumnRecords.emit(true)"
            [checked]="isAllRecordsSelected"
        ></mat-checkbox>
    </div>
</div>
