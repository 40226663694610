<ng-container *ngIf="formGroup">
    <h1 mat-dialog-title>Folder create</h1>
    <div
        class="folder-create-container"
        mat-dialog-content
        *transloco="let tFolderCreate; read:'folder_create'"
    >
        <app-tb-stepper>
            <form [formGroup]="formGroup">
                <cdk-step>
                    <mat-form-field appearance="fill">
                        <mat-label>{{tFolderCreate('dialog_input')}}</mat-label>
                        <input formControlName="name" matInput required />
                    </mat-form-field>
                </cdk-step>
                <cdk-step>
                    <div class="flex-start-center mt-xxl w-100">
                        <span class="text-size-xs text-muted w-80 d-block"
                            >{{tFolderCreate('color_label')}}</span
                        >
                        <a
                            [ngStyle]="{'background': formGroup.value.color} "
                            class="folder-color mr-md"
                            [hideColorPicker]="false"
                            [hideTextInput]="true"
                            formControlName="color"
                            ngx-colors-trigger
                        ></a>
                    </div>
                    <div class="flex-start-center mt-xxl w-100">
                        <span class="text-size-xs text-muted w-80 d-block"
                            >{{tFolderCreate('icon_label')}}</span
                        >
                        <div class="icon">
                            <button>
                                <mat-icon class="icon-picker">--> folder </mat-icon>
                            </button>
                            <!--       TODO: ngx-icon-picker doesn't support Angular 17                     <button-->
                            <!--                                formControlName="icon"-->
                            <!--                                [iconPicker]="formGroup.controls.icon.value"-->
                            <!--                                [ipIconPack]="['mat']"-->
                            <!--                                [ipPosition]="'bottom'"-->
                            <!--                                [ipWidth]="'250px'"-->
                            <!--                                [ipPlaceHolder]="tFolderCreate('icon_picker_placeholder')"-->
                            <!--                                (iconPickerSelect)="onIconPickerSelect($event)"-->
                            <!--                                ngDefaultControl-->
                            <!--                            >-->
                            <!--                                <mat-icon class="icon-picker">-->
                            <!--                                    folder-->
                            <!--                                    {{formGroup.controls.icon.value}}-->
                            <!--                                </mat-icon>-->
                            <!--                            </button>-->
                        </div>
                    </div>
                </cdk-step>
                <cdk-step>
                    <label>{{tFolderCreate('roles_label')}}</label>
                    <span *ngIf="showUser; else isSelectedRoles"
                        >{{currentUserRole(user$ | async)}}</span
                    >
                    <ng-template #isSelectedRoles>
                        <span>{{selectedRoles()}}</span>
                    </ng-template>
                    <div
                        *ngFor="let role of rolesInArray"
                        class="mt-xxl w-100"
                        formGroupName="roles"
                    >
                        <mat-checkbox [formControlName]="role.guid"> {{role.name}} </mat-checkbox>
                    </div>
                </cdk-step>
                <cdk-step *ngIf="!edit">
                    <div
                        *ngFor="let type of viewTypesNames"
                        class="mt-xs w-100"
                        formGroupName="views"
                    >
                        <label>{{type}}</label>
                        <div>
                            <div
                                *ngFor="let view of solutionModel.setting[type].view"
                                formGroupName="{{type}}"
                                class="inline"
                            >
                                <mat-checkbox
                                    *ngIf="view === 'table'; else otherView"
                                    [disabled]="true"
                                    [checked]="true"
                                    [formControlName]="view"
                                >
                                    {{tFolderCreate('view_checkbox_name', {view: view})}}
                                </mat-checkbox>
                                <ng-template #otherView>
                                    <mat-checkbox [formControlName]="view">
                                        {{tFolderCreate('view_checkbox_name', {view: view})}}
                                    </mat-checkbox>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </cdk-step>
                <cdk-step #stepSummary="cdkStep">
                    <div>
                        <div class="flex-start-center">
                            <div class="mr-md">{{tFolderCreate('summarise_step.name')}}</div>
                            <ng-container *ngIf="formGroup.invalid; else correctName">
                                <div class="error-text">Empty name!</div>
                            </ng-container>
                            <ng-template #correctName>
                                <div>{{formValue.name}}</div>
                            </ng-template>
                        </div>
                        <div class="flex-start-center">
                            <div class="mr-md">{{tFolderCreate('summarise_step.color')}}</div>
                            <div [style.background-color]="formValue.color" class="circle-sm"></div>
                        </div>
                        <div class="flex-start-center">
                            <div class="mr-md">{{tFolderCreate('summarise_step.icon')}}</div>
                            <mat-icon>{{formValue.icon}}</mat-icon>
                        </div>
                        <div class="flex-start-center">
                            <div class="mr-md">{{tFolderCreate('summarise_step.roles')}}</div>
                            <div *ngFor="let role of formValueRoles">
                                <div>{{roles[role].name}}</div>
                            </div>
                        </div>
                        <div *ngIf="!edit">
                            <div>{{tFolderCreate('summarise_step.views')}}</div>
                            <div
                                *ngFor="let viewsTypes of formValueViewsTypes"
                                class="flex-start-center"
                            >
                                <div class="mr-md">{{viewsTypes.typeName}}</div>
                                <div>{{viewsTypes.viewsTypes}}</div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="flex-start-center w-100 mt-sm justify-content-end"
                        *transloco="let tButtons; read: 'buttons'"
                    >
                        <button (click)="closeFolderSettings()" mat-button>
                            {{tButtons('cancel')}}
                        </button>
                        <button
                            (click)="saveFolderSettings()"
                            [disabled]="formGroup.invalid"
                            mat-button
                        >
                            {{tButtons('apply')}}
                        </button>
                    </div>
                </cdk-step>
            </form>
        </app-tb-stepper>
    </div>
</ng-container>
