import { createFeatureSelector } from '@ngrx/store'
import { fieldTypeAdapter, FieldTypeState } from './field-type.reducer'

const selectFieldTypeState = createFeatureSelector<FieldTypeState>('FIELD_TYPE')

const { selectIds, selectEntities, selectAll, selectTotal } =
    fieldTypeAdapter.getSelectors(selectFieldTypeState)

export const selectFieldTypeIds = selectIds
export const selectFieldTypeEntities = selectEntities
export const selectAllFieldTypes = selectAll
export const selectFieldTypeTotal = selectTotal
