import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { View } from '../models'
import { SystemRecords, SystemRecordsFacadeService } from './system-records-facade.service'
import { FolderFacadeService, SchemaFacadeService } from './store-facade'
import { take } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class NavigatorService {
    constructor(
        private router: Router,
        private systemRecordsFacadeService: SystemRecordsFacadeService,
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
    ) {}

    goToFolder(folderGuid: string): void {
        this.systemRecordsFacadeService
            .getAllSystemRecordsFromFolder(folderGuid)
            .pipe(take(1))
            .subscribe((systemRecords) => {
                if (systemRecords.view) {
                    this.goToView(systemRecords.view)
                    return
                }
                this.goToViewCreatePage(systemRecords)
            })
    }

    goToSystemObject(systemObjectGuid: string, folderGuid: string) {
        this.systemRecordsFacadeService
            .getAllSystemRecordsFromSystemObject(systemObjectGuid, folderGuid)
            .pipe(take(1))
            .subscribe((systemRecords) => {
                if (systemRecords.view) {
                    this.goToView(systemRecords.view)
                    return
                }
                this.goToViewCreatePage(systemRecords)
            })
    }

    goToView(view: View) {
        void this.router.navigate(['view', view.guid])
    }

    goToViewPage() {
        void this.router.navigate(['view'])
    }

    goToMyFolder() {
        void this.router.navigate(['myFolder'])
    }

    goToNotificationPage() {
        void this.router.navigate(['notificationPage'])
    }

    goToViewCreatePage(systemRecords: SystemRecords) {
        const { folder, tableSchema } = systemRecords

        if (folder) this.folderFacadeService.setSelectedFolderGuid(folder.guid)

        if (tableSchema) this.schemaFacadeService.setSelectedTableSchemaGuid(tableSchema.guid)

        void this.router.navigate(['view', 'create'])
    }
}
