import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { GroupingValuesComponent } from './grouping-values/grouping-values.component'
import { CellTypesModule } from '../cell-types/cell-types.module'
import { TranslocoModule } from '@ngneat/transloco'
import { ActionsComponent } from './actions-panel/actions-panel.component'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatButtonModule } from '@angular/material/button'
import { CommonAppModule } from '../../common/common.module'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { FilterNoResultsComponent } from '../view-controls/filter/filter-no-results/filter-no-results.component'

@NgModule({
    declarations: [GroupingValuesComponent, FilterNoResultsComponent, ActionsComponent],
    imports: [
        CommonModule,
        CellTypesModule,
        TranslocoModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        CommonAppModule,
        MatCheckboxModule,
    ],
    exports: [GroupingValuesComponent, ActionsComponent, FilterNoResultsComponent],
})
export class CommonViewModule {}
