import { Component, Input } from '@angular/core'
import { Cell } from '../../../../@core/models'

@Component({
    selector: 'app-board-card-subtitle',
    templateUrl: './board-card-subtitle.component.html',
    styleUrls: ['./board-card-subtitle.component.sass'],
})
export class BoardCardSubtitleComponent {
    @Input()
    amount?: Cell

    @Input()
    vendorName?: Cell
}
