<form [formGroup]="formGroup" *transloco="let tAddRecordContent; read: 'add_record_content'">
    <div class="flex-start-center w-100">
        <button class="mr-auto" *ngIf="isRecordPinned" (click)="removePinRecord()" mat-icon-button>
            <mat-icon>delete</mat-icon>
        </button>
        <button class="ml-auto" (click)="pinDraftRecord()" mat-icon-button>
            <mat-icon> minimize</mat-icon>
        </button>
    </div>
    <div class="flex-start-center w-100 mb-md">
        <mat-form-field appearance="fill">
            <mat-label>{{tAddRecordContent('name')}}</mat-label>
            <input autocomplete="off" formControlName="name" matInput required />
        </mat-form-field>
    </div>
    <div class="flex-start-center w-100 mb-md">
        <mat-form-field class="w-100 flex-start-center">
            <mat-label>{{tAddRecordContent('workspace_type')}}</mat-label>
            <mat-select formControlName="sotCode">
                <mat-option *ngFor="let code of sotCodes$ | async" value="{{ code }}">
                    {{ code }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="flex-start-center w-100 mb-md">
        <mat-form-field class="w-100 flex-start-center">
            <mat-label>{{tAddRecordContent('folder')}}</mat-label>
            <mat-select formControlName="folder">
                <mat-option *ngFor="let folder of folders$ | async" value="{{ folder.guid }}">
                    {{folder.name.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <ng-container *ngIf="filedTypes$ | async as fieldTypes">
        <div *ngIf="assigneeField" class="flex-start-center w-100 mb-md">
            <app-assign
                (cellValueChanged)="saveAssigneeValue($event)"
                [fieldType]="fieldTypes[assigneeField.field_type_code]"
                [field]="assigneeField"
                [value]="assigneeFieldValue"
                class="flex-start-center text-center"
            ></app-assign>
        </div>
        <div class="flex-start-center w-100 mb-md">
            <mat-form-field class="w-100 flex-start-center">
                <textarea
                    autocomplete="off"
                    formControlName="description"
                    matInput
                    name="text"
                    placeholder="{{tAddRecordContent('description_label')}}"
                >
                </textarea>
            </mat-form-field>
        </div>
        <div class="flex-start-center w-100 mb-md">
            <div *ngIf="statusField" class="position-relative w-100">
                <app-status
                    (cellValueChanged)="saveStatusValue($event)"
                    [fieldType]="fieldTypes[statusField.field_type_code]"
                    [field]="statusField"
                    [value]="statusFieldValue"
                    class="flex-start-center text-center"
                ></app-status>
            </div>
        </div>
        <div *ngIf="watchField" class="flex-start-center w-100 mb-md">
            <app-watch
                (cellValueChanged)="saveWatchValue($event)"
                [fieldType]="fieldTypes[watchField.field_type_code]"
                [field]="watchField"
                [value]="watchFieldValue"
                class="flex-start-center text-center"
            ></app-watch>
        </div>
    </ng-container>
    <div class="flex-start-center w-100 mb-md" *transloco="let tButtons; read: 'buttons'">
        <button (click)="closeMenu.emit()" mat-button>{{tButtons('close')}}</button>
        <button (click)="addRecord()" [disabled]="formGroup.controls.name.invalid" mat-button>
            {{tButtons('create_record')}}
        </button>
    </div>
</form>
