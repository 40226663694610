<form (ngSubmit)="addNewView()" [formGroup]="formGroup">
    <div
        class="sm:pt-4 sm:pb-6 sm:px-1 sm:w-screen max-w-[600px]"
        *transloco="let tAddView; read: 'add_view'"
    >
        <div class="py-0 px-5 max-sm:px-0 mb-6 max-sm:pt-4 text-xl font-semibold text-primaryDark">
            {{tAddView('create_new_view')}}
            <div class="flex absolute sm:top-[8px] top-[16px] sm:right-[6px] right-[12px]">
                <mat-icon
                    (click)="closeModal()"
                    class="flex items-center w-5 h-5 box-content p-0.5 text-xl leading-4 rounded hover:bg-primaryLight cursor-pointer"
                >
                    close
                </mat-icon>
            </div>
        </div>

        <div
            class="flex overflow-auto sm:max-h-[calc(100vh-180px)] max-h-[calc(100vh-361px)] py-0 px-5 max-sm:px-0"
        >
            <div
                class="max-sm:hidden flex flex-col min-w-[140px] max-w-[200px] h-[500px] mr-6 p-2 rounded-lg shadow-light"
            >
                <div
                    [ngClass]="{'bg-secondaryMain hover:bg-secondaryMain text-white': viewType === 'table'}"
                    (click)="selectViewType('table')"
                    class="flex items-center mb-2 px-2 py-1 rounded hover:bg-primaryBackground cursor-pointer"
                >
                    <mat-icon svgIcon="custom_table" class="h-4 w-4 mr-2"></mat-icon>
                    <span>{{tAddView('table')}}</span>
                </div>
                <div
                    [ngClass]="{'bg-secondaryMain hover:bg-secondaryMain text-white': viewType === 'board'}"
                    (click)="selectViewType('board')"
                    class="flex items-center mb-2 px-2 py-1 rounded hover:bg-primaryBackground cursor-pointer"
                >
                    <mat-icon svgIcon="custom_board" class="h-4 w-4 mr-2"></mat-icon>
                    <span>{{tAddView('board')}}</span>
                </div>
                <input type="hidden" [value]="viewType" />
            </div>
            <div class="sm:pl-6 w-full">
                <div class="max-sm:hidden flex flex-col mb-8 text-xs text-primarySecondary">
                    <mat-icon class="w-[363px] h-[230px]" svgIcon="{{viewType}}"></mat-icon>
                    <span class="px-[28px] mt-4">{{tAddView(viewType+'_description')}}</span>
                </div>

                <!--                dynamic styling on pick should be added-->
                <div class="sm:hidden max-xsm:flex-col flex gap-4 mb-6 cursor-pointer">
                    <div
                        class="flex flex-1 p-4 gap-2 shadow-light rounded-2xl"
                        (click)="selectViewType('table')"
                    >
                        <mat-icon
                            svgIcon="custom_table"
                            class="h-10 w-10 min-w-[40px] text-secondaryMainLight"
                        ></mat-icon>
                        <div>
                            <div>{{tAddView('table')}}</div>
                            <div class="text-xs text-primarySecondary">
                                {{tAddView('table_description')}}
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex flex-1 p-4 gap-2 shadow-light rounded-2xl"
                        (click)="selectViewType('board')"
                    >
                        <mat-icon
                            svgIcon="custom_board"
                            class="h-10 w-10 min-w-[40px] text-secondaryMainLight"
                        ></mat-icon>
                        <div>
                            <div>{{tAddView('board')}}</div>
                            <div class="text-xs text-primarySecondary">
                                {{tAddView('board_description')}}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- needs mat-form-field styling pr approval-->
                <div class="mb-6">
                    <div class="mb-1 text-primaryDark">{{tAddView('view_name')}}</div>
                    <mat-form-field class="w-full noHintError" appearance="outline">
                        <input
                            class="w-full"
                            autocomplete="off"
                            formControlName="viewName"
                            matInput
                            name="name"
                            placeholder=""
                            type="text"
                            value="value"
                            [appAutoFocus]="true"
                        />
                    </mat-form-field>
                </div>

                <!--            Access component should be here-->
                <div class="mb-6">
                    <div class="mb-1 text-primaryDark">Access</div>
                    <div
                        class="w-full py-2 px-3 rounded-lg border border-solid border-primarySecondary"
                    >
                        Select
                    </div>
                </div>
            </div>
        </div>

        <div
            class="flex justify-end py-0 px-5 max-sm:px-0"
            *transloco="let tButtons; read: 'buttons'"
        >
            <button
                type="submit"
                class="flex-1 xsm:flex-none py-1 px-5 rounded-lg bg-secondaryMain font-semibold text-white h-9 shadowedBtn"
            >
                {{tButtons('create')}}
            </button>
        </div>
    </div>
</form>
