<div
    class="flex-start-center justify-content-between"
    *transloco="let tBoardCard; read: 'board.board_card'"
>
    <div *ngIf="updateAt">
        {{updateAt.fieldName}}
        <ng-container *ngIf="updateAt.value"> : {{updateAt.value}} </ng-container>
        {{tBoardCard('by')}}
    </div>

    <app-assign
        *ngIf="updateAt && field"
        [disabled]="true"
        [fieldType]="fieldType"
        [field]="field"
        [value]="updateAt.value"
    >
    </app-assign>
</div>
