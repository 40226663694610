import {
    ApplicationRef,
    createComponent,
    Injectable,
    InjectionToken,
    Injector,
} from '@angular/core'
import { ModalContainerComponent } from './modal-container-component/modal-container.component'
import { ComponentType } from '@angular/cdk/overlay'

export const ModalContainerDataToken = new InjectionToken('ModalContainerDataToken')

@Injectable({
    providedIn: 'root',
})
export class ModalContainerFactoryService {
    constructor(private appRef: ApplicationRef, private injector: Injector) {}

    createComponentRef<T extends ModalContainerComponent, U>(
        component: ComponentType<T>,
        data?: U,
    ) {
        return createComponent(component, {
            environmentInjector: this.appRef.injector,
            elementInjector: this.getContainerInjector(data),
        })
    }

    private getContainerInjector<T>(data?: T) {
        return Injector.create({
            providers: [
                {
                    provide: ModalContainerDataToken,
                    useValue: data,
                },
            ],
            parent: this.injector,
        })
    }
}
