export interface UserModelEntities {
    [guid: string]: UserModel
}

export interface UserModel {
    first_name: string
    last_name: string
    avatar_url?: string
    email: string
    role_guid: string
    group_guid?: string
}

export enum AclScopeTypes {
    FOLDER_RECORD = 'folder.record',
    TABLE = 'table',
}

export interface CurrentUser {
    guid: string
    role: {
        guid: string
        configuration: {
            acl_scope: AclScopeTypes | string
            acl_scope_guid: string
            rules?: {
                [key: string]: { action_acl: number }
            }
        }[]
        is_global_folder?: number
        is_global_field?: number
        is_new_folder?: number
    }
    is_admin?: number
}

export class User implements UserModel {
    guid: string
    first_name: string
    last_name: string
    avatar_url?: string
    email: string
    group_guid?: string
    role_guid: string

    constructor(guid: string, model: UserModel) {
        this.guid = guid
        this.first_name = model.first_name
        this.last_name = model.last_name
        this.avatar_url = model.avatar_url
        this.email = model.email
        this.group_guid = model.group_guid
        this.role_guid = model.role_guid
    }

    get fullName(): string {
        return `${this.first_name} ${this.last_name}`
    }
}
