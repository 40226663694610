<div class="board-container">
    <div cdkDropListGroup class="board-container_inner">
        <ng-container *ngIf="groups && groups.length">
            <div *ngFor="let group of groups" class="board-col-wrapper">
                <app-board-col
                    *ngIf="group.data"
                    (createRecord)="createRecord($event,
                    group.data.length ? group.data[0].schemaGuid : undefined, group.field!, group.value)"
                    (dropItem)="drop($event)"
                    (isCreateNewItemShown)="isCreateOpened = $event"
                    (editFields)="fieldsEdit($event)"
                    (selectColumnRecords)="selectRecords($event)"
                    [boardColData]="group.data"
                    [columnName]="group.value"
                    [visibleFields]="visibleFields"
                    [selectedFolder]="(selectedFolder$ | async)"
                    [fieldTypes]="fieldTypes$ | async"
                    [field]="group.field!"
                    [isCreateOpened]="isCreateOpened"
                    (clearAllCreate)="cleanAll = $event"
                    [cleanAll]="cleanAll"
                    (nameFieldNotEmpty)="newRecordHasData = $event"
                    [newRecordHasData]="newRecordHasData"
                    [cells]="cells"
                    [allowCollapsing]="groups.length === 1"
                    [selectedRecords]="selectedRecords"
                ></app-board-col>
            </div>
        </ng-container>
    </div>
</div>

<app-actions-panel
    (clearSelected)="clearSelected()"
    (deleteRecords)="deleteSelected()"
    (editRecords)="editSelected($event)"
    *ngIf="selectedRecords.length"
    [cells]="cells"
    [fieldTypes]="(fieldTypes$ | async)!"
    [fields]="ViewData.fields"
    [selectedCount]="selectedRecords.length"
    [selectedFolder]="selectedFolder$ | async"
    [selectedRecords]="selectedRecords"
></app-actions-panel>
