import { LogService } from './@core/services'

export function initializeApp(logService: LogService) {
    return () => {
        return new Promise((resolve, _reject) => {
            logService.init()
            resolve(true)
        })
    }
}
