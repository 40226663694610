import { Component, EventEmitter, Input, Output } from '@angular/core'
import { BusinessRecords } from '../../../../@core/models'

@Component({
    selector: 'app-table-row-menu',
    templateUrl: './table-row-menu.component.html',
    styleUrls: ['./table-row-menu.component.sass'],
})
export class TableRowMenuComponent {
    @Input()
    record!: BusinessRecords
    @Output()
    deleteRecord: EventEmitter<BusinessRecords> = new EventEmitter<BusinessRecords>()
    @Output()
    shareRecord: EventEmitter<BusinessRecords> = new EventEmitter<BusinessRecords>()
}
