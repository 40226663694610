<div
    class="flex-start-center align-items-start w-100"
    *transloco="let tSearchDialog; read: 'search.dialog'"
>
    <div class="flex-column d-flex">
        <form [formGroup]="formGroup" class="w-100 pr-sm">
            <mat-form-field class="fs-lg-21 b-light h-50px w-100 flex-start-center rounded-sm">
                <input
                    autocomplete="off"
                    class="h-50px"
                    formControlName="searchInput"
                    matInput
                    name="search"
                    placeholder="{{tSearchDialog('search_placeholder')}}"
                    type="text"
                />
            </mat-form-field>
            <div class="w-100">
                <div class="w-100 h-100 py-2">
                    <mat-button-toggle-group
                        aria-label="search Filter"
                        formControlName="searchFilter"
                    >
                        <mat-button-toggle [checked]="true" value="all">
                            {{tSearchDialog('button.all')}}
                        </mat-button-toggle>
                        <mat-button-toggle value="records">
                            {{tSearchDialog('button.records')}}
                        </mat-button-toggle>
                        <mat-button-toggle value="doc">
                            {{tSearchDialog('button.doc')}}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div class="w-100 h-100">
                    <app-search-results
                        [searchFilterValue]="searchFilterValue"
                        [searchValue]="searchValue"
                    ></app-search-results>
                </div>
            </div>
        </form>
    </div>
    <div class="bl-light px-2">
        <div class="mb-0 fs-lg-21 w-100">{{tSearchDialog('quick_filters.name')}}</div>
        <mat-list role="list">
            <mat-list-item role="listitem">
                {{tSearchDialog('quick_filters.assigned')}}
            </mat-list-item>
            <mat-list-item role="listitem">
                {{tSearchDialog('quick_filters.created')}}
            </mat-list-item>
            <mat-list-item role="listitem"> {{tSearchDialog('quick_filters.item')}} </mat-list-item>
        </mat-list>
        <div class="mt-lg fs-lg-21 mb-0">{{tSearchDialog('task_filters.name')}}</div>
        <mat-list role="list">
            <mat-list-item role="listitem">
                {{tSearchDialog('task_filters.active')}}
            </mat-list-item>
            <mat-list-item role="listitem">
                {{tSearchDialog('task_filters.closed')}}
            </mat-list-item>
            <mat-list-item role="listitem">
                {{tSearchDialog('task_filters.archived')}}
            </mat-list-item>
        </mat-list>
    </div>
</div>
