import { Injectable } from '@angular/core'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { BehaviorSubject } from 'rxjs'
import { LogService } from '../@core/services'

export enum DisplaySize {
    XSmall,
    Small,
    Medium,
    Large,
    XLarge,
    Unknown,
}

@Injectable({
    providedIn: 'root',
})
export class BreakpointService {
    displaySize$$ = new BehaviorSubject<DisplaySize>(DisplaySize.Unknown)
    displaySize$ = this.displaySize$$.asObservable()

    private displayNameMap = new Map([
        [Breakpoints.XSmall, DisplaySize.XSmall],
        [Breakpoints.Small, DisplaySize.Small],
        [Breakpoints.Medium, DisplaySize.Medium],
        [Breakpoints.Large, DisplaySize.Large],
        [Breakpoints.XLarge, DisplaySize.XLarge],
    ])

    constructor(breakpointObserver: BreakpointObserver, private logService: LogService) {
        breakpointObserver
            .observe([
                Breakpoints.XSmall,
                Breakpoints.Small,
                Breakpoints.Medium,
                Breakpoints.Large,
                Breakpoints.XLarge,
            ])
            .subscribe((result) => {
                this.logService.resolutionChanged(result)
                for (const query of Object.keys(result.breakpoints)) {
                    if (result.breakpoints[query]) {
                        this.displaySize$$.next(
                            this.displayNameMap.get(query) ?? DisplaySize.Unknown,
                        )
                    }
                }
            })
    }
}
