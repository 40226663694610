import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FieldEntities, Folder } from '../../../../@core/models'
import { MatDialog } from '@angular/material/dialog'

@Component({
    selector: 'app-field-add',
    templateUrl: './field-add.component.html',
    styleUrls: ['./field-add.component.sass'],
})
export class FieldAddComponent {
    @Input()
    fields!: FieldEntities

    @Input()
    isNewColumn!: boolean

    @Input()
    isShowFieldAdd!: boolean

    @Input()
    insertPlace!: string

    @Input()
    selectedFolder!: Folder | undefined | null

    @Input()
    guid!: string

    // todo: [table-ref] remove selected tab
    selectedTab!: number

    // todo: [table-ref] never called
    @Output() insertColumn: EventEmitter<{ place: string; guid: string }> = new EventEmitter<{
        place: string
        guid: string
    }>()

    constructor(public dialog: MatDialog) {}

    setActive(tab: number) {
        this.selectedTab = tab
    }

    // todo: [table-ref] never called
    insertColumnContent() {
        this.insertColumn.emit()
    }
}
