import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslocoModule } from '@ngneat/transloco'

import { ViewComponent } from './view.component'
import { ViewRoutingModule } from './view-routing.module'
import { ViewControlsModule } from './view-controls/view-controls.module'
import { BoardModule } from './board/board.module'
import { CellTypesModule } from './cell-types/cell-types.module'
import { CardModule } from './card/card.module'
import { TableContainerModule } from './table/table-container.module'
import { MyFolderModule } from './my-folder/my-folder.module'
import { NotificationPageModule } from './notificationPage/notification-page.module'
import { ListModule } from './list(dontTouch)/list.module'
import { ErrorPageComponent } from '../error-page/error-page.component'
import { ViewPageComponent } from './view-page/view-page.component'
import { MatIconModule } from '@angular/material/icon'

@NgModule({
    declarations: [ViewComponent, ErrorPageComponent, ViewPageComponent],
    imports: [
        CommonModule,
        ViewControlsModule,
        BoardModule,
        ViewRoutingModule,
        CellTypesModule,
        TableContainerModule,
        CardModule,
        MyFolderModule,
        NotificationPageModule,
        ListModule,
        TranslocoModule,
        MatIconModule,
    ],
    exports: [ViewRoutingModule],
})
export class ViewsModule {}
