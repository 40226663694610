import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { CellComponent } from '../cell.component'
import { FormControl, Validators } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { numericValidationPattern } from '../../../@core/global-util'

@UntilDestroy()
@Component({
    selector: 'app-number',
    templateUrl: './number.component.html',
    styleUrls: ['./number.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberComponent extends CellComponent implements OnInit {
    isEdit: boolean = false
    numberFormControl!: FormControl<string | null>

    ngOnInit() {
        this.numberFormControl = new FormControl<string>(this.value, {
            validators: Validators.pattern(numericValidationPattern),
            updateOn: 'blur',
        })

        this.numberFormControl.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.saveValue())
    }

    editMode() {
        this.isEdit = true
    }

    saveValue() {
        this.isEdit = false

        if (this.numberFormControl.invalid || this.value === this.numberFormControl.value) return

        this.cellValueChanged.emit(this.numberFormControl.value)
    }

    saveOnEnter(event: any) {
        if (event.keyCode === 13) {
            this.saveValue()
        }
    }
}
