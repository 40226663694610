import { Subject } from 'rxjs'
import { DisplaySize } from '../../breakpoint.service'
import { ComponentType } from '@angular/cdk/overlay'
import { ModalContainerComponent } from '../modal-container-component/modal-container.component'
import { ModalContainerFactoryService } from '../modal-container-factory.service'
import { ComponentRef } from '@angular/core'
import { ModalContainer } from '../modal-manager.service'

export abstract class BaseContainer<
    T extends ModalContainerComponent,
    ContainerData = unknown,
    Result = unknown,
> {
    closed = new Subject()
    componentRef!: ComponentRef<unknown>

    constructor(
        protected modalContainerFactoryService: ModalContainerFactoryService,
        protected component: ComponentType<T>,
        protected data?: ContainerData,
    ) {
        this.componentRef = this.modalContainerFactoryService.createComponentRef(
            this.component,
            this.data,
        )
    }

    abstract displaySizeToRelatedContainer(displaySize: DisplaySize): ModalContainer

    close(data?: unknown) {
        this.closed.next(data)
        this.closed.complete()
    }

    destroy() {
        this.componentRef.destroy()
    }
}
