<mat-dialog-content>
    <form [formGroup]="formGroup">
        <div class="w-100 flex-start-center" *transloco="let tTable; read: 'table'">
            <mat-form-field>
                <input
                    autocomplete="off"
                    formControlName="columnName"
                    matInput
                    name="columnName"
                    placeholder="{{tTable('add_column_label')}}"
                />
            </mat-form-field>
            <ng-container *ngIf="!field.name">
                <ng-container *ngIf="fieldTypes$ | async as types">
                    <mat-form-field *ngIf="showTypeSelector">
                        <mat-select
                            (selectionChange)="changeFieldType()"
                            formControlName="selectFieldType"
                        >
                            <mat-option *ngFor="let type of types" [value]="type.guid">
                                {{type.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </ng-container>
        </div>
        <div class="w-100 flex-start-center">
            <app-add-column-content
                (fieldChanged)="changedValue($event)"
                [fieldType]="field.field_type_code"
                [field]="field"
            ></app-add-column-content>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions *transloco="let tButtons; read: 'buttons'">
    <button (click)="creteColumn()" color="primary" mat-button>{{tButtons('apply')}}</button>
    <button (click)="closeDialog()" mat-button>{{tButtons('cancel')}}</button>
</mat-dialog-actions>
