import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Folder } from '../../../@core/models'

@Component({
    selector: 'app-folder',
    templateUrl: './folder.component.html',
    styleUrls: ['./folder.component.sass'],
})
export class FolderComponent {
    @Input() folder!: Folder
    @Input() selectedFolderGuid!: string
    @Input() canEdit: boolean = true
    @Input() canDelete: boolean = true

    @Output() edit = new EventEmitter<void>()
    @Output() delete = new EventEmitter<void>()
    @Output() selectFolder: EventEmitter<Folder> = new EventEmitter<Folder>()
}
