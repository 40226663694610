<form class="edit-mode-form">
    <ng-container *ngIf="!isEdit">
        <ng-container *ngIf="!disabled, else disabledContent">
            <span (click)="editMode()" *ngIf="value; else placeholder" class="input_label">
                <a (click)="$event.stopPropagation()" href="{{value}}"> {{value}} </a>
            </span>
            <ng-template #placeholder>
                <span (click)="editMode()" class="text-muted material-icons input_label">
                    remove
                </span>
            </ng-template>
        </ng-container>
        <ng-template #disabledContent>
            <span *ngIf="value; else placeholder" class="input_label">
                <a (click)="$event.stopPropagation()" href="{{value}}"> {{value}} </a>
            </span>
            <ng-template #placeholder>
                <span class="text-muted material-icons input_label"> remove </span>
            </ng-template>
        </ng-template>
    </ng-container>
    <div [class.active]="isEdit" class="edit-mode-area">
        <mat-form-field class="text-input">
            <div class="flex-start-center justify-content-between">
                <input
                    (keyup)="saveOnEnter($event)"
                    [appAutoFocus]="isEdit"
                    [formControl]="textFormControl"
                    autocomplete="off"
                    matInput
                    name="name"
                    type="text"
                />
                <mat-icon (click)="saveValue()" *ngIf="isEdit" class="text-success">
                    check_circle
                </mat-icon>
            </div>
        </mat-form-field>
    </div>
    <ng-container *ngIf="isEdit">
        <mat-error *transloco="let tCellTypesErrors; read: 'cell_types.errors'">
            {{tCellTypesErrors('value_is')}}
            <strong>{{tCellTypesErrors('required')}}</strong>
        </mat-error>
    </ng-container>
</form>
