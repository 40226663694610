import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Field } from '../../../../../@core/models'
import { FormControl } from '@angular/forms'
import { DialogDropdownInput } from '../dropdown.component'
import cloneDeep from 'lodash/cloneDeep'

@Component({
    selector: 'app-dropdown-dialog',
    templateUrl: './dropdown-dialog.component.html',
    styleUrls: ['./dropdown-dialog.component.sass'],
})
export class DropdownDialogComponent implements OnInit {
    field!: Field
    isAddNew!: boolean
    active!: string | undefined
    default!: string
    fieldCopy!: Field
    dropdownNameFormControl!: FormControl<string>
    @Output()
    cellValueChanged: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        public dialogRef: MatDialogRef<DropdownDialogComponent, Field | undefined>,
        @Inject(MAT_DIALOG_DATA) public data: DialogDropdownInput,
    ) {}

    ngOnInit(): void {
        if (this.data) {
            this.field = this.data.field
        }
        this.active = this.data.active
        this.default = this.data.default
        this.fieldCopy = cloneDeep(this.field)
    }

    closeDialog() {
        this.dialogRef.close()
    }

    saveStatusSettings() {
        this.dialogRef.close(this.fieldCopy)
    }

    cellValueChangedDialog(field: Field) {
        this.fieldCopy = field
    }

    defaultChangedDialog(guid: string) {
        this.default = guid
    }
}
