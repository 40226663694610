import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import {
    FieldEntities,
    FieldType,
    FilterCombinationTypes,
    FilterGroup,
    Folder,
    RecordUpdate,
    View,
} from '../../../../@core/models'
import { Observable, combineLatest } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { RecordsService } from '../../../../@core/services/records.service'
import {
    SchemaFacadeService,
    FieldTypeFacadeService,
    FolderFacadeService,
    RecordFacadeService,
} from '../../../../@core/services/store-facade'
import { FormFilterService } from '../form-filter.service'
import { FormGroup } from '@angular/forms'
import { FilterStorageService } from '../../../../@core/services/session-storage/filter-storage.service'

@UntilDestroy()
@Component({
    selector: 'app-filter-menu',
    templateUrl: './filter-menu.component.html',
    styleUrls: ['./filter-menu.component.sass'],
    providers: [FormFilterService],
})
export class FilterMenuComponent implements OnChanges, OnInit {
    @Input() filterGroups!: FilterGroup[]
    @Input() selectedView!: View
    fields$!: Observable<{ fields: FieldEntities; fieldTypes: FieldType[] } | undefined>

    selectedFolder!: Folder
    showResetDefault: boolean = false

    canAddFilterGroup = false

    constructor(
        private recordsService: RecordsService,
        private schemaFacadeService: SchemaFacadeService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private folderFacadeService: FolderFacadeService,
        private filterStorageService: FilterStorageService,
        private recordFacadeService: RecordFacadeService,
        public formFilterService: FormFilterService,
    ) {}

    get isFilterFormEmpty() {
        return (
            this.formFilterService.getFilterFormArray?.length === 1 &&
            !this.formFilterService.getFilterGroupValues(0).length
        )
    }

    get formGroups(): FormGroup[] {
        return this.formFilterService.getFilterFormArray.controls as FormGroup[]
    }

    get showApplyButton() {
        const filterValue = this.selectedView.filter.value

        return (
            (filterValue && filterValue !== '[]') ||
            this.showResetDefault ||
            this.formFilterService.getFilterFormArray.touched
        )
    }

    get isDisabledApplyAction() {
        return (
            this.formFilterService.getFilterFormArray.untouched ||
            this.formFilterService.getFilterFormArray.invalid
        )
    }

    ngOnChanges(changes: SimpleChanges) {
        const prevGroupLength = changes.filterGroups.previousValue?.length
        const currGroupLength = changes.filterGroups.currentValue?.length
        if (prevGroupLength !== currGroupLength && !this.filterGroups?.length) {
            this.formFilterService.addDefaultFilterGroup()
            this.formFilterService.getFilterFormArray.markAsPristine()
            this.formFilterService.getFilterFormArray.markAsUntouched()
        }
    }

    ngOnInit(): void {
        this.folderFacadeService.selectSelectedFolder$
            .pipe(untilDestroyed(this))
            .subscribe((selectedFolder) => (this.selectedFolder = selectedFolder as Folder))

        this.initializeFields()

        this.formFilterService.initForm(this.filterGroups)

        this.showResetDefault = !!this.filterStorageService.getSessionFilter(this.selectedView.guid)

        this.formFilterService.getFilterFormArray.valueChanges
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe(() => {
                this.canAddFilterGroup = this.formFilterService.canAddFilterGroup
            })
    }

    private initializeFields(): void {
        this.fields$ = combineLatest([
            this.fieldTypeFacadeService.selectAllFieldTypes$,
            this.schemaFacadeService.selectSelectedTableSchemaFieldEntitiesFiltered$(
                this.selectedFolder,
            ),
        ]).pipe(
            map(([fieldTypes, fieldEntities]) => {
                if (!fieldEntities || !fieldTypes) {
                    return { fields: {}, fieldTypes: [] }
                }

                const fields = this.getFields(fieldEntities, fieldTypes)

                return {
                    fields,
                    fieldTypes,
                }
            }),
        )
    }

    addGroupAction(event: any) {
        if (this.formFilterService.getFilterFormArray.invalid) {
            return
        }
        event.stopPropagation()
        this.formFilterService.addFilterGroup(FilterCombinationTypes.ALL)
    }

    saveFilter() {
        let rawFilter = this.formFilterService.prepareToSave()
        if (this.isFilterFormEmpty) {
            rawFilter = []
        }
        const newFilter = JSON.stringify(rawFilter)

        if (this.selectedView.filter.value === newFilter) {
            return
        }

        this.filterStorageService.removeFilter(this.selectedView.guid)
        this.formFilterService.getFilterFormArray.markAsPristine()
        this.formFilterService.getFilterFormArray.markAsUntouched()
        this.updateFilterValueInSelectedView(newFilter)
    }

    applyFilterForSession() {
        let rawFilter = this.formFilterService.prepareToSave()
        if (this.isFilterFormEmpty) {
            rawFilter = []
        }
        this.filterStorageService.setFilter(this.selectedView.guid, rawFilter)
        this.recordFacadeService.refreshViewData()
        this.formFilterService.getFilterFormArray.markAsPristine()
        this.formFilterService.getFilterFormArray.markAsUntouched()
    }

    resetToDefault() {
        this.filterStorageService.removeFilter(this.selectedView.guid)
        this.recordFacadeService.refreshViewData()
        this.formFilterService.getFilterFormArray.markAsPristine()
        this.formFilterService.getFilterFormArray.markAsUntouched()
    }

    deleteGroup(groupIndex: number) {
        if (groupIndex !== -1) {
            this.formFilterService.deleteFilterGroup(groupIndex)
        }

        if (!this.formFilterService.getFilterFormArray.length) {
            this.formFilterService.addDefaultFilterGroup()
        }
    }

    private updateFilterValueInSelectedView(filterValue: string) {
        const dataRecord: RecordUpdate = {
            record: this.selectedView,
            cell: this.selectedView.filter,
            value: filterValue,
        }
        this.recordsService.updateRecord(dataRecord)
    }

    private getFields(fieldEntities: FieldEntities, fieldTypes: FieldType[]) {
        return Object.keys(fieldEntities).reduce((acc: FieldEntities, guid: string) => {
            const field = fieldEntities[guid]
            const currentFieldType = fieldTypes.find(
                (fieldType) => fieldType.guid === field.field_type_code,
            )
            if (currentFieldType) {
                acc[guid] = field
            }
            return acc
        }, {})
    }

    isClearAll() {
        if (this.formFilterService.getFilterFormArray.length > 1) {
            return true
        }

        return this.formFilterService
            .prepareToSave()
            .some((group) => (group.values ? Object.values(group.values).length > 1 : false))
    }

    clearAll() {
        this.formFilterService.getFilterFormArray.clear()
        this.formFilterService.addDefaultFilterGroup()
    }
}
