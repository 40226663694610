import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { schemaFeature } from './schema.reducer'
import { SchemaEffects } from './schema.effects'

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(schemaFeature),
        EffectsModule.forFeature([SchemaEffects]),
    ],
})
export class SchemaStoreModule {}
