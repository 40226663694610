import { Injectable, OnDestroy } from '@angular/core'
import * as signalR from '@microsoft/signalr'
import { HubConnection, LogLevel } from '@microsoft/signalr'
import { AuthResponse, HttpService } from './http-service'
import { EMPTY, from, Observable, of, ReplaySubject } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { IWsService, ObjectResponseModel } from '../models'
import { CommonFacadeService } from './store-facade/common-facade.service'

@Injectable()
export class WsService implements IWsService, OnDestroy {
    private connection: HubConnection | undefined
    private onMessageSubject: ReplaySubject<ObjectResponseModel> =
        new ReplaySubject<ObjectResponseModel>()

    constructor(
        private httpService: HttpService,
        private commonFacadeService: CommonFacadeService,
    ) {}

    private static logError(error: any): Observable<never> {
        console.log(error)
        return EMPTY
    }

    disconnect() {
        return this.httpService.logout().pipe(
            switchMap(() => {
                this.stopConnection()
                this.commonFacadeService.initApp()
                return of(true)
            }),
        )
    }

    connect(): Observable<void> {
        console.log('connect')
        return this.httpService.auth().pipe(
            switchMap((resp: AuthResponse) =>
                this.stopConnection().pipe(
                    switchMap(() => {
                        if (resp.data.ws && resp.data.token) {
                            this.connection = new signalR.HubConnectionBuilder()
                                .withUrl(resp.data.ws, {
                                    accessTokenFactory: (): string | Promise<string> =>
                                        resp.data.token!,
                                })
                                .configureLogging(LogLevel.Debug)
                                .build()

                            this.connection.onclose(() => {
                                // TODO: implement reconnect
                                // this.connect()
                                console.log('onclose')
                            })

                            this.connection.onreconnecting(() => {
                                console.log('onreconnecting')
                            })

                            this.connection.onreconnected(() => {
                                console.log('onreconnected')
                            })

                            return from(this.connection.start())
                        }
                        return EMPTY
                    }),
                ),
            ),
            catchError(() => this.connect()),
        )
    }

    onMessage(): Observable<ObjectResponseModel> {
        return this.onMessageSubject.asObservable()
    }

    sendMessage(endpoint: string, body?: any): void {
        let then = () => {}

        if (body) {
            this.connection!.send(endpoint, body).then(then)
        } else {
            this.connection!.send(endpoint).then(then)
        }
    }

    invokeMessage(endpoint: string, body?: any): Observable<ObjectResponseModel> {
        let request: Promise<ObjectResponseModel> = this.connection!.invoke(endpoint, body)
        return from(request)
    }

    stopConnection(): Observable<void | boolean> {
        if (this.connection) {
            return from(this.connection.stop()).pipe(catchError(WsService.logError))
        }

        return of(true)
    }

    ngOnDestroy(): void {
        this.stopConnection()
    }

    public subOnMessage(endpoint: string): void {
        if (this.connection && this.onMessageSubject) {
            this.connection.on(endpoint, (res) => {
                this.onMessageSubject.next(res)
            })
        }
    }
}
