import { Component, Inject, OnInit } from '@angular/core'
import { DataObject } from '../@core/services/save-data.service'
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'
import { ResponseError } from '../@core/models/response/response-error'
import { TranslocoService } from '@ngneat/transloco'
import { ResponseSchema, TableModel } from '../@core/models'

enum TableActions {
    added = 'notification_messages.table_add',
    updated = 'notification_messages.table_update',
    deleted = 'notification_messages.table_delete',
}
@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.sass'],
})
export class NotificationComponent implements OnInit {
    notificationMessage!: string

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: DataObject,
        private translate: TranslocoService,
    ) {}

    ngOnInit() {
        Object.keys(this.data)
            .filter((dataKey) => this.data[dataKey as 'schemas' | 'tables' | 'errors']?.length)
            .forEach((dataKey) => {
                switch (dataKey) {
                    case 'schemas':
                        this.schemaNotification(this.data[dataKey][0])
                        break
                    case 'tables':
                        this.tableNotifications(this.data[dataKey][0])
                        break
                    case 'errors':
                        this.errorNotification(this.data[dataKey])
                        break
                    default:
                        this.notificationMessage = dataKey
                }
            })
    }

    private schemaNotification(schema: ResponseSchema) {
        const schemaMessage = schema ? schema.guid : ''
        this.notificationMessage = this.translate.translateObject(
            'notification_messages.schema_message',
            { msg: schemaMessage },
        )
    }

    private tableNotifications(table: TableModel) {
        const tableDataKey = Object.keys(table)
            .filter((tableKey) => table[tableKey as keyof TableModel])
            .find((tableKey) => tableKey in TableActions)

        if (!tableDataKey) return

        const tableKey = table[tableDataKey as 'added' | 'updated' | 'deleted']

        if (!tableKey) return

        const tableMessage = Object.keys(tableKey).join(',')
        this.tableNotificationMessage(
            TableActions[tableDataKey as keyof typeof TableActions],
            tableMessage,
        )
    }

    private tableNotificationMessage(translationKey: string, message: string) {
        this.notificationMessage = this.translate.translateObject(translationKey, { msg: message })
    }

    private errorNotification(errors?: ResponseError[]) {
        if (!errors) return

        if (errors[0].message.includes('RSOT')) {
            this.notificationMessage = this.translate.translateObject(
                'notification_messages.server_error',
            )
            return
        }

        this.notificationMessage = this.translate.translateObject(
            'notification_messages.default_error',
        )
    }
}
