import { UntilDestroy } from '@ngneat/until-destroy'
import { Component, Input } from '@angular/core'

@UntilDestroy()
@Component({
    selector: 'app-workspace-btn',
    templateUrl: './workspace-btn.component.html',
    styleUrls: ['./workspace-btn.component.sass'],
})
export class WorkspaceBtnComponent {
    @Input()
    workspaceName!: string

    @Input()
    workspaceColor!: string
}
