import { Action, createReducer, on } from '@ngrx/store'
import { initialUserState, UserState } from './user.state'
import * as UsersActions from './user.actions'
import { CurrentUser, UserModelEntities } from '../../models'

const reducer = createReducer(
    initialUserState,
    on(UsersActions.setUsers, (state: UserState, { users }: { users: UserModelEntities }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            users,
        }
    }),
    on(
        UsersActions.setUser,
        (state: UserState, { currentUser }: { currentUser: CurrentUser | null }) => {
            return {
                ...state,
                loading: false,
                loaded: true,
                currentUser,
            }
        },
    ),
)

export function userReducer(state: UserState | undefined, action: Action) {
    return reducer(state, action)
}
