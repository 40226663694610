import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core'
import {
    BusinessRecords,
    CellEntities,
    Field,
    FieldEntities,
    FieldType,
    Folder,
} from '../../../@core/models'
import { MatDialog } from '@angular/material/dialog'
import {
    DialogEditFieldInput,
    DialogEditFieldOutput,
    EditFieldComponent,
} from '../edit-field/edit-field.component'
import { Dictionary } from '@ngrx/entity'
import { MatBottomSheet } from '@angular/material/bottom-sheet'

@Component({
    selector: 'app-actions-panel',
    templateUrl: './actions-panel.component.html',
    styleUrls: ['./actions-panel.component.sass'],
})
export class ActionsComponent implements OnInit {
    @Input()
    fields!: FieldEntities

    @Input()
    selectedRecords!: BusinessRecords[]

    @Input()
    fieldTypes!: Dictionary<FieldType>

    @Input()
    selectedCount!: number

    @Input()
    selectedFolder!: Folder | undefined | null

    @Input()
    cells!: { [recordGuid: string]: CellEntities }

    @Output()
    deleteRecords: EventEmitter<any> = new EventEmitter()

    @Output()
    clearSelected: EventEmitter<any> = new EventEmitter()

    @Output()
    editRecords: EventEmitter<any> = new EventEmitter()

    fieldsList!: Field[]

    constructor(public dialog: MatDialog, private bottomSheet: MatBottomSheet) {}

    ngOnInit() {
        this.fieldsList = Object.values(this.fields)
            .filter((field) => !field.operationCode?.system)
            .filter((field) => !(field.system_name === 'name'))
        this.selectedCount = this.selectedRecords.length
    }

    @ViewChild('actionButtons') TemplateBottomSheet!: TemplateRef<any>

    openBottomSheetS() {
        this.bottomSheet.open(this.TemplateBottomSheet, { panelClass: 'bottomSheetCustom' })
    }

    closeBottomSheetS() {
        this.bottomSheet.dismiss()
    }

    // todo: [table-ref] it deletes only selected records
    deleteAllRecords() {
        this.deleteRecords.emit()
    }

    clearAllSelected() {
        this.clearSelected.emit()
    }

    editField(field: Field) {
        const dialogRef = this.dialog.open<
            EditFieldComponent,
            DialogEditFieldInput,
            DialogEditFieldOutput | undefined
        >(EditFieldComponent, {
            minWidth: '400px',
            data: {
                records: this.selectedRecords,
                field: field,
                fieldTypes: this.fieldTypes,
                cells: this.cells,
            },
        })

        dialogRef.afterClosed().subscribe((res: DialogEditFieldOutput | undefined) => {
            if (res) {
                this.editRecords.emit({ value: res.value, field: res.field })
            }
        })
    }
}
