import { createFeatureSelector, createSelector } from '@ngrx/store'
import { folderAdapter, folderFeature, FolderState } from './folder.reducer'
import { Dictionary } from '@ngrx/entity'
import { Field, FieldEntities, FieldTypes, findFieldByType, Folder } from '../../models'
import { AppState } from '../app.state'
import { FolderNameEntities } from '../../models/response/folder-names'
import { selectSelectedTableSchemaFieldEntities } from '../schema'

const selectFolderState = createFeatureSelector<FolderState>('FOLDER')

const { selectIds, selectEntities, selectAll, selectTotal } =
    folderAdapter.getSelectors(selectFolderState)

export const selectFolderIds = selectIds
export const selectFolderEntities = selectEntities
export const selectAllFolders = selectAll
export const selectFolderTotal = selectTotal

export const selectFoldersAreLoaded = folderFeature.selectAreLoaded
export const selectFoldersIsError = folderFeature.selectIsError
export const selectSelectedFolderGuid = folderFeature.selectSelectedFolderGuid

export const selectSelectedFolder = createSelector<
    AppState,
    [Dictionary<Folder>, string | null],
    Folder | undefined
>(
    selectFolderEntities,
    selectSelectedFolderGuid,
    (folderDictionary: Dictionary<Folder>, selectedFolderGuid: string | null) => {
        console.info('selectSelectedFolder')
        if (!selectedFolderGuid) return

        return folderDictionary[selectedFolderGuid]
    },
)

export const selectSelectedFolderStatusField = createSelector<
    AppState,
    [Folder | undefined, FieldEntities | undefined],
    Field | undefined
>(
    selectSelectedFolder,
    selectSelectedTableSchemaFieldEntities,
    (selectedFolder: Folder | undefined, fields: FieldEntities | undefined) => {
        if (!selectedFolder || !fields) return

        return findFieldByType(selectedFolder, fields, FieldTypes.STATUS)
    },
)

export const selectFolderNames = createSelector<AppState, FolderNameEntities, FolderNameEntities>(
    folderFeature.selectFolderNames,
    (folderNames: FolderNameEntities) => {
        return folderNames
    },
)
