import { Component, Input } from '@angular/core'
import { Cell } from '../../../../@core/models'

@Component({
    selector: 'app-board-card-main-fields',
    templateUrl: './board-card-main-fields.component.html',
    styleUrls: ['./board-card-main-fields.component.sass'],
})
export class BoardCardMainFieldsComponent {
    @Input()
    address?: Cell
}
