<form *ngIf="numberFormControl" class="edit-mode-form">
    <mat-form-field class="noHintError" appearance="outline">
        <input
            [formControl]="numberFormControl"
            autocomplete="off"
            matInput
            name="number"
            type="number"
        />
    </mat-form-field>
    <mat-error *ngIf="numberFormControl.hasError('required')"></mat-error>
</form>
