import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { CellComponent } from '../cell.component'
import { FormControl, Validators } from '@angular/forms'
import { CardComponent, DialogCardInput } from '../../card/card.component'
import { MatDialog } from '@angular/material/dialog'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-name',
    templateUrl: './name.component.html',
    styleUrls: ['./name.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NameComponent extends CellComponent implements OnInit {
    @Input() isRecordCreate?: boolean

    isEdit: boolean = false
    textFormControl!: FormControl<string | null>
    visibleFields!: string[]

    constructor(public dialog: MatDialog) {
        super()
    }

    ngOnInit() {
        this.isEdit = !!this.isRecordCreate
        this.textFormControl = new FormControl<string>(
            {
                value: this.value,
                disabled: !this.isUpdateAvailable,
            },
            {
                validators: Validators.required,
                updateOn: 'blur',
            },
        )

        this.textFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.saveValue()
        })
    }

    editMode() {
        this.isEdit = true
    }

    saveValue() {
        this.isEdit = false

        if (this.value === this.textFormControl.value || this.textFormControl.invalid) return

        this.cellValueChanged.emit(this.textFormControl.value)
    }

    saveOnEnter(event: any) {
        if (event.keyCode === 13) {
            this.saveValue()
        }
    }

    showCard() {
        if (!this.record) return

        this.dialog.open<CardComponent, DialogCardInput>(CardComponent, {
            width: '100%',
            data: { recordGuid: this.record.guid, isFolder: true },
        })
    }
}
