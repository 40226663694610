<form [formGroup]="formGroup" class="mb-lg" *transloco="let tFilter; read: 'filter'">
    <ng-container *ngIf="!single && !first">
        <app-filter-combination-toggle
            class="flex w-fit mx-auto my-4 andOrToggle"
            [disabled]="isCombinationGroupToggleDisabled"
            [value]="formFilterService.getGroupsCombinationType"
            [combinationTypes]="filterGroupsCombinationTypes"
            (valueChange)="groupCombinationTypeChange($event)"
            translateKey="filter.groups_combination_types"
        ></app-filter-combination-toggle>
    </ng-container>
    <form
        [class.notEmpty]="!isEmpty"
        [formGroup]="formGroup"
        *transloco="let tFilter; read: 'filter'"
    >
        <div [class.emptyTemplate]="isEmpty">
            <div [class.sm:mb-4]="!isEmpty" class="flex items-center p-0 text-primarySecondary">
                <div>{{tFilter('where_label')}}</div>
                <div class="flex items-center" *ngIf="isShowFilterCombinationToggle()">
                    <app-filter-combination-toggle
                        class="mx-4"
                        [value]="filterCombinationType"
                        [combinationTypes]="filterCombinationTypes"
                        (valueChange)="combinationTypeChange($event)"
                    ></app-filter-combination-toggle>
                    <div>{{tFilter('where_conditions_match')}}</div>
                </div>
            </div>
            <ng-container *ngIf="formGroup.value">
                <app-filter-item
                    (deleteFilter)="deleteFilter(itemIndex)"
                    (deleteGroup)="deleteGroup.emit()"
                    [itemIndex]="itemIndex"
                    [groupIndex]="groupIndex"
                    *ngFor="let formItem of formItems; index as itemIndex"
                    [fieldTypes]="fieldTypes"
                    [fieldGuids]="fieldGuids"
                    [totalItems]="formFilterService.getFilterGroupValues(this.groupIndex).length"
                    [fields]="fields"
                    [selectedFolder]="selectedFolder"
                ></app-filter-item>
            </ng-container>
            <div *ngIf="fieldGuids" class="p-0 w-full">
                <div
                    [class.disabled-menu-trigger]="formGroup.invalid"
                    #menuTrigger="matMenuTrigger"
                    [matMenuTriggerFor]="filterAddMenu"
                >
                    <ng-container
                        *ngIf="isEmpty; then emptySelector; else regularSelector"
                    ></ng-container>
                </div>
                <mat-menu #filterAddMenu="matMenu" class="filter initial-filter-select">
                    <ng-template matMenuContent>
                        <button
                            (click)="addFilterClick(fieldGuid, $event); menuTrigger.toggleMenu()"
                            *ngFor="let fieldGuid of fieldGuids"
                            mat-menu-item
                        >
                            <app-field-name
                                [field]="getFieldByGuid(fieldGuid)"
                                [selectedFolder]="selectedFolder"
                                [showIcon]="false"
                            ></app-field-name>
                        </button>
                    </ng-template>
                </mat-menu>
            </div>
        </div>
    </form>

    <ng-template #emptySelector>
        <!--            everything after ml-7 will need to be removed once remade into a mat-select-->
        <span
            class="ml-7 mb-7 mt-7 flex items-center border border-solid border-primaryLight rounded-lg pl-3 h-9 cursor-pointer"
            *transloco="let tFilter; read: 'filter'"
        >
            <span> {{tFilter('select_filter')}} </span>
            <span class="material-icons text-size-sm"> keyboard_arrow_down </span>
        </span>
    </ng-template>

    <ng-template #regularSelector>
        <div class="flex justify-between" *transloco="let tFilter; read: 'filter'">
            <!-- TODO: reuse tb-button in the future and make sure disabled state shows according to the UI Kit design-->
            <button
                class="flex items-center w-fit p-1 text-secondaryMainLight hover:text-secondaryMain"
                [disabled]="formGroup.invalid"
            >
                <!--Todo: // [desing-ref] icon-->
                <mat-icon class="material-icons w-fit h-fit text-xl leading-5 mr-xs"
                    >add_circle_outline</mat-icon
                >
                <span class="font-semibold text-sm">{{tFilter('add_filter')}}</span>
            </button>
            <ng-container *ngIf="!single">
                <button
                    (click)="deleteGroup.emit()"
                    class="flex items-center w-fit p-1 text-accentContrastLight hover:text-accentContrast"
                >
                    <!--Todo: // [desing-ref] icon-->
                    <mat-icon class="material-icons w-fit h-fit text-xl leading-5 mr-xs"
                        >close</mat-icon
                    >
                    <span class="font-semibold text-sm">{{tFilter('delete_condition_group')}}</span>
                </button>
            </ng-container>
        </div>
    </ng-template>
</form>
