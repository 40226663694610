import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FieldNameComponent } from './field-name/field-name.component'
import { CommonDialogComponent } from './common-dialog/common-dialog.component'
import { MatButtonModule } from '@angular/material/button'
import { TranslocoModule } from '@ngneat/transloco'
import { UserAvatarComponent } from './user-avatar/user-avatar.component'

const componentsForExport = [FieldNameComponent, CommonDialogComponent, UserAvatarComponent]

@NgModule({
    declarations: [...componentsForExport],
    imports: [CommonModule, MatButtonModule, TranslocoModule],
    exports: componentsForExport,
})
export class CommonAppModule {}
