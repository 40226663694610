import { Injectable } from '@angular/core'
import { SessionStorageService } from './session-storage.service'
import { FilterGroup, View } from '../../models'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class FilterStorageService {
    constructor(private sessionStorageService: SessionStorageService) {}
    private readonly isSessionFilterSet$$ = new BehaviorSubject<boolean>(false)

    getSessionFilter(viewGuid: string) {
        return this.sessionStorageService.getSessionValue(this.getFilterKey(viewGuid))
    }

    setFilter(viewGuid: string, filterGroups: FilterGroup[]) {
        this.sessionStorageService.setSessionValue(
            this.getFilterKey(viewGuid),
            JSON.stringify(filterGroups),
        )
    }

    removeFilter(viewGuid: string) {
        this.sessionStorageService.removeSessionValue(this.getFilterKey(viewGuid))
    }

    isSessionFilterSet$(): Observable<boolean> {
        return this.isSessionFilterSet$$.asObservable()
    }

    isSessionFilterSetUpdate(view: View) {
        this.isSessionFilterSet$$.next(!!this.getSessionFilter(view.guid))
    }

    private getFilterKey(value: string) {
        return `filter-${value}`
    }
}
