import { FieldTypes, ResponseFieldEntities } from '../response'
import { SelectObjectOptions, SelectOption } from '../response/select-object-options'
import { getIconByFieldType, OperationCode, prepareOperationCode } from './field-type.model'
import { FolderName, FolderNameEntities } from '../response/folder-names'
import { Folder } from './folder.model'
import { reduce } from 'lodash-es'
import { BusinessRecords } from './base-record.model'
import { getBoolean } from '../../global-util'

export interface Field {
    guid: string
    field_type_code: FieldTypes | string
    name?: string
    is_system?: number
    is_required?: number
    is_primary?: number
    folder_name?: FolderName
    configure_json?: string
    revision?: number
    frontend_validations?: string
    shared_with_folder?: string
    folder_guid?: string
    system_name?: string
    select_object_field?: SelectObjectOptions
    select_object_field_sorted?: SelectOption[]
    operationCode?: OperationCode
    link_definition?: LinkDefinition
    virtual_link?: string
}

export interface LinkDefinition {
    target_solution_object_type_guid: string
    target_object_field_guids: string[]
}

export interface FieldEntities {
    [guid: string]: Field
}

export type AddFieldData = Partial<Field> & {
    field_type_code: FieldTypes | string
}

export function getFieldIconFromEntitiesByGuid(fields: FieldEntities, fieldGuid: string) {
    if (fields && fields[fieldGuid]) {
        return getIconByFieldType(fields[fieldGuid].field_type_code)
    }
    return undefined
}

export function getFieldIcon(field: Field) {
    if (field) {
        return getIconByFieldType(field.field_type_code)
    }
    return undefined
}

export function generateFieldEntities(
    responseFieldEntities: ResponseFieldEntities,
    folderNames: FolderNameEntities,
): FieldEntities {
    let global_name: FolderName = {
        name: folderNames[Object.keys(folderNames).find((key) => folderNames[key].is_global)!].name,
        is_global: 1,
    }
    return Object.keys(responseFieldEntities).reduce((res: FieldEntities, fieldGuid: string) => {
        const responseField = responseFieldEntities[fieldGuid]
        if (
            responseField.acl === -1 ||
            (responseField.folder_guid && !folderNames[responseField.folder_guid])
        ) {
            return res
        }

        res[fieldGuid] = {
            guid: fieldGuid,
            folder_name: responseField.folder_guid
                ? folderNames[responseField.folder_guid]
                : global_name,
            ...responseField,
            operationCode: prepareOperationCode(responseField.operation_code),
        }

        return res
    }, {})
}

export function findFieldByType(selectedFolder: Folder, fields: FieldEntities, type: FieldTypes) {
    return Object.values(fields)
        .filter((field) => field.field_type_code === type)
        .find((field) => {
            const areGuidsEqual = field.folder_guid === selectedFolder.guid
            const globalValue =
                !!field.folder_name?.is_global && getBoolean(selectedFolder.is_global.value)

            return areGuidsEqual || globalValue
        })!
}

export function findBySystemNameInFolderOrGlobalFolder(
    obj: FieldEntities,
    name: string,
    folder: Folder,
) {
    const fields = Object.values(obj).filter((field) => field.system_name === name)
    const field = fields.find((field) => field.folder_guid === folder.guid)

    if (field) {
        return field
    }

    return fields.find((field) => !!field.folder_name?.is_global) as Field
}

export function formAllFields(record: BusinessRecords, folder?: Folder | undefined) {
    const foldersCells = ['status', 'assignee', 'description'] as const

    const folderFieldGuids = foldersCells.reduce((array, fieldName) => {
        const cellEntities = record[fieldName]
        if (!cellEntities || typeof cellEntities !== 'object') return array

        const filteredCells = Object.keys(cellEntities)
            .filter((folderGuid) => {
                return folder?.guid !== folderGuid
            })
            .map((folderGuid) => cellEntities[folderGuid].fieldGuid)

        return [...array, ...filteredCells]
    }, [] as string[])

    const noNameFieldGuids = reduce(
        record.noNameCells,
        (array, value) => [...array, value.fieldGuid],
        [] as string[],
    )

    return [...noNameFieldGuids, ...folderFieldGuids]
}
