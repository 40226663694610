import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Field } from '../../../../../@core/models'
import { DialogStatusInput } from '../status.component'
import cloneDeep from 'lodash/cloneDeep'

@Component({
    selector: 'app-status-dialog',
    templateUrl: './status-dialog.component.html',
    styleUrls: ['./status-dialog.component.sass'],
})
export class StatusDialogComponent implements OnInit {
    field!: Field
    fieldCopy!: Field
    active!: string | undefined
    default!: string
    @Output()
    cellValueChanged: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        public dialogRef: MatDialogRef<StatusDialogComponent, Field | undefined>,
        @Inject(MAT_DIALOG_DATA) public data: DialogStatusInput,
    ) {}

    ngOnInit(): void {
        this.field = this.data.field
        this.fieldCopy = cloneDeep(this.field)
        this.active = this.data.active
        this.default = this.data.default
    }

    closeDialog() {
        this.dialogRef.close()
    }

    saveStatusSettings() {
        this.dialogRef.close(this.fieldCopy)
    }

    cellValueChangedDialog(field: Field) {
        this.fieldCopy = field
    }

    defaultChangedDialog(guid: string) {
        this.default = guid
    }
}
