<app-card-activity-item [activity]="groupActivities[0]"> </app-card-activity-item>
<ng-container *transloco="let tListCardActivity; read: 'list.list_card.list_card_activity'">
    <mat-card-actions *ngIf="groupActivities.length > 1">
        <button (click)="showMoreActivities()" mat-button>
            {{tListCardActivity('show_more', {activities: groupActivities.length - 1})}}
        </button>
    </mat-card-actions>
</ng-container>

<ng-container *ngIf="isShowMore">
    <app-card-activity-item *ngFor="let activity of groupActivitiesMore" [activity]="activity">
    </app-card-activity-item>
</ng-container>
