import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import {
    addViews,
    AppState,
    deleteViews,
    initViews,
    selectAllViews,
    selectAvailableViewTypes,
    selectSelectedView,
    selectSelectedTableSchemaViews,
    selectViewEntities,
    selectViewsAreLoaded,
    setSelectedViewGuid,
    updateViews,
    viewsAreLoaded,
} from '../../@ngrx'
import { Deleted, View } from '../../models'
import { Update } from '@ngrx/entity'

@Injectable({
    providedIn: 'root',
})
export class ViewFacadeService {
    constructor(private store: Store<AppState>) {}

    selectViewEntities$ = this.store.select(selectViewEntities)

    selectAllViews$ = this.store.select(selectAllViews)

    selectViewsAreLoaded$ = this.store.select(selectViewsAreLoaded)

    selectSelectedView$ = this.store.select(selectSelectedView)

    selectAvailableViewTypes$ = this.store.select(selectAvailableViewTypes)

    selectSelectedWorkspaceViews$ = this.store.select(selectSelectedTableSchemaViews)

    initViews(views: View[], selectedViewGuid?: string) {
        this.store.dispatch(initViews({ views, selectedViewGuid }))
    }

    viewsAreLoaded() {
        this.store.dispatch(viewsAreLoaded())
    }

    setSelectedViewGuid(selectedViewGuid: string | null) {
        this.store.dispatch(setSelectedViewGuid({ selectedViewGuid }))
    }

    addViews(views: View[]) {
        this.store.dispatch(addViews({ views }))
    }

    updateViews(views: Update<View>[]) {
        this.store.dispatch(updateViews({ views }))
    }

    deleteViews(views: Deleted[]) {
        this.store.dispatch(deleteViews({ views }))
    }
}
