import { Component, Input } from '@angular/core'
import { ContactActivity } from '../../../contact-activity-json'

@Component({
    selector: 'app-card-activity-item',
    templateUrl: './list-card-activity-item.component.html',
    styleUrls: ['./list-card-activity-item.component.sass'],
})
export class ListCardActivityItemComponent {
    @Input()
    activity!: ContactActivity
}
