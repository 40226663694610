<form *ngIf="textFormControl" class="edit-mode-form">
    <mat-form-field class="noHintError" appearance="outline">
        <input
            (keypress)="controlMaxLength()"
            (paste)="controlLengthMaxLength()"
            [formControl]="textFormControl"
            autocomplete="off"
            matInput
            name="name"
            type="text"
        />
        <mat-error *ngIf="textFormControl.hasError('required')"></mat-error>
    </mat-form-field>
</form>
