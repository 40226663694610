import { Component } from '@angular/core'
import { Observable } from 'rxjs'
import { CommonFacadeService } from '../@core/services/store-facade'

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.sass'],
})
export class LoadingComponent {
    loading$: Observable<boolean> = this.commonFacadeService.selectLoading$

    constructor(private commonFacadeService: CommonFacadeService) {}
}
