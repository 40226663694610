<div
    (click)="$event.stopPropagation()"
    class="max-sm:mt-4 filter-area text-sm leading-4"
    *transloco="let tSort; read: 'sort'"
>
    <div class="flex justify-between items-center mb-4">
        <span class="text-primaryDark font-semibold">{{tSort('sort_by')}}</span>
        <div
            *ngIf="sortParams.length > 0"
            (click)="onClear()"
            class="flex items-center cursor-pointer text-secondaryMainLight hover:text-secondaryMain font-semibold"
        >
            <span class="material-icons w-5 h-5 text-xl leading-5 mr-1">close</span>
            <span>{{tSort('clear_all')}}</span>
        </div>
    </div>

    <ng-container *ngIf="sortParams.length === 0; else sortPrimary">
        <div class="flex items-center mb-4">
            <mat-icon
                class="w-5 h-5 text-xl leading-5 mr-2 text-primarySecondary cursor-pointer"
                svgIcon="custom_drag"
            ></mat-icon>
            <div
                class="flex items-center h-10 w-80 content-center cursor-pointer hover:bg-white border border-primaryLight border-solid rounded-lg py-2 px-3"
            >
                Select
            </div>
            <div class="h-8 w-8 px-2">
                <mat-icon
                    svgIcon="custom_sort_up"
                    class="cursor-pointer h-4 w-4 text-base leading-4 text-primarySecondary"
                ></mat-icon>
                <mat-icon
                    svgIcon="custom_sort_down"
                    class="cursor-pointer h-4 w-4 text-base leading-4 text-primarySecondary"
                ></mat-icon>
            </div>
            <span class="material-icons cursor-pointer w-6 h-6 text-2xl leading-6 mr-1">close</span>
        </div>
    </ng-container>
    <ng-template #sortPrimary>
        <ng-container *ngFor="let param of sortParams; let i = index">
            <div class="flex items-center mb-4">
                <mat-icon
                    class="w-5 h-5 text-xl leading-5 mr-2 text-primarySecondary cursor-pointer"
                    svgIcon="custom_drag"
                ></mat-icon>
                <div
                    class="flex items-center h-10 w-100 content-center cursor-pointer hover:bg-white border border-primaryLight border-solid rounded-lg py-2 px-3"
                >
                    {{param.name}}
                </div>
                <div class="h-8 w-8 px-2">
                    <mat-icon
                        [class.text-secondaryMain]="i > 0"
                        svgIcon="custom_sort_up"
                        class="cursor-pointer h-4 w-4 text-base leading-4 text-primarySecondary"
                    ></mat-icon>
                    <mat-icon
                        [class.text-secondaryMain]="i === 0"
                        svgIcon="custom_sort_down"
                        class="cursor-pointer h-4 w-4 text-base leading-4 text-primarySecondary"
                    ></mat-icon>
                </div>
                <span class="material-icons cursor-pointer w-6 h-6 text-2xl leading-6 mr-1"
                    >close</span
                >
            </div>
        </ng-container>
    </ng-template>

    <button
        class="flex items-center justify-center p-1 mb-4 text-secondaryMainLight hover:text-secondaryMain"
    >
        <mat-icon class="material-icons w-fit h-fit text-xl leading-5 mr-xs"
            >add_circle_outline</mat-icon
        >
        <span class="font-semibold">{{tSort('add_sort')}}</span>
    </button>

    <div class="flex max-sm:flex-col items-center">
        <button
            [class.invisible]="!applyForSession"
            class="flex items-center justify-center w-100 sm:w-fit h-11 sm:h-7 py-3 px-6 sm:p-1 max-sm:mb-4 sm:mr-auto whitespace-nowrap rounded-lg max-sm:border max-sm:border-solid max-sm:border-accentContrast cursor-pointer text-accentContrastLight hover:text-accentContrast"
        >
            <span class="material-icons w-5 h-5 text-xl leading-5 mr-1">close</span>
            <span class="font-semibold">{{tSort('reset')}}</span>
        </button>
        <div
            [class.savedOrApplied]="saved || applyForSession"
            class="flex items-center justify-end w-100"
        >
            <button
                (click)="onApplyForSession()"
                class="mx-4 max-sm:ml-0 max-sm:p-0 py-2.5 px-5 font-semibold w-100 sm:w-fit max-sm:h-11 text-primarySecondary rounded-lg border border-primarySecondary border-solid applyBtn"
            >
                {{tSort('apply_session')}}
            </button>
            <button
                (click)="onSaveSort()"
                class="max-sm:p-0 py-2.5 px-5 font-semibold w-100 sm:w-fit max-sm:h-11 text-white rounded-lg bg-primarySecondary saveBtn"
            >
                {{tSort('save')}}
            </button>
        </div>
    </div>
</div>
