import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BoardColComponent } from './board-col/board-col.component'
import { BoardComponent } from './board.component'
import { BoardCardComponent } from './board-card/board-card.component'
import { BoardNewComponent } from './board-new/board-new.component'
import { BoardTitleComponent } from './board-title/board-title.component'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { MatBadgeModule } from '@angular/material/badge'
import { MatMenuModule } from '@angular/material/menu'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatTableModule } from '@angular/material/table'
import { ReactiveFormsModule } from '@angular/forms'
import { CellTypesModule } from '../cell-types/cell-types.module'
import { MatExpansionModule } from '@angular/material/expansion'
import { BoardCardUpdateComponent } from './board-card/board-card-update/board-card-update.component'
import { BoardCardSubtitleComponent } from './board-card/board-card-subtitle/board-card-subtitle.component'
import { BoardCardStatusComponent } from './board-card/board-card-status/board-card-status.component'
import { BoardCardMainFieldsComponent } from './board-card/board-card-main-fields/board-card-main-fields.component'
import { BoardCardHeaderComponent } from './board-card/board-card-header/board-card-header.component'
import { BoardCardFieldsComponent } from './board-card/board-card-fields/board-card-fields.component'
import { TranslocoModule } from '@ngneat/transloco'
import { CommonAppModule } from '../../common/common.module'
import { CommonViewModule } from '../common/common.module'
import { MatCheckboxModule } from '@angular/material/checkbox'

@NgModule({
    declarations: [
        BoardColComponent,
        BoardComponent,
        BoardCardComponent,
        BoardNewComponent,
        BoardTitleComponent,
        BoardCardUpdateComponent,
        BoardCardSubtitleComponent,
        BoardCardStatusComponent,
        BoardCardMainFieldsComponent,
        BoardCardHeaderComponent,
        BoardCardFieldsComponent,
    ],
    imports: [
        CommonModule,
        DragDropModule,
        MatBadgeModule,
        MatMenuModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDialogModule,
        MatIconModule,
        MatTableModule,
        ReactiveFormsModule,
        CellTypesModule,
        MatExpansionModule,
        TranslocoModule,
        CommonViewModule,
        CommonAppModule,
        MatCheckboxModule,
    ],
    exports: [BoardComponent, BoardCardComponent],
})
export class BoardModule {}
