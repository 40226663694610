import {
    AfterContentInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core'
import { moveItemInArray } from '@angular/cdk/drag-drop'
import {
    AddFieldData,
    BusinessRecords,
    Cell,
    CellEntities,
    Field,
    FieldType,
    Folder,
    RecordGroup,
    ViewData,
} from '../../../@core/models'
import { AddColumnComponent } from './add-column/add-column.component'
import { MatDialog } from '@angular/material/dialog'
import { ShareFieldComponent } from './share-field/share-field.component'
import { ChangeFieldFolderComponent } from './change-field-folder/change-field-folder.component'
import { Dictionary } from '@ngrx/entity'
import { DropColumns, MovedRow, ResizeColumn, TableColumn, TableRow } from './table/table.component'
import { isNumber } from 'lodash-es'
import { Sort } from '@angular/material/sort'
import { SelectObjectOptions } from '../../../@core/models/response/select-object-options'

@Component({
    selector: 'app-business-record-table',
    templateUrl: './business-record-table.component.html',
    styleUrls: ['./business-record-table.component.sass'],
})
export class BusinessRecordTableComponent implements AfterContentInit, OnChanges, OnInit {
    @Input() tableData!: ViewData | undefined
    @Input() dropRowData!: BusinessRecords[] | undefined
    // todo: [table-ref] we pass the same data to the "records" and to the "group"
    @Input() records!: BusinessRecords[] | undefined
    @Input() isGrouped!: boolean
    @Input() sortActive!: string | undefined
    @Input() isShowCheckbox!: boolean

    // todo: [table-ref] we pass the same data to the "records" and to the "group"
    @Input() group!: RecordGroup | undefined
    @Input() isNewRecordRow!: boolean
    @Input() selectedRecords: BusinessRecords[] = []
    @Input() newRecordGroup?: RecordGroup
    @Input() currentUser?: string
    @Input() fieldTypes!: Dictionary<FieldType>
    @Input() selectedFolder!: Folder | undefined | null
    @Input() cells!: { [recordGuid: string]: CellEntities }
    @Input() isFilter = false

    @Output() sortColumnClear: EventEmitter<string> = new EventEmitter<string>()
    @Output() dropColumn: EventEmitter<{
        columnOrder?: string[]
        columnPinned?: string[]
    }> = new EventEmitter<{
        columnOrder?: string[]
        columnPinned?: string[]
    }>()
    @Output() moveColumnToStart: EventEmitter<string[]> = new EventEmitter<string[]>()
    @Output() moveColumnToEnd: EventEmitter<string[]> = new EventEmitter<string[]>()
    @Output() showColumn: EventEmitter<string[]> = new EventEmitter<string[]>()
    @Output() copyField: EventEmitter<Field> = new EventEmitter<Field>()
    @Output() deleteRecord: EventEmitter<BusinessRecords> = new EventEmitter<BusinessRecords>()
    @Output() updateRecord: EventEmitter<{ data: BusinessRecords; cell: Cell; value: any }> =
        new EventEmitter<{ data: BusinessRecords; cell: Cell; value: any }>()
    @Output() deleteColumn: EventEmitter<string> = new EventEmitter<string>()
    @Output() pinColumn: EventEmitter<string> = new EventEmitter<string>()
    @Output() unPinColumn: EventEmitter<string[]> = new EventEmitter<string[]>()
    @Output() hideColumn: EventEmitter<string> = new EventEmitter<string>()
    @Output() selectRecord: EventEmitter<BusinessRecords> = new EventEmitter<BusinessRecords>()
    @Output() sortColumn: EventEmitter<string> = new EventEmitter<string>()
    @Output() saveCreateRecord: EventEmitter<BusinessRecords> = new EventEmitter<BusinessRecords>()
    @Output() abandonCreateRecord: EventEmitter<any> = new EventEmitter<any>()
    @Output() sharedField: EventEmitter<Field> = new EventEmitter<Field>()
    @Output() sharedRecord: EventEmitter<BusinessRecords> = new EventEmitter<BusinessRecords>()
    @Output() updatedField: EventEmitter<Field> = new EventEmitter<Field>()
    @Output() moveFieldToFolder: EventEmitter<any> = new EventEmitter<any>()
    @Output() resizeColumn: EventEmitter<any> = new EventEmitter<any>()
    @Output() resetColumnWidth: EventEmitter<any> = new EventEmitter<any>()
    @Output() moveRow: EventEmitter<any> = new EventEmitter<any>()

    isResizable: boolean = false
    isPinAvailable!: boolean
    doesHaveColGroups = false
    rows: TableRow[] = []
    columns: TableColumn[] = []
    selectedRowItems: TableRow[] = []

    constructor(public dialog: MatDialog) {}

    ngAfterContentInit() {
        this.isResizable = true
    }

    ngOnInit() {
        this.checkIfTableHasColGroups()

        if (!this.records) return
        this.prepareRowsAndColumns(this.records)
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.sortActive) {
            this.sortActive = changes.sortActive.currentValue
        } else if (changes.isNewRecordRow) {
            this.isNewRecordRow = changes.isNewRecordRow.currentValue
        } else if (changes.tableData) {
            console.log('changes.tableData.currentValue', changes.tableData.currentValue)
            this.tableData = changes.tableData.currentValue
            this.checkIfTableHasColGroups()
            if (!this.records) return
            this.prepareRowsAndColumns(this.records)
        } else if (changes.dropRowData) {
            this.dropRowData = changes.records.currentValue
            console.log('this.records', this.records)
        } else if (changes.selectedRecords) {
            this.selectedRowItems = this.recordsToRow(changes.selectedRecords.currentValue)
        }
    }

    prepareRowsAndColumns(records: BusinessRecords[]) {
        this.rows = this.recordsToRow(records)

        const tableColumns = this.tableData?.columns
        if (!tableColumns) return

        this.columns = tableColumns.columns.map((fieldGuid) => {
            const colGroup = tableColumns.colGroups && tableColumns.colGroups[fieldGuid]
            const colWidth = tableColumns.columnsWidth && tableColumns.columnsWidth[fieldGuid]

            let column: TableColumn = {
                isPinned: this.isPinned(fieldGuid),
                item: { guid: fieldGuid },
            }

            if (colGroup) {
                column.colGroup = colGroup
            }

            if (isNumber(colWidth)) {
                column.colWidth = colWidth
            }

            return column
        })

        this.isPinAvailable = !!this.tableData?.selectedView?.columns_pinned?.value?.length
    }

    recordsToRow(records: BusinessRecords[]) {
        return records.map((record) => ({ item: record }))
    }

    movedFieldToFolder(field: Field) {
        const dialogRef = this.dialog.open<ChangeFieldFolderComponent, Field>(
            ChangeFieldFolderComponent,
            {
                width: '300px',
                height: '200px',
                data: field,
            },
        )
        dialogRef.afterClosed().subscribe((res: Field | undefined) => {
            field = res ? res : field
            console.log(res)
            this.moveFieldToFolder.emit(field)
        })
    }

    isPinned(guid: string) {
        return this.tableData?.columns.pinnedColumns
            ? this.tableData!.columns.pinnedColumns.includes(guid)
            : false
    }

    isColumnWidth(guid: string) {
        if (this.tableData?.columns.columnsWidth) {
            return !!this.tableData.columns.columnsWidth[guid]
        }
        return false
    }

    updateRecordFn(data: BusinessRecords, cell: Cell, value: any) {
        this.updateRecord.emit({ data, cell, value })
    }

    checkIfTableHasColGroups() {
        const colGroups = this.tableData?.columns?.colGroups
        const colGroupLength = colGroups && Object.keys(colGroups).length
        this.doesHaveColGroups = !!colGroupLength
    }
    deleteRecordFn(data: BusinessRecords) {
        this.deleteRecord.emit(data)
    }

    copyFieldFn(guid: string) {
        const field = this.tableData!.fields[guid]
        this.createFieldFn({
            ...field,
            name: field.name + ' copy',
        })
    }

    createFieldFn(field: Field) {
        this.copyField.emit(field)
    }

    shareRecord(record: BusinessRecords) {
        this.sharedRecord.emit(record)
    }

    shareField(field: Field) {
        const dialogRef = this.dialog.open<ShareFieldComponent, Field>(ShareFieldComponent, {
            width: '400px',
            height: '200px',
            data: field,
        })

        dialogRef.afterClosed().subscribe((res: Field | undefined) => {
            if (res) {
                field = res ? res : field
                console.log(res)
                this.sharedField.emit(field)
            }
        })
    }

    updateField(field: Field) {
        const dialogRef = this.dialog.open<AddColumnComponent, AddFieldData, Field | undefined>(
            AddColumnComponent,
            {
                width: '400px',
                data: {
                    ...field,
                },
            },
        )
        dialogRef.afterClosed().subscribe((res: Field | undefined) => {
            if (res) {
                field = res ? res : field
                console.log(res)
                this.updatedField.emit(field)
            }
        })
    }

    deleteFieldFn(guid: string) {
        this.deleteColumn.emit(guid)
    }

    // todo: [table-ref] moveColumnToStartFn and moveColumnToEndFn the same event output
    moveColumnToStartFn(guid: string) {
        if (this.tableData) {
            const columnsArr = this.selectColumnsFromSelectedView()

            columnsArr.splice(columnsArr.indexOf(guid), 1)
            columnsArr.splice(0, 0, guid)
            this.moveColumnToStart.emit(columnsArr)
        }
    }

    moveColumnToEndFn(guid: string) {
        if (!this.tableData || !this.tableData.columns.columnsOrder) return

        const columnsArr = this.tableData.columns.columnsOrder
        columnsArr.splice(columnsArr.indexOf(guid), 1)
        columnsArr.splice(columnsArr.length, 0, guid)
        this.moveColumnToEnd.emit(columnsArr)
    }

    showColumnFn(item: string) {
        if (this.tableData && this.tableData.columns.hiddenColumns) {
            let hiddenColumns = [...this.tableData.columns.hiddenColumns]
            hiddenColumns.splice(hiddenColumns.indexOf(item), 1)
            this.showColumn.emit(hiddenColumns)
        }
    }

    pinColumnFn(guid: string) {
        if (this.tableData && this.tableData.columns.pinnedColumns) {
            let arr = [...this.tableData.columns.pinnedColumns]
            arr.push(guid)
            const value = arr.join(',')
            this.pinColumn.emit(value)
        }
    }

    unPinColumnFn(guid: string) {
        if (this.tableData && this.tableData.columns.pinnedColumns) {
            let pinnedColumns = [...this.tableData.columns.pinnedColumns]
            pinnedColumns.splice(pinnedColumns.indexOf(guid), 1)
            this.unPinColumn.emit(pinnedColumns)
        }
    }

    hideColumnFn(item: string) {
        if (this.tableData && this.tableData.columns.hiddenColumns) {
            let arr = [...this.tableData.columns.hiddenColumns]
            if (arr.indexOf(item) == -1) {
                arr.push(item)
            }
            const value = arr.join(',')
            this.hideColumn.emit(value)
        }
    }

    sortColumnClearFn(guid: string) {
        this.sortColumnClear.emit(guid)
    }

    isSortAvailable(guid: string | undefined) {
        return this.sortActive == guid
    }

    sortColumnFn(guid: string) {
        this.sortColumn.emit(guid)
    }

    dropColumnFn({ columns, previousIndex, currentIndex }: DropColumns) {
        this.moveColumnInArray({
            columns: columns,
            previousIndex,
            currentIndex,
        })
    }

    dropRow(event: MovedRow) {
        this.moveRecordBetweenGroups(event)
    }

    moveRecordBetweenGroups(event: MovedRow) {
        const field = this.group?.field!
        const value = this.group?.value!

        this.moveRow.emit({
            record: event.item,
            cell: this.cells[event.item.guid][field.guid],
            value: value,
        })
    }

    resizeColumnFn({ column, width }: ResizeColumn) {
        this.resizeColumn.emit({ guid: column.item.guid, width })
    }

    clearSorting() {
        if (!this.records) return
        this.prepareRowsAndColumns(this.records)
    }

    sortData(sort: Sort) {
        const sortGuid = sort.active

        if (!this.records) return
        const sortedData = [...this.records].sort((a: BusinessRecords, b: BusinessRecords) => {
            const isAsc = sort.direction === 'asc'

            return this.compare(a, b, sortGuid, isAsc)
        })

        this.prepareRowsAndColumns(sortedData)
    }

    private moveColumnInArray({ columns, previousIndex, currentIndex }: DropColumns) {
        if (
            !this.tableData ||
            !this.tableData.columns.pinnedColumns ||
            !this.tableData.selectedView
        ) {
            return
        }

        if (!this.tableData) return

        moveItemInArray(columns, previousIndex, currentIndex)

        const lastAffectedIndex = this.getLastIndex(currentIndex, previousIndex)
        const affectedColumns = this.getColumnGuids(columns)

        affectedColumns.splice(lastAffectedIndex, affectedColumns.length)

        const columnsOrder = this.selectColumnsFromSelectedView()

        if (lastAffectedIndex >= columnsOrder.length) {
            this.dropColumn.emit({ columnOrder: affectedColumns })
            return
        }

        columnsOrder.splice(0, lastAffectedIndex)

        this.dropColumn.emit({ columnOrder: affectedColumns.concat(columnsOrder) })
    }

    private getLastIndex(currentIndex: number, previousIndex: number) {
        const indexModifier = this.tableData?.selectedView?.columns_pinned.value.length ? 2 : 1
        return indexModifier + (previousIndex > currentIndex ? previousIndex : currentIndex)
    }

    private getColumnGuids(columns: TableColumn[]): string[] {
        return columns.map((column) => column.item.guid)
    }

    private compare(a: BusinessRecords, b: BusinessRecords, sortGuid: string, isAsc: boolean) {
        const sortField = this.tableData?.fields[sortGuid]
        const aSortValue = this.cells[a.guid][sortGuid].value
        const bSortValue = this.cells[b.guid][sortGuid].value

        if (sortField?.select_object_field) {
            return this.selectFieldsCompare(
                sortField.select_object_field,
                aSortValue,
                bSortValue,
                isAsc,
            )
        }

        if (!Number.isNaN(Number(aSortValue)) && !Number.isNaN(Number(bSortValue))) {
            return this.compareFunc(Number(aSortValue), Number(bSortValue), isAsc)
        }

        return this.compareFunc(aSortValue.toLowerCase(), bSortValue.toLowerCase(), isAsc)
    }

    private selectFieldsCompare(
        selectObject: SelectObjectOptions,
        aValue: string,
        bValue: string,
        isAsc: boolean,
    ) {
        const selectLabel = (sortValue: string) => selectObject[sortValue]?.label ?? 'unknown'
        return this.compareFunc(
            selectLabel(aValue).toLowerCase(),
            selectLabel(bValue).toLowerCase(),
            isAsc,
        )
    }

    private compareFunc(a: string | number, b: string | number, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1)
    }

    private selectColumnsFromSelectedView() {
        if (!this.tableData) return []

        const columnsOrderString = this.tableData.selectedView?.columns_order.value

        return columnsOrderString?.length === 0 ? [] : columnsOrderString?.split(',') ?? []
    }
}
