import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { catchError, concatMap, mergeMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { UpdateService } from '../../services'
import { CreateFolderAcl, CreateFolderViews, Folder } from '../../models'
import { createFolderRequest, createFolderRequestSuccess } from './folder.actions'
import { onMessage, setError, setLoading } from '../common'
import { AppState } from '../app.state'

@Injectable()
export class FolderEffects implements OnInitEffects {
    createFolder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createFolderRequest),
            tap(() => {
                console.log('createFolder$ Effect')
                this.store.dispatch(setLoading({ loading: true }))
            }),
            concatMap(
                (action: {
                    folder: Partial<Folder>
                    views: CreateFolderViews
                    acl: CreateFolderAcl
                }) => {
                    console.log('createFolder$ Effect switchMap')
                    return this.updateService
                        .createFolder(action.folder, action.views, action.acl)
                        .pipe(
                            mergeMap((data) => {
                                return [
                                    createFolderRequestSuccess(),
                                    onMessage({ response: data }),
                                    setLoading({ loading: false }),
                                ]
                            }),
                        )
                },
            ),
            catchError((error: Error) => of(setError({ error: error.stack ?? null }))),
        ),
    )

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private updateService: UpdateService,
    ) {}

    ngrxOnInitEffects(): Action {
        return { type: '[FolderEffects]: Init' }
    }
}
