import { Injectable } from '@angular/core'
import { RecordUpdate, View } from '../../../@core/models'
import { RecordsService } from '../../../@core/services/records.service'
import { GroupStorageService } from '../../../@core/services/session-storage/group-storage.service'

@Injectable({
    providedIn: 'root',
})
export class GroupService {
    constructor(
        private recordsService: RecordsService,
        private groupStorageService: GroupStorageService,
    ) {}

    getGroupByView(view: View) {
        return this.getSessionGroupFromView(view) ?? view.group.value
    }

    saveGroupData(view?: View, guid?: string) {
        if (!view || guid === view.group.value) {
            return
        }

        const data: RecordUpdate = {
            record: view,
            cell: view.group,
            value: guid ?? '',
        }

        this.recordsService.updateRecord(data)
    }

    private getSessionGroupFromView(view: View): string | undefined {
        return this.groupStorageService.getSessionGroup(view.guid)
    }
}
