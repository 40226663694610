import { Observable } from 'rxjs'
import { ObjectResponseModel } from './index'

export interface IWsService {
    connect(): Observable<void>

    disconnect(): Observable<any>

    onMessage(endpoint: string): Observable<ObjectResponseModel>

    sendMessage(endpoint: string, body?: any): void

    invokeMessage(endpoint: string, body?: any): Observable<ObjectResponseModel>

    stopConnection(): void
}
