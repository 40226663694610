import { createFeatureSelector, createSelector } from '@ngrx/store'
import { viewAdapter, viewFeature, ViewState } from './view.reducer'
import { AppState } from '../app.state'
import { Dictionary } from '@ngrx/entity'
import { getSystemRecordsByParentGuid, Schema, SolutionModel, View } from '../../models'
import { selectSolution } from '../common'
import { selectSelectedTableSchema, selectSelectedTableSchemaGuid } from '../schema'
import { selectSelectedFolderGuid } from '../folder'

const selectViewState = createFeatureSelector<ViewState>('VIEW')

const { selectIds, selectEntities, selectAll, selectTotal } =
    viewAdapter.getSelectors(selectViewState)

export const selectViewIds = selectIds
export const selectViewEntities = selectEntities
export const selectAllViews = selectAll
export const selectViewTotal = selectTotal

export const selectViewsAreLoaded = viewFeature.selectAreLoaded

export const selectSelectedView = createSelector<
    AppState,
    [Dictionary<View>, string | null],
    View | undefined
>(
    selectViewEntities,
    viewFeature.selectSelectedViewGuid,
    (viewDictionary: Dictionary<View>, selectedViewGuid: string | null) => {
        console.info('selectSelectedView')
        if (!selectedViewGuid) return

        return viewDictionary[selectedViewGuid]
    },
)

export const selectAvailableViewTypes = createSelector<
    AppState,
    [Schema | undefined, SolutionModel],
    string[]
>(
    selectSelectedTableSchema,
    selectSolution,
    (schema: Schema | undefined, solution: SolutionModel) => {
        if (!schema || !schema.object_type_code) return []

        console.log('schema.object_type_code', schema.object_type_code)
        console.log('solution.setting', solution.setting)
        return solution.setting[schema.object_type_code].view
    },
)

export const selectSelectedTableSchemaViews = createSelector<
    AppState,
    [View[], string | null, string | null],
    View[]
>(
    selectAllViews,
    selectSelectedTableSchemaGuid,
    selectSelectedFolderGuid,
    (views: View[], selectedSchemaGuid: string | null, selectedFolderGuid: string | null) => {
        console.info('selectSelectedTableSchemaViews')
        if (!selectedSchemaGuid) return []

        return getSystemRecordsByParentGuid<View>(views, selectedSchemaGuid, selectedFolderGuid)
    },
)
