<form class="edit-mode-form">
    <div [class.active]="isUpdateAvailable" class="edit-mode-area">
        <input
            ngxDaterangepickerMd
            class="data-input"
            [placeholder]="valueLocale"
            [singleDatePicker]="true"
            [autoApply]="true"
            [customRangeDirection]="false"
            [showDropdowns]="true"
            (change)="filterFrom()"
            [appAutoFocus]="isEdit"
            autocomplete="off"
        />
        <mat-icon (click)="clearDate()" *ngIf="isUpdateAvailable && value" class="text-muted">
            close
        </mat-icon>
    </div>
    <div *ngIf="!isUpdateAvailable">
        <label> {{valueLocale}} </label>
    </div>
</form>
