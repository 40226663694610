import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { generateUuid } from '../@core/global-util'

export interface PinRecord {
    guid: string
    name: string
}

export interface PinDraftRecord {
    name: string
    data: DraftPinRecord
    guid: string
}

export interface DraftPinRecord {
    name: string
    description: string
    sotCode: string
    folder: string
    status: string
    watch: string
    assignee: string
}

export interface PinObject {
    type: PinObjectType
    record: PinRecord | PinDraftRecord
}

export enum PinObjectType {
    CREATED,
    DRAFT,
}

@Injectable({
    providedIn: 'root',
})
export class PinRecordService {
    private readonly pinObjects: Map<string, PinObject> = new Map()
    public readonly pinObjects$: Subject<PinObject[]> = new BehaviorSubject<PinObject[]>([])

    isRecordPinned(guid: string) {
        return this.pinObjects.has(guid)
    }

    pinDraftRecord(data: Partial<DraftPinRecord>) {
        this.updateDraftRecord(generateUuid(), data)
    }

    updateDraftRecord(guid: string, data: Partial<DraftPinRecord>) {
        const pinRecord = {
            name: data.name ? data.name : 'Draft',
            data,
            guid,
        }

        this.pinObjects.set(guid, { type: PinObjectType.DRAFT, record: pinRecord })
        this.pinObjects$.next(Array.from(this.pinObjects.values()))
    }

    pinRecord(guid: string, name: string) {
        if (this.pinObjects.has(guid)) return
        const pinRecord = {
            guid,
            name,
        }

        this.pinObjects.set(guid, { type: PinObjectType.CREATED, record: pinRecord })
        this.pinObjects$.next(Array.from(this.pinObjects.values()))
    }

    removePinRecord(guid: string) {
        this.pinObjects.delete(guid)
        this.pinObjects$.next(Array.from(this.pinObjects.values()))
    }
}
