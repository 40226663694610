import { Component, Input, OnInit } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ExpandMods, MenuItem, SideNavigationService } from './side-navigation.service'

@UntilDestroy()
@Component({
    selector: 'app-tb-side-navigation-layout',
    templateUrl: './tb-side-navigation-layout.component.html',
    styleUrls: ['tb-side-navigation-scroller.sass'],
})
export class TbSideNavigationLayoutComponent implements OnInit {
    @Input() menuItems!: MenuItem[]

    state$ = this.sideNavigationService.state$
    expandMods = ExpandMods
    hideMenuItemNames = false

    constructor(private sideNavigationService: SideNavigationService) {}

    toggleExpand() {
        this.sideNavigationService.toggleExpand()
    }

    toggleOpened() {
        this.sideNavigationService.toggleOpened()
    }

    ngOnInit(): void {
        this.sideNavigationService.state$.pipe(untilDestroyed(this)).subscribe((value) => {
            this.hideMenuItemNames = value.expand === ExpandMods.COLLAPSED
        })
    }

    openedChanged(newState: boolean, opened: boolean) {
        if (newState !== opened) {
            this.toggleOpened()
        }
    }
}
