import { Injectable } from '@angular/core'
import { WsService } from './'
import { ObjectResponseModel } from '../models'
import { switchMap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { SaveDataService } from './save-data.service'
import { CommonFacadeService } from './store-facade/common-facade.service'
import { NotificationService } from './notification.service'

@Injectable({
    providedIn: 'root',
})
export class WsProcessingService {
    constructor(
        private wsService: WsService,
        private saveDataService: SaveDataService,
        private commonFacadeService: CommonFacadeService,
        private notificationService: NotificationService,
    ) {}

    init() {
        console.log('init')
        this.wsService
            .connect()
            .pipe(
                switchMap(() => {
                    this.wsService.subOnMessage(environment.ws.endpoints.on_update)
                    this.wsService.sendMessage(environment.ws.endpoints.init)
                    return this.wsService.onMessage()
                }),
            )
            .subscribe((res) => {
                this.onMessage(res)
            })
    }

    logout() {
        console.log('logout')
        this.wsService.disconnect().subscribe()
    }

    public onMessage(response: ObjectResponseModel) {
        console.log(response)
        switch (response.status) {
            case environment.ws.status.success:
                this.parseSuccessResponse(response)
                break
            case environment.ws.status.error:
                this.parseErrorResponse(response)
                break
            default:
                throw new Error(`Incorrect response status: ${response.status}`)
        }
    }

    private parseSuccessResponse(response: ObjectResponseModel) {
        switch (response.code) {
            case environment.ws.response_types.init:
                this.parseInitResponse(response)
                break
            case environment.ws.response_types.solution_reset:
                this.logout()
                break
            case environment.ws.response_types.folder_create:
                this.parseResetResponse(response)
                break
            // TODO: check if better implement different methods for each update type
            case environment.ws.response_types.field_update:
            case environment.ws.response_types.record_delete:
            case environment.ws.response_types.record_update:
            case environment.ws.response_types.folder_delete:
            case environment.ws.response_types.config_create:
            case environment.ws.response_types.config_delete:
            case environment.ws.response_types.config_update:
                this.parseUpdateResponse(response)
                break
            default:
                throw new Error(`Incorrect response code: ${response.code}`)
        }
    }

    private parseErrorResponse(response: ObjectResponseModel) {
        if (!response.error) return

        console.log(response)
        this.commonFacadeService.setError(JSON.stringify(response))
        this.notificationService.openErrorNotification(response.error)
    }

    private parseUpdateResponse(response: ObjectResponseModel) {
        this.saveDataService.saveUpdatedData(response)
    }

    private parseInitResponse(response: ObjectResponseModel) {
        this.saveDataService.saveInitData(response)
    }

    private parseResetResponse(response: ObjectResponseModel) {
        this.saveDataService.saveResetData(response)
    }
}
