<form class="edit-mode-form">
    <div [class.active]="isUpdateAvailable" class="edit-mode-area">
        <div class="table-calendar">
            <input
                ngxDaterangepickerMd
                class="data-input"
                [placeholder]="dateLabel"
                [singleDatePicker]="false"
                [timePicker]="false"
                [customRangeDirection]="false"
                [showDropdowns]="false"
                [showCancel]="true"
                (change)="filterFrom()"
                autocomplete="off"
            />
        </div>
        <mat-icon (click)="clearDate()" *ngIf="isUpdateAvailable && value" class="text-muted">
            close
        </mat-icon>
    </div>
    <div *ngIf="!isUpdateAvailable">
        <label> {{dateLabel}} </label>
    </div>
</form>
