import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { NotificationComponent } from './notification.component'
import { TranslocoModule } from '@ngneat/transloco'

@NgModule({
    declarations: [NotificationComponent],
    imports: [CommonModule, MatSnackBarModule, TranslocoModule],
})
export class NotificationModule {}
