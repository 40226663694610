import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { FilterTypesComponent } from '../filter-types.component'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-name',
    templateUrl: './name-filter.component.html',
    styleUrls: ['./name-filter.component.sass'],
})
export class NameFilterComponent extends FilterTypesComponent implements OnInit {
    textFormControl!: FormControl<string | string[] | undefined | null>

    constructor() {
        super()
    }

    ngOnInit(): void {
        this.textFormControl = this.form.controls['value'] as FormControl
    }
}
