<div class="board-new" *transloco="let tBoardNew; read: 'board.board_new'">
    <form [formGroup]="formGroup">
        <div class="text-muted text-size-xxs flex-start-center">
            <span class=""> {{this.folderName}} </span>
        </div>
        <div class="w-100 mt-sm mb-md board-item-input">
            <mat-form-field class="add-new-board_text">
                <input
                    autocomplete="off"
                    (keyup)="this.nameFieldNotEmpty.emit(this.formGroup.valid)"
                    formControlName="name"
                    matInput
                    placeholder="{{tBoardNew('name_placeholder')}}"
                    required
                />
            </mat-form-field>
        </div>
        <div class="w-100 d-flex justify-content-end" *transloco="let tButtons; read: 'buttons'">
            <button (click)="saveBoardItemFn()" mat-flat-button>{{tButtons('save')}}</button>
        </div>

        <div class="board-new-nav">
            <button (click)="upBoardItemFn($event)" *ngIf="place; else defaultUp" mat-flat-button>
                <span class="material-icons text-size-sm text-primary"> arrow_upward </span>
            </button>
            <ng-template #defaultUp>
                <button (click)="upBoardItemFn($event)" mat-flat-button>
                    <span class="material-icons text-size-sm text-muted"> arrow_upward </span>
                </button>
            </ng-template>
            <button
                (click)="downBoardItemFn($event)"
                *ngIf="!place; else defaultDown"
                mat-flat-button
            >
                <span class="material-icons text-size-sm text-primary"> arrow_downward </span>
            </button>
            <ng-template #defaultDown>
                <button (click)="downBoardItemFn($event)" mat-flat-button>
                    <span class="material-icons text-size-sm text-muted"> arrow_downward </span>
                </button>
            </ng-template>
        </div>
    </form>
</div>
