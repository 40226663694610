import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslocoModule } from '@ngneat/transloco'
import { PinPanelComponent } from './pin-panel.component'
import { MatButtonModule } from '@angular/material/button'
import { MatMenuModule } from '@angular/material/menu'
import { MatInputModule } from '@angular/material/input'
import { ReactiveFormsModule } from '@angular/forms'
import { MatOptionModule } from '@angular/material/core'
import { MatSelectModule } from '@angular/material/select'
import { MatIconModule } from '@angular/material/icon'

import { RecordSettingsContentComponent } from './record-settings-content/record-settings-content.component'
import { WorkspaceBtnComponent } from './add-record-content/workspace-btn/workspace-btn.component'
import { AddRecordContentComponent } from './add-record-content/add-record-content.component'
import { CellTypesModule } from '../views/cell-types/cell-types.module'
import { PinRecordComponent } from './pin-record/pin-record.component'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatDialogModule } from '@angular/material/dialog'
import { AccessSelectorModule } from '../access-selector/access-selector.module'
import { AddRecordContentOldComponent } from './add-record-content-old/add-record-content-old.component'

@NgModule({
    declarations: [
        PinPanelComponent,
        RecordSettingsContentComponent,
        WorkspaceBtnComponent,
        AddRecordContentComponent,
        AddRecordContentOldComponent,
        PinRecordComponent,
    ],
    imports: [
        AccessSelectorModule,
        CommonModule,
        MatButtonModule,
        MatMenuModule,
        MatInputModule,
        ReactiveFormsModule,
        MatOptionModule,
        MatSelectModule,
        CellTypesModule,
        MatIconModule,
        TranslocoModule,
        MatExpansionModule,
        MatDialogModule,
    ],
    exports: [PinPanelComponent],
})
export class PinPanelModule {}
