<div *transloco="let tViewEdit; read: 'view_edit'">
    <div class="mb-1">{{tViewEdit('view_name')}}</div>
    <div class="relative">
        <input
            (input)="onInputChange(inputName.value)"
            value="{{viewName}}"
            class="inputCustom"
            #inputName
        />
        <div [class.amended]="isAmended" class="inputApproveBtn">
            <mat-icon>check</mat-icon>
        </div>
    </div>
</div>
