<div class="flex items-center w-initial h-10 m-4 px-4 rounded-lg bg-primaryDark">
    <div class="flex items-center justify-between w-full">
        <div *transloco="let tTable; read: 'table'">
            <mat-checkbox checked class="mr-2"></mat-checkbox>
            <span class="text-white"
                >{{tTable('actions_panel_count', {count: selectedCount})}}</span
            >
        </div>
        <div class="flex items-center">
            <div class="max-xsm:hidden">
                <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
            </div>
            <button (click)="openBottomSheetS()" class="xsm:hidden h-6">
                <mat-icon class="text-white"> more_vert </mat-icon>
            </button>
            <button (click)="clearAllSelected()" aria-label="Close" class="h-5 ml-6">
                <mat-icon
                    class="h-5 w-5 text-lg leading-5 text-secondaryMainLight hover:text-white"
                >
                    close
                </mat-icon>
            </button>
        </div>

        <mat-menu class="px-2 mt-2" #fieldsMenu="matMenu">
            <ng-template matMenuContent>
                <button
                    (click)="editField(field)"
                    *ngFor="let field of fieldsList"
                    class="flex items-center justify-start w-full"
                    mat-button
                >
                    <div class="w-full d-flex">
                        <app-field-name
                            [field]="field"
                            [selectedFolder]="selectedFolder!"
                            [showIcon]="true"
                        ></app-field-name>
                    </div>
                </button>
            </ng-template>
        </mat-menu>

        <ng-template #actionButtons>
            <div
                *transloco="let tButtons; read 'buttons'"
                class="flex items-center max-xsm:flex-col gap-2 w-full max-xsm:text-sm"
            >
                <div
                    (click)="closeBottomSheetS()"
                    class="xsm:hidden flex w-full font-semibold my-4 cursor-pointer"
                >
                    <mat-icon class="h-5 w-5 text-lg leading-5 mr-2">arrow_back</mat-icon>
                    <div>{{tButtons('back')}}</div>
                </div>
                <button
                    class="flex items-center gap-2 h-7 max-xsm:w-full px-3 rounded-md font-semibold max-xsm:h-9 max-sm:rounded-lg max-xsm:bg-primaryBackground max-xsm:font-normal xsm:text-white hover:text-secondaryMainLight cursor-pointer"
                >
                    <mat-icon class="h-5 w-5 text-lg leading-5">people_outline</mat-icon>
                    <div class="max-xsm:block max-md:hidden">{{tButtons('set_asignee')}}</div>
                </button>
                <button
                    class="flex items-center gap-2 h-7 max-xsm:w-full px-3 rounded-md font-semibold max-xsm:h-9 max-sm:rounded-lg max-xsm:bg-primaryBackground max-xsm:font-normal xsm:text-white hover:text-secondaryMainLight cursor-pointer"
                >
                    <mat-icon class="h-5 w-5 text-lg leading-5">task_alt</mat-icon>
                    <div class="max-xsm:block max-md:hidden">{{tButtons('set_status')}}</div>
                </button>
                <button
                    class="flex items-center gap-2 h-7 max-xsm:w-full px-3 rounded-md font-semibold max-xsm:h-9 max-sm:rounded-lg max-xsm:bg-primaryBackground max-xsm:font-normal xsm:text-white hover:text-secondaryMainLight cursor-pointer"
                >
                    <mat-icon class="h-5 w-5 text-lg leading-5">star_outline</mat-icon>
                    <div class="max-xsm:block max-md:hidden">{{tButtons('favorite')}}</div>
                </button>
                <button
                    class="flex items-center gap-2 h-7 max-xsm:w-full px-3 rounded-md font-semibold max-xsm:h-9 max-sm:rounded-lg max-xsm:bg-primaryBackground max-xsm:font-normal xsm:text-white hover:text-secondaryMainLight cursor-pointer"
                >
                    <mat-icon class="h-5 w-5 text-lg leading-5">share</mat-icon>
                    <div class="max-xsm:block max-md:hidden">{{tButtons('share')}}</div>
                </button>
                <button
                    class="flex items-center gap-2 h-7 max-xsm:w-full px-3 rounded-md font-semibold max-xsm:h-9 max-sm:rounded-lg max-xsm:bg-primaryBackground max-xsm:font-normal xsm:text-white hover:text-secondaryMainLight cursor-pointer"
                >
                    <mat-icon svgIcon="custom_copy" class="h-5 w-5 text-lg leading-5"></mat-icon>
                    <div class="max-xsm:block max-md:hidden">{{tButtons('duplicate')}}</div>
                </button>
                <button
                    [class.bg-secondaryMain]="trigger.menuOpen"
                    class="flex items-center gap-2 h-7 max-xsm:w-full px-3 rounded-md font-semibold max-xsm:h-9 max-sm:rounded-lg max-xsm:bg-primaryBackground max-xsm:font-normal xsm:text-white hover:text-secondaryMainLight cursor-pointer"
                    #trigger="matMenuTrigger"
                    [mat-menu-trigger-for]="fieldsMenu"
                    aria-label="Edit"
                >
                    <mat-icon class="h-5 w-5 text-lg leading-5">cached</mat-icon>
                    <div class="max-xsm:block max-md:hidden">{{tButtons('edit')}}</div>
                </button>
                <button
                    (click)="deleteAllRecords()"
                    aria-label="Delete"
                    class="flex items-center gap-2 h-7 max-xsm:w-full px-3 rounded-md font-semibold max-xsm:h-9 max-sm:rounded-lg max-xsm:bg-primaryBackground max-xsm:font-normal max-xsm:text-accentContrast xsm:text-white xsm:hover:text-accentContrastLight cursor-pointer"
                >
                    <mat-icon svgIcon="custom_trash" class="h-5 w-5 text-lg leading-5"></mat-icon>
                    <div class="max-xsm:block max-md:hidden">{{tButtons('delete')}}</div>
                </button>
            </div>
        </ng-template>
    </div>
</div>
