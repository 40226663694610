import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { Field } from '../../../../../../@core/models'
import {
    SelectObjectOptions,
    SelectOption,
} from '../../../../../../@core/models/response/select-object-options'
import cloneDeep from 'lodash/cloneDeep'
import { generateUuid } from '../../../../../../@core/global-util'

@Component({
    selector: 'app-dropdown-content',
    templateUrl: './dropdown-content.component.html',
    styleUrls: ['./dropdown-content.component.sass'],
})
export class DropdownContentComponent implements OnInit {
    @Input()
    field!: Field

    @Input()
    activeStatus!: string | undefined

    @Input()
    defaultStatus?: string

    dropdownOptions: SelectObjectOptions = {}

    dropdownOptionsInArray: SelectOption[] = []

    defaultColor!: string

    @Output()
    fieldChanged: EventEmitter<Field> = new EventEmitter<Field>()
    @Output()
    defaultChanged: EventEmitter<string> = new EventEmitter<string>()

    constructor() {}

    ngOnInit(): void {
        this.defaultColor = '#ff00ee'

        if (this.field.select_object_field) {
            this.dropdownOptions = cloneDeep(this.field.select_object_field)
        }

        this.completeOptionsToArray()

        if (!this.defaultStatus) {
            this.defaultStatus = this.dropdownOptionsInArray.find(
                (option) => option.is_default,
            )?.guid
        }

        if (!this.dropdownOptionsInArray.length) {
            this.addOption()
        }
    }

    completeOptionsToArray() {
        if (this.dropdownOptions) {
            this.dropdownOptionsInArray = Object.keys(this.dropdownOptions).map((guid) => {
                return {
                    guid,
                    ...this.dropdownOptions[guid],
                }
            })
        }
    }

    drop(event: CdkDragDrop<SelectOption[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
        this.sendData(true)
    }

    deleteOption(guid: string) {
        delete this.dropdownOptions[guid]
        this.sendData()
    }

    addOption() {
        const newIndex = generateUuid()
        const is_default = this.dropdownOptionsInArray.length === 0 ? 1 : 0
        const newOption: SelectOption = {
            label: '',
            guid: newIndex,
            color: this.defaultColor,
            tags: '',
            icon: '',
            is_new: true,
            is_default,
        }
        this.dropdownOptionsInArray.push(newOption)
        if (this.dropdownOptionsInArray.length === 1) {
            this.setDefaultOption(newIndex)
        }
        this.dropdownOptions = {
            ...this.dropdownOptions,
            [newIndex]: {
                label: '',
                color: this.defaultColor,
                tags: '',
                is_new: true,
                is_default,
            },
        }
        this.sendData()
    }

    optionChanged(guid: string | undefined, optionValue: SelectOption) {
        if (!guid) return

        if (optionValue.color) {
            this.dropdownOptions[guid].color = optionValue.color
        }
        if (optionValue.label) {
            this.dropdownOptions[guid].label = optionValue.label
        }

        this.sendData()
    }

    changeIcon(guid: string | undefined, optionValue: string) {
        if (!guid) return
        this.dropdownOptions[guid].icon = optionValue
        this.sendData()
    }

    setDefaultOption(guid: string | undefined) {
        if (!guid || !this.dropdownOptions || !this.defaultStatus) return

        this.dropdownOptions[guid].is_default = 1
        this.dropdownOptions[this.defaultStatus].is_default = 0

        this.defaultChanged.emit(guid)

        this.sendData()
    }

    private sendData(isDragged: boolean = false) {
        if (isDragged) {
            this.fieldChanged.emit({
                ...this.field,
                select_object_field_sorted: this.dropdownOptionsInArray,
            })
            return
        }
        this.completeOptionsToArray()
        this.fieldChanged.emit({ ...this.field, select_object_field: this.dropdownOptions })
    }
}
