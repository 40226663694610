<div
    class="sm:pt-4 sm:pb-6 sm:px-1 sm:w-screen sm:max-w-[600px] max-w-[568px]"
    *transloco="let tAddRecordContent; read: 'add_record_content'"
>
    <div class="py-0 px-5 max-sm:px-0 mb-4 max-sm:pt-4 text-xl font-semibold text-primaryDark">
        {{tAddRecordContent('create_new_record')}}
        <div class="flex absolute sm:top-[8px] top-[16px] sm:right-[6px] right-[12px]">
            <mat-icon
                mat-dialog-close
                class="flex items-center w-5 h-5 box-content p-0.5 mr-1 text-xl leading-4 rounded hover:bg-primaryLight cursor-pointer"
            >
                remove
            </mat-icon>
            <mat-icon
                mat-dialog-close
                class="flex items-center w-5 h-5 box-content p-0.5 text-xl leading-4 rounded hover:bg-primaryLight cursor-pointer"
            >
                close
            </mat-icon>
        </div>
    </div>

    <div
        class="overflow-auto sm:max-h-[calc(100vh-180px)] max-h-[calc(100vh-361px)] py-0 px-5 max-sm:px-0"
    >
        <div class="flex flex-column xsm:flex-row mb-6">
            <div class="xsm:mr-2 xsm:mb-0 mb-3 w-full">
                <div
                    class="mb-3 py-1 px-2 rounded-lg bg-primaryBackground cursor-pointer recordInfoBlock"
                >
                    <div class="flex items-center w-full">
                        <div class="text-primarySecondary text-xs mr-auto">
                            {{tAddRecordContent('workspace')}}
                        </div>
                        <mat-icon
                            svgIcon="custom_edit"
                            class="flex items-center w-4 h-4 text-primarySecondary text-base leading-4 editIcon"
                        ></mat-icon>
                    </div>
                    <div class="flex">
                        <mat-icon
                            class="flex items-center w-5 h-5 mr-2 text-secondaryMain text-xl leading-4"
                            >workspaces_outline</mat-icon
                        >
                        <!--                        change for variable-->
                        <div class="text-primaryDark text-sm">$Global</div>
                    </div>
                </div>

                <div
                    class="py-1 px-2 rounded-lg bg-primaryBackground cursor-pointer recordInfoBlock"
                >
                    <div class="flex items-center w-full">
                        <div class="text-primarySecondary text-xs mr-auto">
                            {{tAddRecordContent('template')}}
                        </div>
                        <mat-icon
                            svgIcon="custom_edit"
                            class="flex items-center w-4 h-4 text-primarySecondary text-base leading-4 editIcon"
                        ></mat-icon>
                    </div>
                    <div class="flex">
                        <mat-icon
                            svgIcon="custom_table"
                            class="flex items-center w-5 h-5 mr-2 text-primarySecondary text-xl leading-4"
                        ></mat-icon>
                        <!--                        change for variable-->
                        <div class="text-primaryDark text-sm">$Test</div>
                    </div>
                </div>
            </div>

            <div class="xsm:ml-2 w-full">
                <div
                    class="mb-3 py-1 px-2 rounded-lg bg-primaryBackground cursor-pointer recordInfoBlock"
                >
                    <div class="flex items-center w-full">
                        <div class="text-primarySecondary text-xs mr-auto">
                            {{tAddRecordContent('type')}}
                        </div>
                        <mat-icon
                            svgIcon="custom_edit"
                            class="flex items-center w-4 h-4 text-primarySecondary text-base leading-4 editIcon"
                        ></mat-icon>
                    </div>
                    <div class="flex">
                        <mat-icon
                            svgIcon="custom_deals"
                            class="flex items-center w-5 h-5 mr-2 text-secondaryMain text-xl leading-4"
                        ></mat-icon>
                        <!--                        change for variable-->
                        <div class="text-primaryDark text-sm">$Deals</div>
                    </div>
                </div>

                <div
                    class="py-1 px-2 rounded-lg bg-primaryBackground cursor-pointer recordInfoBlock"
                >
                    <div class="flex items-center w-full">
                        <div class="text-primarySecondary text-xs mr-auto">
                            {{tAddRecordContent('access')}}
                        </div>
                        <mat-icon
                            svgIcon="custom_edit"
                            class="flex items-center w-4 h-4 text-primarySecondary text-base leading-4 editIcon"
                        ></mat-icon>
                    </div>
                    <div class="flex">
                        <mat-icon
                            class="flex items-center w-5 h-5 mr-2 text-primarySecondary text-xl leading-4"
                            >person_outline</mat-icon
                        >
                        <!--                        change for variable-->
                        <div class="text-primaryDark text-sm">$Everyone</div>
                    </div>
                </div>
            </div>
        </div>

        <!--    title custom input and rich text input will be here-->

        <div class="flex flex-wrap mb-3 sm:mr-[-8px]">
            <app-workspace-btn
                *ngFor="let workspace of workspaces"
                [workspaceName]="workspace.name"
                [workspaceColor]="workspace.color"
                [class.notGlobalWorkspace]="!isGlobalWorkspace"
            >
            </app-workspace-btn>
        </div>

        <mat-expansion-panel class="xsm:mb-6 mb-2" #workPan hideToggle="true" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title class="text-primaryDark text-sm">
                    <mat-icon>{{workPan.expanded ? 'expand_less' : 'expand_more'}}</mat-icon>
                    <!--                        change for variable-->
                    $Workspace
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="flex xsm:flex-row flex-column mt-2 xsm:mb-0 mb-4">
                <div class="flex mr-auto xsm:min-h-[38px] xsm:mb-0 mb-2">
                    <div class="h-fit mr-3 xsm:mt-[7.5px] p-1 rounded-lg bg-secondaryMain">
                        <mat-icon
                            svgIcon="custom_deals"
                            class="flex items-center w-4 h-4 text-white text-base leading-4"
                        ></mat-icon>
                    </div>
                    <!--                        change for variable-->
                    <div class="flex items-center text-sm text-primaryDark">$Required field</div>
                </div>
                <!--                needs to be changed fr mat-form0field and proper select after style merge-->
                <div
                    class="w-full xsm:max-w-[363px] xsm:ml-4 py-2 px-3 rounded-lg border border-solid border-primarySecondary"
                >
                    Select
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="mb-6" #managersPan hideToggle="true" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title class="text-primaryDark text-sm">
                    <mat-icon>{{managersPan.expanded ? 'expand_less' : 'expand_more'}}</mat-icon>
                    <!--                        change for variable-->
                    $Managers
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="flex xsm:flex-row flex-column mt-2">
                <div class="flex mr-auto xsm:min-h-[38px] xsm:mb-0 mb-2">
                    <div class="h-fit mr-3 xsm:mt-[7.5px] p-1 rounded-lg bg-accentContrast">
                        <mat-icon
                            svgIcon="custom_deals"
                            class="flex items-center w-4 h-4 text-white text-base leading-4"
                        ></mat-icon>
                    </div>
                    <!--                        change for variable-->
                    <div class="flex items-center text-sm text-primaryDark">$Required field</div>
                </div>
                <!--                needs to be changed fr mat-form0field and proper select after style merge-->
                <div
                    class="w-full xsm:max-w-[363px] xsm:ml-4 py-2 px-3 rounded-lg border border-solid border-primarySecondary"
                >
                    Select
                </div>
            </div>
        </mat-expansion-panel>
    </div>

    <div class="flex justify-end mt-3 py-0 px-5 max-sm:px-0">
        <button
            class="mr-1 py-1 px-5 rounded-lg bg-secondaryMain font-semibold text-white h-9 hover:shadow-dark"
        >
            {{tAddRecordContent('create')}}
        </button>
        <button
            #trigger="matMenuTrigger"
            [matMenuTriggerFor]="addTaskMenu"
            [class.bg-indigo-500]="trigger.menuOpen"
            class="flex items-center justify-center h-9 w-9 rounded-lg bg-secondaryMain text-white h-9 hover:shadow-dark"
        >
            <mat-icon>{{ trigger.menuOpen ? 'expand_less' : 'expand_more' }}</mat-icon>
        </button>
    </div>

    <mat-menu class="mb-2" #addTaskMenu="matMenu" yPosition="above" xPosition="before">
        <div class="p-2 w-[222px] text-sm text-primaryDark">
            <div
                class="py-1 px-2 rounded mb-2 hover:bg-primaryBackground hover:font-semibold cursor-pointer"
            >
                {{tAddRecordContent('create_and_open')}}
            </div>
            <div
                class="py-1 px-2 rounded mb-2 hover:bg-primaryBackground hover:font-semibold cursor-pointer"
            >
                {{tAddRecordContent('create_and_start_another')}}
            </div>
            <div
                class="py-1 px-2 rounded hover:bg-primaryBackground hover:font-semibold cursor-pointer"
            >
                {{tAddRecordContent('create_and_duplicate')}}
            </div>
        </div>
    </mat-menu>
</div>
