import { EventEmitter } from '@angular/core'
import { MatMenuTrigger } from '@angular/material/menu'
import { take, takeUntil, withLatestFrom } from 'rxjs/operators'
import { BehaviorSubject, Subject } from 'rxjs'

export class MenuInstanceService {
    private forced$ = new BehaviorSubject(false)
    private destroyed$ = new Subject<void>()
    public closed = new EventEmitter()

    constructor(private menuTrigger: MatMenuTrigger) {
        this.menuTrigger.menuClosed
            .pipe(withLatestFrom(this.forced$), takeUntil(this.destroyed$))
            .subscribe(([_, forced]) => {
                if (forced) {
                    this.forced$.next(false)
                    return
                }

                this.closed.emit()
                this.clear()
            })
    }

    open() {
        this.reopen()

        return this.closed.asObservable().pipe(take(1))
    }

    reopen() {
        this.menuTrigger.openMenu()
    }

    clear() {
        this.menuTrigger.closeMenu()
    }

    hide() {
        if (this.menuTrigger.menuOpen) {
            this.forced$.next(true)
            this.menuTrigger.closeMenu()
        }
    }

    destroy() {
        this.destroyed$.next()
    }
}
