import { Component, Input } from '@angular/core'
import {
    AppRecord,
    BusinessRecords,
    Cell,
    Field,
    FieldType,
    Folder,
    RecordUpdate,
} from '../../../../@core/models'
import { Dictionary } from '@ngrx/entity'
import { RecordsService } from '../../../../@core/services/records.service'

@Component({
    selector: 'app-card-status-row',
    templateUrl: './card-status-row.component.html',
    styleUrls: ['./card-status-row.component.sass'],
})
export class CardStatusRowComponent {
    @Input()
    record!: BusinessRecords

    @Input()
    statusField!: Field | undefined

    @Input()
    folder!: Folder

    @Input()
    fieldTypes!: Dictionary<FieldType>

    constructor(private recordsService: RecordsService) {}

    updateRecord(data: AppRecord, cell: Cell, newValue: any) {
        const dataRecord: RecordUpdate = {
            record: data,
            cell: cell,
            value: newValue,
        }
        this.recordsService.updateRecord(dataRecord)
    }
}
