import { Injectable } from '@angular/core'
import { WsService } from './index'
import { environment } from '../../../environments/environment'
import {
    AppRecord,
    Cell,
    CreateFieldModel,
    CreateFolderAcl,
    CreateFolderModel,
    CreateFolderModelFieldSettings,
    CreateFolderViews,
    CreateRecordModel,
    CurrentUser,
    DeleteConfigUpdateModel,
    DeleteFieldModel,
    DeleteRecordModel,
    Field,
    Folder,
    getFolderSchema,
    ObjectResponseModel,
    RecordUpdate,
    Schema,
    UpdateConfigUpdateModel,
    UpdateFieldModel,
    UpdateLinkModel,
    UpdateLinks,
    UpdateRecordInnerModel,
    UpdateRecordModel,
} from '../models'
import { switchMap, take } from 'rxjs/operators'
import { combineLatest, Observable, of } from 'rxjs'
import {
    prepareSelectOptionModel,
    prepareSelectOptionModelSorted,
} from '../models/response/select-object-options'
import cloneDeep from 'lodash/cloneDeep'
import { FolderFacadeService, SchemaFacadeService, UserFacadeService } from './store-facade'
import { ConfigFields, ConfigUpdateModel } from '../models/ui/config.model'

@Injectable({
    providedIn: 'root',
})
export class UpdateService {
    constructor(
        private wsService: WsService,
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private userFacadeService: UserFacadeService,
    ) {}

    createFolder(folder: Partial<Folder>, views: CreateFolderViews, acl: CreateFolderAcl) {
        return combineLatest([
            this.schemaFacadeService.selectAllSchemas$.pipe(take(1)),
            this.userFacadeService.selectCurrentUser$.pipe(take(1)),
        ]).pipe(
            take(1),
            switchMap(([schemas, user]: [Schema[], CurrentUser | null]) => {
                if (!user) {
                    console.error(new Error('no user in record creation'))
                    return of()
                }

                //TODO: Pasha Poberezhniy Please set TODO with some message to me, because I should rewrite this method during my merge
                const { guid: solution_object_type_guid } = getFolderSchema(schemas)

                const fields: CreateFolderModelFieldSettings = Object.values(folder)
                    .filter((value) => {
                        return typeof value === 'object' && 'fieldGuid' in <Object>value
                    })
                    .map((value) => <Cell>value)
                    .reduce((res: CreateFolderModelFieldSettings, next: Cell) => {
                        res[next.fieldGuid] = next.value
                        return res
                    }, {})

                const roles = [...(acl.roles ?? [])]
                if (!roles.includes(user!.role.guid)) {
                    roles.push(user!.role.guid)
                }

                const body: CreateFolderModel = {
                    solution_object_type_guid,
                    views,
                    fields,
                    acl: {
                        roles,
                    },
                }
                console.log(body)
                return this.wsService.invokeMessage(environment.ws.endpoints.create_folder, body)
            }),
        )
    }

    updateRecord(data: RecordUpdate | RecordUpdate[]): Observable<ObjectResponseModel> {
        return this.folderFacadeService.selectSelectedFolder$.pipe(
            take(1),
            switchMap((folder: Folder | undefined) => {
                if (!Array.isArray(data)) {
                    data = [data]
                }

                const records = data.map((recordData: RecordUpdate) => {
                    let parent_sot_guid: string | undefined
                    if (recordData.record && 'parent_sot_guid' in recordData.record) {
                        parent_sot_guid = recordData.record.parent_sot_guid
                    }

                    if (!folder && !recordData.record.folder_guids) {
                        console.error(new Error('no folderGuid in updateRecord'))
                        return
                    }

                    return {
                        record_guid: recordData.record.guid!,
                        record_revision: recordData.record.revision!.toString(),
                        parent_sot_guid: parent_sot_guid,
                        folders_guid:
                            'folder_guids' in recordData.record
                                ? recordData.record.folder_guids!
                                : folder!.guid,
                        cells: recordData.cell
                            ? this.generateUpdateRequestCell(recordData.cell, recordData.value)
                            : [],
                    }
                }) as UpdateRecordInnerModel[]

                if (!records) {
                    console.error(new Error('empty UpdateRecordInnerModel array in updateRecord'))
                    return of()
                }

                const body: UpdateRecordModel = {
                    solution_object_type_guid: data[0].record.schemaGuid!,
                    records,
                }

                console.log([body, JSON.stringify(body)])
                return this.wsService.invokeMessage(environment.ws.endpoints.update_record, body)
            }),
        )
    }

    updateLink(updateLinksData: UpdateLinkModel[]): Observable<ObjectResponseModel> {
        return this.schemaFacadeService.selectSelectedTableSchema$.pipe(
            take(1),
            switchMap((schema) => {
                if (!schema) {
                    console.error(new Error('no schema in updateField'))
                    return of()
                }

                const body: UpdateLinks = {
                    solution_object_type_guid: schema.guid,
                    records: updateLinksData,
                }

                console.log([body, JSON.stringify(body)])
                console.log(body.records)
                return this.wsService.invokeMessage(environment.ws.endpoints.update_link, body)
            }),
        )
    }

    createRecord(record: CreateRecordModel): Observable<ObjectResponseModel> {
        console.log([record, JSON.stringify(record)])
        return this.wsService.invokeMessage(environment.ws.endpoints.create_record, record)
    }

    deleteRecord(data: AppRecord | Array<AppRecord>): Observable<ObjectResponseModel> {
        if (!Array.isArray(data)) {
            data = [data]
        }

        if (!data.length) {
            console.error('Data not found, delete records lost!')
            return of()
        }

        const body: DeleteRecordModel = {
            solution_object_type_guid: data[0].schemaGuid,
            records: data.map((record) => {
                return {
                    record_guid: record.guid,
                    record_revision: record.revision.toString(),
                }
            }),
        }

        console.log([body, JSON.stringify(body)])
        console.log(body.records)
        return this.wsService.invokeMessage(environment.ws.endpoints.delete_record, body)
    }

    updateField(field: Field): Observable<ObjectResponseModel> {
        return this.schemaFacadeService.selectSelectedTableSchema$.pipe(
            take(1),
            switchMap((schema: Schema | undefined) => {
                if (!schema) {
                    console.error(new Error('no schema in updateField'))
                    return of()
                }

                const body: UpdateFieldModel = {
                    solution_object_type_guid: schema!.guid,
                    solution_object_type_revision: schema!.revision,
                    field_guid: field.guid,
                    revision: field.revision,
                    configure_json: field.configure_json,
                    is_required: field.is_required,
                    system_name: field.system_name,
                    acl: null,
                    name: field.name,
                    frontend_validations: null,
                    shared_with_folder: field.shared_with_folder,
                    folder_guid: field.folder_guid,
                    select_object_field: this.generateSelectObjectField(field),
                    link_definition: field.link_definition,
                }
                console.log([body, JSON.stringify(body)])
                console.log(body.name)
                return this.wsService.invokeMessage(environment.ws.endpoints.update_field, body)
            }),
        )
    }

    createField(field: Partial<Field>): Observable<ObjectResponseModel> {
        return combineLatest([
            this.folderFacadeService.selectSelectedFolder$.pipe(take(1)),
            this.schemaFacadeService.selectSelectedTableSchema$.pipe(take(1)),
        ]).pipe(
            take(1),
            switchMap(([folder, schema]) => {
                if (!schema || !folder) {
                    console.error(new Error('no selectedFolder || schema in field creation'))
                    return of()
                }

                const body: CreateFieldModel = {
                    solution_object_type_guid: schema!.guid,
                    solution_object_type_revision: schema!.revision,
                    field_type_code: field.field_type_code!,
                    configure_json: field.configure_json!,
                    is_required: field.is_required!,
                    acl: null,
                    name: field.name!,
                    frontend_validations: null,
                    folder_guid: folder!.guid,
                    select_object_field: this.generateSelectObjectField(field),
                    link_definition: field.link_definition,
                }

                console.log('filed update.service', [body, JSON.stringify(body)])
                console.log(body.name)
                return this.wsService.invokeMessage(environment.ws.endpoints.create_field, body)
            }),
        )
    }

    deleteField(guid: string): Observable<ObjectResponseModel> {
        return this.schemaFacadeService.selectSelectedTableSchema$.pipe(
            take(1),
            switchMap((schema) => {
                if (!schema) {
                    console.error(new Error('no schema in deleteField'))
                    return of()
                }

                const body: DeleteFieldModel = {
                    solution_object_type_guid: schema!.guid,
                    solution_object_type_revision: schema!.revision,
                    field_guid: guid,
                    revision: schema!.fieldEntities[guid].revision,
                }
                console.log([body, JSON.stringify(body)])
                console.log(body.field_guid)
                return this.wsService.invokeMessage(environment.ws.endpoints.delete_field, body)
            }),
        )
    }

    resetSolutionToTemplate(): Observable<ObjectResponseModel> {
        return this.wsService.invokeMessage(environment.ws.endpoints.reset)
    }

    createConfig(createConfig: Map<string, string>): Observable<ObjectResponseModel> {
        const body = {
            configuration: [...createConfig.keys()].reduce((config, key) => {
                config[key] = {
                    value: createConfig.get(key)!,
                    revision: 1,
                }
                return config
            }, {} as ConfigFields),
        }

        console.log([body, JSON.stringify(body)])
        return this.wsService.invokeMessage(environment.ws.endpoints.create_config, body)
    }

    updateConfig(updateConfig: ConfigUpdateModel): Observable<ObjectResponseModel> {
        const body: UpdateConfigUpdateModel = {
            record_guid: updateConfig.guid,
            record_revision: updateConfig.revision,
            configuration: updateConfig.configuration!,
        }

        console.log([body, JSON.stringify(body)])
        return this.wsService.invokeMessage(environment.ws.endpoints.update_config, body)
    }

    deleteConfig(deleteConfig: ConfigUpdateModel): Observable<ObjectResponseModel> {
        const body: DeleteConfigUpdateModel = {
            record_guid: deleteConfig.guid,
            record_revision: deleteConfig.revision,
        }

        console.log([body, JSON.stringify(body)])
        console.log(body.record_guid)
        return this.wsService.invokeMessage(environment.ws.endpoints.delete_config, body)
    }

    private generateUpdateRequestCell<T extends AppRecord>(cell: Cell, value?: string) {
        return [
            {
                revision: cell.revision!.toString(),
                field_guid: cell.fieldGuid!,
                value: value ?? cell.value,
            },
        ]
    }

    private generateSelectObjectField({
        select_object_field,
        select_object_field_sorted,
    }: Partial<Field>) {
        const selectObjectField = select_object_field
            ? Object.values(prepareSelectOptionModel(cloneDeep(select_object_field)))
            : undefined

        return select_object_field_sorted
            ? prepareSelectOptionModelSorted(select_object_field_sorted)
            : selectObjectField
    }
}
