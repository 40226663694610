import { inject } from '@angular/core'
import { SystemRecordsFacadeService } from '../services/system-records-facade.service'
import { CanActivateFn, Router, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

export const RedirectToErrorPageIfEmptyDataGuard: CanActivateFn = (): Observable<
    boolean | UrlTree
> => {
    const systemRecordsFacadeService = inject(SystemRecordsFacadeService)
    const router = inject(Router)

    console.log('empty folders|schemas|views guarded')
    return systemRecordsFacadeService.getAllSystemRecords().pipe(
        map(([folders, schemas]) => {
            if (!folders.length || !schemas.length) {
                return router.createUrlTree(['error-page'])
            }
            return true
        }),
    )
}
