import { Component } from '@angular/core'
import { CdkStepper } from '@angular/cdk/stepper'

@Component({
    selector: 'app-tb-stepper',
    templateUrl: './tb-stepper.component.html',
    styleUrls: ['./tb-stepper.component.sass'],
    providers: [{ provide: CdkStepper, useExisting: TbStepperComponent }],
})
export class TbStepperComponent extends CdkStepper {
    selectStepByIndex(index: number): void {
        this.selectedIndex = index
    }
}
