import { BaseContainer } from './base-container'
import { ComponentType } from '@angular/cdk/overlay'
import { ModalContainerComponent } from '../modal-container-component/modal-container.component'
import { ModalContainerFactoryService } from '../modal-container-factory.service'
import { DisplaySize } from '../../breakpoint.service'
import { ModalContainer } from '../modal-manager.service'
import { MenuInstanceService } from '../modal-instances/menu-instance'
import { TemplateRef } from '@angular/core'

export class MenuContainer<
    T extends ModalContainerComponent,
    ContainerData = unknown,
    Result = unknown,
> extends BaseContainer<T, ContainerData, Result> {
    constructor(
        public menuInstanceService: MenuInstanceService,
        modalContainerFactoryService: ModalContainerFactoryService,
        component: ComponentType<T>,
        template: TemplateRef<any> | null,
        data?: ContainerData,
    ) {
        super(modalContainerFactoryService, component, data)

        if (!template) return
        // when we import TemplateContainerComponent in the file, it causes circular dependency, temp solution is using "import()"
        import('../template-container/template-container.component').then(
            ({ TemplateContainerComponent }) => {
                if (this.componentRef.instance instanceof TemplateContainerComponent) {
                    this.componentRef.instance.template = template
                }
            },
        )
    }

    displaySizeToRelatedContainer(displaySize: DisplaySize): ModalContainer {
        if (displaySize === DisplaySize.XSmall || displaySize === DisplaySize.Small) {
            return ModalContainer.BottomSheet
        }
        return ModalContainer.Menu
    }

    destroy() {
        this.menuInstanceService.destroy()
        // we delay component destruction to prevent showing empty container because MatMenu has opacity transition when closes
        setTimeout(() => {
            super.destroy()
        }, 300)
    }
}
