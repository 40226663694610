import { Component } from '@angular/core'
import {
    PinDraftRecord,
    PinObject,
    PinObjectType,
    PinRecord,
    PinRecordService,
} from './pin-record.service'
import { CardComponent, DialogCardInput } from '../views/card/card.component'
import { MatDialog } from '@angular/material/dialog'
import { map } from 'rxjs/operators'
import { AddRecordContentComponent } from './add-record-content/add-record-content.component'
import { RecordSettingsContentComponent } from './record-settings-content/record-settings-content.component'
import { MatBottomSheet } from '@angular/material/bottom-sheet'
import { AddRecordContentOldComponent } from './add-record-content-old/add-record-content-old.component'

@Component({
    selector: 'app-pin-panel',
    templateUrl: './pin-panel.component.html',
})
export class PinPanelComponent {
    openDialog() {
        this.dialog.open(AddRecordContentComponent)
    }

    openBottomSheetS() {
        this.bottomSheet.open(AddRecordContentComponent, { panelClass: 'bottomSheetCustom' })
    }

    openOldDialog() {
        this.dialog.open(AddRecordContentOldComponent, { width: '300px' })
    }

    openDialogSettings() {
        this.dialog.open(RecordSettingsContentComponent)
    }

    openBottomSheetSettings() {
        this.bottomSheet.open(RecordSettingsContentComponent, { panelClass: 'bottomSheetCustom' })
    }

    showRecordCount: number = 5

    pinObjects$ = this.pinRecordService.pinObjects$.pipe(
        map((pinObjects) => {
            const pinRecords = pinObjects.filter((object) => object.type === PinObjectType.CREATED)
            const pinDraftRecords = pinObjects.filter(
                (object) => object.type === PinObjectType.DRAFT,
            )
            return [...pinDraftRecords, ...pinRecords]
        }),
    )

    pinRecords$ = this.pinObjects$.pipe(
        map((objects) => objects.slice(0, this.showRecordCount)),
        this.filterRecordByType<PinRecord>(PinObjectType.CREATED),
    )

    pinRecordsDraft$ = this.pinObjects$.pipe(
        map((objects) => objects.slice(0, this.showRecordCount)),
        this.filterRecordByType<PinDraftRecord>(PinObjectType.DRAFT),
    )

    pinRecordsMore$ = this.pinObjects$.pipe(
        map((objects) => objects.slice(this.showRecordCount)),
        this.filterRecordByType<PinRecord>(PinObjectType.CREATED),
    )

    pinRecordsDraftMore$ = this.pinObjects$.pipe(
        map((objects) => objects.slice(this.showRecordCount)),
        this.filterRecordByType<PinDraftRecord>(PinObjectType.DRAFT),
    )

    constructor(
        private pinRecordService: PinRecordService,
        private dialog: MatDialog,
        private bottomSheet: MatBottomSheet,
    ) {}

    showMoreBtn(pinRecordsMore: PinRecord[], pinDraftRecordsMore: PinDraftRecord[]) {
        return pinRecordsMore.length || pinDraftRecordsMore.length
    }

    removePinItem(guid: string) {
        this.pinRecordService.removePinRecord(guid)
    }

    openPinItem(guid: string) {
        this.dialog.open<CardComponent, DialogCardInput>(CardComponent, {
            width: '100%',
            data: { recordGuid: guid, isFolder: true },
        })
    }

    private filterRecordByType<T extends PinRecord | PinDraftRecord>(type: PinObjectType) {
        return map<PinObject[], T[]>((objects) => {
            return objects
                .filter((object) => object.type === type)
                .map((object) => object.record) as T[]
        })
    }
}
