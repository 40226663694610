import { Component, Input, SimpleChanges, ViewChild } from '@angular/core'
import { MatMenuTrigger } from '@angular/material/menu'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { CardComponent, DialogCardInput } from '../../card/card.component'
import {
    BusinessRecords,
    Cell,
    CellEntities,
    FieldEntities,
    FieldType,
    Folder,
    formAllFields,
    isDeal,
    isVendor,
} from '../../../@core/models'
import { FormGroup } from '@angular/forms'
import { Observable } from 'rxjs'
import { Dictionary } from '@ngrx/entity'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { take } from 'rxjs/operators'
import { RecordsService } from '../../../@core/services/records.service'
import { FieldTypeFacadeService, SchemaFacadeService } from '../../../@core/services/store-facade'

@UntilDestroy()
@Component({
    selector: 'app-board-card',
    templateUrl: './board-card.component.html',
    styleUrls: ['./board-card.component.sass'],
})
export class BoardCardComponent {
    @Input()
    visibleFields!: string[]

    @Input()
    selectedFolder!: Folder | undefined | null

    @Input()
    record!: BusinessRecords

    @Input()
    cells!: CellEntities

    isDeal = isDeal

    isVendor = isVendor

    fieldTypes$!: Observable<Dictionary<FieldType>>
    fields!: FieldEntities
    nonMainFields!: string[]

    name?: Cell
    address?: Cell
    updateAt?: Cell
    status?: Cell
    assignee?: Cell
    amount?: Cell
    vendorName?: Cell

    isMenuOpen: boolean = false
    formGroup!: FormGroup

    @ViewChild('boardItemMenuTrigger') boardItemMenuTrigger!: MatMenuTrigger
    dialogRef!: MatDialogRef<CardComponent>

    constructor(
        public dialog: MatDialog,
        private recordService: RecordsService,
        private schemaFacadeService: SchemaFacadeService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
    ) {}

    ngOnInit(): void {
        this.schemaFacadeService
            .selectSelectedTableSchemaFieldEntitiesFiltered$(this.selectedFolder!)
            .pipe(take(1), untilDestroyed(this))
            .subscribe((fields) => {
                if (!fields) return

                this.fields = fields

                this.setComponentsCells()
                this.setNonMainFields()
            })

        this.fieldTypes$ = this.fieldTypeFacadeService.selectFieldTypeEntities$
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.visibleFields) {
            this.visibleFields = changes.visibleFields.currentValue
        }
    }

    setNonMainFields() {
        if (!this.selectedFolder) return

        this.nonMainFields = formAllFields(this.record, this.selectedFolder)

        this.nonMainFields = this.nonMainFields.filter((fieldGuid) =>
            this.visibleFields.includes(fieldGuid),
        )
    }

    openItem() {
        this.isMenuOpen = true
    }

    closeBox() {
        this.isMenuOpen = false
        this.boardItemMenuTrigger.closeMenu()
    }

    deleteCard() {
        this.recordService.deleteRecord(this.record)
    }

    openCardDialog() {
        this.dialogRef = this.dialog.open<CardComponent, DialogCardInput, CellEntities | undefined>(
            CardComponent,
            {
                width: '100%',
                data: { recordGuid: this.record.guid, isFolder: true },
            },
        )
    }

    isVisible(guid: string) {
        return this.visibleFields.includes(guid)
    }

    private setComponentsCells() {
        this.name = this.record.name
        this.updateAt = this.record.update_at

        if (this.selectedFolder) {
            this.status = this.record.status[this.selectedFolder.guid]
            this.assignee = this.record.assignee[this.selectedFolder.guid]
        }

        if (isVendor(this.record)) {
            this.address = this.record.address
        }

        if (isDeal(this.record)) {
            this.vendorName = this.record.vendor_name
            this.amount = this.record.amount
        }
    }
}
