import { ComponentRef, EventEmitter, Injectable } from '@angular/core'
import { ReplaySubject } from 'rxjs'
import { ModalRenderComponent } from '../modal-render-container/modal-render.component'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { BaseContainer } from '../modal-containers/base-container'
import { ModalContainerComponent } from '../modal-container-component/modal-container.component'
import { take } from 'rxjs/operators'
import { ModalContainer } from '../modal-manager.service'

const ForcedData = Symbol('ForcedData')

@Injectable({
    providedIn: 'root',
})
export class DialogInstanceService {
    private dialogInstance: MatDialogRef<unknown> | null = null
    private renderComponent$ = new ReplaySubject<ComponentRef<unknown>>(1)

    public closed = new EventEmitter()

    constructor(private dialog: MatDialog) {}

    open(container: BaseContainer<ModalContainerComponent>) {
        this.reopen(container)

        return container.closed.asObservable().pipe(take(1))
    }

    reopen(container: BaseContainer<ModalContainerComponent>) {
        if (!this.dialogInstance) {
            this.dialogInstance = this.dialog.open(ModalRenderComponent, {
                data: { renderComponent$: this.renderComponent$ },
            })

            this.dialogInstance.afterClosed().subscribe((data) => {
                if (data === ForcedData) return

                this.closed.emit()
                this.clear()
            })
        }

        container.componentRef.setInput('containerLayout', ModalContainer.Dialog)
        this.renderComponent$.next(container.componentRef)
    }

    clear() {
        this.dialogInstance?.close()
        this.dialogInstance = null
    }

    hide() {
        this.dialogInstance?.close(ForcedData)
        this.dialogInstance = null
    }
}
