import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { AddFieldData, Field, FieldEntities, FieldType, Folder } from '../../../../../@core/models'
import { Observable } from 'rxjs'
import { AddColumnComponent } from '../../../../table/table/add-column/add-column.component'
import { MatDialog } from '@angular/material/dialog'
import { take } from 'rxjs/operators'
import {
    FieldTypeFacadeService,
    SchemaFacadeService,
    ViewFacadeService,
} from '../../../../../@core/services/store-facade'

export interface FieldInsertionOptions {
    displayedColumnsOrder: string[]
    insertionIndex: number
    isPinned?: boolean
}

@Component({
    selector: 'app-field-add-content',
    templateUrl: './field-add-content.component.html',
    styleUrls: ['./field-add-content.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldAddContentComponent {
    @Input() fields!: FieldEntities
    @Input() isNewColumn!: boolean
    @Input() isPinned?: boolean
    // todo: [table-ref] we don't need place here, because we know it on the top
    @Input() insertPlace?: string
    @Input() guid?: string
    @Input() activeTab!: number
    @Input() selectedFolder!: Folder | undefined | null
    @Input() columnsOrder?: string[]
    @Output() showColumn: EventEmitter<string> = new EventEmitter<string>()
    @Output() hideColumn: EventEmitter<string> = new EventEmitter<string>()
    @Output() insertColumn: EventEmitter<{ place: string; guid: string }> = new EventEmitter<{
        place: string
        guid: string
    }>()

    fieldTypes$: Observable<FieldType[]> =
        this.fieldTypeFacadeService.selectAvailableFieldTypeByFolder$

    private folder_guid!: string

    constructor(
        public dialog: MatDialog,
        private viewFacadeService: ViewFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
    ) {}

    openAddColumnDialog(fieldType: string) {
        const dialogRef = this.dialog.open<AddColumnComponent, AddFieldData, Field | undefined>(
            AddColumnComponent,
            {
                width: '400px',
                data: {
                    field_type_code: fieldType,
                    folder_guid: this.folder_guid,
                },
            },
        )

        dialogRef.afterClosed().subscribe((field: Field | undefined) => {
            if (field) {
                if (this.insertPlace && this.guid) {
                    this.updateViewModelCreate(field)
                } else {
                    this.schemaFacadeService.createField(field)
                }
            }
        })
    }

    updateViewModelCreate(field: Field) {
        if (this.guid && this.insertPlace) {
            this.viewFacadeService.selectSelectedView$.pipe(take(1)).subscribe((selectedView) => {
                if (!selectedView || !this.guid || !this.columnsOrder) return

                let columnIndex

                if (this.isPinned) {
                    columnIndex = selectedView.columns_pinned.value.split(',').indexOf(this.guid)
                } else {
                    const viewColumns = selectedView.columns_order.value.split(',')
                    columnIndex =
                        selectedView.columns_order.value !== '' && viewColumns.includes(this.guid)
                            ? viewColumns.indexOf(this.guid)
                            : this.columnsOrder.indexOf(this.guid)
                }
                this.schemaFacadeService.createField(
                    field,
                    this.prepareViewModel(columnIndex, this.columnsOrder, this.isPinned ?? false),
                )
            })
        }
    }

    prepareViewModel(
        columnIndex: number,
        displayedColumnsOrder: string[],
        isPinned: boolean,
    ): FieldInsertionOptions {
        return {
            displayedColumnsOrder,
            isPinned,
            insertionIndex: this.insertPlace === 'InsertLeft' ? columnIndex : columnIndex + 1,
        }
    }
}
