import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Field, Folder } from '../../../../@core/models'
import { AddColumnComponent } from '../add-column/add-column.component'
import { Dictionary } from '@ngrx/entity'
import { FormControl } from '@angular/forms'
import { MatOption } from '@angular/material/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { FolderFacadeService } from '../../../../@core/services/store-facade'
import { getBoolean } from '../../../../@core/global-util'

export interface listFolderItem {
    item_id: string
    item_text?: string
}

@UntilDestroy()
@Component({
    selector: 'app-share-field',
    templateUrl: './share-field.component.html',
    styleUrls: ['./share-field.component.sass'],
})
export class ShareFieldComponent implements OnInit {
    field!: Field
    folders!: Dictionary<Folder>
    dropdownList: Array<listFolderItem> = []
    selectedItems: Array<listFolderItem> = []
    sharedFolders!: FormControl
    @ViewChild('allSelected') private allSelected: MatOption | undefined

    constructor(
        private folderFacadeService: FolderFacadeService,
        public dialogRef: MatDialogRef<AddColumnComponent, Field | undefined>,
        @Inject(MAT_DIALOG_DATA) public data: Field,
    ) {
        this.field = data
    }

    ngOnInit(): void {
        this.sharedFolders = new FormControl<any>('')
        this.folderFacadeService.selectFolderEntities$
            .pipe(untilDestroyed(this))
            .subscribe((data) => (this.folders = data))
        Object.keys(this.folders).forEach((folder) => {
            if (
                this.folders[folder]?.name.value !== this.field.folder_name?.name &&
                !getBoolean(this.folders[folder]?.is_global.value)
            ) {
                this.dropdownList.push({
                    item_id: folder,
                    item_text: this.folders[folder]?.name.value,
                })
            }
            if (this.field.shared_with_folder && this.field.shared_with_folder.includes(folder)) {
                this.selectedItems.push({
                    item_id: folder,
                    item_text: this.folders[folder]?.name.value,
                })
            }
        })
        if (this.selectedItems.length !== 0) {
            if (this.selectedItems.length === this.dropdownList.length) {
                this.sharedFolders.patchValue([...this.dropdownList.map((item) => item.item_id), 0])
            } else {
                this.sharedFolders.patchValue(this.selectedItems.map((item) => item.item_id))
            }
        }
    }

    onItemClick() {
        if (this.allSelected?.selected) {
            this.allSelected.deselect()
        }
        if (this.sharedFolders.value.length === this.dropdownList.length) {
            this.allSelected?.select()
        }
        if (this.field.shared_with_folder) {
            let sharedFolders = this.sharedFolders.value.includes(0)
                ? this.sharedFolders.value.slice(0, -1).toString()
                : this.sharedFolders.value.toString()
            this.field = {
                ...this.field,
                shared_with_folder: sharedFolders,
            }
        } else {
            this.field = {
                ...this.field,
                shared_with_folder: this.sharedFolders.value[0],
            }
            this.selectedItems.push(this.sharedFolders.value[0])
        }
    }

    toggleAllSelection() {
        let folder_names = ''
        if (this.allSelected?.selected) {
            this.sharedFolders.patchValue([...this.dropdownList.map((item) => item.item_id), 0])
            this.sharedFolders.value.forEach((item: any) => {
                if (item != 0) {
                    folder_names = folder_names ? folder_names + ',' + item : item
                }
            })
            this.field = {
                ...this.field,
                shared_with_folder: folder_names,
            }
        } else {
            this.sharedFolders.patchValue([])
            this.field = {
                ...this.field,
                shared_with_folder: '',
            }
        }
        console.log('items')
    }

    onDeSelect(item: any) {
        this.field = {
            ...this.field,
            shared_with_folder: this.field.shared_with_folder?.replace(',' + item.item_id, ''),
        }
    }

    applyShare() {
        this.dialogRef.close(this.field)
    }

    cancelShare() {
        this.dialogRef.close()
    }
}
