import { createFeatureSelector, createSelector } from '@ngrx/store'
import { AppState } from '../app.state'
import { schemaAdapter, schemaFeature, SchemaState } from './schema.reducer'
import { FieldEntities, Schema, SystemTypeCode } from '../../models'
import { Dictionary } from '@ngrx/entity'

const selectSchemaState = createFeatureSelector<SchemaState>('SCHEMA')

const { selectIds, selectEntities, selectAll, selectTotal } =
    schemaAdapter.getSelectors(selectSchemaState)

export const selectSchemaIds = selectIds
export const selectSchemaEntities = selectEntities
export const selectAllSchemas = selectAll
export const selectSchemaTotal = selectTotal
export const selectSelectedTableSchemaGuid = schemaFeature.selectSelectedTableSchemaGuid

export const selectTableSchemas = createSelector<AppState, [Dictionary<Schema>], Schema[]>(
    selectSchemaEntities,
    (schemaDictionary: Dictionary<Schema>) => {
        return Object.values(schemaDictionary).filter((schema) => {
            if (!schema) return false

            return schema.system_object_type_code === SystemTypeCode.TABLE
        }) as Schema[]
    },
)

export const selectSelectedTableSchema = createSelector<
    AppState,
    [string | null, Dictionary<Schema>],
    Schema | undefined
>(
    selectSelectedTableSchemaGuid,
    selectSchemaEntities,
    (schemaGuid: string | null, schemaDictionary: Dictionary<Schema>) => {
        console.info('selectSelectedTableSchema')
        if (!schemaGuid) return

        return schemaDictionary[schemaGuid]
    },
)

export const selectSelectedTableSchemaFieldEntities = createSelector<
    AppState,
    [Schema | undefined],
    FieldEntities | undefined
>(selectSelectedTableSchema, (schema: Schema | undefined) => {
    return schema?.fieldEntities
})

export const selectConfigSchema = createSelector<AppState, Schema[], Schema | undefined>(
    selectAllSchemas,
    (schemas: Schema[]) => {
        return schemas.find((schema) => schema.system_object_type_code === SystemTypeCode.CONFIG)
    },
)
