<!--
    We pass the template with "trigger" into a special slot. For example <button>
    <app-tb-menu>
        <button trigger>
            Close
        </button>
    </app-tb-menu>

    stopPropagation() added in order to prevent opening and then instant closing of bottomSheet
    We should call the trigger programmatically to prevent automatic opening, so this logic belongs to the ModalManagerService.
-->
<div #trigger="matMenuTrigger" [matMenuTriggerFor]="menuContent">
    <div (click)="$event.stopPropagation(); openMenu()">
        <ng-content select="[trigger]"></ng-content>
    </div>
</div>

<mat-menu #menuContent="matMenu" [xPosition]="xPosition" [yPosition]="yPosition">
    <div #content></div>
</mat-menu>
