import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'

@Component({
    selector: 'app-board-new',
    templateUrl: './board-new.component.html',
    styleUrls: ['./board-new.component.sass'],
})
export class BoardNewComponent implements OnInit {
    @Input()
    place!: boolean
    @Input()
    folderName?: string

    upBoardItem: EventEmitter<any> = new EventEmitter()
    downBoardItem: EventEmitter<any> = new EventEmitter()
    @Output() saveBoardItem: EventEmitter<any> = new EventEmitter()
    @Output() nameFieldNotEmpty: EventEmitter<boolean> = new EventEmitter<boolean>()

    formGroup!: FormGroup<{
        endDate: FormControl<string | null>
        name: FormControl<string | null>
        startDate: FormControl<string | null>
    }>

    ngOnInit(): void {
        this.formGroup = new FormGroup({
            startDate: new FormControl<string>(''),
            endDate: new FormControl<string>(''),
            name: new FormControl<string>(''),
        })
    }

    saveBoardItemFn() {
        this.saveBoardItem.emit(String(this.formGroup.controls['name'].value))
    }

    cancelBoardItemFn() {
        this.saveBoardItem.emit('')
    }

    upBoardItemFn($event: any) {
        this.upBoardItem.emit($event)
    }

    downBoardItemFn($event: any) {
        this.downBoardItem.emit($event)
    }
}
