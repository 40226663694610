import { Component } from '@angular/core'
import { SystemRecordsErrorService } from '../@core/services/system-records-error.service'

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.sass'],
})
export class ErrorPageComponent {
    systemRecordsErrorMessages$ = this.systemRecordsErrorService.getApplicationErrorMessages()

    constructor(private systemRecordsErrorService: SystemRecordsErrorService) {}
}
