import { Component, OnInit } from '@angular/core'
import { AppState, selectSelectedView } from '../../../@core/@ngrx'
import { Store } from '@ngrx/store'
import { FilterGroup, View } from '../../../@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-manage-fields',
    templateUrl: './manage-fields.component.html',
})
export class ManageFieldsComponent {
    filterGroups!: FilterGroup[]
    selectedView!: View

    constructor(private store: Store<AppState>) {}

    get filterCountText() {
        let text: string = this.filterGroups ? this.filterGroups.length + ' groups' : ''
        if (this.filterGroups && this.filterGroups.length === 1) {
            if (this.filterGroups[0].values) {
                const keys = Object.keys(this.filterGroups[0].values)
                if (keys.length) {
                    text = keys.length.toString()
                }
            } else {
                text = '1'
            }
        }

        return 0
    }
}
