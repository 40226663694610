import { Component, Input } from '@angular/core'
import { UserModel } from '../../@core/models'
import { NavigatorService } from '../../@core/services/navigator.service'
import { WsProcessingService } from '../../@core/services'
import { CommonFacadeService } from '../../@core/services/store-facade'

@Component({
    selector: 'app-user-settings-content',
    templateUrl: './user-settings-content.component.html',
    styleUrls: ['./user-settings-content.component.sass'],
})
export class UserSettingsContentComponent {
    @Input()
    userInfo!: UserModel
    @Input()
    currentUserIsAdmin!: number | undefined

    constructor(
        private commonFacadeService: CommonFacadeService,
        private navigatorService: NavigatorService,
        private wsProcessingService: WsProcessingService,
    ) {}

    navigateNotifications() {
        this.navigatorService.goToNotificationPage()
    }

    logout() {
        this.wsProcessingService.logout()
    }

    resetSolution() {
        this.commonFacadeService.resetSolutionToTemplate()
    }
}
