import { Component, Input, OnInit } from '@angular/core'
import { AppRecord, FieldEntities, Folder, RecordUpdate, View } from '../../../@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { map } from 'rxjs/operators'
import { combineLatest } from 'rxjs'
import { RecordsService } from '../../../@core/services/records.service'
import {
    FieldTypeFacadeService,
    FolderFacadeService,
    SchemaFacadeService,
} from '../../../@core/services/store-facade'
import { getBoolean } from '../../../@core/global-util'

@UntilDestroy()
@Component({
    selector: 'app-show',
    templateUrl: './show.component.html',
    styleUrls: ['./show.component.sass'],
})
export class ShowComponent implements OnInit {
    @Input()
    selectedFolder!: Folder

    fieldTypes$ = this.fieldTypeFacadeService.selectAvailableFieldTypeByFolder$
    record!: AppRecord
    fields!: FieldEntities
    visibleFields: string[] = []
    hiddenFields: string[] = []

    _selectedView!: View

    @Input() set selectedView(value) {
        this._selectedView = value
        this.hiddenFields = this._selectedView.columns_hide.value.length
            ? this._selectedView.columns_hide.value.split(',')
            : []
        if (this.fields) {
            this.visibleFields = Object.keys(this.fields).filter(
                (field) => !this.hiddenFields.includes(field) && this.fields[field],
            )
        }
    }

    get selectedView() {
        return this._selectedView
    }

    constructor(
        private recordsService: RecordsService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private folderFacadeService: FolderFacadeService,
        private schemaFacadeService: SchemaFacadeService,
    ) {}

    ngOnInit(): void {
        combineLatest([
            this.schemaFacadeService.selectSelectedTableSchema$,
            this.folderFacadeService.selectSelectedFolder$,
        ])
            .pipe(
                untilDestroyed(this),
                map(([schema, folder]) => {
                    console.log('show component')
                    if (folder && schema) {
                        let sortedFields: FieldEntities = {}
                        Object.keys(schema.fieldEntities).forEach((guid) => {
                            if (schema && !getBoolean(folder.is_global.value)) {
                                if (
                                    schema.fieldEntities[guid].folder_guid === folder.guid ||
                                    schema.fieldEntities[guid].shared_with_folder?.includes(
                                        folder.guid,
                                    ) ||
                                    schema.fieldEntities[guid].folder_name?.is_global
                                ) {
                                    sortedFields[guid] = schema.fieldEntities[guid]
                                }
                            } else {
                                sortedFields[guid] = schema!.fieldEntities[guid]
                            }
                        })
                        return sortedFields
                    } else {
                        return schema?.fieldEntities
                    }
                }),
            )
            .subscribe((fields: FieldEntities | undefined) => {
                if (fields) {
                    this.fields = fields

                    this.visibleFields = Object.keys(this.fields).filter(
                        (field) => !this.hiddenFields.includes(field) && this.fields[field],
                    )
                }
            })
    }

    changeFieldState(guid: string, state: boolean) {
        const visibleArr = [...this.visibleFields]
        const hiddenArr = [...this.hiddenFields]

        if (state) {
            hiddenArr.splice(hiddenArr.indexOf(guid), 1)
            visibleArr.push(guid)
        } else {
            visibleArr.splice(visibleArr.indexOf(guid), 1)
            hiddenArr.push(guid)
        }
        console.log('checked', hiddenArr)
        this.visibleFields = visibleArr
        this.hiddenFields = hiddenArr
        console.log('this.hiddenFields', this.hiddenFields)
        console.log('this.visibleFields', this.visibleFields)
        const data: RecordUpdate = {
            record: this.selectedView,
            cell: this.selectedView.columns_hide,
            value: this.hiddenFields.join(','),
        }
        this.recordsService.updateRecord(data)
    }
}
